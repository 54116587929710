//create MAP Project
export const MAP_PROJECT_SAVE_DETAILS_FORM = "MAP_PROJECT_SAVE_DETAILS_FORM";
export const CREATE_MAP_PROJECT_REQUEST = "CREATE_MAP_PROJECT_REQUEST";
export const CREATE_MAP_PROJECT_SUCCESS = "CREATE_MAP_PROJECT_SUCCESS";
export const CREATE_MAP_PROJECT_FAILURE = "CREATE_MAP_PROJECT_FAILURE";
export const DISABLE_NEXT_STEP = "DISABLE_NEXT_STEP";
export const RESET_MAP_PROJECT_FORM = "RESET_MAP_PROJECT_FORM";
export const SET_MIGRATION_TAGS = "SET_MIGRATION_TAGS";
export const SET_MANAGEMENT_ACCOUNT = "SET_MANAGEMENT_ACCOUNT";
export const SET_TARGET_ACCOUNTS = "SET_TARGET_ACCOUNTS";
export const SET_BULK_TAG_RESOURCES_SUCCESS = "SET_BULK_TAG_RESOURCES_SUCCESS";
export const SET_BULK_TAG_RESOURCES_START = "SET_BULK_TAG_RESOURCES_START";
export const SET_BULK_TAG_RESOURCES_FAILURE = "SET_BULK_TAG_RESOURCES_FAILURE";
export const CLEAR_MAP_PROJECT_DETAILS = "CLEAR_MAP_PROJECT_DETAILS";

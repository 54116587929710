import { getCurrentDateTime } from "../../../utils/convert-date";
import {
  CLEAR_COST_POLICY_DATA,
  SAVE_ADVANCED_COST_OPTIONS,
  SAVE_COST_POLICY_BUDGET_AMOUNT_END_MONTH,
  SAVE_COST_POLICY_BUDGET_AMOUNT_START_MONTH,
  SAVE_COST_POLICY_BUDGET_AMOUNT_TYPE,
  SAVE_COST_POLICY_BUDGET_END_MONTH,
  SAVE_COST_POLICY_BUDGET_START_MONTH,
  SAVE_COST_POLICY_BUDGET_TYPE,
  SAVE_COST_POLICY_COST_AGGREGATION_TYPE,
  SAVE_COST_POLICY_DETAILS,
  SAVE_COST_POLICY_FIXED_BUDGET,
  SAVE_COST_POLICY_MONTHLY_BUDGETS,
  SAVE_COST_POLICY_PERIOD,
} from "../actionTypes/cost-policy";

const initialState = {
  policyData: {
    policyName: "",
    policyDescription: "",
  },
  policyPeriod: { id: "monthly", value: "Monthly", isSelected: false },
  policyPeriods: [
    {
      id: "monthly",
      value: "Monthly",
      isSelected: false,
    },
    {
      id: "quarterly",
      value: "Quarterly",
      isSelected: false,
    },
    {
      id: "annually",
      value: "Annually",
      isSelected: false,
    },
  ],
  policyBudgetType: "recurring",
  policyBudgetStartMonth: getCurrentDateTime(),
  policyBudgetEndMonth: getCurrentDateTime(),
  budgetAmountType: "fixed",
  budgetFixedAmount: "",
  budgetAmountStartMonth: getCurrentDateTime(),
  budgetAmountEndMonth: getCurrentDateTime(),
  monthlyBudgets: [],
  selectedCostAggregation: {
    id: "unblended",
    value: "Unblended Costs",
    isSelected: false,
  },
  costAggregations: [
    {
      id: "unblended",
      value: "Unblended Costs",
      isSelected: false,
    },
    {
      id: "amortized",
      value: "Amortized Costs",
      isSelected: false,
    },
    {
      id: "blended",
      value: "Blended Costs",
      isSelected: false,
    },
  ],

  selectedAdvancedOptions: [
    {
      id: "refunds",
      value: "Refunds",
      isSelected: false,
    },
    {
      id: "credits",
      value: "Credits",
      isSelected: false,
    },
    {
      id: "upfront-reservation-fees",
      value: "Upfront Reservation Fees",
      isSelected: false,
    },
    {
      id: "recurring-reservation-charges",
      value: "Recurring Reservation Charges",
      isSelected: false,
    },
    {
      id: "other-subscription-costs",
      value: "Other Subscription Costs",
      isSelected: false,
    },
    {
      id: "taxes",
      value: "Taxes",
      isSelected: false,
    },
    {
      id: "support-charges",
      value: "Support Charges",
      isSelected: false,
    },
  ],
};

const costPolicy = (state = initialState, action) => {
  switch (action.type) {
    // Policy Details

    case SAVE_COST_POLICY_DETAILS:
      return {
        ...state,
        policyData: action.payload,
      };

    // Policy Period

    case SAVE_COST_POLICY_PERIOD:
      return {
        ...state,
        policyPeriod: action.payload,
      };

    // Policy Budget Type

    case SAVE_COST_POLICY_BUDGET_TYPE:
      return {
        ...state,
        policyBudgetType: action.payload,
      };
    case SAVE_COST_POLICY_BUDGET_START_MONTH:
      return {
        ...state,
        policyBudgetStartMonth: new Date(action.payload),
      };
    case SAVE_COST_POLICY_BUDGET_END_MONTH:
      return {
        ...state,
        policyBudgetEndMonth: new Date(action.payload),
      };

    // Policy Budget Amount Type

    case SAVE_COST_POLICY_BUDGET_AMOUNT_TYPE:
      return {
        ...state,
        budgetAmountType: action.payload,
      };
    case SAVE_COST_POLICY_FIXED_BUDGET:
      return {
        ...state,
        budgetFixedAmount: action.payload,
      };
    case SAVE_COST_POLICY_BUDGET_AMOUNT_START_MONTH:
      return {
        ...state,
        budgetAmountStartMonth: new Date(action.payload),
      };
    case SAVE_COST_POLICY_BUDGET_AMOUNT_END_MONTH:
      return {
        ...state,
        budgetAmountEndMonth: new Date(action.payload),
      };
    case SAVE_COST_POLICY_MONTHLY_BUDGETS:
      return {
        ...state,
        monthlyBudgets: action.payload,
      };

    // Policy Advanced Options

    case SAVE_COST_POLICY_COST_AGGREGATION_TYPE:
      return {
        ...state,
        selectedCostAggregation: action.payload,
      };

    case SAVE_ADVANCED_COST_OPTIONS:
      return {
        ...state,
        selectedAdvancedOptions: action.payload,
      };

    // Default

    case CLEAR_COST_POLICY_DATA:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default costPolicy;

export const GET_HYBRID_ACT_ROLE_REQUEST = 'GET_HYBRID_ACT_ROLE_REQUEST';
export const GET_HYBRID_ACT_ROLE_SUCCESS = 'GET_HYBRID_ACT_ROLE_SUCCESS';
export const GET_HYBRID_ACT_ROLE_FAILURE = 'GET_HYBRID_ACT_ROLE_FAILURE';

export const GET_ACTIVATION_CODE_REQUEST = 'GET_ACTIVATION_CODE_REQUEST';
export const GET_ACTIVATION_CODE_SUCCESS = 'GET_ACTIVATION_CODE_SUCCESS';
export const GET_ACTIVATION_CODE_FAILURE = 'GET_ACTIVATION_CODE_FAILURE';

export const ACTIVATE_REGISTRATION_COUNT = 'ACTIVATE_REGISTRATION_COUNT';
export const ACTIVATE_DAYS_COUNT = 'ACTIVATE_DAYS_COUNT';

export const CLEAR_ACTIVATION_DATA = 'CLEAR_ACTIVATION_DATA';
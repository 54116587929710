import React from "react";
import MCButton from "../../../components/MCButton/MCButton";
import s from "./NewFirstRun.module.scss";
import classnames from "classnames";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { FIRST_RUN_COMPLETED } from "../../../utils/app-constants.json";
import {  useNavigate } from "react-router-dom";

export const FirstRunDone = ({ history }) => {
  const { t } = useTranslation(["firstRun"]);
  const navigate = useNavigate()

  const navigateToDashboard = () => {
    localStorage.setItem(FIRST_RUN_COMPLETED, true);
    navigate("/app")
  };

  const username = localStorage.getItem("username");

  return (
    <Row>
      <Col xs={12} className="text-center mt-5 pt-5">
        <div>
          <img loading="lazy"
            className={s.MCLogo}
            src="/images/DAY2Logo.jpg"
            alt="MontyCloud"
          />
        </div>

        <h2 className={classnames(s.WelcomeText)}>
          {t("congratulations")},&nbsp;
          <span className="orange-text">{username}</span>
        </h2>

        <MCButton
          size="lg"
          text={t("takeMeToDashboard")}
          color="mc-blue"
          onClick={navigateToDashboard}
          className={classnames("mt-5", s.robotoFont)}
        />
      </Col>
    </Row>
  );
};

export default FirstRunDone;

import {
  ATTACH_POLICY_REQUEST,
  ATTACH_POLICY_SUCCESS,
  ATTACH_POLICY_FAILURE,
  GET_ATTACHED_POLICIES_REQUEST,
  GET_ATTACHED_POLICIES_SUCCESS,
  GET_ATTACHED_POLICIES_FAILURE,
  CLEAR_ATTACH_POLICY_DATA
  
} from "../actionTypes/policy";

const initialState = {
  attachingPolicy: false,
  fetchingPolicies: false,
  policyList: [],
  policyUpdated: false
};

const attachPolicy = (state = initialState, action) => {
  switch (action.type) {
    case ATTACH_POLICY_REQUEST:
      return {
        ...state,
        attachingPolicy: true,
        policyUpdated: initialState.policyUpdated
      };
    case ATTACH_POLICY_SUCCESS:
      return {
        ...state,
        attachingPolicy: false,
        policyUpdated: true
      };
    case ATTACH_POLICY_FAILURE:
      return {
        ...state,
        attachingPolicy: false,
        errorMessage: action.payload
      };
    case GET_ATTACHED_POLICIES_REQUEST:
      return {
        ...state,
        fetchingPolicies: true,
        policyList: initialState.policyList
      };
    case GET_ATTACHED_POLICIES_SUCCESS:
      return {
        ...state,
        fetchingPolicies: false,
        policyList: action.payload
      };
    case GET_ATTACHED_POLICIES_FAILURE:
      return {
        ...state,
        fetchingPolicies: false,
        errorMessage: action.payload
      };
    case CLEAR_ATTACH_POLICY_DATA:
      return {
        ...state,
        ...initialState,
        policyList: state.policyList
      };
    default:
      return state;
  }
};

export default attachPolicy;

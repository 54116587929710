import {
  GET_DESIRED_STATE_POLICIES_REQUEST,
  GET_DESIRED_STATE_POLICIES_SUCCESS,
  GET_DESIRED_STATE_POLICIES_FAILURE,
} from "../actionTypes/desired-state-policies";

const initialState = {
  isFetching: false,
  desiredStatePolicyList: null,
  showDesiredStatePolicyList: true,
};

const desiredStatePolicies = (state = initialState, action) => {
  switch (action.type) {
    case GET_DESIRED_STATE_POLICIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        desiredStatePolicyList: initialState.desiredStatePolicyList,
      };
    case GET_DESIRED_STATE_POLICIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        desiredStatePolicyList: action.payload,
        showDesiredStatePolicyList: true,
      };
    case GET_DESIRED_STATE_POLICIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default desiredStatePolicies;

export const CREATE_SERVER_GROUP_REQUEST = "CREATE_SERVER_GROUP_REQUEST";
export const CREATE_SERVER_GROUP_SUCCESS = "CREATE_SERVER_GROUP_SUCCESS";
export const CREATE_SERVER_GROUP_FAILURE = "CREATE_SERVER_GROUP_FAILURE";
export const SAVE_SERVER_GROUP_DETAILS = "SAVE_SERVER_GROUP_DETAILS";
export const RESET_SERVER_GROUP_DETAILS = "RESET_SERVER_GROUP_DETAILS";
export const GET_SERVER_GROUP_DETAILS_REQUEST =
  "GET_SERVER_GROUP_DETAILS_REQUEST";
export const GET_SERVER_GROUP_DETAILS_SUCCESS =
  "GET_SERVER_GROUP_DETAILS_SUCCESS";
export const GET_SERVER_GROUP_DETAILS_FAILURE =
  "GET_SERVER_GROUP_DETAILS_FAILURE";
export const EDIT_SERVER_GROUP_REQUEST = "EDIT_SERVER_GROUP_REQUEST";
export const EDIT_SERVER_GROUP_SUCCESS = "EDIT_SERVER_GROUP_SUCCESS";
export const EDIT_SERVER_GROUP_FAILURE = "EDIT_SERVER_GROUP_FAILURE";
export const DELETE_SERVER_GROUP_REQUEST = "DELETE_SERVER_GROUP_REQUEST";
export const DELETE_SERVER_GROUP_SUCCESS = "DELETE_SERVER_GROUP_SUCCESS";
export const DELETE_SERVER_GROUP_FAILURE = "DELETE_SERVER_GROUP_FAILURE";

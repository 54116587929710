import { useEffect, useRef } from "react";

export const useOnUnmount = (callback, dependencies) => {
  const isUnmounting = useRef(false);

  useEffect(() => () => (isUnmounting.current = true), []);

  useEffect(
    () => () => {
      if (isUnmounting.current) {
        callback();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies
  );
};

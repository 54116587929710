import i18n from "../../config/locale/i18n";
import { FEATURE_PLANS } from "../../utils/app-constants.json";
import { permissionsLink } from "../../utils/app-links";

export const configureAzureSubscriptionStates = {
  SHOW_ONBOARDING_SCRIPT: "SHOW_ONBOARDING_SCRIPT",
  SHOW_SCRIPT_EXECUTION_MESSAGE: "SHOW_SCRIPT_EXECUTION_MESSAGE",
  SHOW_SUBSCRIPTION_CONNECTION_FAILURE: "SHOW_SUBSCRIPTION_CONNECTION_FAILURE",
  SHOW_SET_SUBSCRIPTION_NAME: "SHOW_SET_SUBSCRIPTION_NAME",
};

export const continuousVisibilityPlan = {
  title: i18n.t("firstRun:continuousVisibility.title"),
  type: FEATURE_PLANS.CONTINUOUS_VISIBILITY,
  features: [
    i18n.t("firstRun:continuousVisibility.feature.inventoryTagVisibility"),
    i18n.t("firstRun:continuousVisibility.feature.cloudBillsCostSavings"),
    i18n.t("firstRun:continuousVisibility.feature.securityCompliancePosture"),
    i18n.t("firstRun:continuousVisibility.feature.wellArchitectedAssessments"),
    i18n.t("firstRun:continuousVisibility.feature.reports"),
    i18n.t("firstRun:continuousVisibility.feature.mapProjects"),
  ],
  featurePlan: FEATURE_PLANS.CONTINUOUS_VISIBILITY,
  permissionLink: permissionsLink,
  isAdmin: false,
  badgeText: i18n.t("common:basicPermission"),
  badgeColor: "success",
};

export const automatedCloudOpsPlan = {
  title: i18n.t("firstRun:automatedCloudOps.title"),
  type: FEATURE_PLANS.AUTOMATED_CLOUD_OPS,
  features: [
    i18n.t("firstRun:automatedCloudOps.feature.continousVisibility"),
    i18n.t("firstRun:automatedCloudOps.feature.wellArchitectedBlueprints"),
    i18n.t(
      "firstRun:automatedCloudOps.feature.selfserviceprovisioninggovernance"
    ),
    i18n.t("firstRun:automatedCloudOps.feature.WellManagedEnvironments"),
    i18n.t("firstRun:automatedCloudOps.feature.RemediationPlaybooks"),
  ],
  featurePlan: FEATURE_PLANS.AUTOMATED_CLOUD_OPS,
  permissionLink: permissionsLink,
  isAdmin: true,
  badgeText: i18n.t("common:advancedPermissions"),
  badgeColor: "warning",
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);

export const ADD_NEW_STEP = "ADD_NEW_STEP";
export const SAVE_SELECTED_TASK = "SAVE_SELECTED_TASK";
export const SAVE_SCHEDULE_DATA = "SAVE_SCHEDULE_DATA";
export const SET_APP_DATA = "SET_APP_DATA";
export const SET_ENV_DATA = "SET_ENV_DATA";
export const SET_SELECTED_ACCOUNT = "SET_SELECTED_ACCOUNT";
export const SET_SELECTED_REGION = "SET_SELECTED_REGION";
export const SET_SELECTED_REGIONS = "SET_SELECTED_REGIONS";
export const SAVE_SELECT_ALL_REGIONS = "SAVE_SELECT_ALL_REGIONS";
export const SET_ROLE_ARN = "SET_ROLE_ARN";
export const SAVE_SELECTED_TARGET = "SAVE_SELECTED_TARGET";
export const SAVE_SELECTED_INSTANCES = "SAVE_SELECTED_INSTANCES";
export const SAVE_INSTANCE_STATE_IS_DIRTY = "SAVE_INSTANCE_STATE_IS_DIRTY";
export const SAVE_SELECTED_BLUEPRINT = "SAVE_SELECTED_BLUEPRINT";
export const SAVE_SELECTED_VOLUMES = "SAVE_SELECTED_VOLUMES";
export const SAVE_SNAPSHOT_DESCRIPTION = "SAVE_SNAPSHOT_DESCRIPTION";
export const SAVE_PATCH_TYPE = "SAVE_PATCH_TYPE";
export const SAVE_PATCH_VOLUME_TYPE = "SAVE_PATCH_VOLUME_TYPE";
export const SAVE_TASK_TAGS = "SAVE_TASK_TAGS";
export const CLEAR_TASK_TAGS = "CLEAR_TASK_TAGS";
export const SET_PARAMETERS = "SET_PARAMETERS";
export const SET_APP_ACTION_PARAMETERS = "SET_APP_ACTION_PARAMETERS";
export const SAVE_SCHEDULE_TYPE = "SAVE_SCHEDULE_TYPE";
export const SAVE_DATE_TIME = "SAVE_DATE_TIME";
export const SAVE_CRON_EXPRESSION = "SAVE_CRON_EXPRESSION";
export const SAVE_PATCH_POLICY = "SAVE_PATCH_POLICY";
export const SAVE_SERVER_GROUP = "SAVE_SERVER_GROUP";
export const CREATE_SCHEDULE_REQUEST = "CREATE_SCHEDULE_REQUEST";
export const CREATE_SCHEDULE_SUCCESS = "CREATE_SCHEDULE_SUCCESS";
export const CREATE_SCHEDULE_FAILURE = "CREATE_SCHEDULE_FAILURE";
export const CLEAR_TASK_DATA = "CLEAR_TASK_DATA";
export const DELETE_SCHEDULE_REQUEST = "DELETE_SCHEDULE_REQUEST";
export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS";
export const DELETE_SCHEDULE_FAILURE = "DELETE_SCHEDULE_FAILURE";
export const CLEAR_DELETE_SCHEDULE_DATA = "CLEAR_DELETE_SCHEDULE_DATA";
export const GET_SCHEDULE_REQUEST = "GET_SCHEDULE_REQUEST";
export const GET_SCHEDULE_SUCCESS = "GET_SCHEDULE_SUCCESS";
export const GET_SCHEDULE_FAILURE = "GET_SCHEDULE_FAILURE";
export const SET_NEXT_STEP_STATE = "SET_NEXT_STEP_STATE";
export const EDIT_SCHEDULE_REQUEST = "EDIT_SCHEDULE_REQUEST";
export const EDIT_SCHEDULE_SUCCESS = "EDIT_SCHEDULE_SUCCESS";
export const EDIT_SCHEDULE_FAILURE = "EDIT_SCHEDULE_FAILURE";
export const SAVE_SECURITY_POLICY = "SAVE_SECURITY_POLICY";
export const SAVE_COST_POLICY = "SAVE_COST_POLICY";
export const SET_BLUEPRINT_CAPABILITIES = "SET_BLUEPRINT_CAPABILITIES";
export const SCHEDULE_STATUS_REQUEST = "SCHEDULE_STATUS_REQUEST";
export const SCHEDULE_STATUS_SUCCESS = "SCHEDULE_STATUS_SUCCESS";
export const SCHEDULE_STATUS_FAILURE = "SCHEDULE_STATUS_FAILURE";
export const REMOVE_TASK_DATA_CLEAR_STATE = "REMOVE_TASK_DATA_CLEAR_STATE";
export const SET_CURRENT_APP_ID = "SET_CURRENT_APP_ID";
export const SET_ENABLE_MONITORING = "SET_ENABLE_MONITORING";
export const SAVE_SELECTED_SCRIPT = "SAVE_SELECTED_SCRIPT";
export const SAVE_SCRIPT_ARGUMENTS = "SAVE_SCRIPT_ARGUMENTS";
export const SAVE_SCRIPT_IAM_ROLE = " SAVE_SCRIPT_IAM_ROLE";
export const SAVE_SCRIPT_HANDLER_NAME = "SAVE_SCRIPT_HANDLER_NAME";
export const CLEAR_PARAMETER_DATA = "CLEAR_PARAMETER_DATA";
export const SAVE_APPLICATIONS = "SAVE_APPLICATIONS";
export const CLEAR_SCRIPT_HANDLER_NAME = "CLEAR_SCRIPT_HANDLER_NAME";
export const SAVE_SNAPSHOT = "SAVE_SNAPSHOT";
export const SAVE_SELECTED_WORKSPACES = "SAVE_SELECTED_WORKSPACES";
export const SAVE_SELECTED_NOTEBOOK_INSTANCES =
  "SAVE_SELECTED_NOTEBOOK_INSTANCES";
export const SET_REBOOT_INSTANCE = "SET_REBOOT_INSTANCE";
export const SAVE_SELECTED_INSTANCE_TYPE = "SAVE_SELECTED_INSTANCE_TYPE";
export const CLEAR_DATE_TIME = "CLEAR_DATE_TIME";
export const CLEAR_CRON_EXPRESSION = "CLEAR_CRON_EXPRESSION";

export const SET_IS_EXISTING_VPC_ON = "SET_IS_EXISTING_VPC_ON";
export const SET_VPC_DROPDOWN_OPTION = "SET_VPC_DROPDOWN_OPTION";
export const SET_VPC_DROPDOWN_ITEM = "SET_VPC_DROPDOWN_ITEM";
export const RESET_VPC_CONFIG = "RESET_VPC_CONFIG";
import i18n from "../../config/locale/i18n";
import {
  areDateEqual,
  getDate,
  isFirstDateBeforeSecondDate,
  isFirstDateGreaterThanSecondDate,
} from "../../utils/convert-date";
import {
  OrgDetailFormatter,
  orgActionsFormatter,
  orgDateFormatter,
  orgIconFormatter,
  orgOwnerDetailFormatter,
  orgfeatureFormatter,
} from "./utils/columnUtils";

export const organisationDetailsFormFields = ({
  form,
  portalUsersList,
  tenantCategoryList = [],
}) => {
  return [
    {
      type: "text",
      label: i18n.t("multiTenancy:list.OrgName"),
      id: "form-Name",
      key: "Name",
      required: true,
      value: form.Name,
      minLength: 2,
      maxLength: 50,
      helpText: i18n.t("multiTenancy:text.nameOrg"),
    },
    {
      type: "textarea",
      label: i18n.t("multiTenancy:list.Description"),
      id: "form-Description",
      key: "Description",
      required: true,
      value: form.Description,
      minLength: 2,
      helpText: i18n.t("multiTenancy:text.describeOrg"),
    },
    {
      type: "select",
      label: i18n.t("multiTenancy:list.Owner"),
      id: "form-Owner",
      key: "Owner",
      required: true,
      options: portalUsersList,
      value: form.Owner,
      placeholder: i18n.t("multiTenancy:selectUser"),
      extraStyles: { width: "100%", minWidth: 400 },
      helpText: i18n.t("multiTenancy:text.orgOwner"),
    },
    {
      type: "select",
      label: i18n.t("multiTenancy:list.Category"),
      id: "form-Category",
      key: "CategoryId",
      required: false,
      options: tenantCategoryList,
      value: form.Category,
      placeholder: i18n.t("multiTenancy:selectCategory"),
      extraStyles: { width: "100%", minWidth: 400 },
      helpText: i18n.t("multiTenancy:text.category"),
    },
  ];
};

export const orgListColumns = [
  {
    headerName: "",
    cellRenderer: orgIconFormatter,
    maxWidth: 25,
    cellStyle: { display: "flex", justifyContent: "center" },
    filter: false,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
  },
  {
    field: "Name",
    headerName: i18n.t("multiTenancy:grid.orgDetail"),
    cellRenderer: OrgDetailFormatter,
    autoHeight: true,
    filter: "agTextColumnFilter",
    suppressColumnsToolPanel: true,
  },
  {
    field: "OwnerName",
    headerName: i18n.t("multiTenancy:list.Owner"),
    cellRenderer: orgOwnerDetailFormatter,
    filter: "agTextColumnFilter",
    maxWidth: 250,
    minWidth: 235,
  },
  {
    field: "Category",
    headerName: i18n.t("multiTenancy:list.Category"),
    filter: "agTextColumnFilter",
    maxWidth: 250,
    minWidth: 235,
  },
  {
    field: "Feature",
    headerName: i18n.t("multiTenancy:list.feature"),
    cellRenderer: orgfeatureFormatter,
    filter: "agTextColumnFilter",
    maxWidth: 200,
    minWidth: 150,
  },

  {
    field: "CreatedOn",
    headerName: i18n.t("common:createdOn"),
    cellRenderer: orgDateFormatter,
    filter: "agDateColumnFilter",
    cellStyle: { display: "flex", alignItems: "center" },
    maxWidth: 150,
    filterParams: {
      inRangeInclusive: true,
      comparator: function (filterLocalDate, cellValue) {
        filterLocalDate = getDate(filterLocalDate);
        cellValue = getDate(cellValue);

        if (areDateEqual(filterLocalDate, cellValue)) {
          return 0;
        }

        if (isFirstDateBeforeSecondDate(filterLocalDate, cellValue)) {
          return 1;
        }

        if (isFirstDateGreaterThanSecondDate(filterLocalDate, cellValue)) {
          return -1;
        }
      },
    },
  },
  {
    headerName: i18n.t("common:actions"),
    autoHeight: true,
    cellRenderer: orgActionsFormatter,
    cellStyle: { display: "flex", alignItems: "center" },
    filter: false,
    maxWidth: 150,
    sortable: false,
    suppressMovable: true,
    suppressMenu: true,
    pinned: "right",
    suppressColumnsToolPanel: true,
  },
];

export const ORG_DETAILS_STEP_INDEX = 1;
export const FEATURE_SELECTION_STEP_INDEX = 2;

export const ORG_FEATURE_LAYOUT_TYPE = {
  FRE: "FRE",
  CREATE_ORG: "CREATE_ORG",
};

import {
  GET_HYBRID_ACT_ROLE_REQUEST,
  GET_HYBRID_ACT_ROLE_SUCCESS,
  GET_HYBRID_ACT_ROLE_FAILURE,
  GET_ACTIVATION_CODE_REQUEST,
  GET_ACTIVATION_CODE_SUCCESS,
  GET_ACTIVATION_CODE_FAILURE,
  ACTIVATE_REGISTRATION_COUNT,
  ACTIVATE_DAYS_COUNT,
  CLEAR_ACTIVATION_DATA,
} from "../actionTypes/activate-onprem";

const initialState = {
  isFetchingRoleDetails: false,
  hybridActivationRoleDetails: null,
  hybridActivationRoleDetailsFailed: null,
  failedToFetchRoleDetails: false,
  isFetchingActivationCode: false,
  activationCodeDetails: null,
  activationCodeFailed: null,
  activationCodeGenerated: false,
  failedToFetchActivationCodeDetails: false,
  activateRegistrationCount: 100,
  activateDaysCount: 29,
};

const activateOnprem = (state = initialState, action) => {
  switch (action.type) {
    case GET_HYBRID_ACT_ROLE_REQUEST:
      return {
        ...state,
        isFetchingRoleDetails: true,
        hybridActivationRoleDetails: initialState.HybridActivationRoleDetails,
        hybridActivationRoleDetailsFailed:
          initialState.HybridActivationRoleDetailsFailed,
        failedToFetchRoleDetails: initialState.failedToFetchRoleDetails,
      };
    case GET_HYBRID_ACT_ROLE_SUCCESS:
      return {
        ...state,
        isFetchingRoleDetails: false,
        hybridActivationRoleDetails: action.payload,
      };
    case GET_HYBRID_ACT_ROLE_FAILURE:
      return {
        ...state,
        isFetchingRoleDetails: false,
        failedToFetchRoleDetails: true,
        hybridActivationRoleDetailsFailed: action.payload,
      };
    case GET_ACTIVATION_CODE_REQUEST:
      return {
        ...state,
        isFetchingActivationCode: true,
        activationCodeDetails: initialState.activationCodeDetails,
        failedToFetchActivationCodeDetails:
          initialState.failedToFetchActivationCodeDetails,
        activationCodeFailed: initialState.activationCodeFailed,
        activationCodeGenerated: initialState.activationCodeGenerated,
      };
    case GET_ACTIVATION_CODE_SUCCESS:
      return {
        ...state,
        isFetchingActivationCode: false,
        activationCodeGenerated: true,
        activationCodeDetails: action.payload,
      };
    case GET_ACTIVATION_CODE_FAILURE:
      return {
        ...state,
        isFetchingActivationCode: false,
        failedToFetchActivationCodeDetails: true,
        activationCodeFailed: action.payload,
      };
    case ACTIVATE_DAYS_COUNT:
      return {
        ...state,
        activateDaysCount: action.payload,
      };
    case ACTIVATE_REGISTRATION_COUNT:
      return {
        ...state,
        activateRegistrationCount: action.payload,
      };
    case CLEAR_ACTIVATION_DATA:
      return {
        ...state,
        isFetchingRoleDetails: initialState.isFetchingRoleDetails,
        hybridActivationRoleDetails: initialState.HybridActivationRoleDetails,
        hybridActivationRoleDetailsFailed:
          initialState.HybridActivationRoleDetailsFailed,
        failedToFetchRoleDetails: initialState.failedToFetchRoleDetails,
        activateDaysCount: initialState.activateDaysCount,
        activateRegistrationCount: initialState.activateRegistrationCount,
      };
    default:
      return state;
  }
};

export default activateOnprem;

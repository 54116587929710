export const GET_ENVIRONMENT_DETAILS = "GET_ENVIRONMENT_DETAILS";
export const ADD_RESOURCES_ENVIRONMENT = "ADD_RESOURCES_ENVIRONMENT";
export const SET_ENVIRONMENT_NAME = "SET_ENVIRONMENT_NAME";
export const SAVE_ENVIRONMENT = "SAVE_ENVIRONMENT";
export const GET_ENVIRONMENTS_REQUEST = "GET_ENVIRONMENTS_REQUEST";
export const GET_ENVIRONMENTS_SUCCESS = "GET_ENVIRONMENTS_SUCCESS";
export const GET_ENVIRONMENTS_FAILURE = "GET_ENVIRONMENTS_FAILURE";
export const CREATE_ENVIRONMENT_REQUEST = "CREATE_ENVIRONMENT_REQUEST";
export const CREATE_ENVIRONMENT_SUCCESS = "CREATE_ENVIRONMENT_SUCCESS";
export const CREATE_ENVIRONMENT_FAILURE = "CREATE_ENVIRONMENT_FAILURE";
export const CLEAR_ENVIRONMENT_DATA = "CLEAR_ENVIRONMENT_DATA";
export const GET_ENVIRONMENT_RESOURCES_REQUEST =
  "GET_ENVIRONMENT_RESOURCES_REQUEST";
export const GET_ENVIRONMENT_RESOURCES_SUCCESS =
  "GET_ENVIRONMENT_RESOURCES_SUCCESS";
export const GET_ENVIRONMENT_RESOURCES_FAILURE =
  "GET_ENVIRONMENT_RESOURCES_FAILURE";
export const ADD_ENVIRONMENT_RESOURCES_REQUEST =
  "ADD_ENVIRONMENT_RESOURCES_REQUEST";
export const ADD_ENVIRONMENT_RESOURCES_SUCCESS =
  "ADD_ENVIRONMENT_RESOURCES_SUCCESS";
export const ADD_ENVIRONMENT_RESOURCES_FAILURE =
  "ADD_ENVIRONMENT_RESOURCES_FAILURE";

export const GET_ENVIRONMENT_RESOURCES_SUMMARY_REQUEST =
  "GET_ENVIRONMENT_RESOURCES_SUMMARY_REQUEST";
export const GET_ENVIRONMENT_RESOURCES_SUMMARY_SUCCESS =
  "GET_ENVIRONMENT_RESOURCES_SUMMARY_SUCCESS";
export const GET_ENVIRONMENT_RESOURCES_SUMMARY_FAILURE =
  "GET_ENVIRONMENT_RESOURCES_SUMMARY_FAILURE";
export const CLEAR_ENVIRONMENT_SUMMARY = "CLEAR_ENVIRONMENT_SUMMARY";

import {
  GET_ALL_TASKS_REQUEST,
  GET_ALL_TASKS_SUCCESS,
  GET_ALL_TASKS_FAILURE,
  SET_SELECTED_TASK_FOR_LAUNCH,
  GET_TASK_DETAILS_REQUEST,
  GET_TASK_DETAILS_SUCCESS,
  GET_TASK_DETAILS_FAILURE,
  CLEAR_SELECTED_TASK,
  CLEAR_ADMIN_TASKS_DATA,
} from "../actionTypes/tasks";

const initialState = {
  tasksLoaded: false,
  isTasksLoading: false,
  taskList: [],
  loadingTasksFailed: false,
  selectedTaskForLaunch: null,
  isFetchingTaskDetails: false,
  taskDetailsLoaded: false,
  taskDetails: null,
  fetchingTaskFailed: false,
};

const adminTasks = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TASKS_REQUEST:
      return {
        ...state,
        isTasksLoading: true,
        tasksLoaded: false,
        loadingTasksFailed: false,
      };
    case GET_ALL_TASKS_SUCCESS:
      return {
        ...state,
        isTasksLoading: false,
        tasksLoaded: true,
        taskList: action.payload,
      };
    case GET_ALL_TASKS_FAILURE:
      return {
        ...state,
        isTasksLoading: false,
        tasksLoaded: true,
        loadingTasksFailed: true,
      };
    case SET_SELECTED_TASK_FOR_LAUNCH:
      return {
        ...state,
        selectedTaskForLaunch: action.payload,
      };
    case GET_TASK_DETAILS_REQUEST:
      return {
        ...state,
        isFetchingTaskDetails: true,
        taskDetails: initialState.taskDetails,
        taskDetailsLoaded: initialState.taskDetailsLoaded,
        fetchingTaskFailed: initialState.fetchingTaskFailed,
      };
    case GET_TASK_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingTaskDetails: false,
        taskDetailsLoaded: true,
        taskDetails: action.payload,
      };
    case GET_TASK_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingTaskDetails: false,
        fetchingTaskFailed: true,
      };
    case CLEAR_SELECTED_TASK:
      return {
        ...state,
        isFetchingTaskDetails: initialState.isFetchingTaskDetails,
        taskDetailsLoaded: initialState.taskDetailsLoaded,
        taskDetails: initialState.taskDetails,
      };
    case CLEAR_ADMIN_TASKS_DATA:
      return {
        ...state,
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default adminTasks;

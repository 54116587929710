export const SET_PATCH_POLICY_CONFIG = "SET_PATCH_POLICY_INITIAL_STATE";

//HEADER FIELDS
export const PATCH_POLICY_NAME_CHANGE = "PATCH_POLICY_NAME_CHANGE";
export const PATCH_POLCIY_DESCRIPTION_CHANGE =
  "PATCH_POLCIY_DESCRIPTION_CHANGE";
export const PATCH_POLCY_OPERATIONSYSTEM_CHANGE =
  "PATCH_POLCY_OPERATIONSYSTEM_CHANGE";
export const PATCH_POLICY_ACCOUNTS_CHANGE = "PATCH_POLICY_ACCOUNTS_CHANGE";
export const PATCH_POLICY_REGIONS_CHANGE = "PATCH_POLICY_REGIONS_CHANGE";

//APPROVAL RULES
export const APPROVAL_RULES_PRODUCT_SELECT = "APPROVAL_RULES_PRODUCT_SELECT";
export const APPROVAL_RULES_CLASSIFICATION_SELECT =
  "APPROVAL_RULES_CLASSIFICATION_SELECT";
export const APPROVAL_RULES_SEVERITY_SELECT = "APPROVAL_RULES_SEVERITY_SELECT";
export const APPROVAL_RULES_COMPLIANCE_SELECT =
  "APPROVAL_RULES_COMPLIANCE_SELECT";
export const APPROVAL_RULES_SECTION_SELECT = "APPROVAL_RULES_SECTION_SELECT";
export const APPROVAL_RULES_PRIORITY_SELECT = "APPROVAL_RULES_PRIORITY_SELECT";
export const APPROVAL_RULES_AUTO_APPROVALDAYS_CHANGE =
  "APPROVAL_RULES_AUTO_APPROVALDAYS_CHANGE";
export const APPROVAL_RULES_INCLUDE_NON_SECURITY_CHANGE =
  "APPROVAL_RULES_INCLUDE_NON_SECURITY_CHANGE";

export const ADD_APPROVAL_RULE = "ADD_APPROVAL_RULE";
export const REMOVE_APPROVAL_RULE = "REMOVE_APPROVAL_RULE";

//PATCH EXCEPTIONS
export const PATCH_EXCEPTIONS_APPROVED_PATCHES_CHANGE =
  "PATCH_EXCEPTIONS_APPROVED_PATCHES_CHANGE";
export const PATCH_EXCEPTIONS_REJECTED_PATCHES_CHANGE =
  "PATCH_EXCEPTIONS_REJECTED_PATCHES_CHANGE";
export const PATCH_EXCEPTIONS_REJECTED_PATCHES_ACTION_CHANGE =
  "PATCH_EXCEPTIONS_REJECTED_PATCHES_ACTION_CHANGE";
export const PATCH_EXCEPTIONS_COMPLIANCE_SELECT =
  "PATCH_EXCEPTIONS_COMPLIANCE_SELECT";
export const PATCH_EXCEPTIONS_INCLUDE_NON_SECURITY_CHANGE =
  "PATCH_EXCEPTIONS_INCLUDE_NON_SECURITY_CHANGE";

//PATCH SOURCES
export const PATCH_SOURCES_NAME_CHANGE = "PATCH_SOURCES_NAME_CHANGE";
export const PATCH_SOURCES_PRODUCT_SELECT = "PATCH_SOURCES_PRODUCT_SELECT";
export const PATCH_SOURCES_CONFIGURATION_CHANGE =
  "PATCH_SOURCES_CONFIGURATION_CHANGE";
export const PATCH_SOURCES_ACCOUNTS_CHANGE = "PATCH_SOURCES_ACCOUNTS_CHANGE";
export const PATCH_SOURCES_REGIONS_CHANGE = "PATCH_SOURCES_REGIONS_CHANGE";

export const ADD_PATCH_SOURCE = "ADD_PATCH_SOURCE";
export const REMOVE_PATCH_SOURCE = "REMOVE_PATCH_SOURCE";
export const SET_PATCH_SOURCE = "SET_PATCH_SOURCE";

//SAVE PATCH POLICY
export const SAVE_PATCH_POLICY_REQUEST = "SAVE_PATCH_POLICY_REQUEST";
export const SAVE_PATCH_POLICY_SUCCESS = "SAVE_PATCH_POLICY_SUCCESS";
export const SAVE_PATCH_POLICY_FAILURE = "SAVE_PATCH_POLICY_FAILURE";
export const PATCH_POLICY_RESET_FORM_FIELDS = "PATCH_POLICY_RESET_FORM_FIELDS";
export const PATCH_POLICY_RESET_SAVE_DATA = "PATCH_POLICY_RESET_SAVE_DATA";

//DELETE PATCH POLICY
export const DELETE_PATCH_POLICY_REQUEST = "DELETE_PATCH_POLICY_REQUEST";
export const DELETE_PATCH_POLICY_SUCCESS = "DELETE_PATCH_POLICY_SUCCESS";
export const DELETE_PATCH_POLICY_FAILURE = "DELETE_PATCH_POLICY_FAILURE";

// GET PATCH POLCY DETAILS
export const GET_PATCH_POLICY_DETAIL_REQUEST =
  "GET_PATCH_POLICY_DETAIL_REQUEST";
export const GET_PATCH_POLICY_DETAIL_SUCCESS =
  "GET_PATCH_POLICY_DETAIL_SUCCESS";
export const GET_PATCH_POLICY_DETAIL_FAILURE =
  "GET_PATCH_POLICY_DETAIL_FAILURE";

export const SET_NEXT_STEP_STATE = "SET_NEXT_STEP_STATE";

import {
  CLEAR_MAP_PROJECT_DETAILS,
  SET_BULK_TAG_RESOURCES_FAILURE,
  SET_BULK_TAG_RESOURCES_START,
  SET_BULK_TAG_RESOURCES_SUCCESS,
} from "../actionTypes/details";
import {
  MAP_PROJECT_DETAILS_FAILURE,
  MAP_PROJECT_DETAILS_REQUEST,
  MAP_PROJECT_DETAILS_SUCCESS,
  MAP_PROJECT_HIDE_FIRST_RUN_POPUP,
  MAP_PROJECT_SHOW_FIRST_RUN_POPUP,
  SET_MAP_PROJECT_COMPLETED,
} from "../actionTypes/mapProjectDetails";

const initialState = {
  name: "",
  mpeNumber: "",
  tagList: [],
  startDate: "",
  endDate: "",
  scope: [],
  taggedResourceCost: "",
  unTaggedResourceCost: "",
  mapCreditsDisbursed: "",
  totalWorkloadSpend: "",
  resources: [],
  isFetchingDetails: false,
  showFirstRunPopUp: false,
  bulkTagsResources: [],
  bulkTaggingInitiated: false,
  costLastUpdatedDate: null,
  mapProjectDetailsFailed: false,
};

export const mapProjectDetails = (state = initialState, action) => {
  switch (action.type) {
    case MAP_PROJECT_DETAILS_REQUEST: {
      return {
        ...state,
        isFetchingDetails: true,
      };
    }
    case MAP_PROJECT_DETAILS_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isFetchingDetails: false,
        mapProjectDetailsFailed: false,
      };
    }
    case MAP_PROJECT_DETAILS_FAILURE: {
      return {
        ...state,
        mapProjectDetailsFailed: true,
        isFetchingDetails: false,
      };
    }
    case MAP_PROJECT_SHOW_FIRST_RUN_POPUP: {
      return {
        ...state,
        showFirstRunPopUp: true,
      };
    }
    case MAP_PROJECT_HIDE_FIRST_RUN_POPUP: {
      return {
        ...state,
        showFirstRunPopUp: false,
      };
    }
    case SET_MAP_PROJECT_COMPLETED: {
      return {
        ...state,
        isProjectCompleted: true,
      };
    }
    case SET_BULK_TAG_RESOURCES_START: {
      return {
        ...state,
        bulkTaggingInitiated: true,
      };
    }
    case SET_BULK_TAG_RESOURCES_SUCCESS: {
      return {
        ...state,
        bulkTagsResources: action.payload,
        bulkTaggingInitiated: false,
      };
    }
    case SET_BULK_TAG_RESOURCES_FAILURE: {
      return {
        ...state,
        bulkTaggingInitiated: false,
      };
    }
    case CLEAR_MAP_PROJECT_DETAILS: {
      return {
        ...state,
        ...initialState,
      };
    }
    // TODO : Add clear logic
    default:
      return { ...state };
  }
};

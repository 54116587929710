import {
  MOVE_AWS_ACCOUNT_REQUEST,
  MOVE_AWS_ACCOUNT_SUCCESS,
  MOVE_AWS_ACCOUNT_FAILED,
  GET_CONTROLTOWER_STATUS_REQUEST,
  GET_CONTROLTOWER_STATUS_SUCCESS,
  GET_CONTROLTOWER_STATUS_FAILED,
  CLEAR_CONTROLTOWER_STATUS,
  CREATE_AWS_ACCOUNT_REQUEST,
  CREATE_AWS_ACCOUNT_SUCCESS,
  CREATE_AWS_ACCOUNT_FAILED,
  CLEAR_MOVE_AWS_ACCOUNT,
  CLEAR_CREATE_AWS_ACCOUNT,
} from "../actionTypes/awsAccounts";

const initialState = {
  movingAccount: false,
  movedAccount: false,
  moveAccountFailed: false,
  isFetchingControlTowerStatus: false,
  isControlTowerEnabled: null,
  isFetchingControlTowerStatusFailed: false,
  isCreatingAwsAccount: false,
  isCreatingAwsAccountFailed: false,
  createAwsAccountSuccess: null,
};

const awsAccounts = (state = initialState, action) => {
  switch (action.type) {
    case MOVE_AWS_ACCOUNT_REQUEST:
      return {
        ...state,
        movingAccount: true,
        moveAccountFailed: false,
      };
    case MOVE_AWS_ACCOUNT_SUCCESS:
      return {
        ...state,
        movingAccount: false,
        movedAccount: true,
      };
    case MOVE_AWS_ACCOUNT_FAILED:
      return {
        ...state,
        movingAccount: false,
        moveAccountFailed: true,
      };

    case GET_CONTROLTOWER_STATUS_REQUEST:
      return {
        ...state,
        isFetchingControlTowerStatus: true,
        isControlTowerEnabled: initialState.isControlTowerEnabled,
        isFetchingControlTowerStatusFailed: false,
      };

    case GET_CONTROLTOWER_STATUS_SUCCESS:
      return {
        ...state,
        isFetchingControlTowerStatus: false,
        isControlTowerEnabled: action.payload,
      };

    case GET_CONTROLTOWER_STATUS_FAILED:
      return {
        ...state,
        isFetchingControlTowerStatus: false,
        isControlTowerEnabled: initialState.isControlTowerEnabled,
        isFetchingControlTowerStatusFailed: true,
      };

    case CLEAR_CONTROLTOWER_STATUS:
      return {
        ...state,
        isFetchingControlTowerStatus: initialState.isFetchingControlTowerStatus,
        isControlTowerEnabled: initialState.isControlTowerEnabled,
        isFetchingControlTowerStatusFailed:
          initialState.isFetchingControlTowerStatusFailed,
      };

    case CREATE_AWS_ACCOUNT_REQUEST:
      return {
        ...state,
        isCreatingAwsAccount: true,
      };

    case CREATE_AWS_ACCOUNT_SUCCESS:
      return {
        ...state,
        isCreatingAwsAccount: false,
        createAwsAccountSuccess: action.payload,
        isCreatingAwsAccountFailed: false,
      };
    case CREATE_AWS_ACCOUNT_FAILED:
      return {
        ...state,
        isCreatingAwsAccount: false,
        isCreatingAwsAccountFailed: true,
      };
    case CLEAR_CREATE_AWS_ACCOUNT:
      return {
        isCreatingAwsAccount: initialState.isCreatingAwsAccount,
        isCreatingAwsAccountFailed: initialState.isCreatingAwsAccountFailed,
        createAwsAccountSuccess: initialState.createAwsAccountSuccess,
      };
    case CLEAR_MOVE_AWS_ACCOUNT:
      return {
        movingAccount: initialState.movingAccount,
        movedAccount: initialState.movedAccount,
        moveAccountFailed: initialState.moveAccountFailed,
      };
    default:
      return {
        ...state,
      };
  }
};

export default awsAccounts;

import {
  GET_RULES_REQUEST,
  GET_RULES_SUCCESS,
  GET_RULES_FAILED,
  GET_EVENTS_METADATA_REQUEST,
  GET_EVENTS_METADATA_SUCCESS,
  GET_EVENTS_METADATA_FAILED,
  GET_RULE_CONDITION_METADATA_REQUEST,
  GET_RULE_CONDITION_METADATA_SUCCESS,
  GET_RULE_CONDITION_METADATA_FAILED,
  SAVE_RULE_REQUEST,
  SAVE_RULE_SUCCESS,
  SAVE_RULE_FAILED,
  DELETE_RULE_REQUEST,
  DELETE_RULE_SUCCESS,
  DELETE_RULE_FAILED,
  EDITING_RULE,
  CLEAR_RULES_DATA,
  EXECUTION_HISTORY_REQUEST,
  EXECUTION_HISTORY_SUCCESS,
  EXECUTION_HISTORY_FAILED,
} from "../actionTypes/rules";

const initialState = {
  rules: [],
  eventsMetaData: [],
  isFetchingRules: true,
  isFetchingRulesFailed: false,
  isFetchingEventsMetadata: true,
  isFetchingEventsMetadataFailed: false,
  ruleConditionMetadata: [],
  ruleActionMetadata: [],
  isFetchingRuleConditionMetadata: false,
  fetchingRuleConditionMetadataFailed: false,
  isSavingRule: false,
  saveRuleSuccess: false,
  saveRuleFailure: false,
  isDeletingRule: false,
  isEditingRule: false,
  executionHistoryData: null,
  isFetchingExecutionHistoryData: true,
};

const rulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RULES_REQUEST:
      return {
        ...state,
        rules: initialState.rules,
        isFetchingRules: initialState.isFetchingRules,
        isFetchingRulesFailed: initialState.isFetchingRulesFailed,
      };
    case GET_RULES_SUCCESS:
      return {
        ...state,
        rules: action.payload,
        isFetchingRules: false,
        isFetchingRulesFailed: false,
      };
    case GET_RULES_FAILED:
      return {
        ...state,
        rules: initialState.rules,
        isFetchingRules: false,
        isFetchingRulesFailed: true,
      };
    case GET_EVENTS_METADATA_REQUEST:
      return {
        ...state,
        isFetchingEventsMetadata: true,
        isFetchingEventsMetadataFailed: false,
      };
    case GET_EVENTS_METADATA_SUCCESS:
      return {
        ...state,
        eventsMetaData: action.payload,
        isFetchingEventsMetadata: false,
        isFetchingEventsMetadataFailed: false,
      };
    case GET_EVENTS_METADATA_FAILED:
      return {
        ...state,
        eventsMetaData: initialState.eventsMetaData,
        isFetchingEventsMetadata: false,
        isFetchingEventsMetadataFailed: true,
      };
    case GET_RULE_CONDITION_METADATA_REQUEST:
      return {
        ...state,
        ruleConditionMetadata: initialState.ruleConditionMetadata,
        ruleActionMetadata: initialState.ruleActionMetadata,
        isFetchingRuleConditionMetadata: true,
        fetchingRuleConditionMetadataFailed:
          initialState.fetchingRuleConditionMetadataFailed,
      };
    case GET_RULE_CONDITION_METADATA_SUCCESS:
      return {
        ...state,
        ruleConditionMetadata: action.payload.ruleConditionMetadata,
        ruleActionMetadata: action.payload.ruleActionMetadata,
        isFetchingRuleConditionMetadata: false,
      };
    case GET_RULE_CONDITION_METADATA_FAILED:
      return {
        ...state,
        isFetchingRuleConditionMetadata: false,
        fetchingRuleConditionMetadataFailed: true,
      };
    case SAVE_RULE_REQUEST:
      return {
        ...state,
        isSavingRule: true,
        saveRuleSuccess: initialState.saveRuleSuccess,
        saveRuleFailure: initialState.saveRuleFailure,
      };
    case SAVE_RULE_SUCCESS:
      return {
        ...state,
        isSavingRule: initialState.isSavingRule,
        saveRuleSuccess: true,
      };
    case SAVE_RULE_FAILED:
      return {
        ...state,
        isSavingRule: initialState.isSavingRule,
        saveRuleFailure: true,
      };
    case DELETE_RULE_REQUEST:
      return {
        ...state,
        isDeletingRule: true,
      };
    case DELETE_RULE_SUCCESS:
      return {
        ...state,
        isDeletingRule: initialState.isDeletingRule,
      };
    case DELETE_RULE_FAILED:
      return {
        ...state,
        isDeletingRule: initialState.isDeletingRule,
      };
    case EDITING_RULE:
      return {
        ...state,
        isEditingRule: action.payload,
      };
    case CLEAR_RULES_DATA:
      return {
        ...state,
        ...initialState,
      };
    case EXECUTION_HISTORY_REQUEST:
      return {
        ...state,
        executionHistoryData: initialState.executionHistoryData,
        isFetchingExecutionHistoryData:
          initialState.isFetchingExecutionHistoryData,
      };
    case EXECUTION_HISTORY_SUCCESS:
      return {
        ...state,
        executionHistoryData: action.payload,
        isFetchingExecutionHistoryData: false,
      };
    case EXECUTION_HISTORY_FAILED:
      return {
        ...state,
        isFetchingExecutionHistoryData: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export default rulesReducer;

import {
  GET_TASK_PARAMETERS_REQUEST,
  GET_TASK_PARAMETERS_SUCCESS,
  GET_TASK_PARAMETERS_FAILURE,
  SET_TASK_PARAMETERS,
  RESET_TASK_PARAMETERS,
} from "../actionTypes/parameter";

const initialState = {
  loadingParameters: false,
  parameterGroups: null,
  parameters: null,
  loadingParametersFailed: false,
};

const taskParameter = (state = initialState, action) => {
  switch (action.type) {
    case GET_TASK_PARAMETERS_REQUEST:
      return {
        ...state,
        loadingParameters: true,
        parameterGroups: initialState.parameterGroups,
        parameters: initialState.parameters,
        loadingParametersFailed: initialState.loadingParametersFailed,
      };
    case GET_TASK_PARAMETERS_SUCCESS:
      return {
        ...state,
        loadingParameters: false,
        parameterGroups: action.payload.groups,
        parameters: action.payload.parameters,
      };
    case GET_TASK_PARAMETERS_FAILURE:
      return {
        ...state,
        loadingParameters: false,
        loadingParametersFailed: true,
      };
    case SET_TASK_PARAMETERS:
      return {
        ...state,
        parameters: action.payload,
      };
    case RESET_TASK_PARAMETERS:
      return {
        ...state,
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default taskParameter;

// Assessments List
export const FETCHING_ASSESSMENTS_START = "FETCHING_ASSESSMENTS_START";
export const FETCHING_ASSESSMENTS_SUCCESS = "FETCHING_ASSESSMENTS_SUCCESS";
export const FETCHING_ASSESSMENTS_FAILURE = "FETCHING_ASSESSMENTS_FAILURE";
export const FETCHING_PENDING_ASSESSMENTS_SUCCESS =
  "FETCHING_PENDING_ASSESSMENTS_SUCCESS";
export const FETCHING_PENDING_FAILURE = "FETCHING_PENDING_ASSESSMENTS_SUCCESS";
export const FETCHING_COMPLETED_ASSESSMENTS_FAILURE =
  "FETCHING_COMPLETED_ASSESSMENTS_FAILURE";
export const FETCHING_COMPLETED_ASSESSMENTS_SUCCESS =
  "FETCHING_COMPLETED_ASSESSMENTS_SUCCESS";
export const UPDATE_ASSESSMENT_STATS = "UPDATE_ASSESSMENT_STATS";

// Assessment Details
export const FETCHING_ASSESSMENT_DETAILS_START =
  "FETCHING_ASSESSMENT_DETAILS_START";
export const FETCHING_ASSESSMENT_DETAILS_SUCCESS =
  "FETCHING_ASSESSMENT_DETAILS_SUCCESS";
export const FETCHING_ASSESSMENT_DETAILS_FAILURE =
  "FETCHING_ASSESSMENT_DETAILS_FAILURE";
export const CLEAR_ASSESSMENT_DETAILS = "CLEAR_ASSESSMENT_DETAILS";

//Assessment Questions List
export const GET_ASSESSMENT_QUESTIONS_REQUEST =
  "GET_ASSESSMENT_QUESTIONS_REQUEST";
export const GET_ASSESSMENT_QUESTIONS_SUCCESS =
  "GET_ASSESSMENT_QUESTIONS_SUCCESS";
export const GET_ASSESSMENT_QUESTIONS_FAILURE =
  "GET_ASSESSMENT_QUESTIONS_FAILURE";

//Assessment Question Detail
export const GET_ASSESSMENT_QUESTION_DETAILS_REQUEST =
  "GET_ASSESSMENT_QUESTION_DETAILS_REQUEST";
export const GET_ASSESSMENT_QUESTION_DETAILS_SUCCESS =
  "GET_ASSESSMENT_QUESTION_DETAILS_SUCCESS";
export const GET_ASSESSMENT_QUESTION_DETAILS_FAILURE =
  "GET_ASSESSMENT_QUESTION_DETAILS_FAILURE";

//Assessment Questions
export const SAVE_ASSESSMENT_QUESTION_REQUEST =
  "SAVE_ASSESSMENT_QUESTION_REQUEST";
export const SAVE_ASSESSMENT_QUESTION_SUCCESS =
  "SAVE_ASSESSMENT_QUESTION_SUCCESS";
export const SAVE_ASSESSMENT_QUESTION_FAILURE =
  "SAVE_ASSESSMENT_QUESTION_FAILURE";

export const CLEAR_ASSESSMENT_REVIEW = "CLEAR_ASSESSMENT_REVIEW";

// Mark Assessment Complete
export const MARK_COMPLETE_REQUEST = "MARK_COMPLETE_REQUEST";
export const MARK_COMPLETE_SUCCESS = "MARK_COMPLETE_SUCCESS";
export const MARK_COMPLETE_FAILURE = "MARK_COMPLETE_FAILURE";
export const MARK_COMPLETE_RESET = "MARK_COMPLETE_RESET";

// Delete Assessment
export const DELETE_ASSESSMENT_REQUEST = "DELETE_ASSESSMENT_REQUEST";
export const DELETE_ASSESSMENT_SUCCESS = "DELETE_ASSESSMENT_SUCCESS";
export const DELETE_ASSESSMENT_FAILURE = "DELETE_ASSESSMENT_FAILURE";
export const DELETE_ASSESSMENT_RESET = "DELETE_ASSESSMENT_RESET";

//Get Assessment Reasons
export const GET_ASSESSMENT_QUESTION_REASONS_SUCCESS =
  "GET_ASSESSMENT_QUESTION_REASONS_SUCCESS";

export const SET_IMPROVEMENT_STATUS_OPTIONS = "SET_IMPROVEMENT_STATUS_OPTIONS";

// Mark Assessment Complete
export const RESTART_WORKLOAD_REQUEST = "RESTART_WORKLOAD_REQUEST";
export const RESTART_WORKLOAD_SUCCESS = "RESTART_WORKLOAD_SUCCESS";
export const RESTART_WORKLOAD_FAILURE = "RESTART_WORKLOAD_FAILURE";
export const RESTART_WORKLOAD_RESET = "RESTART_COMPLETE_RESET";

//Share Assessment
export const SHARE_ASSESSMENT_REQUEST = "SHARE_ASSESSMENT_REQUEST";
export const SHARE_ASSESSMENT_SUCCESS = "SHARE_ASSESSMENT_SUCCESS";
export const SHARE_ASSESSMENT_FAILURE = "SHARE_ASSESSMENT_FAILURE";
export const SHARE_ASSESSMENT_RESET = "SHARE_ASSESSMENT_RESET";

//Execution History
export const FETCH_EXECUTION_HISTORY_REQUEST =
  "FETCH_EXECUTION_HISTORY_REQUEST";
export const FETCH_EXECUTION_HISTORY_SUCCESS =
  "FETCH_EXECUTION_HISTORY_SUCCESS";
export const FETCH_EXECUTION_HISTORY_FAILURE =
  "FETCH_EXECUTION_HISTORY_FAILURE";

//Best Practice Choices
export const SELECT_BEST_PRACTICE_CHOICE = "SELECT_BEST_PRACTICE_CHOICE";
export const UNSELECT_BEST_PRACTICE_CHOICE = "UNSELECT_BEST_PRACTICE_CHOICE";
export const SELECT_BEST_PRACTICE_CHOICE_REASON =
  "SELECT_BEST_PRACTICE_CHOICE_REASON";
export const SET_BEST_PRACTICE_CHOICE_ADDITIONAL_REASON =
  "SET_BEST_PRACTICE_CHOICE_ADDITIONAL_REASON";
export const INITIALIZE_BEST_PRACTICE_CHOICE =
  "INITIALIZE_BEST_PRACTICE_CHOICE";
export const CLEAR_BEST_PRACTICES = "CLEAR_BEST_PRACTICES";

// List Shares
export const FETCH_LIST_SHARES_REQUEST = "FETCH_LIST_SHARES_REQUEST";
export const FETCH_LIST_SHARES_SUCCESS = "FETCH_LIST_SHARES_SUCCESS";
export const FETCH_LIST_SHARES_FAILURE = "FETCH_LIST_SHARES_FAILURE";
export const UPDATE_SHARE_REQUEST = "UPDATE_SHARE_REQUEST";
export const UPDATE_SHARE_SUCCESS = "UPDATE_SHARE_SUCCESS";
export const UPDATE_SHARE_FAILURE = "UPDATE_SHARE_FAILURE";
export const DELETE_SHARE_REQUEST = "DELETE_SHARE_REQUEST";
export const DELETE_SHARE_SUCCESS = "DELETE_SHARE_SUCCESS";
export const DELETE_SHARE_FAILURE = "DELETE_SHARE_FAILURE";
export const FETCH_ALL_ASSESSMENTS = "FETCH_ALL_ASSESSMENTS";

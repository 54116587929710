import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

import i18n from "../../../config/locale/i18n";
import childOrgIcon from "../../../images/icons/child-org.svg";
import userLockIcon from "../../../images/icons/common/user-lock.svg";
import parentOrgIcon from "../../../images/icons/parent-org.svg";
import { TENANT_MANAGEMENT } from "../../../utils/app-paths.json";
import { isArrayWithLength } from "../../../utils/array-methods";
import { formatDateToLocalTimeZone } from "../../../utils/convert-date";
import EditOrganizationButton from "../components/OrganizationDetails/EditOrganizationButton";
import styles from "../multi-tenency.module.scss";
import { ORG_TABLE_VIEW } from "./constants";

function checkEllipsis(el) {
  const styles = getComputedStyle(el);
  const widthEl = parseFloat(styles.width);
  const ctx = document.createElement("canvas").getContext("2d");
  ctx.font = `${styles.fontSize} ${styles.fontFamily}`;
  const text = ctx.measureText(el.innerText);
  return Math.round(text.width) > Math.round(widthEl);
}

export const OrgDetailFormatter = ({ data, ...props }) => {
  const { t } = useTranslation("common");
  const [showMore, setShowMore] = useState(false);
  const [isOverFlow, setIsOverFlow] = useState(false);
  const ref = useRef();

  let description = data && data.Description ? `${data.Description}` : "";
  const toggleShowContent = (e) => {
    e.stopPropagation();
    const newShowMoreState = !showMore;
    setShowMore(newShowMoreState);
    if (showMore) {
      props.api.resetRowHeights();
    }
    props.api.onRowHeightChanged();
  };

  useEffect(() => {
    let event = window.addEventListener("resize", function () {
      const el = ref.current ? ref.current : null;
      setIsOverFlow(() => checkEllipsis(el));
    });

    return () => window.removeEventListener("resize", event);
  }, []);

  return (
    <>
      {data && (
        <div className={classNames(styles.columnWrapper, "mt-2")}>
          <Link
            className={`table-link ${styles.orgName} mc-text-base-semi-bold`}
            to={data.ID}
          >
            {data.Name}
          </Link>
          <div className="d-flex mb-2">
            <div
              ref={ref}
              id={`text-${data.ID}`}
              className={classNames(
                styles.textMid,
                showMore && styles.textWrap
              )}
            >
              {description}
            </div>
            {isOverFlow && (
              <div className="ml-1 cursor-pointer" onClick={toggleShowContent}>
                {!showMore && (
                  <span title={t("label.showMore")}>
                    <i className="fa fa-eye" />
                  </span>
                )}
              </div>
            )}

            {showMore && (
              <div className="ml-1 cursor-pointer" onClick={toggleShowContent}>
                <span title={t("hide")}>
                  <i className="fa fa-eye-slash" />
                </span>
              </div>
            )}
          </div>
          {isOverFlow && (
            <UncontrolledTooltip placement="bottom" target={`text-${data.ID}`}>
              {description}
            </UncontrolledTooltip>
          )}
        </div>
      )}
    </>
  );
};

export const orgOwnerDetailFormatter = ({ data, value }) => {
  return (
    <div className={styles.columnWrapper}>
      {value ? (
        <>
          <div className={styles.userName}>{value}</div>
          {data && data.Email && (
            <div className={styles.textMid}>{data.Email}</div>
          )}
        </>
      ) : (
        "-"
      )}
    </div>
  );
};

export const mapAccountNameFormatter = ({ data, node, accountList }) => {
  const isDisabled = !node.selectable;
  const disabledText = "Account not in scope";
  let divProps = {
    title: isDisabled ? disabledText : "",
  };
  const accountNumber = data.AccountNumber;
  const accountName =
    isArrayWithLength(accountList) &&
    accountList.find((acc) => acc.accountNumber === accountNumber)
      ? accountList.find((acc) => acc.accountNumber === accountNumber)
          .accountName
      : "-";

  return (
    <div {...divProps} className="d-flex">
      <div>
        <div className={"mc-text-base-regular text-ellipsis"}>
          {accountName}
        </div>
        {accountNumber && (
          <div className="mc-text-sm-regular text-ellipsis">
            {accountNumber}
          </div>
        )}
      </div>
      {isDisabled && (
        <div className="d-flex align-items-center">
          <span>
            <i
              className="fa fa-exclamation-circle text-warning ml-2 cursor-pointer"
              id={`ac-${accountNumber}`}
            />
            <UncontrolledTooltip
              target={`ac-${accountNumber}`}
              // placement="bottom"
            >
              {disabledText}
            </UncontrolledTooltip>
          </span>
        </div>
      )}
    </div>
  );
};

export const orgfeatureFormatter = ({ value }) => {
  const feature = value ? i18n.t(`multiTenancy:${value}`) : "-";

  return (
    <div className={classNames(styles.columnWrapper, styles.userName)}>
      {feature}
    </div>
  );
};

export const orgIconFormatter = ({ data }) => {
  const icon =
    data && !data.ParentOrganizationId ? parentOrgIcon : childOrgIcon;
  return <img loading="lazy" src={icon} alt="" className={styles.orgIcon} />;
};

export const orgDateFormatter = ({ value }) => {
  return (
    <div className={styles.userName}>
      {formatDateToLocalTimeZone({ date: value, format: "DD/MMM/YY" })}
    </div>
  );
};

export const orgActionsFormatter = ({ data }) => {
  const tooltipId = `manage-access-${data & data.ID}`;
  return (
    <div className={styles.actionContainer}>
      <EditOrganizationButton view={ORG_TABLE_VIEW} organization={data} />

      {data && data.ParentOrganizationId && (
        <>
          <span id={tooltipId}>
            <Link to={`${TENANT_MANAGEMENT}/${data.ID}`}>
              <img loading="lazy" src={userLockIcon} alt="Manage-access" />
            </Link>
          </span>
          <UncontrolledTooltip target={tooltipId} placement="bottom">
            {i18n.t("common:manageAccess")}
          </UncontrolledTooltip>
        </>
      )}
    </div>
  );
};

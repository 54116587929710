export const GET_CLASSIFIED_RESOURCES_REQUEST =
  "GET_CLASSIFIED_RESOURCES_REQUEST";
export const GET_CLASSIFIED_RESOURCES_SUCCESS =
  "GET_CLASSIFIED_RESOURCES_SUCCESS";
export const GET_CLASSIFIED_RESOURCES_FAILURE =
  "GET_CLASSIFIED_RESOURCES_FAILURE";
export const CLEAR_CLASSIFIED_RESOURCES = "CLEAR_CLASSIFIED_RESOURCES";
export const GET_RESOURCES_BY_RESOURCE_ID_REQUEST =
  "GET_RESOURCES_BY_RESOURCE_ID_REQUEST";
export const GET_RESOURCES_BY_RESOURCE_ID_SUCCESS =
  "GET_RESOURCES_BY_RESOURCE_ID_SUCCESS";
export const GET_RESOURCES_BY_RESOURCE_ID_FAILURE =
  "GET_RESOURCES_BY_RESOURCE_ID_FAILURE";
export const GET_TAG_ATTRIBUTES_COUNT = "GET_TAG_ATTRIBUTES_COUNT";
export const GET_TAG_FILTER_COUNT = "GET_TAG_FILTER_COUNT";
export const SAVE_SELECTED_CLASSIFY_RESOURCE_TYPE =
  "SAVE_SELECTED_CLASSIFY_RESOURCE_TYPE";
export const SAVE_RESOURCE_FILTERS = "SAVE_RESOURCE_FILTERS";
export const SAVE_APPLICATION_NAME = "SAVE_APPLICATION_NAME";
export const SAVE_APPLICATION_DESCRIPTION = "SAVE_APPLICATION_DESCRIPTION";
export const SAVE_APPLICATION_DIMENSION = "SAVE_APPLICATION_DIMENSION";
export const SAVE_SELECTED_VPC = "SAVE_SELECTED_VPC";
export const SAVE_SELECTED_CFN_STACK = "SAVE_SELECTED_CFN_STACK";
export const SAVE_SELECTED_TAG = "SAVE_SELECTED_TAG";
export const SAVE_APPLICATION_ENV_TYPE = "SAVE_APPLICATION_ENV_TYPE";
export const CREATE_CLASSIFIED_APPLICATION_REQUEST =
  "CREATE_CLASSIFIED_APPLICATION_REQUEST";
export const CREATE_CLASSIFIED_APPLICATION_SUCCESS =
  "CREATE_CLASSIFIED_APPLICATION_SUCCESS";
export const CREATE_CLASSIFIED_APPLICATION_FAILURE =
  "CREATE_CLASSIFIED_APPLICATION_FAILURE";
export const GET_TAG_ATTRIBUTES_REQUEST = "GET_TAG_ATTRIBUTES_REQUEST";
export const GET_TAG_ATTRIBUTES_SUCCESS = "GET_TAG_ATTRIBUTES_SUCCESS";
export const GET_TAG_ATTRIBUTES_FAILURE = "GET_TAG_ATTRIBUTES_FAILURE";
export const SET_TAGS_FILTER_VALUES = "SET_TAGS_FILTER_VALUES";
export const SET_RESOURCE_FILTER_LIST = "SET_RESOURCE_FILTER_LIST";
export const SET_INDIVIDUAL_RESOURCE_FILTER = "SET_INDIVIDUAL_RESOURCE_FILTER";
export const CLEAR_CREATE_APPLICATION_DATA = "CLEAR_CREATE_APPLICATION_DATA";
export const SAVE_SELECTED_CLASSIFY_AZURE_RESOURCE_TYPE =
  "SAVE_SELECTED_CLASSIFY_AZURE_RESOURCE_TYPE";
export const GET_CLASSIFIED_AZURE_RESOURCES_SUCCESS =
  "GET_CLASSIFIED_AZURE_RESOURCES_SUCCESS";
export const GET_AZURE_RESOURCES_BY_RESOURCE_ID_SUCCESS =
  "GET_AZURE_RESOURCES_BY_RESOURCE_ID_SUCCESS";
export const SET_INDIVIDUAL_AZURE_RESOURCE_FILTER =
  "SET_INDIVIDUAL_AZURE_RESOURCE_FILTER";
export const SET_AZURE_RESOURCE_FILTER_LIST = "SET_AZURE_RESOURCE_FILTER_LIST";
export const SAVE_AZURE_RESOUCE_FILTERS = "SAVE_AZURE_RESOUCE_FILTERS";
export const SAVE_KEYWORD = "SAVE_KEYWORD";
export const SEARCH_KEYWORD = "SEARCH_KEYWORD";
export const HAS_NEXT_PAGE = "HAS_NEXT_PAGE";

import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../config/customBaseQuery";
import { WAR_ASSESSMENT_LIST_URL } from "../../../config/service";

export const assessmentListApi = createApi({
  reducerPath: "assessmentsApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getAssessmentsList: builder.query({
      query: ({ workloadStatus, currentPage, limit, searchKey = "" }) => {
        const filters = {
          Status: workloadStatus,
          Keyword: searchKey,
        };
        const filterParams = "Filters=" + encodeURI(JSON.stringify(filters));
        const queryParams = `&Limit=${limit}&PageNumber=${currentPage}`;
        const params = `${filterParams}${queryParams}`;
        return {
          url: `${WAR_ASSESSMENT_LIST_URL}?${params}`,
          method: "GET",
        };
      },
      refetchOnMountOrArgChange: true,
    }),
  }),
});

export const { useGetAssessmentsListQuery } = assessmentListApi;

import { sha256 } from "js-sha256";

/**
 * helper function for serializing the message
 * @param {*} result
 * @param {*} source
 * @param {*} beginIndex
 * @param {*} endIndex
 */
export function BuildByteArrayByOffsets(result, source, beginIndex, endIndex) {
  // console.log(result.constructor, source);
  source = new Uint8Array(source);
  const totalLength = result.byteLength;
  const sourceLength = source.byteLength;
  // console.log("totalLength", totalLength, "sourceLength", sourceLength)
  const destinationLength = endIndex - beginIndex;
  // console.log("(beginIndex >= 0) && (endIndex <= totalLength)", (beginIndex >= 0) && (endIndex <= totalLength))
  if (beginIndex >= 0 && endIndex <= totalLength) {
    // console.log("destinationLength > sourceLength", destinationLength > sourceLength)
    if (destinationLength > sourceLength) {
      const diff = destinationLength - sourceLength;
      for (let i = 0; i < sourceLength; i++) {
        result[beginIndex + diff + i] = source[i];
      }
    } else {
      // console.log("Math.min(sourceLength, destinationLength)", Math.min(sourceLength, destinationLength))
      for (let i = 0; i < Math.min(sourceLength, destinationLength); i++) {
        // console.log(source, i);
        // console.log("source[i]", source[i])
        result[beginIndex + i] = source[i];
      }
    }
  }
}

/**
 * Convert string to byte array
 * @param {*} str
 */
// export function ConvertStringToByteArray(str) {
//     // replace is used to trim null characters
//     var bytes = [];
//     for (var i = 0; i < str.length; ++i) {
//         var code = str.charCodeAt(i);
//         bytes = bytes.concat([code]);
//     }
//     return new Uint8Array(bytes).buffer;
// }

/** * Convert string to Uint8 array. * * @param {string} content - the content of the message * @returns {Uint8Array} the message in Uint8 array */
export function ConvertStringToByteArray(content) {
  return new TextEncoder("utf8").encode(content);
}

/**
 * Convert Int32 to bytes
 * @param {*} num
 */
// export function int32ToBytes(num) {
//     // console.log("int32ToBytes: input", num);
//     // const arr = new Uint8Array([
//     //      (num & 0xff000000) >> 24,
//     //      (num & 0x00ff0000) >> 16,
//     //      (num & 0x0000ff00) >> 8,
//     //      (num & 0x000000ff)
//     // ]);
//     // console.log("int32ToBytes: output", arr.buffer);
//     // return arr;
//     const arr = new ArrayBuffer(4); // an Int32 takes 4 bytes
//     const view = new DataView(arr);
//     view.setUint32(0, num, false); // byteOffset = 0; litteEndian = false
//     return arr;
// }

// // Convert a byte array to a hex string
// function bytesToHex(bytes) {
//     for (var hex = [], i = 0; i < bytes.length; i++) {
//         var current = bytes[i] < 0 ? bytes[i] + 256 : bytes[i];
//         hex.push((current >>> 4).toString(16));
//         hex.push((current & 0xF).toString(16));
//     }
//     return hex.join("");
// }

// function hexToBytes(hex) {
//     // 01 70 3e ee 26 70
//     for (var bytes = [], c = hex.length-1; c >= 1; c += 2)
//     bytes.push(parseInt(hex.substr(c, 2), 16));
//     return bytes;
// }
function reverseString(str) {
  return str
    .split("")
    .reverse()
    .join("");
}

export function hexToBytes(hex) {
  // 1 70 3e ee 26 70
  // 07 62 ee e3 07 1
  // 70 26 ee 3e 70 1
  // 70 26 ee 3e 70 10
  const hex_string_reverse = reverseString(hex);

  for (var bytes_rev = [], c = 0; c < hex.length; c += 2) {
    let hex_to_convert_rev = hex_string_reverse.substr(c, 2);
    const hex_to_convert = reverseString(hex_to_convert_rev);
    bytes_rev.push(parseInt(hex_to_convert, 16));
  }
  const bytes = bytes_rev.reverse();
  return bytes;
}

export function int32ToBytesOld(num) {
  // return longToByteArray(num);
  // number to binary
  const bin = num.toString(2);
  // binary to hex string
  const hexString = parseInt(bin, 2).toString(16);
  // hex to bytes
  const bytes = hexToBytes(hexString);
  // const ab = new Uint8Array(bytes).buffer
  // console.log(num, bin, hexString, bytes, ab)
  return bytes;
}

export function int32ToBytes(num) {
  return new Uint8Array([
    (num & 0xff000000) >> 24,
    (num & 0x00ff0000) >> 16,
    (num & 0x0000ff00) >> 8,
    num & 0x000000ff
  ]);
}

export function longToByteArray(/*long*/ long) {
  // we want to represent the input as a 8-bytes array
  var byteArray = [0, 0, 0, 0, 0, 0, 0, 0];

  for (var index = 0; index < byteArray.length; index++) {
    var byte = long & 0xff;
    byteArray[index] = byte;
    long = (long - byte) / 256;
  }

  return byteArray;
}

/**
 * Convert uuid to byte array
 */
// export function uuidToBytes(uuid) {
//     const uuidBuffer = parse(uuid);
//     return uuidBuffer;
// }
export function uuidToBytes(guid) {
  const uuidFormat = guid.match(/[\da-f]{2}/gi);
  if (uuidFormat) {
    return new Uint8Array(
      uuidFormat.map(h => {
        return parseInt(h, 16);
      })
    );
  } else {
    // should not happen
    return ConvertStringToByteArray(guid);
  }
}

export function getDigestBytesForMessage(payloadByteArray) {
  const shajs = sha256.create();
  const hashedPayload = shajs.update(payloadByteArray).hex();
  const hashed_payload_bytes = ConvertStringToByteArray(hashedPayload);
  return hashed_payload_bytes;
}

import {
  ORGANIZATION_BASE_URL,
  DEPARTMENTS_BASE_URL,
  DEPARTMENT_BASE_URL,
  ENVIRONMENT_BASE_URL,
  APPLICATION_BASE_URL,
  RESOURCES_BASE_URL,
  RESOURCE_BASE_URL,
  REQUEST,
  REQUESTS,
} from "../../../config/service/index";
import { getApiService } from "../../../helpers/apiService";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiServiceHelper";

export const getDepartmentDetailsApi = (orgId, deptId) => {
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    ORGANIZATION_BASE_URL + orgId + DEPARTMENT_BASE_URL + deptId
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const getDepartmentResourcesApi = (orgId, deptId) => {
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    ORGANIZATION_BASE_URL +
    orgId +
    DEPARTMENT_BASE_URL +
    deptId +
    APPLICATION_BASE_URL +
    "all" +
    ENVIRONMENT_BASE_URL +
    "all" +
    RESOURCES_BASE_URL
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const addResourcesToDeptApi = (param, orgId, deptId) => {
  return getApiService(
    WEB_REQUEST_TYPE.POST,
    ORGANIZATION_BASE_URL +
    orgId +
    DEPARTMENT_BASE_URL +
    deptId +
    APPLICATION_BASE_URL +
    "default" +
    ENVIRONMENT_BASE_URL +
    "default" +
    RESOURCE_BASE_URL,
    param
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const saveDepartmentApi = (param, orgId) => {
  return getApiService(
    WEB_REQUEST_TYPE.POST,
    ORGANIZATION_BASE_URL + orgId + DEPARTMENT_BASE_URL,
    param
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const editDepartmentApi = (param, orgId, deptId) => {
  return getApiService(
    WEB_REQUEST_TYPE.PUT,
    ORGANIZATION_BASE_URL + orgId + DEPARTMENT_BASE_URL + deptId,
    param
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const getAllDepartmentsApi = (orgId) => {
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    ORGANIZATION_BASE_URL + orgId + DEPARTMENTS_BASE_URL
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const createProjectRequest = (orgId, param) => {
  return getApiService(
    WEB_REQUEST_TYPE.POST,
    ORGANIZATION_BASE_URL + orgId + REQUEST,
    param
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const getProjectRequests = (orgId, queryParams) => {
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    `${ORGANIZATION_BASE_URL}${orgId}${REQUESTS}?${queryParams}`
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const addProjectRequest = (orgId, params) => {
  return getApiService(
    WEB_REQUEST_TYPE.POST,
    `${ORGANIZATION_BASE_URL}${orgId}${REQUEST}`,
    params
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const updateProjectRequest = (orgId, params) => {
  return getApiService(
    WEB_REQUEST_TYPE.PUT,
    `${ORGANIZATION_BASE_URL}${orgId}${REQUEST}/${params.ID}`,
    params
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const getDepartmentsList = (orgId) => {
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    `${ORGANIZATION_BASE_URL}${orgId}${DEPARTMENTS_BASE_URL}`
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const createProject = (orgId, params) => {
  return getApiService(
    WEB_REQUEST_TYPE.POST,
    `${ORGANIZATION_BASE_URL}${orgId}${DEPARTMENT_BASE_URL}`,
    params
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const saveDepartmentApiV1 = (orgId, params) => {
  return getApiService(
    WEB_REQUEST_TYPE.PUT,
    `${ORGANIZATION_BASE_URL}${orgId}${DEPARTMENT_BASE_URL}${params.ID}`,
    params
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const denyProjectRequest = (orgId, projectRequestId, params) => {
  return getApiService(
    WEB_REQUEST_TYPE.PATCH,
    `${ORGANIZATION_BASE_URL}${orgId}${REQUESTS}/${projectRequestId}`,
    params
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const approveProjectRequest = (orgId, projectRequestId, params) => {
  return getApiService(
    WEB_REQUEST_TYPE.PATCH,
    `${ORGANIZATION_BASE_URL}${orgId}${REQUESTS}/${projectRequestId}`,
    params
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const withdrawProjectRequest = (orgId, projectRequestId, params) => {
  return getApiService(
    WEB_REQUEST_TYPE.PATCH,
    `${ORGANIZATION_BASE_URL}${orgId}${REQUESTS}/${projectRequestId}`,
    params
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const updateDepartment = (orgId, deptId, params) => {
  return getApiService(
    WEB_REQUEST_TYPE.PATCH,
    `${ORGANIZATION_BASE_URL}${orgId}${DEPARTMENT_BASE_URL}${deptId}`,
    params
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const deleteDepartment = (orgId, deptId, params) => {
  return getApiService(
    WEB_REQUEST_TYPE.DELETE,
    `${ORGANIZATION_BASE_URL}${orgId}${DEPARTMENT_BASE_URL}${deptId}`,
    params
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

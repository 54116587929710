import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { withRouter } from "@hocs/withRouter";
import {
  Alert,
  Col,
  Container,
  FormGroup,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import Widget from "../../../../components/Widget";
import {
  forgotPassword,
  receiveToken,
} from "../../../../modules/authentication/actions/auth";

class ForgotPassword extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  static isAuthenticated(token) {
    if (!token) return;
    return true;
  }

  constructor(props) {
    super(props);
    const params = new URLSearchParams(this.props.location.search);
    const defaultEmail = params.get(decodeURIComponent("email"));

    this.state = {
      username: defaultEmail,
    };

    this.forgotPassword = this.forgotPassword.bind(this);
    this.changeUsername = this.changeUsername.bind(this);
  }

  changeUsername(event) {
    this.setState({ username: event.target.value });
  }

  forgotPassword(e) {
    e.preventDefault();
    this.props.dispatch(forgotPassword({ Username: this.state.username }));
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const token = params.get("token");
    if (token) {
      this.props.dispatch(receiveToken(token));
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: "AUTH_PAGE_UNLOADED" });
  }

  render() {
    const { t, isFetching } = this.props;
    const { from } = this.props.location.state || {
      from: { pathname: "/app" },
    }; // eslint-disable-line

    // cant access login page while logged in
    if (ForgotPassword.isAuthenticated(localStorage.getItem("token"))) {
      return <Navigate to={from} />;
    }

    const { username } = this.state;

    return (
      <div className="auth-container">
        <Row className="row-transparent-bg">
          <Col xl={12}>
            <Container className="padding-yaxis-10em min-height-100vh">
              {this.props.forgotPasswordEmailSent ? (
                <Navigate
                  to={`/change-password?email=${encodeURIComponent(username)}`}
                />
              ) : (
                <Widget
                  className="widget padding-30px mx-auto"
                  title={
                    <h4 className="mt-3 mb-2 ml-2 orange-text">
                      {t("forgotPassword")}
                    </h4>
                  }
                  logo={
                    <a
                      href="https://www.montycloud.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        loading="lazy"
                        className="logo"
                        src="/images/DAY2Logo.jpg"
                        alt="MontyCloud"
                      ></img>
                    </a>
                  }
                >
                  <form className="mt" onSubmit={this.forgotPassword}>
                    {this.props.errorMessage && (
                      <Alert className="alert-sm" color="danger">
                        {this.props.errorMessage}
                      </Alert>
                    )}
                    <FormGroup row>
                      <Col>
                        <InputGroup>
                          <Input
                            className="form-control"
                            value={username}
                            onChange={this.changeUsername}
                            type="email"
                            required
                            name="username"
                            placeholder={t("email")}
                          />
                          <span className="input-group-text">
                            <i className="fa fa-envelope-o" />
                          </span>
                        </InputGroup>
                      </Col>
                    </FormGroup>

                    <div className="clearfix">
                      <div className="btn-toolbar float-right">
                        <button
                          type="submit"
                          href="/app"
                          disabled={isFetching}
                          className="btn btn-primary btn-md"
                        >
                          {isFetching
                            ? t("loading")
                            : t("sendVerificationCode")}
                        </button>
                      </div>
                    </div>
                  </form>
                  <h6 className="clearfix mt-3">
                    <Link className="mt-sm mt-2" to="/login">
                      {t("login")}
                    </Link>
                  </h6>
                </Widget>
              )}
            </Container>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isFetching: state.auth.isFetching,
    forgotPasswordEmailSent: state.auth.forgotPasswordEmailSent,
    isAuthenticated: state.tokenReducer.isAuthenticated,
    errorMessage: state.auth.errorMessage,
  };
}

export default withRouter(
  connect(mapStateToProps)(withTranslation(["forgotPassword"])(ForgotPassword))
);

import {
  GET_CLASSIFIED_RESOURCES_REQUEST,
  GET_CLASSIFIED_RESOURCES_SUCCESS,
  GET_CLASSIFIED_RESOURCES_FAILURE,
  CLEAR_CLASSIFIED_RESOURCES,
  GET_RESOURCES_BY_RESOURCE_ID_REQUEST,
  GET_RESOURCES_BY_RESOURCE_ID_SUCCESS,
  GET_RESOURCES_BY_RESOURCE_ID_FAILURE,
  SAVE_SELECTED_CLASSIFY_RESOURCE_TYPE,
  SAVE_RESOURCE_FILTERS,
  SAVE_APPLICATION_NAME,
  SAVE_APPLICATION_DESCRIPTION,
  SAVE_APPLICATION_ENV_TYPE,
  CREATE_CLASSIFIED_APPLICATION_REQUEST,
  CREATE_CLASSIFIED_APPLICATION_SUCCESS,
  CREATE_CLASSIFIED_APPLICATION_FAILURE,
  GET_TAG_ATTRIBUTES_REQUEST,
  GET_TAG_ATTRIBUTES_SUCCESS,
  GET_TAG_ATTRIBUTES_FAILURE,
  SET_TAGS_FILTER_VALUES,
  SET_RESOURCE_FILTER_LIST,
  SET_INDIVIDUAL_RESOURCE_FILTER,
  CLEAR_CREATE_APPLICATION_DATA,
  SAVE_SELECTED_CLASSIFY_AZURE_RESOURCE_TYPE,
  GET_CLASSIFIED_AZURE_RESOURCES_SUCCESS,
  GET_AZURE_RESOURCES_BY_RESOURCE_ID_SUCCESS,
  SET_INDIVIDUAL_AZURE_RESOURCE_FILTER,
  SET_AZURE_RESOURCE_FILTER_LIST,
  SAVE_AZURE_RESOUCE_FILTERS,
  GET_TAG_ATTRIBUTES_COUNT,
  GET_TAG_FILTER_COUNT,
  SAVE_APPLICATION_DIMENSION,
  SAVE_SELECTED_VPC,
  SAVE_SELECTED_CFN_STACK,
  SAVE_SELECTED_TAG,
  SAVE_KEYWORD,
  SEARCH_KEYWORD,
  HAS_NEXT_PAGE,
} from "../actionTypes/classification";

const initialState = {
  isLoadingClassifiedResources: false,
  isLoadingResourcesById: false,
  classifiedResources: null,
  allResources: null,
  loadingClassifiedResourcesFailed: false,
  loadingResourcesByFailed: false,
  selectedResourceType: "",
  resourceFilters: null,
  applicationName: "",
  applicationDescription: "",
  applicationDimension: null,
  appEnvType: "",
  applicationType: "",
  isCreatingApplication: false,
  applicationCreated: false,
  createApplicationFailed: false,
  isLoadingTagsAttributes: false,
  tagsAttribute: [],
  tagsFilterValues: null,
  resourceFiltersList: [],
  individualFiltersList: {
    accountFilters: [],
    regionFilters: [],
    resourceFilters: [],
    departmentFilters: [],
    applicationFilters: [],
    resourceName: "",
  },
  individualAzureFiltersList: {
    accountFilters: [],
    regionFilters: [],
    resourceFilters: [],
    resourceName: "",
  },
  azureResourceFiltersList: [],
  azureResourceFilters: null,
  selectedAzureResourceType: "",
  azureClassifiedResources: null,
  allAzureResources: null,
  tagsAttributeCount: 0,
  tagsFilterCount: 0,
  tagsResourcesVisitedPages: [],
  selectedVpc: null,
  selectedCfnStack: null,
  selectedTag: null,
  keyword: null,
  searchKeyword: null,
  hasNextPage: false,
};

const classificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SELECTED_CLASSIFY_RESOURCE_TYPE:
      const type = action.payload && {
        label: action.payload.label,
        value: action.payload.value,
      };
      return {
        ...state,
        selectedResourceType: action.payload,
        applicationType: type,
      };
    case SAVE_RESOURCE_FILTERS:
      return {
        ...state,
        resourceFilters: action.payload,
      };
    case SAVE_APPLICATION_NAME:
      return {
        ...state,
        applicationName: action.payload,
      };
    case SAVE_APPLICATION_DESCRIPTION:
      return {
        ...state,
        applicationDescription: action.payload,
      };
    case SAVE_APPLICATION_DIMENSION:
      return {
        ...state,
        applicationDimension: action.payload,
      };

    case SAVE_SELECTED_VPC:
      return {
        ...state,
        selectedVpc: action.payload,
      };

    case SAVE_SELECTED_CFN_STACK:
      return {
        ...state,
        selectedCfnStack: action.payload,
      };

    case SAVE_SELECTED_TAG:
      return {
        ...state,
        selectedTag: action.payload,
      };

    case SAVE_KEYWORD:
      return {
        ...state,
        keyword: action.payload,
      };

    case SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.payload,
      };

    case SAVE_APPLICATION_ENV_TYPE:
      return {
        ...state,
        appEnvType: action.payload,
      };
    case GET_CLASSIFIED_RESOURCES_REQUEST:
      return {
        ...state,
        isLoadingClassifiedResources: true,
        loadingClassifiedResourcesFailed:
          initialState.loadingClassifiedResourcesFailed,
      };
    case GET_CLASSIFIED_RESOURCES_SUCCESS:
      return {
        ...state,
        isLoadingClassifiedResources: false,
        classifiedResources: action.payload,
      };
    case GET_CLASSIFIED_RESOURCES_FAILURE:
      return {
        ...state,
        isLoadingClassifiedResources: false,
        loadingClassifiedResourcesFailed: true,
      };
    case CLEAR_CLASSIFIED_RESOURCES:
      return {
        ...state,
        isLoadingClassifiedResources: initialState.isLoadingClassifiedResources,
        classifiedResources: initialState.classifiedResources,
        loadingClassifiedResourcesFailed:
          initialState.loadingClassifiedResourcesFailed,
        azureClassifiedResources: initialState.azureClassifiedResources,
      };
    case GET_RESOURCES_BY_RESOURCE_ID_REQUEST:
      return {
        ...state,
        isLoadingResourcesById: true,
        allResources: initialState.allResources,
        loadingResourcesByFailed: initialState.loadingResourcesByFailed,
      };
    case GET_RESOURCES_BY_RESOURCE_ID_SUCCESS:
      return {
        ...state,
        isLoadingResourcesById: false,
        allResources: action.payload,
      };
    case GET_RESOURCES_BY_RESOURCE_ID_FAILURE:
      return {
        ...state,
        isLoadingResourcesById: false,
        loadingResourcesByFailed: true,
      };
    case CREATE_CLASSIFIED_APPLICATION_REQUEST:
      return {
        ...state,
        isCreatingApplication: true,
      };
    case CREATE_CLASSIFIED_APPLICATION_SUCCESS:
      return {
        ...state,
        isCreatingApplication: false,
        applicationCreated: true,
      };
    case CREATE_CLASSIFIED_APPLICATION_FAILURE:
      return {
        ...state,
        isCreatingApplication: false,
        createApplicationFailed: false,
      };
    case GET_TAG_ATTRIBUTES_REQUEST:
      return {
        ...state,
        isLoadingTagsAttributes: true,
        tagsAttributesLoaded: false,
      };
    case GET_TAG_ATTRIBUTES_SUCCESS:
      const tags = action.payload.filter(
        (newTag) =>
          !state.tagsAttribute.some(
            (existingTag) => existingTag.label === newTag.label
          )
      );
      return {
        ...state,
        isLoadingTagsAttributes: false,
        tagsAttributesLoaded: true,
        tagsAttribute: [...state.tagsAttribute, ...tags],
      };
    case GET_TAG_ATTRIBUTES_FAILURE:
      return {
        ...state,
        isLoadingTagsAttributes: false,
        tagsAttributesLoaded: false,
      };
    case GET_TAG_ATTRIBUTES_COUNT:
      return {
        ...state,
        tagsAttributeCount: action.payload,
      };
    case GET_TAG_FILTER_COUNT:
      return {
        ...state,
        tagsFilterCount: action.payload,
      };
    case SET_TAGS_FILTER_VALUES:
      return {
        ...state,
        tagsFilterValues: action.payload,
      };
    case SET_RESOURCE_FILTER_LIST:
      return {
        ...state,
        resourceFiltersList: action.payload,
      };
    case SET_INDIVIDUAL_RESOURCE_FILTER:
      return {
        ...state,
        individualFiltersList: action.payload,
      };
    case CLEAR_CREATE_APPLICATION_DATA:
      return {
        ...state,
        applicationName: initialState.applicationName,
        applicationDescription: initialState.applicationDescription,
        appEnvType: initialState.appEnvType,
        isLoadingResourcesById: initialState.isLoadingResourcesById,
        applicationCreated: initialState.applicationCreated,
        applicationDimension: initialState.applicationDimension,
        selectedVpc: initialState.selectedVpc,
        selectedCfnStack: initialState.selectedCfnStack,
        selectedTag: initialState.selectedTag,
      };
    case SAVE_SELECTED_CLASSIFY_AZURE_RESOURCE_TYPE:
      const resourceType = action.payload && {
        label: action.payload.label,
        value: action.payload.value,
      };
      return {
        ...state,
        selectedAzureResourceType: action.payload,
        applicationType: resourceType,
      };
    case GET_CLASSIFIED_AZURE_RESOURCES_SUCCESS:
      return {
        ...state,
        isLoadingClassifiedResources: false,
        azureClassifiedResources: action.payload,
      };
    case GET_AZURE_RESOURCES_BY_RESOURCE_ID_SUCCESS:
      return {
        ...state,
        isLoadingResourcesById: false,
        allAzureResources: action.payload,
      };
    case SET_INDIVIDUAL_AZURE_RESOURCE_FILTER:
      return {
        ...state,
        individualAzureFiltersList: action.payload,
      };
    case SET_AZURE_RESOURCE_FILTER_LIST:
      return {
        ...state,
        azureResourceFiltersList: action.payload,
      };
    case SAVE_AZURE_RESOUCE_FILTERS:
      return {
        ...state,
        azureResourceFilters: action.payload,
      };
    case HAS_NEXT_PAGE:
      return {
        ...state,
        hasNextPage: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default classificationReducer;

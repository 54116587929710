import { GLOBAL_USER_DATA, USER_BASE_URL } from "../../../config/service/index";
import { getApiService, } from "../../../helpers/apiService";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiServiceHelper";

export const getUserDataApi = () => {
  return getApiService(WEB_REQUEST_TYPE.GET, GLOBAL_USER_DATA)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error.message);
    });
};

export const saveUserDataApi = (param) => {
  return getApiService(WEB_REQUEST_TYPE.POST, GLOBAL_USER_DATA, param)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error.message);
    });
};

export const createAdminUserApi = (params) => {
  return getApiService(WEB_REQUEST_TYPE.POST, USER_BASE_URL, params);
};

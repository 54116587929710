import { createApi } from "@reduxjs/toolkit/query/react";
import { WEB_REQUEST_TYPE } from "../../../../../helpers/apiService";
import { axiosBaseQuery } from "../../../../../config/customBaseQuery";
import {
  ORG_GOVERNANCE_SUMMARY,
  ORG_INVENTORY_SUMMARY,
} from "../../../../../config/service";
import {
  CLOUDPROVIDERS,
  ALL_RESOURCES_SUMMARY_FILTERS,
} from "../../../../../utils/app-constants.json";

export const azureDashboardAPI = createApi({
  tagTypes: ["AzureDashboardData"],
  reducerPath: "azureDashboardAPI",
  baseQuery: axiosBaseQuery(),
  credentials: "true",
  endpoints: (builder) => ({
    getAzureResourceSummary: builder.query({
      query: (param) => {
        const params = encodeURI(JSON.stringify(param));
        return {
          url: `${ORG_INVENTORY_SUMMARY}?Filters=${params}&CloudProvider=${CLOUDPROVIDERS.AZURE}&SummaryType=${ALL_RESOURCES_SUMMARY_FILTERS.BY_RESOURCE_TYPE}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
      providesTags: [{ type: "AzureDashboardData", id: "ResourceSummary" }],
    }),
    getAzureRegionSummary: builder.query({
      query: (param) => {
        const params = encodeURI(JSON.stringify(param));
        return {
          url: `${ORG_INVENTORY_SUMMARY}?Filters=${params}&CloudProvider=${CLOUDPROVIDERS.AZURE}&SummaryType=${ALL_RESOURCES_SUMMARY_FILTERS.BY_REGION}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
      providesTags: [{ type: "AzureDashboardData", id: "RegionSummary" }],
    }),
    getCostCardSummary: builder.query({
      query: () => {},
      providesTags: [{ type: "AzureDashboardData", id: "CostCardSummary" }],
    }),
    getGovernanceSummary: builder.query({
      query: (param) => {
        const params = encodeURI(JSON.stringify(param));
        return {
          url: `${ORG_GOVERNANCE_SUMMARY}?Filters=${params}&CloudProvider=${CLOUDPROVIDERS.AZURE}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
      providesTags: [{ type: "AzureDashboardData", id: "GovernanceSummary" }],
    }),
  }),
});

export const {
  useGetAzureResourceSummaryQuery,
  useGetAzureRegionSummaryQuery,
  useGetCostCardSummaryQuery,
  useGetGovernanceSummaryQuery,
} = azureDashboardAPI;

import React from "react";
import { animationPlayerURL, getAnimationPath } from "./utils";
import useScript from "../../hooks/useScript";
import PropTypes from "prop-types";

const AnimationPlayer = ({ type }) => {
  useScript(animationPlayerURL);

  return (
    <div>
      <lottie-player
        src={getAnimationPath(type)}
        background="#f8fbfd"
        speed="1"
        style={{ width: "300px", height: "300px", margin: "auto" }}
        loop
        autoplay
      ></lottie-player>
    </div>
  );
};

AnimationPlayer.propTypes = {
  type: PropTypes.string,
};

AnimationPlayer.defaultProps = {
  type: "success",
};
export default AnimationPlayer;

import {
  SUBMIT_FEEDBACK_REQUEST,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAILED,
  RESET_FEEDBACK_STATE
} from "../actionTypes/feedback";

const initialState = {
  submittingFeedback: false,
  submittedFeedback: false,
  errorMessage: null,
  successMessage: null
};

const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_FEEDBACK_REQUEST:
      return {
        ...state,
        submittingFeedback: true
      };
    case SUBMIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        submittingFeedback: false,
        submittedFeedback: true,
        successMessage: action.payload
      };
    case SUBMIT_FEEDBACK_FAILED:
      return {
        ...state,
        submittingFeedback: false,
        submittedFeedback: false,
        errorMessage: action.payload
      };
    case RESET_FEEDBACK_STATE:
      return {
        submittingFeedback: false,
        submittedFeedback: false,
        errorMessage: null,
        successMessage: null
      };
    default:
      return state;
  }
};

export default feedbackReducer;

import {
  START_CHECKS_EXECUTION_REQUEST,
  START_CHECKS_EXECUTION_SUCCESS,
  START_CHECKS_EXECUTION_FAILURE,
  SUPPRESS_FINDING_REQUEST,
  SUPPRESS_FINDING_SUCCESS,
  SUPPRESS_FINDING_FAILURE,
  SHOW_RE_RUN_CHECKS_CONFIRMATION_MODAL,
  HIDE_RE_RUN_CHECKS_CONFIRMATION_MODAL,
  CLEAR_SUPPRESS_FINDING,
} from "../actionTypes/checksSummary";

const initialState = {
  startingAssessmentChecksExecution: false,
  startingAssessmentChecksExecutionError: false,
  assessmentChecksExecution: null,
  reRunChecksShowModal: false,
  reRunChecksAction: null,
  reRunChecksTarget: "",
  isSuppressInProgress: false,
  isSuppressSuccess: false,
  isSupressFailure: false,
};

const assessmentChecksSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_CHECKS_EXECUTION_REQUEST: {
      return {
        ...state,
        startingAssessmentChecksExecution: true,
        startingAssessmentChecksExecutionError: false,
      };
    }
    case START_CHECKS_EXECUTION_SUCCESS: {
      return {
        ...state,
        startingAssessmentChecksExecution: false,
        assessmentChecksExecution: action.payload,
      };
    }
    case START_CHECKS_EXECUTION_FAILURE: {
      return {
        ...state,
        startingAssessmentChecksExecution: false,
        startingAssessmentChecksExecutionError: true,
      };
    }
    case SUPPRESS_FINDING_REQUEST: {
      return {
        ...state,
        isSuppressInProgress: true,
        isSuppressSuccess: false,
        isSupressFailure: false,
      };
    }
    case SUPPRESS_FINDING_SUCCESS: {
      return {
        ...state,
        isSuppressInProgress: false,
        isSuppressSuccess: true,
      };
    }
    case SUPPRESS_FINDING_FAILURE: {
      return {
        ...state,
        isSuppressInProgress: false,
        isSupressFailure: true,
      };
    }
    case SHOW_RE_RUN_CHECKS_CONFIRMATION_MODAL: {
      return {
        ...state,
        reRunChecksShowModal: true,
        reRunChecksAction: action.payload.action,
        reRunChecksTarget: action.payload.target,
      };
    }
    case HIDE_RE_RUN_CHECKS_CONFIRMATION_MODAL: {
      return {
        ...state,
        reRunChecksShowModal: false,
        reRunChecksAction: null,
        reRunChecksTarget: "",
      };
    }
    case CLEAR_SUPPRESS_FINDING: {
      return {
        ...state,
        isSuppressInProgress: false,
        isSuppressSuccess: false,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default assessmentChecksSummaryReducer;

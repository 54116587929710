import { isArrayWithLength } from "../../../../utils/array-methods";

export const filterTargetProjectEnviroments = (environments) => {
  return (
    isArrayWithLength(environments) &&
    environments.map((environment) => {
      return {
        value: environment.ID,
        label: environment.Name,
      };
    })
  );
};

export const SCOPE_TYPE = {
  PROJECTS: "projects",
  ACCOUNTS: "accounts",
};

export const MOVE_AWS_ACCOUNT_REQUEST = "MOVE_AWS_ACCOUNT_REQUEST";
export const MOVE_AWS_ACCOUNT_SUCCESS = "MOVE_AWS_ACCOUNT_SUCCESS";
export const MOVE_AWS_ACCOUNT_FAILED = "MOVE_AWS_ACCOUNT_FAILED";
export const CLEAR_MOVE_AWS_ACCOUNT = "CLEAR_MOVE_AWS_ACCOUNT";
export const GET_CONTROLTOWER_STATUS_REQUEST =
  "GET_CONTROLTOWER_STATUS_REQUEST";
export const GET_CONTROLTOWER_STATUS_SUCCESS =
  "GET_CONTROLTOWER_STATUS_SUCCESS";
export const GET_CONTROLTOWER_STATUS_FAILED = "GET_CONTROLTOWER_STATUS_FAILED";
export const CLEAR_CONTROLTOWER_STATUS = "CLEAR_CONTROLTOWER_STATUS";
export const CREATE_AWS_ACCOUNT_REQUEST = "CREATE_AWS_ACCOUNT_REQUEST";
export const CREATE_AWS_ACCOUNT_SUCCESS = "CREATE_AWS_ACCOUNT_SUCCESS";
export const CREATE_AWS_ACCOUNT_FAILED = "CREATE_AWS_ACCOUNT_FAILED";
export const CLEAR_CREATE_AWS_ACCOUNT = "CLEAR_CREATE_AWS_ACCOUNT";


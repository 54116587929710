import {
  MFA_CONFIG_URL,
  INITIATE_MFA_SETUP_URL,
  REGISTER_MFA_DEVICE_URL,
  VERIFY_MFA_URL,
  RESET_MFA_CONFIG_URL,
} from "../../../config/service/index";
import { getApiService, } from "../../../helpers/apiService";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiServiceHelper";

export const updateMFAConfigApi = (params) => {
  return getApiService(WEB_REQUEST_TYPE.PUT, MFA_CONFIG_URL, params);
};

export const initiateMFASetupApi = (params) => {
  return getApiService(WEB_REQUEST_TYPE.POST, INITIATE_MFA_SETUP_URL, params);
};

export const registerMFADeviceApi = (params) => {
  return getApiService(WEB_REQUEST_TYPE.POST, REGISTER_MFA_DEVICE_URL, params);
};

export const verifyMFAApi = (params) => {
  return getApiService(WEB_REQUEST_TYPE.POST, VERIFY_MFA_URL, params);
};

export const resetMFAConfigApi = (userId) => {
  const url = RESET_MFA_CONFIG_URL.replace("{userId}", userId);
  return getApiService(WEB_REQUEST_TYPE.POST, url);
};

import {
  CLOSE_BOT_SECTION,
  PIN_BOT_SECTION,
  SHOW_BOT_SECTION,
} from "../actionTypes/marvinActionTypes";

const initialState = {
  showBotSection: false,
  isPinned: false,
};

const marvinReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_BOT_SECTION: {
      return {
        ...state,
        showBotSection: true,
      };
    }
    case CLOSE_BOT_SECTION: {
      return {
        ...state,
        showBotSection: false,
      };
    }
    case PIN_BOT_SECTION: {
      return {
        ...state,
        isPinned: action.payload,
      };
    }
    default:
      return state;
  }
};

export default marvinReducer;

import {
  GET_RESOURCE_INSIGHT_SUMMARY_REQUEST,
  GET_RESOURCE_INSIGHT_SUMMARY_SUCCESS,
  GET_RESOURCE_INSIGHT_SUMMARY_FAILURE,
  TRIGGER_RESOURCE_INSIGHTS_ON_DEMAND_REQUEST,
  TRIGGER_RESOURCE_INSIGHTS_ON_DEMAND_SUCCESS,
  TRIGGER_RESOURCE_INSIGHTS_ON_DEMAND_FAILURE,
  RESET_RESOURCE_OPTIMIZATION_DATA,
} from "../actionTypes/optimization";

const initialState = {
  fetchingResourceInsightSummary: false,
  resourceInsightSummary: null,
  fetchingResourceInsightSummaryFailed: false,
  isTriggeringResourceInsights: false,
  triggeredResourceInsightsOnDemand: false,
  triggeringResourceInsightsFailed: false,
};

const resourceOptimization = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESOURCE_INSIGHT_SUMMARY_REQUEST:
      return {
        ...state,
        fetchingResourceInsightSummary: true,
        fetchingResourceInsightSummaryFailed:
          initialState.fetchingResourceInsightSummaryFailed,
        resourceInsightSummary: initialState.resourceInsightSummary,
      };
    case GET_RESOURCE_INSIGHT_SUMMARY_SUCCESS:
      return {
        ...state,
        fetchingResourceInsightSummary: false,
        resourceInsightSummary: action.payload,
      };
    case GET_RESOURCE_INSIGHT_SUMMARY_FAILURE:
      return {
        ...state,
        fetchingResourceInsightSummary: false,
        fetchingResourceInsightSummaryFailed: true,
        resourceInsightSummary: action && action.payload && action.payload.data,
      };
    case TRIGGER_RESOURCE_INSIGHTS_ON_DEMAND_REQUEST:
      return {
        ...state,
        isTriggeringResourceInsights: true,
        triggeredResourceInsightsOnDemand:
          initialState.triggeredResourceInsightsOnDemand,
        triggeringResourceInsightsFailed:
          initialState.triggeringResourceInsightsFailed,
      };
    case TRIGGER_RESOURCE_INSIGHTS_ON_DEMAND_SUCCESS:
      return {
        ...state,
        isTriggeringResourceInsights: false,
        triggeredResourceInsightsOnDemand: true,
      };
    case TRIGGER_RESOURCE_INSIGHTS_ON_DEMAND_FAILURE:
      return {
        ...state,
        isTriggeringResourceInsights: false,
        triggeringResourceInsightsFailed: true,
      };
    case RESET_RESOURCE_OPTIMIZATION_DATA:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default resourceOptimization;

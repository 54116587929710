import i18n from "../config/locale/i18n";

export const getNavFirstTabItems = (items, hideNav) =>
  items.map((item) => {
    if (item.id === "admin") {
      return {
        ...item,
        children: item.children.map((childItem) => {
          if (childItem.id === "subscription") {
            return {
              ...childItem,
              hide: hideNav,
            };
          }
          return childItem;
        }),
      };
    }
    return item;
  });

//This is allowed only in Basic Plan
export const PRODUCT_ID_DNC = "fdcd4a58-e27c-5d95-94b9-f99998a452f9";
//These are allowed in Advanced Plan
export const PRODUCT_ID_WMA = "f9096364-14b9-53cd-b7e1-ac71843b1532";
export const PRODUCT_ID_WMS = "b3acb8d7-47c6-5df2-8996-7daca76f6ad0";
export const PRODUCT_ID_WMC = "02d20664-d01e-5a4f-8059-4dd3c5b24bf7";

export const subscriptionProductIcon = (name) => {
  let icon = "";
  switch (name) {
    case PRODUCT_ID_WMS:
      icon = "/images/fre/50X50/server.png";
      break;
    case PRODUCT_ID_WMA:
      icon = "/images/fre/50X50/application.png";
      break;
    case PRODUCT_ID_WMC:
      icon = "/images/fre/50X50/governance.png";
      break;
    case PRODUCT_ID_DNC:
      icon = "/images/fre/50X50/visibility.png";
      break;
    default:
      break;
  }

  return icon;
};

export const subscriptionProductName = (name) => {
  let nameLabel = "";
  switch (name) {
    case PRODUCT_ID_WMS:
      nameLabel = i18n.t("common:serverLabel");
      break;
    case PRODUCT_ID_WMA:
      nameLabel = i18n.t("common:applicationLabel");
      break;
    default:
      break;
  }

  return nameLabel;
};

export const planType = [PRODUCT_ID_WMS, PRODUCT_ID_WMA, PRODUCT_ID_WMC];

export const CREATE_REPORT_SCHEDULE_REQUEST = "CREATE_REPORT_SCHEDULE_REQUEST";
export const CREATE_REPORT_SCHEDULE_SUCCESS = "CREATE_REPORT_SCHEDULE_SUCCESS";
export const CREATE_REPORT_SCHEDULE_FAILURE = "CREATE_REPORT_SCHEDULE_FAILURE";
export const GET_REPORT_SCHEDULES_REQUEST = "GET_REPORTS_SCHEDULE_REQUEST";
export const GET_REPORT_SCHEDULES_SUCCESS = "GET_REPORT_SCHEDULES_SUCCESS";
export const GET_REPORT_SCHEDULES_FAILURE = "GET_REPORT_SCHEDULES_FAILURE";
export const RESET_CREATE_REPORT_SCHEDULE = "RESET_CREATE_REPORT_SCHEDULE";
export const EDIT_REPORT_SCHEDULE_REQUEST = "EDIT_REPORT_SCHEDULE_REQUEST";
export const EDIT_REPORT_SCHEDULE_SUCCESS = "EDIT_REPORT_SCHEDULE_SUCCESS";
export const EDIT_REPORT_SCHEDULE_FAILURE = "EDIT_REPORT_SCHEDULE_FAILURE";
export const RESET_EDIT_REPORT_SCHEDULE = "RESET_EDIT_REPORT_SCHEDULE";

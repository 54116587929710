import React from "react";
import { useTranslation } from "react-i18next";

const AccountAlreadyExists = ({ handleStartOver }) => {
  const { t } = useTranslation("addAccount");

  return (
    <div className="text-center">
      <p className="mb-4">
        <strong>{t("accountAlreadyExistsMessage1")}</strong>
      </p>
      <p>{t("accountAlreadyExistsMessage2")}</p>
      <button
        className="btn orange-text btn-sm button-without-border"
        onClick={handleStartOver}
      >
        <i className="fa fa-repeat" /> {t("btn.startOver")}
      </button>
    </div>
  );
};

export default AccountAlreadyExists;

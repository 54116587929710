import React from "react";
import styles from "./NewFirstRun.module.scss";
import classnames from "classnames";
import { Col, Row } from "reactstrap";
import MCButton from "../../../components/MCButton/MCButton";
import { useTranslation, Trans } from "react-i18next";

const WelcomeContent = ({ next }) => {
  const { t } = useTranslation(["firstRun", "common"]);

  const username = localStorage.getItem("username");

  return (
    <div className={classnames(styles.BackDrop, "pb-4")}>
      <Row className={classnames(styles.row)}>
        <Col lg={5}>
          <img loading="lazy"
            className={styles.MCLogo}
            src="/images/DAY2Logo.jpg"
            alt="MontyCloud"
          />
          <div className={classnames("pl-3 mt-2", styles.partnerImageWrapper)}>
            <img loading="lazy"
              className={classnames(styles.PartnerInfo)}
              src="/images/badges/cloud_management_tools_competency.png"
              alt="MontyCloud"
            />
            <img loading="lazy"
              className={classnames(styles.PartnerInfo)}
              src="/images/badges/wafr_partner.png"
              alt="MontyCloud"
            />
            <img loading="lazy"
              className={classnames(styles.PartnerInfo)}
              src="/images/badges/public_sector_partner.png"
              alt="MontyCloud"
            />
          </div>
        </Col>
        <Col>
          <div>
            <h2
              className={classnames(styles.WelcomeText, styles.UserNameWidth)}
            >
              {t("common:welcome")},&nbsp;
              <span className="orange-text">{username}</span>
            </h2>
          </div>
          <div className="mt-5">
            <div className={classnames(styles.MCHeading3, styles.Bold600)}>
              {t("featureMsg1")}
            </div>
            <div
              className={classnames(
                styles.MCHeading4,
                styles.Bold500,
                styles.Opacity,
                "mt-3"
              )}
            >
              {t("featureMsg2")}
            </div>
            <div
              className={classnames(
                styles.MCHeading4,
                styles.Bold500,
                styles.Opacity,
                "mt-3"
              )}
            >
              <Trans
                i18nKey="firstRun:featureMsg3"
                values={{ percentage: "70%" }}
                components={[
                  <span
                    className={classnames(
                      styles.Bold600,
                      styles.MCHeading3,
                      "success-text"
                    )}
                  ></span>,
                ]}
              />
            </div>
            <div className={classnames("mt-4")}>
              <div>
                <span
                  className={classnames(
                    styles.StepNumberContainer,
                    styles.Active
                  )}
                >
                  <span className={styles.StepNumber}>{t("common:1")}</span>
                </span>
                <span className={styles.StepName}>{t("chooseFeatures​")}</span>
              </div>
              <div className="mt-4">
                <span
                  className={classnames(
                    styles.StepNumberContainer,
                    styles.Active
                  )}
                >
                  <span className={styles.StepNumber}>{t("common:2")}</span>
                </span>
                <span className={styles.StepName}>
                  {t("connectYourAccounts​")}
                </span>
              </div>
              <div className="mt-4">
                <span
                  className={classnames(
                    styles.StepNumberContainer,
                    styles.Active
                  )}
                >
                  <span className={styles.StepNumber}>{t("common:3")}</span>
                </span>
                <span className={styles.StepName}>{t("configureAccess")}</span>
              </div>
            </div>
            <MCButton
              size="lg"
              text={t("letGetStarted")}
              color="mc-blue"
              onClick={next}
              className={classnames("mt-5", styles.robotoFont)}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default WelcomeContent;

export const SET_NEXT_STEP_STATE = "SET_NEXT_STEP_STATE";
export const SET_DESIRED_STATE_POLICY_CONFIG = "SET_DESIRED_STATE_POLICY_CONFIG";

//HEADER FIELDS
export const DESIRED_STATE_POLICY_NAME_CHANGE = "DESIRED_STATE_POLICY_NAME_CHANGE";
export const DESIRED_STATE_POLICY_DESCRIPTION_CHANGE =
  "DESIRED_STATE_POLICY_DESCRIPTION_CHANGE";
export const DESIRED_STATE_POLICY_TYPE_CHANGE =
  "DESIRED_STATE_POLICY_TYPE_CHANGE";

//Reset forms
export const DESIRED_STATE_POLICY_RESET_FORM_FIELDS = "DESIRED_STATE_POLICY_RESET_FORM_FIELDS";
export const DESIRED_STATE_POLICY_RESET_SAVE_DATA = "DESIRED_STATE_POLICY_RESET_SAVE_DATA";

//Delete DSS Policy
export const DELETE_DESIRED_STATE_POLICY_REQUEST = "DELETE_DESIRED_STATE_POLICY_REQUEST";
export const DELETE_DESIRED_STATE_POLICY_SUCCESS = "DELETE_DESIRED_STATE_POLICY_SUCCESS";
export const DELETE_DESIRED_STATE_POLICY_FAILURE = "DELETE_DESIRED_STATE_POLICY_FAILURE";

// GET PATCH POLCY DETAILS
export const GET_DESIRED_STATE_POLICY_DETAIL_REQUEST =
  "GET_DESIRED_STATE_POLICY_DETAIL_REQUEST";
export const GET_DESIRED_STATE_POLICY_DETAIL_SUCCESS =
  "GET_DESIRED_STATE_POLICY_DETAIL_SUCCESS";
export const GET_DESIRED_STATE_POLICY_DETAIL_FAILURE =
  "GET_DESIRED_STATE_POLICY_DETAIL_FAILURE";

  //SAVE PATCH POLICY
export const SAVE_DESIRED_STATE_POLICY_REQUEST = "SAVE_DESIRED_STATE_POLICY_REQUEST";
export const SAVE_DESIRED_STATE_POLICY_SUCCESS = "SAVE_DESIRED_STATE_POLICY_SUCCESS";
export const SAVE_DESIRED_STATE_POLICY_FAILURE = "SAVE_DESIRED_STATE_POLICY_FAILURE";

export const SET_DESIRED_STATE_SCHEDULER = "SET_DESIRED_STATE_SCHEDULER";


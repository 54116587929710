export const SAVE_ORGANIZATION_SUCCESS = "SAVE_ORGANIZATION_SUCCESS";
export const SAVE_ORGANIZATION_FAILED = "SAVE_ORGANIZATION_FAILED";
export const SAVE_ORGANIZATION_REQUEST = "SAVE_ORGANIZATION_REQUEST";
export const EDIT_ORGANIZATION_SUCCESS = "EDIT_ORGANIZATION_SUCCESS";
export const EDIT_ORGANIZATION_FAILED = "EDIT_ORGANIZATION_FAILED";
export const EDIT_ORGANIZATION_REQUEST = "EDIT_ORGANIZATION_REQUEST";
export const SET_ORGANIZATION_NAME = "SET_ORGANIZATION_NAME";
export const SET_ORGANIZATION_DESCRIPTION = "SET_ORGANIZATION_DESCRIPTION";
export const SET_DEPARTMENT_NAME = "SET_DEPARTMENT_NAME";
export const SET_DEPARTMENT_DESCRIPTION = "SET_DEPARTMENT_DESCRIPTION";
export const GET_DISCOVERY_SUMMARY_REQUEST = "GET_DISCOVERY_SUMMARY_REQUEST";
export const GET_DISCOVERY_SUMMARY_SUCCESS = "GET_DISCOVERY_SUMMARY_SUCCESS";
export const GET_DISCOVERY_SUMMARY_FAILED = "GET_DISCOVERY_SUMMARY_FAILED";
export const GET_ALL_RESOURCES_REQUEST = "GET_ALL_RESOURCES_REQUEST";
export const GET_ALL_RESOURCES_SUCCESS = "GET_ALL_RESOURCES_SUCCESS";
export const GET_ALL_RESOURCES_FAILED = "GET_ALL_RESOURCES_FAILED";
export const GET_ALL_ORGANIZATIONS_REQUEST = "GET_ALL_ORGANIZATIONS_REQUEST";
export const GET_ALL_ORGANIZATIONS_SUCCESS = "GET_ALL_ORGANIZATIONS_SUCCESS";
export const GET_ALL_ORGANIZATIONS_FAILED = "GET_ALL_ORGANIZATIONS_FAILED";
export const GET_ALL_DEPARTMENTS_REQUEST = "GET_ALL_DEPARTMENTS_REQUEST";
export const GET_ALL_DEPARTMENTS_SUCCESS = "GET_ALL_DEPARTMENTS_SUCCESS";
export const GET_ALL_DEPARTMENTS_FAILED = "GET_ALL_DEPARTMENTS_FAILED";
export const SAVE_DEPARTMENT_SUCCESS = "SAVE_DEPARTMENT_SUCCESS";
export const SAVE_DEPARTMENT_FAILED = "SAVE_DEPARTMENT_FAILED";
export const SAVE_DEPARTMENT_REQUEST = "SAVE_DEPARTMENT_REQUEST";
export const ADD_RESOURCES_TO_DEPT_REQUEST = "ADD_RESOURCES_TO_DEPT_REQUEST";
export const ADD_RESOURCES_TO_DEPT_SUCCESS = "ADD_RESOURCES_TO_DEPT_SUCCESS";
export const ADD_RESOURCES_TO_DEPT_FAILED = "ADD_RESOURCES_TO_DEPT_FAILED";
export const SET_SELECTED_ORG = "SET_SELECTED_ORG";
export const SET_SELECTED_DEPT = "SET_SELECTED_DEPT";
export const CLEAR_DEPARTMENT_DATA = "CLEAR_DEPARTMENT_DATA";
export const CLEAR_FIRST_RUN_DATA = "CLEAR_FIRST_RUN_DATA";
export const UPDATE_DEPARTMENT_SEARCH_KEY = "UPDATE_DEPARTMENT_SEARCH_KEY";
export const CONFIGURE_REGIONS_REQUEST = "CONFIGURE_REGIONS_REQUEST";
export const CONFIGURE_REGIONS_SUCCESS = "CONFIGURE_REGIONS_SUCCESS";
export const CONFIGURE_REGIONS_FAILED = "CONFIGURE_REGIONS_FAILED";
export const SET_HIDE_CLOSE_BUTTON = "SET_HIDE_CLOSE_BUTTON";
export const CLEAR_SELECTED_DEPT = "CLEAR_SELECTED_DEPT";
export const SET_SELECTED_CLOUD_PROVIDER = "SET_SELECTED_CLOUD_PROVIDER";
export const SET_ACCOUNT_TYPE = "SET_ACCOUNT_TYPE";
export const CLEAR_DISCOVERY_SUMMARY = "CLEAR_DISCOVERY_SUMMARY";
export const ORG_HIERARCHY_REQUEST = "ORG_HIERARCHY_REQUEST";
export const ORG_HIERARCHY_SUCCESS = "ORG_HIERARCHY_SUCCESS";
export const ORG_HIERARCHY_ERROR = "ORG_HIERARCHY_ERROR";
export const ONBOARD_MEMBER_ACCOUNT_REQUEST = "ONBOARD_MEMBER_ACCOUNT_REQUEST";
export const ONBOARD_MEMBER_ACCOUNT_SUCCESS = "ONBOARD_MEMBER_ACCOUNT_SUCCESS";
export const ONBOARD_MEMBER_ACCOUNT_FAILED = "ONBOARD_MEMBER_ACCOUNT_FAILED";
export const OFFBOARD_MEMBER_ACCOUNT_REQUEST = "OFFBOARD_MEMBER_ACCOUNT_REQUEST";
export const OFFBOARD_MEMBER_ACCOUNT_SUCCESS = "OFFBOARD_MEMBER_ACCOUNT_SUCCESS";
export const OFFBOARD_MEMBER_ACCOUNT_FAILED = "OFFBOARD_MEMBER_ACCOUNT_FAILED";
export const TOGGLE_VIEW_TYPE = 'TOGGLE_VIEW_TYPE';



export const GET_AWS_INVENTORY_SUMMARY_BY_ACCOUNT_REQUEST =
  "GET_AWS_INVENTORY_SUMMARY_BY_ACCOUNT_REQUEST";

export const GET_AWS_INVENTORY_SUMMARY_BY_ACCOUNT_SUCCESS =
  "GET_AWS_INVENTORY_SUMMARY_BY_ACCOUNT_SUCCESS";

export const GET_AWS_INVENTORY_SUMMARY_BY_ACCOUNT_FAILURE =
  "GET_AWS_INVENTORY_SUMMARY_BY_ACCOUNT_FAILURE";

export const GET_AZURE_INVENTORY_SUMMARY_BY_ACCOUNT_REQUEST =
  "GET_AZURE_INVENTORY_SUMMARY_BY_ACCOUNT_REQUEST";

export const GET_AZURE_INVENTORY_SUMMARY_BY_ACCOUNT_SUCCESS =
  "GET_AZURE_INVENTORY_SUMMARY_BY_ACCOUNT_SUCCESS";

export const GET_AZURE_INVENTORY_SUMMARY_BY_ACCOUNT_FAILURE =
  "GET_AZURE_INVENTORY_SUMMARY_BY_ACCOUNT_FAILURE";

export const GET_AWS_INVENTORY_SUMMARY_BY_REGION_REQUEST =
  "GET_AWS_INVENTORY_SUMMARY_BY_REGION_REQUEST";

export const GET_AWS_INVENTORY_SUMMARY_BY_REGION_SUCCESS =
  "GET_AWS_INVENTORY_SUMMARY_BY_REGION_SUCCESS";

export const GET_AWS_INVENTORY_SUMMARY_BY_REGION_FAILURE =
  "GET_AWS_INVENTORY_SUMMARY_BY_REGION_FAILURE";

export const GET_AZURE_INVENTORY_SUMMARY_BY_REGION_REQUEST =
  "GET_AZURE_INVENTORY_SUMMARY_BY_REGION_REQUEST";

export const GET_AZURE_INVENTORY_SUMMARY_BY_REGION_SUCCESS =
  "GET_AZURE_INVENTORY_SUMMARY_BY_REGION_SUCCESS";

export const GET_AZURE_INVENTORY_SUMMARY_BY_REGION_FAILURE =
  "GET_AZURE_INVENTORY_SUMMARY_BY_REGION_FAILURE";

export const GET_AWS_INVENTORY_SUMMARY_TOTAL_RESOURCE_COUNT =
  "GET_AWS_INVENTORY_SUMMARY_TOTAL_RESOURCE_COUNT";

export const GET_AZURE_INVENTORY_SUMMARY_TOTAL_RESOURCE_COUNT =
  "GET_AZURE_INVENTORY_SUMMARY_TOTAL_RESOURCE_COUNT";

export const GET_AWS_INVENTORY_SUMMARY_BY_RESOURCE_REQUEST =
  "GET_AWS_INVENTORY_SUMMARY_BY_RESOURCE_REQUEST";

export const GET_AWS_INVENTORY_SUMMARY_BY_RESOURCE_SUCCESS =
  "GET_AWS_INVENTORY_SUMMARY_BY_RESOURCE_SUCCESS";

export const GET_AWS_INVENTORY_SUMMARY_BY_RESOURCE_FAILURE =
  "GET_AWS_INVENTORY_SUMMARY_BY_RESOURCE_FAILURE";

export const GET_AZURE_INVENTORY_SUMMARY_BY_RESOURCE_REQUEST =
  "GET_AZURE_INVENTORY_SUMMARY_BY_RESOURCE_REQUEST";

export const GET_AZURE_INVENTORY_SUMMARY_BY_RESOURCE_SUCCESS =
  "GET_AZURE_INVENTORY_SUMMARY_BY_RESOURCE_SUCCESS";

export const GET_AZURE_INVENTORY_SUMMARY_BY_RESOURCE_FAILURE =
  "GET_AZURE_INVENTORY_SUMMARY_BY_RESOURCE_FAILURE";

export const CLEAR_AWS_INVENTORY_SUMMARY = "CLEAR_AWS_INVENTORY_SUMMARY";
export const CLEAR_AZURE_INVENTORY_SUMMARY = "CLEAR_AZURE_INVENTORY_SUMMARY";

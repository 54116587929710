import { BuildByteArrayByOffsets } from "./serialize_utility";

/**
 * Bytes to Long
 * @param {*} byteArray
 * @param {*} beginIndex
 * @param {*} endIndex
 */
export function bytesToLong(byteArray, beginIndex, endIndex) {
  var arr = new Uint8Array(byteArray);
  let result = 0;
  for (let index = 0; index < endIndex - beginIndex; index++) {
    var char_index = endIndex - 1 - index;
    var char = arr[char_index];
    result = result + char * Math.pow(256, index);
  }
  return result;
}

/**
 * Byte array to string
 * @param {*} byteArray
 * @param {*} beginIndex
 * @param {*} endIndex
 */
export function ConvertByteArrayToString(byteArray, beginIndex, endIndex) {
  // const byteArrayPart = byteArray.slice(beginIndex, endIndex)
  // // // replace is used to trim null characters
  // // return new TextDecoder()
  // //     .decode(
  // //         byteArray.slice(beginIndex, endIndex)
  // //     ).repl(ace(/\0/g, '');
  // return ab2str(byteArrayPart);
  return new TextDecoder("utf-8")
    .decode(byteArray.slice(beginIndex, endIndex))
    .replace(/\0/g, "");
}

/** * Convert array of 16 byte values to UUID string format of the form: * XXXXXXXX-XXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX * * @param {Uint8Array} buf - content of the value * @returns {string} value - in UUID string format */
export function bytesToUuid(buf) {
  let i;
  const byteToHex = [];
  for (i = 0; i < 256; ++i) {
    byteToHex[i] = (i + 0x100).toString(16).substr(1);
  }
  const bth = byteToHex;
  i = 0;
  return (
    bth[buf[i++]] +
    bth[buf[i++]] +
    bth[buf[i++]] +
    bth[buf[i++]] +
    "-" +
    bth[buf[i++]] +
    bth[buf[i++]] +
    "-" +
    bth[buf[i++]] +
    bth[buf[i++]] +
    "-" +
    bth[buf[i++]] +
    bth[buf[i++]] +
    "-" +
    bth[buf[i++]] +
    bth[buf[i++]] +
    bth[buf[i++]] +
    bth[buf[i++]] +
    bth[buf[i++]] +
    bth[buf[i++]]
  );
}

/**
 * Bytes to UUID
 * @param {*} byteArray
 * @param {*} offset
 */

/** * Converts Uint8 array to uuid string by given offsets. * * @param {Uint8Array} byteArray - given Uint8 array * @param {number} beginIndex - begin index of the array * @returns {string} - uuid in string format */
export function bytesToUuidString(byteArray, beginIndex) {
  const reversedByteArray = new Uint8Array(16);
  const leastSignificantBits = byteArray.slice(beginIndex, beginIndex + 8);
  const mostSignificantBits = byteArray.slice(beginIndex + 8, beginIndex + 16);
  BuildByteArrayByOffsets(reversedByteArray, mostSignificantBits, 0, 8);
  BuildByteArrayByOffsets(reversedByteArray, leastSignificantBits, 8, 16);
  const uuid = bytesToUuid(reversedByteArray);
  return uuid;
}

/**
 * Convert bytes to int32
 * @param {*} byteArray
 */
// export function bytesToInt32(byteArray) {
//     // console.log("bytesToInt32", byteArray)
//     // bytes to byte array
//     const arr = new Uint8Array(byteArray);
//     // byte array to hex
//     const hex  = bytesToHex(arr);
//     // hex to binarty
//     const bin = hex2bin(hex);
//     // binary to decimal
//     const dec = binTodec(bin)
//     // console.log(dec, bin, hex, arr, byteArray);
//     return dec;
// }

export function bytesToInt32(byteArray) {
  const ab = new Uint8Array(byteArray).buffer;
  const view = new DataView(ab);
  let data = view.getInt32(0);
  return data;
}

/**
 * Arraybuffer to string
 */
export function ab2str(buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf));
}

/**
 * Convert string to array buffer
 */
export function str2ab(str) {
  var buf = new ArrayBuffer(str.length); // 2 bytes for each char
  var bufView = new Uint8Array(buf);
  for (var i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

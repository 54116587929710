import {
  GET_BILLING_GROUP_ACCOUNTS_LIST_REQUEST,
  GET_BILLING_GROUP_ACCOUNTS_LIST_SUCCESS,
  GET_BILLING_GROUP_ACCOUNTS_LIST_FAILURE,
  RESET_BILLING_GROUP_ACCOUNTS_LIST,
} from "../actionTypes/billingGroupAccountsTableActionTypes";

const initialState = {
  isFetchingBillingGroupAccountsList: false,
  fetchingBillingGroupAccountsListFailed: false,
  isFetchedBillingGroupAccountsList: false,
  billingGroupAccountsList: [],
  billingGroupAccountsListError: {},
};

const billingGroupAccountsList = (state = initialState, action) => {
  switch (action.type) {
    case GET_BILLING_GROUP_ACCOUNTS_LIST_REQUEST:
      return {
        ...state,
        isFetchingBillingGroupAccountsList: true,
        fetchingBillingGroupAccountsListFailed:
          initialState.fetchingBillingGroupAccountsListFailed,
        isFetchedBillingGroupAccountsList:
          initialState.isFetchedBillingGroupAccountsList,
      };
    case GET_BILLING_GROUP_ACCOUNTS_LIST_SUCCESS:
      return {
        ...state,
        isFetchingBillingGroupAccountsList: false,
        isFetchedBillingGroupAccountsList: true,
        billingGroupAccountsList: action.payload,
      };
    case GET_BILLING_GROUP_ACCOUNTS_LIST_FAILURE:
      return {
        ...state,
        isFetchingBillingGroupAccountsList: false,
        fetchingBillingGroupAccountsListFailed: true,
        isFetchedBillingGroupAccountsList: true,
        billingGroupAccountsListError: action.payload,
      };
    case RESET_BILLING_GROUP_ACCOUNTS_LIST:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default billingGroupAccountsList;

import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../config/customBaseQuery";
import {
  CONFIGURE_FEDERATED_ACCOUNT_ACCESS_LINK,
  FEDERATED_ACCESS_ACCOUNTS_BASE_PATH,
} from "../../../config/service";

export const federatedAccessApi = createApi({
  reducerPath: "federatedAccess",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["FederatedAccess"],
  endpoints: (builder) => ({
    getFederatedAccountsAccess: builder.query({
      query: ({ pageNumber, pageSize }) => {
        const params = `PageNumber=${pageNumber}&PageSize=${pageSize}`;
        return {
          url: `${FEDERATED_ACCESS_ACCOUNTS_BASE_PATH}?${params}`,
          method: "GET",
        };
      },
      providesTags: ["FederatedAccess"],
    }),
    createFederatedAccountAccess: builder.mutation({
      query: ({ accountId, Roles }) => {
        const data = {
          CustomerAccountId: accountId,
          Roles,
        };
        return {
          url: `${FEDERATED_ACCESS_ACCOUNTS_BASE_PATH}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["FederatedAccess"],
    }),
    createFederatedAccountAccessLink: builder.mutation({
      query: ({ accountId, role, duration }) => {
        const data = {
          CustomerAccountId: accountId,
          RoleId: role,
          Duration: duration,
        };
        return {
          url: `${FEDERATED_ACCESS_ACCOUNTS_BASE_PATH}${CONFIGURE_FEDERATED_ACCOUNT_ACCESS_LINK}`,
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetFederatedAccountsAccessQuery,
  useCreateFederatedAccountAccessMutation,
  useCreateFederatedAccountAccessLinkMutation,
} = federatedAccessApi;

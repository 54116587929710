import { CUSTOMER_ROLE_APP_ACCESS_ID, CUSTOMER_ROLE_DEPARTMENT_ACCESS_ID, CUSTOMER_ROLE_INFRA_ACCESS_ID, CUSTOMER_ROLE_OPERATION_ACCESS_ID, CUSTOMER_ROLE_REPORT_ACCESS_ID, FEATURE_PLANS,   CLOUD_PROVIDER_KEY,
} from "./app-constants.json";
import { Base64 } from "js-base64";
import _ from "lodash";
import { includes, lowerCase, some } from "lodash";

import { getAWSRegionName } from "../utils/aws-region-list";
import { areDateEqual, getDate, isFirstDateBeforeSecondDate, isFirstDateGreaterThanSecondDate, isValidDate } from "../utils/convert-date";
import { isArrayWithLength } from "./array-methods";

export const getParameters = (filters, offset, limit, category) => {
  const offsetFilter = offset ? `&Offset=${encodeURIComponent(offset)}` : "";
  const limitCount = limit ? `&Limit=${limit}` : "";
  const categoryFilter = category ? `&Category=${category}` : "";
  const filterSet = _.isEmpty(filters)
    ? ""
    : `Filters=${encodeURI(JSON.stringify(filters))}`;
  return `?${filterSet}${offsetFilter}${limitCount}${categoryFilter}`;
};

export const getCombinedOldNewData = (oldList, NewList) => {
  const combinedData = oldList && oldList.length > 0 && oldList.concat(NewList);
  return [...new Set(combinedData)];
};

export const getFilterParameters = (
  filters,
  offset,
  limit,
  additionalFilter,
  filterName
) => {
  const offsetFilter = offset ? `&Offset=${encodeURIComponent(offset)}` : "";
  const limitCount = limit ? `&Limit=${limit}` : "";
  const filterSet = _.isEmpty(filters)
    ? ""
    : `&Filters=${encodeURI(JSON.stringify(filters))}`;
  const additionalFilterSet = additionalFilter
    ? `${filterName}=${additionalFilter}`
    : "";
  return `?${additionalFilterSet}${offsetFilter}${limitCount}${filterSet}`;
};

export const getCustomerRoleType = (roleId) => {
  let roleType = "full-access";
  switch (roleId) {
    case CUSTOMER_ROLE_APP_ACCESS_ID:
      roleType = "app-access";
      break;
    case CUSTOMER_ROLE_REPORT_ACCESS_ID:
      roleType = "report-access";
      break;
    case CUSTOMER_ROLE_OPERATION_ACCESS_ID:
      roleType = "operation-access";
      break;
    case CUSTOMER_ROLE_INFRA_ACCESS_ID:
      roleType = "infrastructure-access";
      break;
    case CUSTOMER_ROLE_DEPARTMENT_ACCESS_ID:
      roleType = "department-access";
      break;
    default:
      roleType = "full-access";
      break;
  }

  return roleType;
};

export const getPermissionObject = (
  plansPermissions,
  path,
  isFreemium,
  isTrialExpired,
  isBasicPlan,
  isAdvancedPlan,
  isAdminUpgradeRequired,
  isEnterpriseCustomer
) => {
  const feature = path.split("/");
  const currentPath = feature[feature.length - 1];
  let permissionObject;
  //if freemium or trial has expired, only allow accounts screen
  if (isFreemium || (isTrialExpired && !isEnterpriseCustomer)) {
    const accountsFeature =
      isArrayWithLength(plansPermissions) &&
      plansPermissions.find(
        (permission) => permission.featureId === "accounts"
      );
    if (accountsFeature && accountsFeature.featureId === currentPath) {
      permissionObject = accountsFeature;
    }
    //if on basic plan, only allow features that work with basic permissions
  } else if (isBasicPlan || isAdminUpgradeRequired) {
    const basicPlanFeatures =
      isArrayWithLength(plansPermissions) &&
      plansPermissions.filter(
        (permission) =>
          permission.featurePlan === FEATURE_PLANS.CONTINUOUS_VISIBILITY
      );
    permissionObject =
      isArrayWithLength(basicPlanFeatures) &&
      basicPlanFeatures.find(
        (permission) => permission.featureId === currentPath
      );
    //if advanced, allow all paths
  } else if (isAdvancedPlan) {
    return { featureId: "*", featurePlan: "*" };
  }
  return permissionObject;
};

export const getSearchParams = (location, key) => {
  const params = new URLSearchParams(location.search);
  return params.get(key);
};

export const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  return !!token;
};

/**
 * @param element - The element you want to check if it's overflowing.
 * @returns An object with three properties: isOverFlow, isOverFlowHorizontally, and
 * isOverFlowVertically.
 * The value of isOverFlow is true if the element is overflowing in either direction.
 * The value of isOverFlowHorizontally is true if the element is overflowing horizontally.
 * The value of isOverFlowVertically is true if the element is overflowing vertically.
 */
export const isElementOverFlow = (element) => {
  let isOverFlowHorizontally = false;
  let isOverFlowVertically = false;
  let isOverFlow = false;

  if (element && element.current) {
    let el = element.current;
    isOverFlowHorizontally = el.clientWidth < el.scrollWidth;
    isOverFlowVertically = el.clientHeight < el.scrollHeight;
    isOverFlow = isOverFlowHorizontally || isOverFlowVertically;
  }

  return {
    isOverFlow,
    isOverFlowHorizontally,
    isOverFlowVertically,
  };
};

export const checkPasswordVisibility = (password) => {
  let pwdRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  return pwdRegex.test(password);
};

export const getParentOrgName = (orgList = []) => {
  let parentOrgName = "";
  if (isArrayWithLength(orgList)) {
    const parentOrg = orgList.find((org) => !org.ParentOrganizationId);
    parentOrgName = parentOrg ? parentOrg.Name : "";
  }
  return parentOrgName;
};

export const checkIsParentOrg = (currentOrgsList = []) => {
  return !(
    isArrayWithLength(currentOrgsList) &&
    currentOrgsList[0].ParentOrganizationId
  );
};

export const transitionViewIfSupported = (updateCb = () => { }) => {
  if (document.startViewTransition) {

    document.startViewTransition(updateCb);
  } else {
    updateCb();
  }
};

export function isHTML(str) {
  let doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

export function stripHtml(dirtyString) {
  const doc = new DOMParser().parseFromString(dirtyString, "text/html");
  return (
    doc.body.textContent.replaceAll("\\n", "").replace(/\s+/g, " ").trim() || ""
  );
}

export const encodeToBase64 = (string = "") => {
  return Base64.encode(string);
};

const lowerCaseReplace = (text) => lowerCase(text).replace(/\s/g, "");

export const customAccounts = (filter, value, filterText, accountList) => {
  const filterTextLowercase = lowerCaseReplace(filterText);
  const valueLowercase = lowerCaseReplace(value);

  //matching the value entered with the account number
  const matchesValue = includes(valueLowercase, filterTextLowercase);
  if (matchesValue) {
    return matchesValue;
  }

  //matching the value entered with the account name
  const matchesAccount = some(accountList, (account) => {
    const accountName = lowerCaseReplace(account.accountName);
    return (
      account.accountNumber === value &&
      includes(accountName, filterTextLowercase)
    );
  });

  return matchesAccount;
};

export const customRegions = (filter, value, filterText, regionList) => {
  const filterTextLowercase = lowerCaseReplace(filterText);
  const awsRegions = lowerCaseReplace(getAWSRegionName(value));
  const valueLowercase = lowerCaseReplace(value);

  //matching the value entered with the region name and region code
  return (
    includes(valueLowercase, filterTextLowercase) ||
    includes(awsRegions, filterTextLowercase) ||
    some(regionList, (region) => {
      const regionNameLowercase = lowerCaseReplace(region.regionName);
      return (
        region.regionCode === value &&
        includes(regionNameLowercase, filterTextLowercase)
      );
    })
  );
};

export const customDateComparator = (filterLocalDate, cellValue) => {
  if (!isValidDate(cellValue)) {
    return -1;
  }

  const updatedFilterLocalDate = getDate(filterLocalDate);
  const updatedCellValue = getDate(cellValue);

  if (areDateEqual(updatedFilterLocalDate, updatedCellValue)) {
    return 0;
  }

  if (isFirstDateBeforeSecondDate(updatedFilterLocalDate, updatedCellValue)) {
    return 1;
  }

  if (
    isFirstDateGreaterThanSecondDate(updatedFilterLocalDate, updatedCellValue)
  ) {
    return -1;
  }
};

export const getCloudProvider = () => {
  const cloudProviderType = JSON.parse(
    localStorage.getItem(CLOUD_PROVIDER_KEY)
  );
  return cloudProviderType || "AWS";
};

import { combineReducers } from "redux";
import { createReducer } from "../../../utils/create-reducer";
import {
  REPORT_EXPORTS,
  EXPORT_REPORT,
  SCHEDULED_REPORT_EXPORTS,
} from "../../../utils/action-names.json";

const reportExports = createReducer(REPORT_EXPORTS);
const exportReport = createReducer(EXPORT_REPORT);
const scheduledReportExports = createReducer(SCHEDULED_REPORT_EXPORTS);

export default combineReducers({
  reportExports,
  exportReport,
  scheduledReportExports,
});

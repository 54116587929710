export const ACCOUNT_TYPES = {
  STANDALONE: "STANDALONE",
  MANAGEMENT: "MANAGEMENT",
  MEMBER: "MEMBER",
};

export const configureAWSAccountStates = {
  SHOW_CONFIGURE_PERMISSIONS: "SHOW_CONFIGURE_PERMISSIONS",
  SHOW_STACK_CREATION: "SHOW_STACK_CREATION",
  SHOW_ACCOUNT_ALREADY_EXISTS: "SHOW_ACCOUNT_ALREADY_EXISTS",
  SHOW_SET_ACCOUNT_DETAILS: "SHOW_SET_ACCOUNT_DETAILS",
};

export function appendRandomNumberToStackName(url) {
  // Splitting the URL at the first question mark
  const parts = url.split("?");

  // Handling URLs that do not conform to the standard structure
  if (parts.length < 3) {
    console.error("URL format is not as expected");
    return url;
  }

  // The part containing the stackName parameter is likely the last part
  const queryParamsPart = parts[2];

  // Converting the query string into an object for easy manipulation
  const queryParams = new URLSearchParams(queryParamsPart);

  // Function to generate a random 6-digit number
  const generateRandomNumber = () =>
    Math.floor(100000 + Math.random() * 900000);

  // Checking if the stackName parameter exists
  if (queryParams.has("stackName")) {
    // Appending the random number to the stackName parameter
    const stackName =
      queryParams.get("stackName") + "-" + generateRandomNumber();
    queryParams.set("stackName", stackName);
  }

  // Reconstructing the URL with the modified stackName parameter
  return `${parts[0]}?${parts[1]}?${queryParams.toString()}`;
}

const baseURLApi = import.meta.env.VITE_API_BASE_PATH;
const cloudFormationUrl = import.meta.env.VITE_CLOUD_FORMATION_URL;
const offBoardCfnUrl = import.meta.env.VITE_OFFBOARD_CFN_URL;
const demoApp = import.meta.env.VITE_DEMO_APP;
const demoProd = import.meta.env.VITE_DEMO_PROD;
const environment = import.meta.env.VITE_ENVIRONMENT;
const environmentSuffix = import.meta.env.VITE_ENVIRONMENT_SUFFIX;
const paymentKey = import.meta.env.VITE_STRIPE_PAYMENT_KEY;
const activateSelfServiceKitLink = import.meta.env
  .VITE_ACTIVATION_SELF_SERVICE_KIT_LINK;
const rdpConnectorDownloadLink = import.meta.env
  .VITE_RDP_CONNECTOR_DOWNLOAD_LINK;
const adminUpgradeStackName = import.meta.env.VITE_ADMIN_UPGRADE_STACK_NAME;
const agGridLicenseKey = import.meta.env.VITE_AG_GRID_LICENSE_KEY;
const mixPanelKey = import.meta.env.VITE_MIX_PANEL_KEY;
const statsigKey = import.meta.env.VITE_STATSIG_KEY;
const isDevelopment = import.meta.env.VITE_NODE_ENV === "development";
const isEventTrackingEnabled =
  !isDevelopment && !demoApp && import.meta.env.VITE_TRACK_EVENT === "true";
const marvinUIURL = import.meta.env.VITE_MARVIN_UI_LINK;
const mixPanelEnabled = import.meta.env.VITE_MIX_PANEL_ENABLED === "true";
const statsigEnabled = import.meta.env.VITE_STATSIG_ENABLED === "true";

export default {
  baseURLApi,
  cloudFormationUrl,
  offBoardCfnUrl,
  demoApp,
  demoProd,
  environment,
  environmentSuffix,
  paymentKey,
  activateSelfServiceKitLink,
  rdpConnectorDownloadLink,
  adminUpgradeStackName,
  agGridLicenseKey,
  mixPanelKey,
  statsigKey,
  isEventTrackingEnabled,
  marvinUIURL,
  mixPanelEnabled,
  statsigEnabled
};

export const SAVE_COMMON_SELECTED_ACCOUNTS = "SAVE_COMMON_SELECTED_ACCOUNTS";
export const SAVE_ACCOUNT_PERMISSIONS = "SAVE_ACCOUNT_PERMISSIONS";
export const RESET_COMMONS_DATA = "RESET_COMMONS_DATA";
export const GET_COMMON_ACCOUNTS_REQUEST = "GET_COMMON_ACCOUNTS_REQUEST";
export const GET_COMMON_ACCOUNTS_SUCCESS = "GET_COMMON_ACCOUNTS_SUCCESS";
export const GET_COMMON_ACCOUNTS_FAILURE = "GET_COMMON_ACCOUNTS_FAILURE";
export const DO_RESOURCES_EXIST_REQUEST = "DO_RESOURCES_EXIST_REQUEST";
export const DO_RESOURCES_EXIST_SUCCESS = "DO_RESOURCES_EXIST_SUCCESS";
export const DO_RESOURCES_EXIST_FAILURE = "DO_RESOURCES_EXIST_FAILURE";
export const SET_FETCH_BP_PARAMETERS = "SET_FETCH_BP_PARAMETERS";
export const SET_RESET_RESOURCE_PARAMETERS = "SET_RESET_RESOURCE_PARAMETERS";
export const SAVE_COMMON_ADDED_NOTIFICATIONS =
  "SAVE_COMMON_ADDED_NOTIFICATIONS";
export const SAVE_PAGINATION_PAGE_DETAILS = "SAVE_PAGINATION_PAGE_DETAILS";
export const GET_ACCOUNT_PERMISSION_CFN_URL_REQUEST =
  "GET_ACCOUNT_PERMISSION_CFN_URL_REQUEST";
export const GET_ACCOUNT_PERMISSION_CFN_URL_SUCCESS =
  "GET_ACCOUNT_PERMISSION_CFN_URL_SUCCESS";
export const GET_ACCOUNT_PERMISSION_CFN_URL_FAILURE =
  "GET_ACCOUNT_PERMISSION_CFN_URL_FAILURE";
export const CLEAR_UPDATE_PERMISSION_DATA = "CLEAR_UPDATE_PERMISSION_DATA";
export const SAVE_MULTI_SCOPE_FILTERS = "SAVE_MULTI_SCOPE_FILTERS";
export const SAVE_MULTI_TREE_VIEW_FILTERS = "SAVE_MULTI_TREE_VIEW_FILTERS";
export const SAVE_ADDED_ACTIONS = "SAVE_ADDED_ACTIONS";
export const CLEAR_ADDED_ACTIONS = "CLEAR_ADDED_ACTIONS";
export const SAVE_ACTIONS_API_RESPONSE = "SAVE_ACTIONS_API_RESPONSE";
export const SAVE_COMMON_FILTERS_DATA = "SAVE_COMMON_FILTERS_DATA";
export const SAVE_GEN_REPORT_MULTI_FILTERS_DATA =
  "SAVE_GEN_REPORT_MULTI_FILTERS_DATA";
export const SAVE_GEN_REPORT_MULTI_SCOPE_DATA =
  "SAVE_GEN_REPORT_MULTI_SCOPE_DATA";
export const SET_GEN_REPORT_SCOPE_TYPE = "SET_GEN_REPORT_SCOPE_TYPE";
export const TOGGLE_ACCOUNT_IN_SELECTED_SCOPE_DATA =
  "TOGGLE_ACCOUNT_IN_SELECTED_SCOPE_DATA";
export const TOGGLE_PROJECT_IN_SELECTED_SCOPE_DATA =
  "TOGGLE_PROJECT_IN_SELECTED_SCOPE_DATA";
export const TOGGLE_ENVIRONMENTS_IN_SELECTED_SCOPE_DATA =
  "TOGGLE_ENVIRONMENTS_IN_SELECTED_SCOPE_DATA";
export const RESET_GEN_REPORT_SCOPE_DATA = "RESET_GEN_REPORT_SCOPE_DATA";
export const SAVE_GEN_REPORT_FILTER_SCOPE_DATA =
  "SAVE_GEN_REPORT_FILTER_SCOPE_DATA";

export const TOGGLE_SELECT_ALL_PROJECT_IN_SCOPE_DATA =
  "TOGGLE_SELECT_ALL_PROJECT_IN_SCOPE_DATA";

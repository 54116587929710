export const GET_RESOURCE_INSIGHT_SUMMARY_REQUEST =
  "GET_RESOURCE_INSIGHT_SUMMARY_REQUEST";
export const GET_RESOURCE_INSIGHT_SUMMARY_SUCCESS =
  "GET_RESOURCE_INSIGHT_SUMMARY_SUCCESS";
export const GET_RESOURCE_INSIGHT_SUMMARY_FAILURE =
  "GET_RESOURCE_INSIGHT_SUMMARY_FAILURE";
export const TRIGGER_RESOURCE_INSIGHTS_ON_DEMAND_REQUEST =
  "TRIGGER_RESOURCE_INSIGHTS_ON_DEMAND_REQUEST";
export const TRIGGER_RESOURCE_INSIGHTS_ON_DEMAND_SUCCESS =
  "TRIGGER_RESOURCE_INSIGHTS_ON_DEMAND_SUCCESS";
export const TRIGGER_RESOURCE_INSIGHTS_ON_DEMAND_FAILURE =
  "TRIGGER_RESOURCE_INSIGHTS_ON_DEMAND_FAILURE";
export const RESET_RESOURCE_OPTIMIZATION_DATA =
  "RESET_RESOURCE_OPTIMIZATION_DATA";

import i18n from "../../../config/locale/i18n";
import {
  SAVE_CREATE_BLUEPRINT_NAME,
  SAVE_CREATE_BLUEPRINT_DESCRIPTION,
  SAVE_CREATE_BLUEPRINT_TYPE,
  SAVE_CREATE_BLUEPRINT_CATEGORY,
  SAVE_CREATE_BLUEPRINT_DEPARTMENTS,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  SAVE_UPLOAD_TEMPLATE_BY_EDITOR,
  SAVE_UPLOAD_TEMPLATE_FILES,
  SAVE_UPLOAD_TEMPLATE_TEXT,
  SAVE_UPLOAD_TEMPLATE_FILES_PARENT,
  SAVE_PARAMETER_MANIFEST,
  SAVE_ALL_PARAMETERS,
  GET_CATEGORIES_REQUEST,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAILURE,
  VALIDATE_TEMPLATE_FILE_UPLOAD_REQUEST,
  VALIDATE_TEMPLATE_FILE_UPLOAD_SUCCESS,
  VALIDATE_TEMPLATE_FILE_UPLOAD_FAILURE,
  VALIDATE_TEMPLATE_TEXT_EDITOR_REQUEST,
  VALIDATE_TEMPLATE_TEXT_EDITOR_SUCCESS,
  VALIDATE_TEMPLATE_TEXT_EDITOR_FAILURE,
  SAVE_VALIDATION_REQUEST,
  SAVE_SELECTED_ROLE,
  GET_SELECTED_ROLE_DETAILS_REQUEST,
  GET_SELECTED_ROLE_DETAILS_SUCCESS,
  GET_SELECTED_ROLE_DETAILS_FAILURE,
  SAVE_ROLE_TEMPLATE,
  SAVE_NEW_ROLE_TEMPLATE,
  GET_APPENDED_ROLE_TEMPLATE_REQUEST,
  GET_APPENDED_ROLE_TEMPLATE_SUCCESS,
  GET_APPENDED_ROLE_TEMPLATE_FAILURE,
  CREATE_BLUEPRINT_REQUEST,
  CREATE_BLUEPRINT_SUCCESS,
  CREATE_BLUEPRINT_FAILURE,
  EDIT_BLUEPRINT_REQUEST,
  EDIT_BLUEPRINT_SUCCESS,
  EDIT_BLUEPRINT_FAILURE,
  CREATE_BLUEPRINT_ID_REQUEST,
  CREATE_BLUEPRINT_ID_SUCCESS,
  CREATE_BLUEPRINT_ID_FAILURE,
  TEMPLATE_UPDATED,
  RESET_CREATE_BLUEPRINT_DATA,
  SAVE_BLUEPRINT_DETAILS,
  HIDE_APPENDED_ROLE_TEXT,
  SET_ROLE_SELECTION,
  CLEAR_MANIFEST_EDITED,
  GET_SELECTED_BLUEPRINT_ROLE_DETAILS_REQUEST,
  GET_SELECTED_BLUEPRINT_ROLE_DETAILS_SUCCESS,
  GET_SELECTED_BLUEPRINT_ROLE_DETAILS_FAILURE,
  SET_BLUEPRINT_ROLE_EDITOR_DATA,
  UPDATE_BLUEPRINT_PERMISSIONS_REQUEST,
  UPDATE_BLUEPRINT_PERMISSIONS_SUCCESS,
  UPDATE_BLUEPRINT_PERMISSIONS_FAILURE,
  SET_BLUEPRINT_DETAILS,
  GET_UPLOADED_TEMPLATE_REQUEST,
  GET_UPLOADED_TEMPLATE_SUCCESS,
  GET_UPLOADED_TEMPLATE_FAILURE,
  SET_BLUEPRINT_TEMPLATES,
  SET_BLUEPRINT_ID,
  RESET_BLUEPRINT_ID,
  PERMISSION_HAS_ERRORS,
  GET_BLUEPRINT_COST_REQUEST,
  GET_BLUEPRINT_COST_SUCCESS,
  GET_BLUEPRINT_COST_FAILURE,
  SET_BLUEPRINT_COST_ID,
  CLEAR_BLUEPRINT_COST_DATA,
  SET_COST_CALCULATE_POPUP,
  UPDATE_BLUEPRINT_PARAMETERS_REQUEST,
  UPDATE_BLUEPRINT_PARAMETERS_SUCCESS,
  UPDATE_BLUEPRINT_PARAMETERS_FAILURE,
  RESET_CREATE_CATEGORY_DATA,
  UPDATE_BLUEPRINT_MONITORING_METRICS_REQUEST,
  UPDATE_BLUEPRINT_MONITORING_METRICS_SUCCESS,
  UPDATE_BLUEPRINT_MONITORING_METRICS_FAILURE,
  TF_SAVE_UPLOADED_FILE,
  SAVE_CREATE_BLUEPRINT_TERRAFORM_VERSION,
  SAVE_TERRAFORM_FILES_PERCENTAGE,
  SAVE_TERRAFORM_FILES_STATUS,
  CLEAR_TERRAFORM_FILE_UPLOAD,
  SKIP_PERMISSIONS,
} from "../actionTypes/blueprint";

import { setBlueprintValues, setBlueprintTemplates } from "../utils";
import { blueprintTypes, terraformVersions } from "../constants/blueprint";

const initialState = {
  blueprintName: "",
  blueprintDescription: "",
  blueprintType: {
    label: i18n.t("blueprints:blueprintType.cloudformation"),
    value: blueprintTypes.CLOUDFORMATION,
  },
  blueprintCategory: "",
  blueprintDepartments: [],
  creatingCategory: false,
  categoryCreated: false,
  createCategoryFailed: false,
  createCategoryErrorMessage: null,
  uploadTemplateByEditor: false,
  uploadedFiles: [],
  templateText: "",
  parentTemplate: null,
  isLoadingCategories: false,
  categoriesLoaded: false,
  categories: [],
  getCategoriesFailed: false,
  getCategoriesErrorMessage: "",
  validatingTemplateFileUpload: false,
  validateTempateFileUploadSuccess: false,
  validateTemplateFileUploadFailed: false,
  templateValidationFileUploadErrorMessage: "",
  validatingTemplateTextEditor: false,
  validateTempateTextEditorSuccess: false,
  validateTemplateTextEditorFailed: false,
  templateValidationTextEditorErrorMessage: "",
  parameterManifest: [],
  allParameterList: [],
  blueprintValidationRequest: null,
  creatingBlueprint: false,
  blueprintCreated: false,
  createBlueprintFailed: false,
  createBlueprintErrorMessage: "",
  templateUpdated: false,
  blueprintId: null,
  fetchingBlueprintId: false,
  blueprintIdFetched: false,
  selectedRoleName: null,
  isLoadingRoleDetails: false,
  selectedRoleDetails: null,
  loadedRoleDetails: false,
  loadingRoleDetailsFailed: true,
  roleText: "",
  roleNewText: "",
  editPermissionsRoleText: "",
  isLoadingAppendedRoleDetails: false,
  selectedAppendedRoleDetails: null,
  loadedAppendedRoleDetails: false,
  loadingAppendedRoleDetailsFailed: true,
  hideAppendedRole: false,
  createNewRole: null, // Since this field is used to operate toggle, null would indicate no selection as default
  customRoleName: null,
  parameterManifestSaved: false,
  manifestEdited: false,
  isLoadingBlueprintRoleDetails: false,
  selectedBlueprintRoleDetails: "",
  loadedBlueprintRoleDetails: false,
  updatingBlueprintPermissions: null, // Since this field is used to operate the save button in edit permissions, null would indicate no selection 
  blueprintPermissionsUpdated: false,
  isLoadingUploadedTemplateDetails: false,
  fetchUploadedTemplateDetailsFailed: false,
  uploadedTemplateDetails: null,
  editingBlueprint: false,
  blueprintEdited: false,
  editBlueprintFailed: false,
  editBlueprintErrorMessage: "",
  loadingBlueprintRoleDetailsFailed: false,
  permissionhasErrors: false,
  isFetchingBlueprintCost: false,
  blueprintCost: null,
  blueprintCostFetchError: false,
  blueprintCostId: null,
  isCostCalculatePopup: false,
  updatingParameters: false,
  updatingParametersSuccess: false,
  updatingParametersFailed: false,
  updatingBPMonitoring: false,
  updatedBPMonitoring: false,
  failedToUpdateBPMonitoring: false,
  terraform: {
    file: null,
  },
  terraformVersion: {
    label: i18n.t("blueprints:terraform.version.1.3"),
    value: terraformVersions.VERSION_1_3,
  },
  terraformFilesStatus: {},
  terraformFilesPercentage: null,
};

const blueprint = (state = initialState, action) => {
  switch (action.type) {
    case TEMPLATE_UPDATED:
      return {
        ...state,
        templateUpdated: action.payload,
      };
    case SAVE_CREATE_BLUEPRINT_NAME:
      return {
        ...state,
        blueprintName: action.payload,
      };
    case SAVE_CREATE_BLUEPRINT_DESCRIPTION:
      return {
        ...state,
        blueprintDescription: action.payload,
      };
    case SAVE_CREATE_BLUEPRINT_TYPE:
      return {
        ...state,
        blueprintType: action.payload,
      };
    case SAVE_CREATE_BLUEPRINT_CATEGORY:
      return {
        ...state,
        blueprintCategory: action.payload,
      };
    case SAVE_CREATE_BLUEPRINT_DEPARTMENTS:
      return {
        ...state,
        blueprintDepartments: action.payload,
      };
    case SAVE_UPLOAD_TEMPLATE_BY_EDITOR:
      return {
        ...state,
        uploadTemplateByEditor: action.payload,
      };
    case SAVE_UPLOAD_TEMPLATE_FILES:
      return {
        ...state,
        uploadedFiles: action.payload,
      };
    case SAVE_UPLOAD_TEMPLATE_TEXT:
      return {
        ...state,
        templateText: action.payload,
      };
    case SAVE_UPLOAD_TEMPLATE_FILES_PARENT:
      return {
        ...state,
        parentTemplate: action.payload,
      };
    case SAVE_PARAMETER_MANIFEST:
      const { data, edited } = action.payload;
      return {
        ...state,
        parameterManifest: data,
        parameterManifestSaved: true,
        manifestEdited: edited,
      };
    case CLEAR_MANIFEST_EDITED:
      return {
        ...state,
        manifestEdited: initialState.manifestEdited,
      };
    case SAVE_ALL_PARAMETERS:
      return {
        ...state,
        allParameterList: action.payload,
      };
    case SAVE_VALIDATION_REQUEST:
      return {
        ...state,
        blueprintValidationRequest: action.payload,
      };
    case CREATE_CATEGORY_REQUEST:
      return {
        ...state,
        creatingCategory: true,
        categoryCreated: false,
        createCategoryFailed: false,
        createCategoryErrorMessage: "",
      };
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        creatingCategory: false,
        categoryCreated: true,
        createCategoryFailed: false,
        createCategoryErrorMessage: "",
      };
    case CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        creatingCategory: false,
        categoryCreated: false,
        createCategoryFailed: true,
        createCategoryErrorMessage: action.payload,
      };
    case GET_CATEGORIES_REQUEST:
      return {
        ...state,
        isLoadingCategories: true,
        categoriesLoaded: false,
        getCategoriesFailed: false,
        getCategoriesErrorMessage: "",
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoadingCategories: false,
        categoriesLoaded: true,
        categories: action.payload,
        getCategoriesFailed: false,
        getCategoriesErrorMessage: "",
      };
    case GET_CATEGORIES_FAILURE:
      return {
        ...state,
        isLoadingCategories: false,
        categoriesLoaded: false,
        getCategoriesFailed: true,
        getCategoriesErrorMessage: action.payload,
      };
    case VALIDATE_TEMPLATE_FILE_UPLOAD_REQUEST:
      return {
        ...state,
        validatingTemplateFileUpload: true,
        validateTempateFileUploadSuccess: false,
        validateTemplateFileUploadFailed: false,
        templateValidationFileUploadErrorMessage: "",
      };
    case VALIDATE_TEMPLATE_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        validatingTemplateFileUpload: false,
        validateTempateFileUploadSuccess: true,
        validateTemplateFileUploadFailed: false,
        templateValidationFileUploadErrorMessage: "",
      };
    case VALIDATE_TEMPLATE_FILE_UPLOAD_FAILURE:
      let error = {};
      if (action.payload.status === 400) {
        error = action.payload;
      }
      return {
        ...state,
        validatingTemplateFileUpload: false,
        validateTempateFileUploadSuccess: false,
        validateTemplateFileUploadFailed: true,
        templateValidationFileUploadErrorMessage: error,
      };
    case VALIDATE_TEMPLATE_TEXT_EDITOR_REQUEST:
      return {
        ...state,
        validatingTemplateTextEditor: true,
        validateTempateTextEditorSuccess: false,
        validateTemplateTextEditorFailed: false,
        templateValidationTextEditorErrorMessage: "",
      };
    case VALIDATE_TEMPLATE_TEXT_EDITOR_SUCCESS:
      return {
        ...state,
        validatingTemplateTextEditor: false,
        validateTempateTextEditorSuccess: true,
        validateTemplateTextEditorFailed: false,
        templateValidationTextEditorErrorMessage: "",
      };
    case VALIDATE_TEMPLATE_TEXT_EDITOR_FAILURE:
      let textError = {};
      let didFail = false;
      if (action.payload.status === 400) {
        didFail = true;
        textError = action.payload;
      }
      return {
        ...state,
        validatingTemplateTextEditor: false,
        validateTempateTextEditorSuccess: false,
        validateTemplateTextEditorFailed: didFail,
        templateValidationTextEditorErrorMessage: textError,
      };
    case SAVE_SELECTED_ROLE:
      return {
        ...state,
        selectedRoleName: action.payload,
      };
    case GET_SELECTED_ROLE_DETAILS_REQUEST:
      return {
        ...state,
        isLoadingRoleDetails: true,
        selectedRoleDetails: initialState.selectedRoleDetails,
        loadedRoleDetails: initialState.loadedRoleDetails,
        loadingRoleDetailsFailed: initialState.loadingRoleDetailsFailed,
      };
    case GET_SELECTED_ROLE_DETAILS_SUCCESS:
      return {
        ...state,
        isLoadingRoleDetails: false,
        selectedRoleDetails: action.payload,
        loadedRoleDetails: true,
        loadingRoleDetailsFailed: false,
      };
    case GET_SELECTED_ROLE_DETAILS_FAILURE:
      return {
        ...state,
        isLoadingRoleDetails: false,
        selectedRoleDetails: null,
        loadedRoleDetails: false,
        loadingRoleDetailsFailed: true,
      };
    case SAVE_ROLE_TEMPLATE:
      return {
        ...state,
        roleText: action.payload,
      };
    case SAVE_NEW_ROLE_TEMPLATE:
      return {
        ...state,
        roleNewText: action.payload,
      };
    case SKIP_PERMISSIONS:
      // In Create Task, to Skip need to set roleText as []
      if (action.payload) {
        return {
          ...state,
          selectedRoleName: null,
          roleText: action.payload,
          roleNewText: "",
          createNewRole: null,
        };
      } else if (action.payload !== undefined) {
        return {
          ...state,
          selectedRoleName: null,
          roleText: action.payload,
          roleNewText: "",
          createNewRole: null,
        };
      } else {
        return {
          ...state,
          selectedRoleName: null,
          roleNewText: "",
          createNewRole: null,
        };
      }
    case PERMISSION_HAS_ERRORS:
      return {
        ...state,
        permissionhasErrors: action.payload,
      };
    case GET_APPENDED_ROLE_TEMPLATE_REQUEST:
      return {
        ...state,
        isLoadingAppendedRoleDetails: true,
        selectedAppendedRoleDetails: initialState.selectedAppendedRoleDetails,
        loadedAppendedRoleDetails: initialState.loadedAppendedRoleDetails,
        loadingAppendedRoleDetailsFailed:
          initialState.loadingAppendedRoleDetailsFailed,
      };
    case GET_APPENDED_ROLE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoadingAppendedRoleDetails: false,
        selectedAppendedRoleDetails: action.payload,
        loadedAppendedRoleDetails: true,
        loadingAppendedRoleDetailsFailed: false,
      };
    case GET_APPENDED_ROLE_TEMPLATE_FAILURE:
      return {
        ...state,
        isLoadingAppendedRoleDetails: false,
        selectedAppendedRoleDetails: null,
        loadedAppendedRoleDetails: false,
        loadingAppendedRoleDetailsFailed: true,
      };
    case CREATE_BLUEPRINT_REQUEST:
      return {
        ...state,
        creatingBlueprint: true,
        blueprintCreated: false,
        createBlueprintFailed: false,
        createBlueprintErrorMessage: "",
      };
    case CREATE_BLUEPRINT_SUCCESS:
      return {
        ...state,
        creatingBlueprint: false,
        blueprintCreated: true,
        createBlueprintFailed: false,
        createBlueprintErrorMessage: "",
      };
    case CREATE_BLUEPRINT_FAILURE:
      return {
        ...state,
        creatingBlueprint: false,
        blueprintCreated: false,
        createBlueprintFailed: true,
        createBlueprintErrorMessage: action.payload,
      };
    case EDIT_BLUEPRINT_REQUEST:
      return {
        ...state,
        editingBlueprint: true,
        blueprintEdited: false,
        editBlueprintFailed: false,
        editBlueprintErrorMessage: "",
      };
    case EDIT_BLUEPRINT_SUCCESS:
      return {
        ...state,
        editingBlueprint: false,
        blueprintEdited: true,
        editBlueprintFailed: false,
        editBlueprintErrorMessage: "",
      };
    case EDIT_BLUEPRINT_FAILURE:
      return {
        ...state,
        editingBlueprint: false,
        blueprintEdited: false,
        editBlueprintFailed: true,
        editBlueprintErrorMessage: action.payload,
      };
    case SET_BLUEPRINT_ID:
      return {
        ...state,
        blueprintId: action.payload,
      };
    case RESET_BLUEPRINT_ID:
      return {
        ...state,
        blueprintId: initialState.blueprintId,
        uploadedTemplateDetails: initialState.uploadedTemplateDetails,
        isLoadingUploadedTemplateDetails:
          initialState.isLoadingUploadedTemplateDetails,
        fetchUploadedTemplateDetailsFailed:
          initialState.fetchUploadedTemplateDetailsFailed,
      };
    case CREATE_BLUEPRINT_ID_REQUEST:
      return {
        ...state,
        fetchingBlueprintId: true,
        blueprintIdFetched: false,
      };
    case CREATE_BLUEPRINT_ID_SUCCESS:
      return {
        ...state,
        blueprintId: action.payload,
        fetchingBlueprintId: false,
        blueprintIdFetched: true,
      };
    case CREATE_BLUEPRINT_ID_FAILURE:
      return {
        ...state,
        fetchingBlueprintId: false,
        blueprintIdFetched: false,
      };
    case SAVE_BLUEPRINT_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_CREATE_BLUEPRINT_DATA:
      return {
        ...state,
        ...initialState,
        categories: state.categories,
      };
    case HIDE_APPENDED_ROLE_TEXT:
      return {
        ...state,
        hideAppendedRole: action.payload,
      };
    case SET_ROLE_SELECTION:
      return {
        ...state,
        createNewRole: action.payload,
      };
    case GET_SELECTED_BLUEPRINT_ROLE_DETAILS_REQUEST:
      return {
        ...state,
        isLoadingBlueprintRoleDetails: true,
        selectedBlueprintRoleDetails: initialState.selectedBlueprintRoleDetails,
        loadedBlueprintRoleDetails: false,
        loadingBlueprintRoleDetailsFailed:
          initialState.loadingBlueprintRoleDetailsFailed,
      };
    case GET_SELECTED_BLUEPRINT_ROLE_DETAILS_SUCCESS:
      return {
        ...state,
        isLoadingBlueprintRoleDetails: false,
        selectedBlueprintRoleDetails: action.payload,
        loadedBlueprintRoleDetails: true,
      };
    case GET_SELECTED_BLUEPRINT_ROLE_DETAILS_FAILURE:
      return {
        ...state,
        isLoadingBlueprintRoleDetails: false,
        loadedBlueprintRoleDetails: false,
        loadingBlueprintRoleDetailsFailed: true,
      };
    case SET_BLUEPRINT_ROLE_EDITOR_DATA:
      return {
        ...state,
        roleText: action.payload,
        createNewRole: false,
      };
    case UPDATE_BLUEPRINT_PERMISSIONS_REQUEST:
      return {
        ...state,
        updatingBlueprintPermissions: true,
        blueprintPermissionsUpdated: false,
      };
    case UPDATE_BLUEPRINT_PERMISSIONS_SUCCESS:
      return {
        ...state,
        updatingBlueprintPermissions: false,
        blueprintPermissionsUpdated: true,
      };
    case UPDATE_BLUEPRINT_PERMISSIONS_FAILURE:
      return {
        ...state,
        updatingBlueprintPermissions: false,
        blueprintPermissionsUpdated: false,
      };
    case GET_UPLOADED_TEMPLATE_REQUEST:
      return {
        ...state,
        isLoadingUploadedTemplateDetails: true,
        fetchUploadedTemplateDetailsFailed:
          initialState.fetchUploadedTemplateDetailsFailed,
        uploadedTemplateDetails: initialState.uploadedTemplateDetails,
      };
    case GET_UPLOADED_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadedTemplateDetails: action.payload,
        isLoadingUploadedTemplateDetails: false,
      };
    case GET_UPLOADED_TEMPLATE_FAILURE:
      return {
        ...state,
        isLoadingUploadedTemplateDetails: false,
        fetchUploadedTemplateDetailsFailed: true,
      };
    case UPDATE_BLUEPRINT_PARAMETERS_REQUEST:
      return {
        ...state,
        updatingParameters: true,
        updatingParametersSuccess: initialState.updatingParametersSuccess,
        updatingParametersFailed: initialState.updatingParametersFailed,
      };
    case UPDATE_BLUEPRINT_PARAMETERS_SUCCESS:
      return {
        ...state,
        updatingParameters: false,
        updatingParametersSuccess: true,
        manifestEdited: initialState.manifestEdited,
      };
    case UPDATE_BLUEPRINT_PARAMETERS_FAILURE:
      return {
        ...state,
        updatingParameters: false,
        updatingParametersFailed: true,
      };
    case SET_BLUEPRINT_DETAILS:
      const blueprintDetails = setBlueprintValues(action.payload);
      return {
        ...state,
        ...blueprintDetails,
      };
    case SET_BLUEPRINT_TEMPLATES:
      const blueprintTemplates = setBlueprintTemplates(action.payload);
      return {
        ...state,
        ...blueprintTemplates,
      };
    case GET_BLUEPRINT_COST_REQUEST:
      return {
        ...state,
        isFetchingBlueprintCost: true,
        blueprintCost: initialState.blueprintCost,
        blueprintCostFetchError: false,
      };
    case GET_BLUEPRINT_COST_SUCCESS:
      return {
        ...state,
        isFetchingBlueprintCost: false,
        blueprintCost: action.payload,
      };
    case GET_BLUEPRINT_COST_FAILURE:
      return {
        ...state,
        isFetchingBlueprintCost: false,
        blueprintCost: null,
        blueprintCostFetchError: true,
      };

    case UPDATE_BLUEPRINT_MONITORING_METRICS_REQUEST:
      return {
        ...state,
        updatingBPMonitoring: true,
        updatedBPMonitoring: initialState.updatedBPMonitoring,
        failedToUpdateBPMonitoring: initialState.failedToUpdateBPMonitoring,
      };
    case UPDATE_BLUEPRINT_MONITORING_METRICS_SUCCESS:
      return {
        ...state,
        updatingBPMonitoring: false,
        updatedBPMonitoring: action.payload,
      };
    case UPDATE_BLUEPRINT_MONITORING_METRICS_FAILURE:
      return {
        ...state,
        updatingBPMonitoring: false,
        blueprintCost: null,
        failedToUpdateBPMonitoring: true,
      };

    case SET_BLUEPRINT_COST_ID:
      return {
        ...state,
        blueprintCostId: action.payload,
      };
    case SET_COST_CALCULATE_POPUP:
      return {
        ...state,
        isCostCalculatePopup: action.payload,
      };
    case CLEAR_BLUEPRINT_COST_DATA:
      return {
        ...state,
        blueprintCostId: initialState.blueprintCostId,
        isFetchingBlueprintCost: initialState.isFetchingBlueprintCost,
        blueprintCost: initialState.blueprintCost,
        blueprintCostFetchError: initialState.blueprintCostFetchError,
        isCostCalculatePopup: initialState.isCostCalculatePopup,
      };
    case RESET_CREATE_CATEGORY_DATA:
      return {
        ...state,
        creatingCategory: initialState.creatingCategory,
        categoryCreated: initialState.categoryCreated,
        createCategoryFailed: initialState.createCategoryFailed,
        createCategoryErrorMessage: initialState.createCategoryErrorMessage,
      };
    case TF_SAVE_UPLOADED_FILE:
      return {
        ...state,
        terraform: {
          ...state.terraform,
          file: action.payload,
        },
      };
    case SAVE_CREATE_BLUEPRINT_TERRAFORM_VERSION:
      return {
        ...state,
        terraformVersion: action.payload,
      };
    case SAVE_TERRAFORM_FILES_PERCENTAGE:
      return {
        ...state,
        terraformFilesPercentage: action.payload,
      };
    case SAVE_TERRAFORM_FILES_STATUS:
      return {
        ...state,
        terraformFilesStatus: action.payload,
      };
    case CLEAR_TERRAFORM_FILE_UPLOAD:
      return {
        ...state,
        terraformFilesStatus: initialState.terraformFilesStatus,
        terraform: initialState.terraform,
        terraformFilesPercentage: initialState.terraformFilesPercentage,
        parameterManifest: initialState.parameterManifest,
        parameterManifestSaved: initialState.parameterManifestSaved,
        selectedRoleName: initialState.selectedRoleName,
        createNewRole: initialState.createNewRole,
        roleText: initialState.roleText,
        selectedAppendedRoleDetails: initialState.selectedAppendedRoleDetails,
        loadedAppendedRoleDetails: initialState.loadedAppendedRoleDetails,
        loadingAppendedRoleDetailsFailed:
          initialState.loadingAppendedRoleDetailsFailed,
        customRoleName: initialState.customRoleName,
      };
    default:
      return {
        ...state,
      };
  }
};

export default blueprint;

import { createApi } from "@reduxjs/toolkit/query/react";
import {
  ASSIGN_CREDIT,
  BULK_ASSIGN_CREDIT,
  BULK_UNASSIGN_CREDIT,
  CREDITS_LIST,
  CREDITS_SUMMARY,
  TOTAL_CREDITS_RECEIVED,
  UNASSIGN_CREDIT,
} from "../../../../../../config/service";
import {
  WEB_REQUEST_TYPE,
  getApiService,
} from "../../../../../../helpers/apiService";
import { axiosBaseQuery } from "../../../../../../config/customBaseQuery";
import { isArrayWithLength } from "../../../../../../utils/array-methods";

export const fetchCreditsGroup = ({
  BillingPeriod,
  OrganizationId,
  Dimension,
  Limit,
  Offset,
  sortModel = null,
}) => {
  const url = CREDITS_SUMMARY.replace("{orgId}", OrganizationId);
  let filters = {};
  filters["BillingPeriod"] = BillingPeriod;
  if (Dimension) {
    filters["Dimension"] = Dimension;
  }
  let urlWithParams = `${url}?Filters=${encodeURI(
    JSON.stringify(filters)
  )}&Limit=${Limit}&Offset=${Offset}`;

  if (sortModel) {
    urlWithParams = `${urlWithParams}&SortBy=${sortModel.sortField}&SortOrder=${
      sortModel.sortOrder === "asc" ? "ASC" : "DESC"
    }`;
  }

  return getApiService(WEB_REQUEST_TYPE.GET, urlWithParams);
};

export const fetchCredits = ({
  BillingPeriod,
  OrganizationId,
  Dimension,
  groupKey,
  Limit,
  Offset,
  sortModel = null,
  filterModel = null,
  status = null,
}) => {
  const url = CREDITS_LIST.replace("{orgId}", OrganizationId);
  let filters = {};
  if (isArrayWithLength(filterModel)) {
    filters = { KeywordSearch: [...filterModel] };
  }
  filters["BillingPeriod"] = BillingPeriod;
  if (Dimension) {
    filters[Dimension] = groupKey;
  }
  if (status) {
    filters["Status"] = status;
  }

  let urlWithParams = `${url}?Filters=${encodeURI(
    JSON.stringify(filters)
  )}&Limit=${Limit}&Offset=${Offset}`;

  if (sortModel) {
    urlWithParams = `${urlWithParams}&SortBy=${sortModel.sortField}&SortOrder=${
      sortModel.sortOrder === "asc" ? "ASC" : "DESC"
    }`;
  }

  return getApiService(WEB_REQUEST_TYPE.GET, urlWithParams);
};

export const creditsAPI = createApi({
  reducerPath: "creditsAPI",
  baseQuery: axiosBaseQuery(),
  credentials: "true",
  endpoints: (builder) => ({
    unassignCredit: builder.mutation({
      query({ CreditId, OrganizationId }) {
        let url = UNASSIGN_CREDIT.replace("{orgId}", OrganizationId);
        url = url.replace("{creditId}", CreditId);
        return {
          url: url,
          method: WEB_REQUEST_TYPE.PUT,
        };
      },
    }),
    assignCredit: builder.mutation({
      query({
        CreditId,
        OrganizationId,
        Name,
        Description,
        BillingGroupARN,
        ChargeDetails,
        AccountNumber,
        AppliesTo,
        BillingPeriod,
      }) {
        let url = ASSIGN_CREDIT.replace("{orgId}", OrganizationId);
        url = url.replace("{creditId}", CreditId);

        return {
          url: url,
          method: WEB_REQUEST_TYPE.POST,
          body: {
            Name,
            Description,
            BillingGroupArn: BillingGroupARN,
            ChargeDetails,
            AccountNumber,
            AppliesTo,
            BillingPeriod,
          },
        };
      },
    }),
    bulkAssignCredit: builder.mutation({
      query({ selectedCredits, OrganizationId }) {
        let url = BULK_ASSIGN_CREDIT.replace("{orgId}", OrganizationId);
        return {
          url: url,
          method: WEB_REQUEST_TYPE.POST,
          body: selectedCredits,
        };
      },
    }),
    bulkUnassignCredit: builder.mutation({
      query({ selectedCredits, OrganizationId }) {
        let url = BULK_UNASSIGN_CREDIT.replace("{orgId}", OrganizationId);
        return {
          url: url,
          method: WEB_REQUEST_TYPE.POST,
          body: selectedCredits,
        };
      },
    }),
    getTotalCreditsReceived: builder.query({
      query({ OrganizationId, BillingPeriod }) {
        return {
          url: `${TOTAL_CREDITS_RECEIVED.replace(
            "{orgId}",
            OrganizationId
          )}?billingPeriod=${BillingPeriod}`,
          method: WEB_REQUEST_TYPE.GET,
        };
      },
    }),
  }),
});

export const {
  useEditCreditMutation,
  useUnassignCreditMutation,
  useAssignCreditMutation,
  useBulkAssignCreditMutation,
  useBulkUnassignCreditMutation,
  useGetTotalCreditsReceivedQuery,
} = creditsAPI;

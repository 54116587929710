import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../config/customBaseQuery";
import {
  AWS_TENANT_ACCOUNT_BASE_URL,
  LIST_ONBOARDED_ACCOUNTS,
} from "../../../config/service";
import { CLOUDPROVIDERS } from "../../../utils/app-constants.json";

export const tenantAccountsApi = createApi({
  reducerPath: "tenantAccountsAPI",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getTenantAccounts: builder.query({
      query: ({ tenantId }) => {
        return {
          url: `${AWS_TENANT_ACCOUNT_BASE_URL}${tenantId}/${LIST_ONBOARDED_ACCOUNTS}?CloudProvider=${CLOUDPROVIDERS.AWS}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetTenantAccountsQuery } = tenantAccountsApi;

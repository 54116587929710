import { postNotification } from "./notification-message";
import i18n from "../config/locale/i18n";

export const showErrorNotification = (
  dispatch,
  error,
  notificationMessage,
  showToaster = true
) => {
  const err = JSON.parse(error) && JSON.parse(error);
  if (err.status !== 403) {
    const message = notificationMessage
      ? notificationMessage
      : (err && err.data && err.data.ErrorMessage) ||
        i18n.t("common:errorMessage");
    const type = "danger";
    if (showToaster) dispatch(postNotification(message, type));
    return message;
  }
};

import { getAccountName } from "../../utils/account-region";
import i18n from "../../config/locale/i18n";
import { isArrayWithLength } from "../../utils/array-methods";
import { TERRAFORM, MC } from "../../utils/app-constants.json";
import {
  BLUEPRINT_FILTER_TYPES,
  blueprintTypesList,
} from "./constants/blueprint";
import { get, has } from "lodash";

export const BLUEPRINT_MIGRATION_VERSION = "1.0";
export const MAX_PERMISSION_LENGTH = 5780;

export const setBlueprintValues = (blueprint) => {
  const {
    metadata: { name, description, category, departments, type },
    roleName,
    parameters,
    roleArn,
  } = blueprint;
  const parameterManifest = parameters && parameters.parameterGroups;
  const allParameterList =
    parameterManifest && parameterManifest.length
      ? parameterManifest
          .map(
            (p) =>
              p.parameters &&
              p.parameters.length &&
              p.parameters.map((param) => param.parameterKey)
          )
          .flat()
      : [];

  let blueprintType = blueprintTypesList.find(
    (bpType) => bpType.value === type
  );
  let blueprintDetails = {
    blueprintName: name,
    blueprintDescription: description,
    blueprintType: blueprintType,
    blueprintCategory: { label: category, value: category },
    blueprintDepartments:
      departments &&
      departments.map((d) => {
        return { ID: d, checked: true };
      }),
    parameterManifest,
    allParameterList,
  };
  if (roleName) {
    blueprintDetails.selectedRoleName = {
      label: roleName,
      value: roleArn ? roleArn : "customRole",
    };
  }
  return blueprintDetails;
};

const getFileObject = (file) => {
  if (file && file.FileContent && file.FileName) {
    return new File([file.FileContent], file.FileName);
  }
};

export const setBlueprintTemplates = (files) => {
  const masterFile =
    files &&
    files.UploadedFiles &&
    files.UploadedFiles.length &&
    files.UploadedFiles.find((file) => file.FileName === files.MasterFile);
  return {
    uploadedFiles:
      files &&
      files.UploadedFiles &&
      files.UploadedFiles.length &&
      files.UploadedFiles.map((file) => getFileObject(file)),
    parentTemplate: getFileObject(masterFile),
    validateTempateFileUploadSuccess: true,
  };
};

export const blueprintManifestMigration = (manifest) => {
  const updatedManifest = {
    ...manifest,
    parameters: {
      parameterGroups: manifest.parameters.parameterGroups.map((group) => {
        return {
          ...group,
          parameters: group.parameters.map((param) => {
            const value = param.parameterValue;
            return {
              ...param,
              defaultValue: param.defaultValue ? param.defaultValue : value,
              parameterValue: "",
            };
          }),
        };
      }),
    },
    updateVersion: BLUEPRINT_MIGRATION_VERSION,
  };
  return updatedManifest;
};

export const getPermissionURL = (
  manifest,
  blueprints,
  blueprintId,
  selectedAccounts,
  account
) => {
  const roleName = manifest.roleName;
  const currentAccount =
    isArrayWithLength(selectedAccounts) &&
    selectedAccounts.find((acc) => acc.accountNumber === account.accountNumber);
  let blueprint = isArrayWithLength(blueprints)
    ? blueprints.find((b) => b.BlueprintId === blueprintId)
    : [];
  let permissionsUrl;
  if (roleName && currentAccount && blueprint) {
    const regionCode = currentAccount.regions[0].regionCode;
    const roleUrl = blueprint.PermissionCFNURL;
    permissionsUrl =
      "https://console.aws.amazon.com/cloudformation/home?region=" +
      regionCode +
      "#/stacks/create/review?stackName=MontyCloud-Role-Permissions-" +
      roleName.replace(/\s+/g, "") +
      "&templateURL=" +
      roleUrl;
  }
  return permissionsUrl;
};

export const handleSaveParameter = (data, t) => {
  const isEmptyDefaultValue =
    data.hideParameter && !data.isOptional && !data.defaultValue;
  const defaultValueDoesNotExist =
    (data.parameterType && (data.parameterType.value || data.parameterType)) ===
      "List" &&
    data.defaultValue &&
    data.allowedValues &&
    Array.isArray(data.allowedValues) &&
    !data.allowedValues
      .map((i) => i.value)
      .includes(
        data.defaultValue && (data.defaultValue.value || data.defaultValue)
      );
  if (isEmptyDefaultValue) {
    return t("emptyDefaultValueErrorMessage");
  }
  if (defaultValueDoesNotExist) {
    return t("defaultValueDoesNotExistErrorMessage");
  }
};

const selectedRegions = (regions, isList) => {
  const selectedRegions =
    isArrayWithLength(regions) && isList
      ? regions
      : isArrayWithLength(regions) &&
        regions.filter((region) => region.isSelected);
  return selectedRegions.length < 3
    ? selectedRegions.map((region) => region.regionName).join(", ")
    : selectedRegions.length > 0
    ? `${selectedRegions.length} ${i18n.t("common:regions")}`
    : "-";
};

export const getSelectedScope = (selectedAccounts, accounts, isList) => {
  return (
    selectedAccounts &&
    Array.isArray(selectedAccounts) &&
    selectedAccounts.map((acc) => {
      return {
        account:
          acc.accountName ||
          getAccountName(acc.accountNumber, accounts) ||
          acc.accountNumber,
        regions: selectedRegions(acc.regions, isList),
        department: acc.departmentName || acc.deptName,
      };
    })
  );
};

export const isTerraformEnabled = ({
  features,
  blueprint,
  blueprintManifest,
}) => {
  const isTerraformService = blueprintManifest
    ? has(blueprintManifest, "metadata.type") &&
      get(blueprintManifest, "metadata.type", "") === TERRAFORM
    : get(blueprint, "blueprintType.value", "") === TERRAFORM;

  return isTerraformService && get(features, "TerraformEnabled");
};

export const getSelectedFilterData = (filters, filterType) => {
  const selectedFilterData = [];
  filters.some((filter) => {
    if (filter.value === filterType) {
      filter.options.forEach((option) => {
        if (option.checked) {
          selectedFilterData.push(option.value);
        }
      });
      return true;
    }
    return false;
  });
  return selectedFilterData;
};

export const filterBlueprintsByKey = (blueprints, key = "") => {
  let filteredBlueprints = blueprints;
  let lowCaseKey = key.toLowerCase();
  if (lowCaseKey) {
    filteredBlueprints = blueprints.filter(
      (bp) =>
        (bp.Name && bp.Name.toLowerCase().includes(lowCaseKey)) ||
        (bp.Description && bp.Description.toLowerCase().includes(lowCaseKey))
    );
  }
  return filteredBlueprints;
};

export const getFilteredBlueprintsAndCategories = ({
  filters,
  isCategoryDetailView = false,
  categories,
  blueprintCategory,
  serviceGroupByCategory,
}) => {
  const selectedCategories = getSelectedFilterData(
    filters,
    BLUEPRINT_FILTER_TYPES.CATEGORY
  );

  const selectedTypes = getSelectedFilterData(
    filters,
    BLUEPRINT_FILTER_TYPES.TYPE
  );
  let filteredServiceGroupByCategory = Object.assign(
    {},
    serviceGroupByCategory
  );

  if (isArrayWithLength(selectedTypes)) {
    Object.entries(filteredServiceGroupByCategory).forEach(
      ([category, blueprints]) => {
        let filteredBlueprints = blueprints;
        if (isArrayWithLength(filteredBlueprints)) {
          filteredBlueprints = filteredBlueprints.filter(
            ({ Type: blueprintType }) => selectedTypes.includes(blueprintType)
          );
          filteredServiceGroupByCategory[category] = filteredBlueprints;
        }
      }
    );
  }

  let filteredCategories = isCategoryDetailView
    ? [{ label: blueprintCategory }]
    : categories;

  if (isArrayWithLength(selectedCategories)) {
    filteredCategories = categories.filter(({ label: category }) =>
      selectedCategories.includes(category)
    );
  }

  return {
    filteredServiceGroupByCategory,
    filteredCategories,
    selectedCategories,
  };
};

export const getFilteredBlueprints = ({
  blueprintList,
  selectedCategory,
  selectedTypes,
}) => {
  let newBlueprintList = blueprintList;

  if (isArrayWithLength(selectedCategory)) {
    const categories = selectedCategory.map((type) => type.value);
    newBlueprintList = newBlueprintList.filter((blueprint) =>
      categories.includes(blueprint.Category)
    );
  }

  if (isArrayWithLength(selectedTypes)) {
    const types = selectedTypes.map((type) => type.value);
    newBlueprintList = newBlueprintList.filter((blueprint) =>
      types.includes(blueprint.Type)
    );
  }

  return newBlueprintList;
};

export const addPathToMetadata = (node, parentPath = "") => {
  const { Path, Children } = node;

  node.metadata = {
    path: Path,
  };

  if (Children && Children.length > 0) {
    for (let i = 0; i < Children.length; i++) {
      const child = Children[i];
      const childPath = `${child.path}`;
      addPathToMetadata(child, childPath);
    }
  }

  delete node.path;

  return node;
};

export const getOrgName = (orgId, orgList) => {
  let org =
    isArrayWithLength(orgList) && orgList.find((org) => org.ID === orgId);
  return org ? org.Name : "-";
};

export const checkIfBlueprintIsDay2Owned = (owner) => owner === MC;

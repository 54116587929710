const initialState = {
  currentTourStep: 0,
  tourOpen: false,
  showTour: "",
  selfManagedBlueprintTourOpen: false
};

export const SET_TOUR_STEP = "SET_TOUR_STEP";
export const OPEN_TOUR = "OPEN_TOUR";
export const SHOW_TOUR = "SHOW_TOUR";
export const OPEN_SELF_MANAGED_BLUEPRINT_TOUR =
  "OPEN_SELF_MANAGED_BLUEPRINT_TOUR";

export default function tour(state = initialState, action) {
  switch (action.type) {
    case SET_TOUR_STEP:
      return {
        ...state,
        currentTourStep: action.payload
      };
    case OPEN_TOUR:
      return {
        ...state,
        tourOpen: !state.tourOpen,
        showTour: initialState.showTour
      };
    case SHOW_TOUR:
      return {
        ...state,
        showTour: action.payload,
        tourOpen: !state.tourOpen
      };
    case OPEN_SELF_MANAGED_BLUEPRINT_TOUR:
      return {
        ...state,
        selfManagedBlueprintTourOpen: !state.selfManagedBlueprintTourOpen,
        showTour: initialState.showTour
      };
    default:
      return state;
  }
}

import {
  GET_USER_DATA_REQUEST,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAILURE,
  SAVE_USER_DATA_REQUEST,
  SAVE_USER_DATA_SUCCESS,
  SAVE_USER_DATA_FAILURE,
  RESET_SAVE_USER_DATA,
  SAVE_USER_REQUEST,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILURE,
} from "../actionTypes/user-data";

import {
  getUserDataApi,
  saveUserDataApi,
  createAdminUserApi,
} from "../services/user-data";

import { GLOBAL_CONFIG_USER_DATA } from "../../../reducers/global-config";
import { postNotification } from "../../../utils/notification-message";

function requestGetUserData() {
  return {
    type: GET_USER_DATA_REQUEST,
  };
}

export function receiveGetUserData(payload) {
  return {
    type: GET_USER_DATA_SUCCESS,
    payload,
  };
}

function getUserDataError(payload) {
  return {
    type: GET_USER_DATA_FAILURE,
    payload,
  };
}

export const filterUserData = (data) => (dispatch) => {
  dispatch({
    type: GLOBAL_CONFIG_USER_DATA,
    payload: data,
  });
};

export const getUserData = () => (dispatch) => {
  dispatch(requestGetUserData());
  getUserDataApi()
    .then((data) => {
      if (data.Status && data.Status === "Failed") {
        dispatch(getUserDataError(data.Error));
        dispatch(filterUserData({}));
        dispatch(postNotification("Failed to load users' selection", "danger"));
      } else {
        dispatch(receiveGetUserData(data));
        dispatch(filterUserData(data));
      }
    })
    .catch((err) => {
      dispatch(getUserDataError(err.body));
      dispatch(postNotification("Failed to load users' selection", "danger"));
      dispatch(filterUserData({}));
    });
};

function requestSaveUserData() {
  return {
    type: SAVE_USER_DATA_REQUEST,
  };
}

export function receiveSaveUserData(payload) {
  return {
    type: SAVE_USER_DATA_SUCCESS,
    payload,
  };
}

function saveUserDataError(payload) {
  return {
    type: SAVE_USER_DATA_FAILURE,
    payload,
  };
}

export const saveUserData = (params) => (dispatch) => {
  dispatch(requestSaveUserData());
  saveUserDataApi(params)
    .then((data) => {
      if (data && data.Status && data.Status === "Failed") {
        dispatch(saveUserDataError(data.Error));
        dispatch(postNotification("Failed to save users' selection", "danger"));
      } else {
        dispatch(receiveSaveUserData(data));
      }
    })
    .catch((err) => {
      dispatch(saveUserDataError(err.body));
    });
};

export const saveFreScope = (deptList) => (dispatch, getState) => {
  const { globalConfig } = getState();
  let filteredUserData = globalConfig.filteredUserData;
  let allDepts = deptList.map((dept) => {
    return { ...dept, isSelected: true };
  });
  const request = {
    Organizations: filteredUserData.Organizations,
    Departments: allDepts,
    Regions: filteredUserData.Regions,
    Accounts: [],
  };
  dispatch(saveUserData(request));
};

export const resetSaveUserData = () => ({
  type: RESET_SAVE_USER_DATA,
});

export const addUsers = (users) => async (dispatch, state) => {
  dispatch({
    type: SAVE_USER_REQUEST,
  });
  try {
    const allPromise = users.map((user) => createAdminUserApi(user));
    Promise.all(allPromise).then((results) => {
      dispatch({
        type: SAVE_USER_SUCCESS,
      });
    });
  } catch (error) {
    dispatch({
      type: SAVE_USER_FAILURE,
    });
  }
};

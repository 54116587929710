import { createReducer } from "../../../utils/create-reducer";
import { combineReducers } from "redux";
import {
  ACTION_WORKSPACES,
  ACTION_WORKSPACE,
} from "../../../utils/action-names.json";

const workspaces = createReducer(ACTION_WORKSPACES);
const workspace = createReducer(ACTION_WORKSPACE);

export default combineReducers({
  workspaces,
  workspace,
});

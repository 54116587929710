import {
  SAVE_SSO_METADATA_TYPE,
  SET_SSO_SIGN_IN_URL,
  SET_SSO_ISSUER_URL,
  SET_SSO_CERTIFICATE,
  RESET_SSO_SETTINGS,
  SAVE_SSO_IDENTITY_PROVIDER_TYPE,
  SAVE_IDENTITY_PROVIDER_FILE,
  SAVE_SSO_IDENTITY_PROVIDER,
  GET_SSO_CONFIGURATIONS_REQUEST,
  GET_SSO_CONFIGURATIONS_SUCCESS,
  GET_SSO_CONFIGURATIONS_FAILURE,
  GET_SSO_CONFIGURATION_ID_REQUEST,
  GET_SSO_CONFIGURATION_ID_SUCCESS,
  GET_SSO_CONFIGURATION_ID_FAILURE,
  GET_SSO_ACTIVE_SETTINGS_REQUEST,
  GET_SSO_ACTIVE_SETTINGS_SUCCESS,
  GET_SSO_ACTIVE_SETTINGS_FAILURE,
  SAVE_SSO_CONFIGURATIONS_REQUEST,
  SAVE_SSO_CONFIGURATIONS_SUCCESS,
  SAVE_SSO_CONFIGURATIONS_FAILURE,
  DELETE_SSO_CONFIGURATIONS_REQUEST,
  DELETE_SSO_CONFIGURATIONS_SUCCESS,
  DELETE_SSO_CONFIGURATIONS_FAILURE,
  SAVE_SSO_DATA_FOR_EDITING,
  SET_SSO_INVALID_FILE,
  SET_SSO_ERROR_MESSAGES,
  SET_SSO_VALIDATING_FILE,
  SET_SSO_NAME,
  SET_SSO_DESCRIPTION,
  SET_SSO_DEFAULT_DEPT,
  SET_SSO_DEFAULT_ROLE,
  ENABLE_SSO_REQUEST,
  ENABLE_SSO_SUCCESS,
  ENABLE_SSO_FAILURE,
  GET_SSO_DOMAINS_REQUEST,
  GET_SSO_DOMAINS_SUCCESS,
  GET_SSO_DOMAINS_FAILURE,
  ADD_SSO_DOMAIN_REQUEST,
  ADD_SSO_DOMAIN_SUCCESS,
  ADD_SSO_DOMAIN_FAILURE,
  VERIFY_SSO_DOMAIN_REQUEST,
  VERIFY_SSO_DOMAIN_SUCCESS,
  VERIFY_SSO_DOMAIN_FAILURE,
  DELETE_SSO_DOMAIN_REQUEST,
  DELETE_SSO_DOMAIN_SUCCESS,
  DELETE_SSO_DOMAIN_FAILURE,
  SAVE_SSO_FILE_UPLOAD_STATUS,
  RESET_ROLE_MAPPING_STATUS,
  RAISE_SSO_SUPPORT_TICKET_REQUEST,
  RAISE_SSO_SUPPORT_TICKET_SUCCESS,
  RAISE_SSO_SUPPORT_TICKET_FAILURE,
  GET_SSO_APPLICATION_ROLES_REQUEST,
  GET_SSO_APPLICATION_ROLES_SUCCESS,
  GET_SSO_APPLICATION_ROLES_FAILURE,
  SSO_UPDATE_CERTIFICATE_REQUEST,
  SSO_UPDATE_CERTIFICATE_SUCCESS,
  SSO_UPDATE_CERTIFICATE_FAILURE,
  IS_SSO_ENABLED_REQUEST,
  IS_SSO_ENABLED_SUCCESS,
  IS_SSO_ENABLED_FAILURE,
  SAVE_SSO_DETAILS_STATUS,
  GET_SSO_PROFILES_REQUEST,
  GET_SSO_PROFILES_SUCCESS,
  GET_SSO_PROFILES_FAILURE,
  SET_SHOW_PROJECTS,
} from "../actionTypes/sso";

const initialState = {
  metadataTypeShowValues: false,
  identityProviderEnterValues: false,
  entityID: "",
  replyURL: "",
  signOnURL: "",
  metadataURL: "",
  signInURL: "",
  issuerURL: "",
  certificate: "",
  uploadedFile: [],
  identityProviderTypes: null,
  selectedProviderType: null,
  loadingSSOConfiguration: false,
  ssoConfigurationLoaded: false,
  ssoConfiguration: null,
  loadingSSOConfigurationFailed: false,
  savingSSOConfiguration: false,
  ssoConfigurationSaved: false,
  savingSSOConfigurationFailed: false,
  deletingSSOConfiguration: false,
  ssoConfigurationDeleted: false,
  deletingSSOConfigurationFailed: false,
  invalidFile: false,
  errorMessages: [],
  validatingFile: true,
  settingSSOStatus: false,
  ssoStatusSet: false,
  settingSSOStatusFailed: false,
  fetchingSSOConfigId: false,
  ssoConfigId: "",
  profileResourceId: "",
  fetchingSSOConfigIdFailed: false,
  fetchingDomains: false,
  addedDomains: null,
  fetchingDomainsFailed: false,
  addingDomain: false,
  domainAdded: false,
  addedDomainFailed: false,
  verifyingDomain: false,
  domainVerified: false,
  verifyingDomainFailed: false,
  deletingDomain: false,
  domainDeleted: false,
  deletingDomainFailed: false,
  fetchingActiveSettings: false,
  fetchingActiveSettingsSuccess: false,
  fetchingActiveSettingsFailed: false,
  name: null,
  description: null,
  defaultDept: null,
  defaultRole: null,
  uploadedFileStatus: false,
  savedInitialData: false,
  roleMappingChanged: false,
  raisingSupportTicket: false,
  supportTicketRaised: false,
  raisingSupportTicketFailed: false,
  fetchingSSORoles: false,
  ssoRoles: null,
  fetchingSSORolesFailed: false,
  updatingSSOCertificate: false,
  certificateUpdated: false,
  updatingSSOCertificateFailed: false,
  isEnabled: null,
  status: null,
  fetchingIsSSOEnabled: false,
  isSSOEnabledLoaded: false,
  isSSOEnabledFailed: false,
  detailsUpdated: false,
  isAccessDenied: false,
  fetchingSSOProfiles: false,
  ssoProfiles: null,
  fetchingSSOProfilesFailed: false,
  showProjects: false,
};

const sso = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SSO_METADATA_TYPE:
      return {
        ...state,
        metadataTypeShowValues: action.payload,
      };
    case SAVE_SSO_IDENTITY_PROVIDER_TYPE:
      return {
        ...state,
        identityProviderEnterValues: action.payload,
      };
    case SET_SSO_SIGN_IN_URL:
      return {
        ...state,
        signInURL: action.payload,
      };
    case SET_SSO_ISSUER_URL:
      return {
        ...state,
        issuerURL: action.payload,
      };
    case SET_SSO_CERTIFICATE:
      return {
        ...state,
        certificate: action.payload,
      };
    case SAVE_IDENTITY_PROVIDER_FILE:
      const uploadedFile = action.payload;
      return {
        ...state,
        uploadedFile: action.payload,
        uploadedFileStatus:
          uploadedFile && uploadedFile.length > 0 ? "added" : "removed",
      };
    case SAVE_SSO_FILE_UPLOAD_STATUS:
      return {
        ...state,
        uploadedFileStatus: action.payload,
      };
    case SAVE_SSO_IDENTITY_PROVIDER:
      return {
        ...state,
        selectedProviderType: action.payload,
        detailsUpdated: true,
      };
    case SET_SSO_INVALID_FILE:
      return {
        ...state,
        invalidFile: action.payload,
      };
    case SET_SSO_ERROR_MESSAGES:
      return {
        ...state,
        errorMessages: action.payload,
      };
    case SET_SSO_VALIDATING_FILE:
      return {
        ...state,
        validatingFile: action.payload,
      };
    case SET_SSO_NAME:
      return {
        ...state,
        name: action.payload,
        detailsUpdated: true,
      };
    case SET_SSO_DESCRIPTION:
      return {
        ...state,
        description: action.payload,
        detailsUpdated: true,
      };
    case SAVE_SSO_DETAILS_STATUS:
      return {
        ...state,
        detailsUpdated: action.payload,
      };
    case SET_SSO_DEFAULT_DEPT:
      return {
        ...state,
        defaultDept: action.payload,
        roleMappingChanged: true,
      };
    case SET_SSO_DEFAULT_ROLE:
      return {
        ...state,
        defaultRole: action.payload,
        roleMappingChanged: true,
      };
    case RESET_ROLE_MAPPING_STATUS: {
      return {
        ...state,
        roleMappingChanged: action.payload,
      };
    }
    case RESET_SSO_SETTINGS:
      return {
        ...state,
        ...initialState,
        ssoConfiguration: state.ssoConfiguration,
        fetchingIsSSOEnabled: state.fetchingIsSSOEnabled,
        isSSOEnabledLoaded: state.isSSOEnabledLoaded,
        isEnabled: state.isEnabled,
        isSSOEnabledFailed: state.isSSOEnabledFailed,
      };
    case GET_SSO_CONFIGURATIONS_REQUEST:
      return {
        ...state,
        loadingSSOConfiguration: true,
        isAccessDenied: initialState.isAccessDenied,
        ssoConfiguration: initialState.ssoConfiguration,
        ssoConfigurationLoaded: initialState.ssoConfigurationLoaded,
        loadingSSOConfigurationFailed:
          initialState.loadingSSOConfigurationFailed,
      };
    case GET_SSO_CONFIGURATIONS_SUCCESS:
      const {
        entityID,
        replyURL,
        signOnURL,
        ssoConfigId,
        profileResourceId,
        name,
        description,
        isEnabled,
        status,
        subStatus,
        addedDomains,
        defaultDept,
        defaultRole,
        metadataURL,
        selectedProviderType,
      } = action.payload;
      return {
        ...state,
        loadingSSOConfiguration: false,
        ssoConfigurationLoaded: true,
        ssoConfiguration: action.payload,
        ssoConfigId,
        entityID,
        replyURL,
        signOnURL,
        metadataURL,
        profileResourceId,
        name,
        description,
        isEnabled,
        status,
        subStatus,
        addedDomains,
        defaultDept,
        defaultRole,
        selectedProviderType,
      };
    case GET_SSO_CONFIGURATIONS_FAILURE:
      return {
        ...state,
        loadingSSOConfiguration: false,
        loadingSSOConfigurationFailed: true,
        isAccessDenied: action.payload,
      };
    case GET_SSO_CONFIGURATION_ID_REQUEST:
      return {
        ...state,
        fetchingSSOConfigId: true,
        ssoConfigId: initialState.ssoConfigId,
        fetchingSSOConfigIdFailed: initialState.fetchingSSOConfigIdFailed,
        savedInitialData: false,
      };
    case GET_SSO_CONFIGURATION_ID_SUCCESS:
      return {
        ...state,
        fetchingSSOConfigId: false,
        savedInitialData: true,
        ...action.payload,
      };
    case GET_SSO_CONFIGURATION_ID_FAILURE:
      return {
        ...state,
        fetchingSSOConfigId: false,
        fetchingSSOConfigIdFailed: true,
      };
    case GET_SSO_ACTIVE_SETTINGS_REQUEST:
      return {
        ...state,
        fetchingActiveSettings: true,
        fetchingActiveSettingsSuccess:
          initialState.fetchingActiveSettingsSuccess,
        fetchingActiveSettingsFailed: initialState.fetchingActiveSettingsFailed,
      };
    case GET_SSO_ACTIVE_SETTINGS_SUCCESS:
      return {
        ...state,
        fetchingActiveSettings: false,
        fetchingActiveSettingsSuccess: true,
        ...action.payload,
      };
    case GET_SSO_ACTIVE_SETTINGS_FAILURE:
      return {
        ...state,
        fetchingActiveSettings: false,
        fetchingActiveSettingsFailed: true,
      };
    case SAVE_SSO_CONFIGURATIONS_REQUEST:
      return {
        ...state,
        savingSSOConfiguration: true,
        ssoConfigurationSaved: initialState.ssoConfigurationSaved,
        savingSSOConfigurationFailed: initialState.savingSSOConfigurationFailed,
      };
    case SAVE_SSO_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        savingSSOConfiguration: false,
        ssoConfigurationSaved: true,
      };
    case SAVE_SSO_CONFIGURATIONS_FAILURE:
      return {
        ...state,
        savingSSOConfiguration: false,
        savingSSOConfigurationFailed: true,
      };
    case SAVE_SSO_DATA_FOR_EDITING:
      return {
        ...state,
        ...action.payload,
      };
    case DELETE_SSO_CONFIGURATIONS_REQUEST:
      return {
        ...state,
        deletingSSOConfiguration: true,
        ssoConfigurationDeleted: initialState.ssoConfigurationDeleted,
        deletingSSOConfigurationFailed:
          initialState.deletingSSOConfigurationFailed,
      };
    case DELETE_SSO_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        deletingSSOConfiguration: false,
        ssoConfigurationDeleted: true,
      };
    case DELETE_SSO_CONFIGURATIONS_FAILURE:
      return {
        ...state,
        deletingSSOConfiguration: false,
        deletingSSOConfigurationFailed: true,
      };
    case ENABLE_SSO_REQUEST:
      return {
        ...state,
        settingSSOStatus: true,
        ssoStatusSet: initialState.ssoStatusSet,
        settingSSOStatusFailed: initialState.settingSSOStatusFailed,
      };
    case ENABLE_SSO_SUCCESS:
      return {
        ...state,
        settingSSOStatus: false,
        ssoStatusSet: true,
      };
    case ENABLE_SSO_FAILURE:
      return {
        ...state,
        settingSSOStatus: false,
        settingSSOStatusFailed: true,
      };
    case IS_SSO_ENABLED_REQUEST:
      return {
        ...state,
        fetchingIsSSOEnabled: true,
        isSSOEnabledLoaded: initialState.isSSOEnabledLoaded,
        isEnabled: initialState.isEnabled,
        isSSOEnabledFailed: initialState.isSSOEnabledFailed,
      };
    case IS_SSO_ENABLED_SUCCESS:
      return {
        ...state,
        fetchingIsSSOEnabled: false,
        isSSOEnabledLoaded: true,
        isEnabled: action.payload,
      };
    case IS_SSO_ENABLED_FAILURE:
      return {
        ...state,
        fetchingIsSSOEnabled: false,
        isSSOEnabledFailed: true,
      };
    case GET_SSO_DOMAINS_REQUEST:
      return {
        ...state,
        fetchingDomains: true,
        fetchingDomainsFailed: initialState.fetchingDomainsFailed,
      };
    case GET_SSO_DOMAINS_SUCCESS:
      return {
        ...state,
        fetchingDomains: false,
        addedDomains: action.payload,
      };
    case GET_SSO_DOMAINS_FAILURE:
      return {
        ...state,
        fetchingDomains: false,
        fetchingDomainsFailed: true,
      };
    case ADD_SSO_DOMAIN_REQUEST:
      return {
        ...state,
        addingDomain: true,
        domainAdded: initialState.domainAdded,
        addingDomainFailed: initialState.addingDomainFailed,
      };
    case ADD_SSO_DOMAIN_SUCCESS:
      return {
        ...state,
        addingDomain: false,
        domainAdded: true,
      };
    case ADD_SSO_DOMAIN_FAILURE:
      return {
        ...state,
        addingDomain: false,
        addingDomainFailed: true,
      };
    case VERIFY_SSO_DOMAIN_REQUEST:
      return {
        ...state,
        verifyingDomain: true,
        domainVerified: initialState.domainVerified,
        verifyingDomainFailed: initialState.verifyingDomainFailed,
      };
    case VERIFY_SSO_DOMAIN_SUCCESS:
      return {
        ...state,
        verifyingDomain: false,
        domainVerified: true,
      };
    case VERIFY_SSO_DOMAIN_FAILURE:
      return {
        ...state,
        verifyingDomain: false,
        verifyingDomainFailed: true,
      };
    case DELETE_SSO_DOMAIN_REQUEST:
      return {
        ...state,
        deletingDomain: true,
        domainDeleted: initialState.domainDeleted,
        deletingDomainFailed: initialState.deletingDomainFailed,
      };
    case DELETE_SSO_DOMAIN_SUCCESS:
      return {
        ...state,
        deletingDomain: false,
        domainDeleted: true,
      };
    case DELETE_SSO_DOMAIN_FAILURE:
      return {
        ...state,
        deletingDomain: false,
        deletingDomainFailed: true,
      };
    case RAISE_SSO_SUPPORT_TICKET_REQUEST:
      return {
        ...state,
        raisingSupportTicket: true,
        supportTicketRaised: initialState.supportTicketRaised,
        raisingSupportTicketFailed: initialState.raisingSupportTicketFailed,
      };
    case RAISE_SSO_SUPPORT_TICKET_SUCCESS:
      return {
        ...state,
        raisingSupportTicket: false,
        supportTicketRaised: true,
      };
    case RAISE_SSO_SUPPORT_TICKET_FAILURE:
      return {
        ...state,
        raisingSupportTicket: false,
        raisingSupportTicketFailed: true,
      };
    case GET_SSO_APPLICATION_ROLES_REQUEST:
      return {
        ...state,
        fetchingSSORoles: true,
        ssoRoles: initialState.ssoRoles,
        fetchingSSORolesFailed: initialState.fetchingSSORolesFailed,
      };
    case GET_SSO_APPLICATION_ROLES_SUCCESS:
      return {
        ...state,
        fetchingSSORoles: false,
        ssoRoles: action.payload,
      };
    case GET_SSO_APPLICATION_ROLES_FAILURE:
      return {
        ...state,
        fetchingSSORoles: false,
        fetchingSSORolesFailed: true,
      };
    case SSO_UPDATE_CERTIFICATE_REQUEST:
      return {
        ...state,
        updatingSSOCertificate: true,
        certificateUpdated: initialState.certificateUpdated,
        updatingSSOCertificateFailed: initialState.updatingSSOCertificateFailed,
      };
    case SSO_UPDATE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        updatingSSOCertificate: false,
        certificateUpdated: true,
      };
    case SSO_UPDATE_CERTIFICATE_FAILURE:
      return {
        ...state,
        updatingSSOCertificate: false,
        updatingSSOCertificateFailed: true,
      };
    case GET_SSO_PROFILES_REQUEST:
      return {
        ...state,
        fetchingSSOProfiles: true,
      };
    case GET_SSO_PROFILES_SUCCESS:
      return {
        ...state,
        fetchingSSOProfiles: false,
        ssoProfiles: action.payload,
      };
    case GET_SSO_PROFILES_FAILURE:
      return {
        ...state,
        fetchingSSOProfiles: false,
        fetchingSSOProfilesFailed: true,
      };
    case SET_SHOW_PROJECTS:
      return {
        ...state,
        showProjects: action.payload,
      };
    default:
      return state;
  }
};

export default sso;

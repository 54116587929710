import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { Col, Row } from "reactstrap";
import s from "../signup/Signup.module.scss";
import MCButton from "../../../../components/MCButton/MCButton";
import { getSearchParams } from "../../../../utils/common";
import { EMAIL } from "../../../../utils/app-constants.json";
import { resendConfirmation } from "../../actions/signup";
import { clearLoginScreen } from "../../actions/auth";
import { montyCloudWebsiteLink } from "../../../../utils/app-links";
import { SUPPORT_EMAIL } from "../../../../utils/app-constants.json";
import McAlert from "../../../../components/Alert";
import { withRouter } from "@hocs/withRouter";
export const VerifyEmail = ({ location }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["verifyEmail", "signupForm"]);
  const paramsEmail = getSearchParams(location, EMAIL);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);

  const {
    sendingConfirmationEmail,
    confirmationEmailResent,
    confirmationEmailFailed,
  } = useSelector(({ signup }) => {
    return {
      sendingConfirmationEmail: signup.sendingConfirmationEmail,
      confirmationEmailResent: signup.confirmationEmailResent,
      confirmationEmailFailed: signup.confirmationEmailFailed,
    };
  }, shallowEqual);

  useEffect(() => {
    if (confirmationEmailResent) {
      setShowSuccessMessage(true);
      setShowFailureMessage(false);
      dispatch(clearLoginScreen());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationEmailResent]);

  useEffect(() => {
    if (confirmationEmailFailed) {
      setShowSuccessMessage(false);
      setShowFailureMessage(true);
      dispatch(clearLoginScreen());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationEmailFailed]);

  if (!paramsEmail) {
    return <Navigate to="/app" />;
  }

  const sendConfirmationEmail = () => {
    dispatch(resendConfirmation({ Username: paramsEmail }));
  };

  return (
    <div className="auth-container signup verify-email">
      <Row className="p-3">
        <Col xl={6} xs={6}>
          <a
            href={montyCloudWebsiteLink}
            target="_blank"
            rel="noopener noreferrer"
            className={s.MCLogo}
          >
            <img loading="lazy"
              className={s.logo}
              src="/images/DAY2Logo-NoBg.png"
              alt="MontyCloud"
            />
          </a>
        </Col>

        <Col xl={6} xs={6} className="text-right">
          <h5 className={s.HeaderLogin}>
            <Link to="/login">{t("signupForm:loginLink")}</Link>
          </h5>
        </Col>
      </Row>
      <Row>
        <Col xl={12} xs={12} className="text-center">
          <div className={s.VerifyEmailContainer}>
            <a
              href={montyCloudWebsiteLink}
              target="_blank"
              rel="noopener noreferrer"
              className="mt-4"
            >
              <img loading="lazy"
                className={s.VerifyLogo}
                src="/images/DAY2Logo-NoBg.png"
                alt="MontyCloud"
              />
            </a>
            <h4 className="mt-2">{t("pleaseVerifyEmail")}</h4>
            <p className={s.VerifyEmailText}>
              {t("subHeading")}
              <br />
              <span className={s.Highlight}>{paramsEmail}</span>
            </p>
            <p className={s.VerifyEmailText}>
              {t("details.1")} <br />
              <Trans
                i18nKey="verifyEmail:details.2"
                components={[<span className={s.Highlight}></span>]}
              />
            </p>
            <p className={s.VerifyEmailText}>{t("cantFind")}</p>
            <p>
              <MCButton
                text={
                  sendingConfirmationEmail
                    ? `${t("sendingConfirmationEmail")}...`
                    : t("resendEmail")
                }
                color="mc-blue"
                onClick={() => sendConfirmationEmail()}
                disabled={sendingConfirmationEmail}
              ></MCButton>
              {showSuccessMessage && (
                <McAlert content={t("confirmationSent")} className="mt-3"/>
              )}
              {showFailureMessage && (
                <McAlert 
                content={t("failedToSendConfirmation")}
                className="mt-3"
                type="error"/>
              )}
            </p>
            <p>
              {t("needHelp")}
              <a href={`mailto:${SUPPORT_EMAIL}`}> {t("contactUs")}</a>
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(VerifyEmail);

import React, { Component } from "react";
import { connect } from "react-redux";
import s from "./Header.module.scss";
import { withTranslation } from "react-i18next";

class LoggingOutLoader extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <div className={s.logOutLoader}>
          <i className="fa fa-spin fa-spinner ml-1" />
          <div>{t("loggingOut")}</div>
        </div>
      </div>
    );
  }
}

export default connect()(withTranslation(["feedback"])(LoggingOutLoader));

import {
  SAVE_ADD_METRIC_STATE,
  SAVE_ADDED_METRICS,
  SET_SELECTED_MONITORING_RESOURCE_TYPE,
  SET_SELECTED_MONITORING_METRICS,
  SAVE_MANIFEST_RESOURCES,
  SAVE_APPLICATION_RESOURCES,
  SAVE_SELECTED_APPLICATION_RESOURCES,
  CLEAR_MONITORING_DATA,
} from "../actionTypes/monitoring";

import { convertListValueLabel } from "../../../utils/convert-select-value";
import {
  getMetrics,
  getResourceTypesByManifest,
  getResourceTypesByApplication,
} from "../../../utils/monitoring";

const initialState = {
  isAddMetricsEnabled: true,
  addedMetrics: [],
  resourceTypeOptions: [],
  selectedResourceType: null,
  selectedMetrics: [],
  applicationResources: null,
  metricOptions: [],
  applicationResourcesOptions: [],
  selectedResources: [],
};

const monitoring = (state = initialState, action) => {
  let types;
  let options;
  switch (action.type) {
    case SAVE_MANIFEST_RESOURCES:
      types = getResourceTypesByManifest(action.payload);
      options = convertListValueLabel(types);
      return {
        ...state,
        resourceTypeOptions: options,
      };
    case SAVE_APPLICATION_RESOURCES:
      types = getResourceTypesByApplication(action.payload);
      options = convertListValueLabel(types);
      return {
        ...state,
        applicationResources: action.payload,
        resourceTypeOptions: options,
      };
    case SAVE_ADD_METRIC_STATE:
      return {
        ...state,
        isAddMetricsEnabled: action.payload,
      };
    case SAVE_ADDED_METRICS:
      return {
        ...state,
        addedMetrics: action.payload,
      };
    case SET_SELECTED_MONITORING_RESOURCE_TYPE:
      const type = action.payload;
      let metrics;
      if (type) {
        metrics = getMetrics(type.value);
      }
      let resourceOptions = [];
      if (state.applicationResources && type) {
        types = state.applicationResources.filter(
          (r) => r.resourceType === type.value
        );
        resourceOptions = types.map((t) => {
          return {
            label: `${t.resourceId} (${t.resourceName})`,
            value: t.resourceId,
          };
        });
      }
      const sameResourceType =
        (state.selectedResourceType && state.selectedResourceType.value) ===
        (type && type.value);
      return {
        ...state,
        selectedResourceType: type,
        metricOptions: metrics,
        selectedResources: sameResourceType
          ? state.selectedResources
          : initialState.selectedResources,
        applicationResourcesOptions: resourceOptions,
        selectedMetrics: sameResourceType
          ? state.selectedMetrics
          : initialState.selectedMetrics,
      };
    case SET_SELECTED_MONITORING_METRICS:
      return {
        ...state,
        selectedMetrics: action.payload,
      };
    case SAVE_SELECTED_APPLICATION_RESOURCES:
      return {
        ...state,
        selectedResources: action.payload,
      };
    case CLEAR_MONITORING_DATA:
      return {
        ...state,
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default monitoring;

export const DETECT_CLOUD_WATCH_AGENT_REQUEST =
  "DETECT_CLOUD_WATCH_AGENT_REQUEST";
export const DETECT_CLOUD_WATCH_AGENT_SUCCESS =
  "DETECT_CLOUD_WATCH_AGENT_SUCCESS";
export const DETECT_CLOUD_WATCH_AGENT_FAILURE =
  "DETECT_CLOUD_WATCH_AGENT_FAILURE";

export const CHECK_CLOUD_WATCH_USER_EXIST_REQUEST =
  "CHECK_CLOUD_WATCH_USER_EXIST_REQUEST";
export const CHECK_CLOUD_WATCH_USER_EXIST_SUCCESS =
  "CHECK_CLOUD_WATCH_USER_EXIST_SUCCESS";
export const CHECK_CLOUD_WATCH_USER_EXIST_FAILURE =
  "CHECK_CLOUD_WATCH_USER_EXIST_FAILURE";

export const CLEAR_INSTANCE_METRIC_DETAILS_DATA =
  "CLEAR_INSTANCE_METRIC_DETAILS_DATA";
export const CLEAR_CLOUD_WATCH_DATA = "CLEAR_CLOUD_WATCH_DATA";

export const GET_INSTANCE_MONITORING_METRICS_REQUEST =
  "GET_INSTANCE_MONITORING_METRICS_REQUEST";
export const GET_INSTANCE_MONITORING_METRICS_SUCCESS =
  "GET_INSTANCE_MONITORING_METRICS_SUCCESS";
export const GET_INSTANCE_MONITORING_METRICS_FAILURE =
  "GET_INSTANCE_MONITORING_METRICS_FAILURE";

export const GET_INSTANCE_MONITORING_METRICS_DATA_REQUEST =
  "GET_INSTANCE_MONITORING_METRICS_DATA_REQUEST";
export const GET_INSTANCE_MONITORING_METRICS_DATA_SUCCESS =
  "GET_INSTANCE_MONITORING_METRICS_DATA_SUCCESS";
export const GET_INSTANCE_MONITORING_METRICS_DATA_FAILURE =
  "GET_INSTANCE_MONITORING_METRICS_DATA_FAILURE";


import config from "../../config";
import { getBasicAnalyticsPayload } from "./analytics-payload";
import MixpanelService from "./services/mixpanel-service";
import StatsigService from "./services/statsig-service";


export class AnalyticsRouter {
    constructor() {
        this.services = [];
        this.loadServices();
    }

    loadServices() {
        if (config.mixPanelEnabled) {
            this.services.push(new MixpanelService(config));
        }
        if (config.statsigEnabled) {
            this.services.push(new StatsigService(config));
        }
    }

    identify(userId) {
        this.services.forEach(service => service.identify(userId));
    }

    track({ eventName, additionalProps = {} }) {
        const defaultProperties = getBasicAnalyticsPayload();
        const propertiesWithDefault = {
            ...defaultProperties,
            ...additionalProps
        }
        this.services.forEach(service => service.track(eventName, propertiesWithDefault));
    }

    trackPage(url) {
        this.services.forEach(service => service.trackPage(url));
    }
}

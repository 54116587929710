import React from "react";
import classnames from "classnames";
import s from "./UIComponents.module.scss";

const Heading = ({
  level,
  text,
  color = "black",
  className,
  icon,
  iconPosition = "right",
  pointer,
  subHeading,
}) => {
  return (
    <span
      className={classnames(
        className,
        s.heading,
        subHeading && s.subHeading,
        s[`heading${level}`],
        s[`color-${color}`],
        pointer && s.pointer
      )}
      role="heading"
      aria-level={level}
    >
      {iconPosition === "left" && <span>{icon}</span>}
      <span>{text}</span>
      {iconPosition === "right" && <span>{icon}</span>}
    </span>
  );
};

export default Heading;

import {
  ORG_MGR_DROPDOWN_LOADED,
  ORG_MGR_DROPDOWN_LOADING,
  ORG_MGR_SWITCHED,
  ORG_MGR_SWITCHING,
  ORG_MGR_RESET,
  ORG_MGR_LIST_LOADING,
  ORG_MGR_LIST_LOADED,
  ORG_MGR_LIST_LOADING_FALIED,
  ORG_MGR_UPDATE_DETAILS_FORM,
  ORG_MGR_UPDATE_INVITES,
  ORG_MGR_CREATING,
  ORG_MGR_CREATED,
  ORG_MGR_CREATING_FAILED,
  ORG_MGR_INVITING_USERS,
  ORG_MGR_INVITED,
  ORG_MGR_USER_INVITED,
  ORG_MGR_INVITING_FAILED,
  ORG_MGR_EDIT,
  ORG_MGR_EDIT_LOADED,
  ORG_MGR_UPDATED,
  ORG_MGR_UPDATE_FAILED,
  ORG_MGR_ACCESS_LIST_REQUEST,
  ORG_MGR_ACCESS_LIST_SUCCESS,
  ORG_MGR_ACCESS_LIST_CLEAR,
  ORG_MGR_ACCESS_REVOKE,
  ORG_MGR_ACCESS_REVOKE_REQUEST,
  ORG_MGR_ACCESS_REVOKE_SUCCESS,
  ORG_MGR_ACCESS_REVOKE_RESET,
  ORG_MGR_DELETE_ORG,
  ORG_MGR_DELETEING_ORG,
  ORG_MGR_DELETED_ORG,
  ORG_MGR_DELETE_ABORT,
  ORG_MGR_SET_ACTIVE,
  ORG_MGR_SWITCHING_AFTER_CREATE,
  ORG_MGR_SET_ACTIVE_LOADING,
} from "../actions/org-manager-action-types";

const initialState = {
  loading: false,
  organizations: null,
  switchableOrganizations: false,

  isEditMode: false,
  isEditingFromDetails: false,
  isOnlyFeatureSelection: false,

  activity: {
    dropdown: {
      isLoading: false,
      isLoadingFailed: false,
      isSwitching: false,
      isSwitched: false,
      isSwitchingFailed: false,
      isSwitchingAfterCreate: false,
    },
    organizationsList: {
      isLoading: false,
      isFailed: false,
      isLoaded: false,
    },
    create: {
      isLoading: false,
      isFailed: false,
      isCreated: false,
    },
    edit: {
      isLoading: false,
      isFailed: false,
      isUpdated: false,
    },
    invite: {
      isLoading: false,
      isFailed: false,
      isInvited: false,
      successCount: 0,
    },
    delete: {
      deletingOrganization: "",
      showDelete: false,
      isDeleting: false,
      isDeleted: false,
    },
    revoke: {
      revokeUser: null,
      showRevoke: false,
      isRevoking: false,
      isRevoked: false,
    },
    general: {
      isLoading: false,
    },
  },

  organizationsList: null,
  organizationAccessList: [],
  organizationAccessListLoading: false,
  activeOrganization: null,

  createOrganization: {
    createdOrganization: null,
    showOnlyInviteUsers: false,
    details: {
      Name: null,
      Description: null,
      Owner: null,
      Feature: null,
    },
    inviteUsers: null,
    alreadyInvitedUser: null,
    unInviteUsers: [],
  },
};

export default function organizationManager(state = initialState, action) {
  let { type, payload } = action;

  switch (type) {
    case ORG_MGR_DROPDOWN_LOADING:
      return {
        ...state,
        activity: {
          ...state.activity,
          dropdown: {
            ...state.activity.dropdown,
            isLoading: true,
          },
        },
      };

    case ORG_MGR_DROPDOWN_LOADED:
      return {
        ...state,
        switchableOrganizations: action.payload,
        activity: {
          ...state.activity,
          dropdown: {
            ...state.activity.dropdown,
            isLoading: false,
          },
        },
      };

    case ORG_MGR_SWITCHING:
      return {
        ...state,
        activity: {
          ...state.activity,
          dropdown: {
            ...state.activity.dropdown,
            isLoading: true,
            isSwitching: true,
            isSwitched: false,
          },
        },
      };

    case ORG_MGR_SWITCHED:
      return {
        ...state,
        activity: {
          ...state.activity,
          dropdown: {
            ...state.activity.dropdown,
            isLoading: false,
            isSwitching: false,
            isSwitched: true,
          },
        },
      };

    case ORG_MGR_SWITCHING_AFTER_CREATE:
      return {
        ...state,
        activity: {
          ...state.activity,
          dropdown: {
            ...state.activity.dropdown,
            isSwitchingAfterCreate: true,
            isSwitching: true,
          },
        },
      };

    case ORG_MGR_LIST_LOADING:
      return {
        ...state,
        activity: {
          ...state.activity,
          organizationsList: {
            ...state.activity.organizationsList,
            isLoading: true,
            isFailed: false,
          },
        },
      };

    case ORG_MGR_LIST_LOADED:
      return {
        ...state,
        organizationsList: action.payload,
        activity: {
          ...state.activity,
          organizationsList: {
            ...state.activity.organizationsList,
            isLoading: false,
            isFailed: false,
            isLoaded: true,
          },
        },
      };

    case ORG_MGR_LIST_LOADING_FALIED:
      return {
        ...state,
        activity: {
          ...state.activity,
          organizationsList: {
            ...state.activity.organizationsList,
            isLoading: false,
            isFailed: true,
            isLoaded: false,
          },
        },
      };

    case ORG_MGR_UPDATE_DETAILS_FORM:
      return {
        ...state,
        createOrganization: {
          ...state.createOrganization,
          details: payload,
        },
      };

    case ORG_MGR_UPDATE_INVITES:
      return {
        ...state,
        createOrganization: {
          ...state.createOrganization,
          inviteUsers: payload,
        },
      };

    case ORG_MGR_CREATING:
      return {
        ...state,
        activity: {
          ...state.activity,
          create: {
            ...state.activity.create,
            isLoading: true,
            isFailed: false,
          },
        },
      };

    case ORG_MGR_CREATED:
      return {
        ...state,
        activity: {
          ...state.activity,
          create: {
            ...state.activity.create,
            isLoading: false,
            isFailed: false,
            isCreated: true,
          },
        },
        createOrganization: {
          ...state.createOrganization,
          createdOrganization: payload,
        },
      };

    case ORG_MGR_CREATING_FAILED:
      return {
        ...state,
        activity: {
          ...state.activity,
          create: {
            ...initialState.activity.create,
            isFailed: true,
          },
        },
      };

    case ORG_MGR_INVITING_USERS:
      return {
        ...state,
        activity: {
          ...state.activity,
          invite: {
            ...initialState.activity.invite,
            isLoading: true,
            successCount: 0,
          },
        },
      };

    case ORG_MGR_USER_INVITED:
      return {
        ...state,
        activity: {
          ...state.activity,
          invite: {
            ...state.activity.invite,
            successCount: state.activity.invite.successCount++,
            isLoading: false,
          },
        },
        createOrganization: {
          ...state.createOrganization,
          inviteUsers: null,
        },
      };

    case ORG_MGR_INVITED:
      return {
        ...state,
        activity: {
          ...state.activity,
          invite: {
            ...initialState.activity.invite,
            isLoading: true,
            isInvited: true,
          },
        },
      };

    case ORG_MGR_INVITING_FAILED:
      return {
        ...state,
        activity: {
          ...state.activity,
          invite: {
            ...initialState.activity.invite,
            isFailed: true,
          },
        },
      };

    case ORG_MGR_RESET:
      const activity = initialState.activity;
      const createOrganization = initialState.createOrganization;

      let newState = {
        ...state,
        activity,
        isEditMode: false,
        isEditingFromDetails: false,
        isOnlyFeatureSelection: false,
        organizationAccessList: [],
        createOrganization: {
          ...createOrganization,
          details: {
            ...createOrganization.details,
          },
          showOnlyInviteUsers: false,
        },
      };

      return newState;

    case ORG_MGR_EDIT:
      return {
        ...state,
        isEditMode: true,
        activity: {
          ...state.activity,
          edit: {
            ...state.activity.edit,
            isLoading: true,
          },
        },
        createOrganization: {
          ...state.createOrganization,
          createdOrganization: payload, // data will be org id
        },
      };

    case ORG_MGR_EDIT_LOADED:
      let {
        details,
        inviteUsers,
        isEditingFromDetails,
        isOnlyFeatureSelection,
      } = payload;

      return {
        ...state,
        createOrganization: {
          ...state.createOrganization,
          details,
          inviteUsers,
        },
        activity: {
          ...state.activity,
          edit: {
            ...state.activity.edit,
            isLoading: false,
          },
        },
        isEditingFromDetails,
        isOnlyFeatureSelection,
      };

    case ORG_MGR_UPDATED:
      return {
        ...state,
        activity: {
          ...state.activity,
          create: {
            ...state.activity.create,
            isLoading: false,
          },
          edit: {
            ...state.activity.edit,
            isUpdated: true,
          },
        },
      };

    case ORG_MGR_UPDATE_FAILED:
      return {
        ...state,
        activity: {
          ...state.activity,
          edit: {
            ...state.activity.edit,
            isLoading: true,
            isUpdated: false,
            isFailed: true,
          },
          create: {
            ...state.activity.create,
            isLoading: false,
            isUpdated: true,
          },
        },
      };

    case ORG_MGR_DELETE_ORG:
      return {
        ...state,
        activity: {
          ...state.activity,
          delete: {
            ...state.activity.delete,
            showDelete: true,
            deletingOrganization: payload,
          },
        },
      };

    case ORG_MGR_DELETEING_ORG:
      return {
        ...state,
        activity: {
          ...state.activity,
          delete: {
            ...state.activity.delete,
            isDeleting: true,
            isDeleted: false,
          },
        },
      };

    case ORG_MGR_DELETED_ORG:
      return {
        ...state,
        activity: {
          ...state.activity,
          delete: {
            ...state.activity.delete,
            isDeleted: true,
            isDeleting: false,
          },
        },
      };

    case ORG_MGR_DELETE_ABORT:
      return {
        ...state,
        activity: {
          ...state.activity,
          delete: {
            ...initialState.activity.delete,
          },
        },
      };

    case ORG_MGR_ACCESS_LIST_REQUEST: {
      return {
        ...state,
        organizationAccessListLoading: true,
      };
    }
    case ORG_MGR_ACCESS_LIST_SUCCESS: {
      return {
        ...state,
        organizationAccessListLoading: false,
        organizationAccessList: action.payload,
        createOrganization: {
          ...state.createOrganization,
          inviteUsers: null,
        },
      };
    }
    case ORG_MGR_ACCESS_LIST_CLEAR: {
      return {
        ...state,
        organizationAccessListLoading: false,
        organizationAccessList: [],
      };
    }

    case ORG_MGR_ACCESS_REVOKE:
      return {
        ...state,
        activity: {
          ...state.activity,
          revoke: {
            revokeUser: payload,
            showRevoke: true,
            isRevoking: false,
            isRevoked: false,
          },
        },
      };

    case ORG_MGR_ACCESS_REVOKE_REQUEST:
      return {
        ...state,
        activity: {
          ...state.activity,
          revoke: {
            revokeUser: payload,
            showRevoke: false,
            isRevoking: true,
            isRevoked: false,
          },
        },
      };

    case ORG_MGR_ACCESS_REVOKE_SUCCESS:
      return {
        ...state,
        activity: {
          ...state.activity,
          revoke: {
            revokeUser: payload,
            showRevoke: false,
            isRevoking: false,
            isRevoked: true,
          },
        },
      };

    case ORG_MGR_ACCESS_REVOKE_RESET:
      return {
        ...state,
        activity: {
          ...state.activity,
          revoke: {
            revokeUser: null,
            showRevoke: false,
            isRevoking: false,
            isRevoked: false,
          },
        },
      };

    case ORG_MGR_SET_ACTIVE:
      return {
        ...state,
        activity: {
          ...state.activity,
          general: {
            isLoading: false,
          },
        },
        activeOrganization: payload,
      };

    case ORG_MGR_SET_ACTIVE_LOADING:
      return {
        ...state,
        activity: {
          ...state.activity,
          general: {
            isLoading: true,
          },
        },
      };

    default:
      return state;
  }
}

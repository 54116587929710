export const POST_BILLING_GROUP_REQUEST = "POST_BILLING_GROUP_REQUEST";
export const POST_BILLING_GROUP_SUCCESS = "POST_BILLING_GROUP_SUCCESS";
export const POST_BILLING_GROUP_FAILURE = "POST_BILLING_GROUP_FAILURE";

export const GET_OU_LIST_REQUEST = "GET_OU_LIST_REQUEST";
export const GET_OU_LIST_SUCCESS = "GET_OU_LIST_SUCCESS";
export const GET_OU_LIST_FAILURE = "GET_OU_LIST_FAILURE";

export const CREATE_TENANT_AWS_ACCOUNT_REQUEST =
  "CREATE_TENANT_AWS_ACCOUNT_REQUEST";
export const CREATE_TENANT_AWS_ACCOUNT_SUCCESS =
  "CREATE_TENANT_AWS_ACCOUNT_SUCCESS";
export const CREATE_TENANT_AWS_ACCOUNT_FAILURE =
  "CREATE_TENANT_AWS_ACCOUNT_FAILURE";

export const GET_BILLING_GROUP_EXISTING_ACCOUNTS_REQUEST =
  "GET_BILLING_GROUP_EXISTING_ACCOUNTS_REQUEST";
export const GET_BILLING_GROUP_EXISTING_ACCOUNTS_SUCCESS =
  "GET_BILLING_GROUP_EXISTING_ACCOUNTS_SUCCESS";
export const GET_BILLING_GROUP_EXISTING_ACCOUNTS_FAILURE =
  "GET_BILLING_GROUP_EXISTING_ACCOUNTS_FAILURE";

export const RESET_CREATE_BILLING_GROUP = "RESET_CREATE_BILLING_GROUP";

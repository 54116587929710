//Create Milestone
export const CREATE_MILESTONE_REQUEST = "CREATE_MILESTONE_REQUEST";
export const CREATE_MILESTONE_SUCCESS = "CREATE_MILESTONE_SUCCESS";
export const CREATE_MILESTONE_FAILURE = "CREATE_MILESTONE_FAILURE";
export const CREATE_MILESTONE_RESET = "CREATE_MILESTONE_RESET";

// List Milestones
export const LIST_MILESTONE_REQUEST = "LIST_MILESTONE_REQUEST";
export const LIST_MILESTONE_SUCCESS = "LIST_MILESTONE_SUCCESS";
export const LIST_MILESTONE_PICKER_SUCCESS = "LIST_MILESTONE_PICKER_SUCCESS";
export const LIST_MILESTONE_FAILURE = "LIST_MILESTONE_FAILURE";
export const LIST_MILESTONE_RESET = "LIST_MILESTONE_RESET";

//Get Milestone Details
export const GET_MILESTONE_DETAILS_REQUEST = "GET_MILESTONE_DETAILS_REQUEST";
export const GET_MILESTONE_DETAILS_FAILURE = "GET_MILESTONE_DETAILS_FAILURE";
export const GET_MILESTONE_DETAILS_SUCCESS = "GET_MILESTONE_DETAILS_SUCCESS";
export const GET_MILESTONE_DETAILS_RESET = "GET_MILESTONE_DETAILS_RESET";

//Assessment Milestone Questions List
export const GET_ASSESSMENT_MILESTONES_QUESTIONS_REQUEST =
  "GET_ASSESSMENT_MILESTONES_QUESTIONS_REQUEST";
export const GET_ASSESSMENT_MILESTONES_QUESTIONS_SUCCESS =
  "GET_ASSESSMENT_MILESTONES_QUESTIONS_SUCCESS";
export const GET_ASSESSMENT_MILESTONES_QUESTIONS_FAILURE =
  "GET_ASSESSMENT_MILESTONES_QUESTIONS_FAILURE";

//Assessment Milestone Question Detail
export const GET_ASSESSMENT_MILESTONE_QUESTION_DETAILS_REQUEST =
  "GET_ASSESSMENT_MILESTONE_QUESTION_DETAILS_REQUEST";
export const GET_ASSESSMENT_MILESTONE_QUESTION_DETAILS_SUCCESS =
  "GET_ASSESSMENT_MILESTONE_QUESTION_DETAILS_SUCCESS";
export const GET_ASSESSMENT_MILESTONE_QUESTION_DETAILS_FAILURE =
  "GET_ASSESSMENT_MILESTONE_QUESTION_DETAILS_FAILURE";

export const CLEAR_MILESTONE_QUESTION_DETAILS =
  "CLEAR_MILESTONE_QUESTION_DETAILS";

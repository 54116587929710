export const GET_APP_RESOURCES_SUMMARY_SUCCESS =
  "GET_APP_RESOURCES_SUMMARY_SUCCESS";
export const GET_APP_RESOURCES_SUMMARY_REQUEST =
  "GET_APP_RESOURCES_SUMMARY_REQUEST";
export const GET_APP_RESOURCES_SUMMARY_FAILED =
  "GET_APP_RESOURCES_SUMMARY_FAILED";
export const GET_ALL_RESOURCES_SUMMARY_REQUEST =
  "GET_ALL_RESOURCES_SUMMARY_REQUEST";
export const GET_ALL_RESOURCES_SUMMARY_SUCCESS =
  "GET_ALL_RESOURCES_SUMMARY_SUCCESS";
export const GET_ALL_RESOURCES_SUMMARY_FAILED =
  "GET_ALL_RESOURCES_SUMMARY_FAILED";
export const GET_RESOURCE_SUMMARY_DETAILS = "GET_RESOURCE_SUMMARY_DETAILS";
export const GET_ALL_RESOURCES_REQUEST = "GET_ALL_RESOURCES_REQUEST";
export const GET_ALL_RESOURCES_SUCCESS = "GET_ALL_RESOURCES_SUCCESS";
export const GET_ALL_RESOURCES_FAILED = "GET_ALL_RESOURCES_FAILED";
export const GET_RESOURCE_DETAILS = "GET_RESOURCE_DETAILS";
export const CLEAR_RESOURCE_LIST = "CLEAR_RESOURCE_LIST";
export const SAVE_RESOURCE_TYPES = "SAVE_RESOURCE_TYPES";
export const RESET_RESOURCELIST_IS_FETCHED = "RESET_RESOURCELIST_IS_FETCHED";
export const UPDATE_RESOURCE_SEARCH_KEY = "UPDATE_RESOURCE_SEARCH_KEY";
export const UPDATE_SELECTED_DEPARTMENTS = "UPDATE_SELECTED_DEPARTMENTS";
export const UPDATE_SELECTED_APPLICATIONS = "UPDATE_SELECTED_APPLICATIONS";
export const UPDATE_SELECTED_ACCOUNTS = "UPDATE_SELECTED_ACCOUNTS";
export const UPDATE_SELECTED_REGIONS = "UPDATE_SELECTED_REGIONS";
export const RESOURCE_EXIST_REQUEST = "RESOURCE_EXIST_REQUEST";
export const RESOURCE_EXIST_SUCCESS = "RESOURCE_EXIST_SUCCESS";
export const RESOURCE_EXIST_FAILURE = "RESOURCE_EXIST_FAILURE";
export const UPDATE_ORGANIZATION_TAGS_REQUEST =
  "UPDATE_ORGANIZATION_TAGS_REQUEST";
export const UPDATE_ORGANIZATION_TAGS_SUCCESS =
  "UPDATE_ORGANIZATION_TAGS_SUCCESS";
export const UPDATE_ORGANIZATION_TAGS_FAILURE =
  "UPDATE_ORGANIZATION_TAGS_FAILURE";
export const CLEAR_RESOURCE_EXISTS_DATA = "CLEAR_RESOURCE_EXISTS_DATA";
export const CLEAR_ALL_RESOURCE_LISTS = "CLEAR_ALL_RESOURCE_LISTS";
export const CLEAR_MANAGE_TAGS_DATA = "CLEAR_MANAGE_TAGS_DATA";
export const GET_ALL_RESOURCES_AZURE_SUCCESS =
  "GET_ALL_RESOURCES_AZURE_SUCCESS";

export const UPDATE_SELECTED_AZURE_SUBSCRIPTIONS =
  "UPDATE_SELECTED_AZURE_SUBSCRIPTIONS";
export const UPDATE_SELECTED_AZURE_REGIONS = "UPDATE_SELECTED_AZURE_REGIONS";
export const CLEAR_AZURE_RESOURCE_LIST = "CLEAR_AZURE_RESOURCE_LIST";

export const SHOW_TAG_RESOURCE_ALERT = "SHOW_TAG_RESOURCE_ALERT";
export const HIDE_TAG_RESOURCE_ALERT = "HIDE_TAG_RESOURCE_ALERT";

export const GET_SNS_TOPIC_RESOURCES_REQUEST =
  "GET_SNS_TOPIC_RESOURCES_REQUEST";
export const GET_SNS_TOPIC_RESOURCES_SUCCESS =
  "GET_SNS_TOPIC_RESOURCES_SUCCESS";
export const GET_SNS_TOPIC_RESOURCES_FAILED = "GET_SNS_TOPIC_RESOURCES_FAILED";

import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { isValidPhoneNumber } from "react-phone-number-input";
import i18n from "../../../../config/locale/i18n";
import {
  numberRegex,
  fullNameRegex,
  emailRegex,
  specialCharacterRegex,
  passwordRegex,
} from "../../../../utils/regex-expressions";
import { allowedSpecialCharacters } from "../../../../utils/string-constants";
import {
  sluCaseStudyLink,
  jgiCaseStudyLink,
  leadSquaredCaseStudyLink,
} from "../../../../utils/app-links";
import botIcon from "../../../../images/bots/bot-blue.png";

export const MIN_CHARACTERS = "min-characters";
export const UPPER_CASE_LETTER = "uppercase-letter";
export const LOWER_CASE_LETTER = "lowercase-letter";
export const NUMBER = "number";
export const SPECIAL_CHARACTER = "special-character";
export const SIGNUP_PAGE = "signup_page";

export const passwordItems = [
  {
    key: MIN_CHARACTERS,
    value: i18n.t("signupForm:passwordMinCharacterText"),
  },
  {
    key: UPPER_CASE_LETTER,
    value: i18n.t("signupForm:passwordUppercaseLetterText"),
  },
  {
    key: LOWER_CASE_LETTER,
    value: i18n.t("signupForm:passwordLowercaseLetterText"),
  },
  {
    key: NUMBER,
    value: i18n.t("signupForm:passwordNumberText"),
  },
  {
    key: SPECIAL_CHARACTER,
    value: (
      <>
        {i18n.t("signupForm:passwordSpecialCharacterText")}

        <i
          className="fa fa-info-circle ml-1"
          id="password-special-characters"
        ></i>
        <UncontrolledTooltip
          placement="right"
          target="password-special-characters"
        >
          <small>{allowedSpecialCharacters}</small>
        </UncontrolledTooltip>
      </>
    ),
  },
];

export const validatePassword = (password, key) => {
  let isValid = false;
  if (password) {
    switch (key) {
      case MIN_CHARACTERS:
        isValid = password.length >= 8;
        break;
      case UPPER_CASE_LETTER:
        isValid = password.toLowerCase() !== password;
        break;
      case LOWER_CASE_LETTER:
        isValid = password.toUpperCase() !== password;
        break;
      case NUMBER:
        isValid = numberRegex.test(password);
        break;
      case SPECIAL_CHARACTER:
        isValid = specialCharacterRegex.test(password);
        break;
      default:
        break;
    }
  }
  return isValid;
};

export const validateSignUpForm = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = i18n.t("common:required");
  } else if (values.name.trim().length < 3) {
    errors.name = i18n.t("signupForm:validNameHint");
  } else if (!fullNameRegex.test(values.name.trim())) {
    errors.name = i18n.t("common:enterValidFullName");
  }
  if (!values.company) {
    errors.company = i18n.t("common:required");
  } else if (values.company.trim().length < 2) {
    errors.company = i18n.t("signupForm:validCompanyNameHint");
  }
  if (values.phoneNumber && !isValidPhoneNumber(values.phoneNumber)) {
    errors.phoneNumber = i18n.t("signupForm:validPhoneNumberHint");
  }
  if (!values.password) {
    errors.password = i18n.t("common:required");
  } else if (!passwordRegex.test(values.password)) {
    errors.password = i18n.t("signupForm:passwordMsg");
  }
  if (!values.customerDesignation) {
    errors.customerDesignation = i18n.t("common:required");
  }
  if (!values.email) {
    errors.email = i18n.t("common:required");
  } else if (!emailRegex.test(values.email)) {
    errors.email = i18n.t("common:invalidEmailAddress");
  }
  return errors;
};

export const customers = [
  {
    key: "slu",
    icon: "slu.png",
    link: sluCaseStudyLink,
  },
  {
    key: "jgi",
    icon: "jgi.png",
    link: jgiCaseStudyLink,
  },
  {
    key: "lsq",
    icon: "lsq.png",
    link: leadSquaredCaseStudyLink,
  },
];

export const signUpContentItems = [
  {
    key: "devOps",
    icon: "fa fa-sliders",
    heading: i18n.t("signupLayout:content.Heading1"),
    description: i18n.t("signupLayout:content.Description1"),
  },
  {
    key: "bots",
    image: botIcon,
    heading: i18n.t("signupLayout:content.Heading2"),
    description: i18n.t("signupLayout:content.Description2"),
  },
  {
    key: "intelligentApps",
    icon: "fa fa-lightbulb-o",
    heading: i18n.t("signupLayout:content.Heading3"),
    description: i18n.t("signupLayout:content.Description3"),
  },
  {
    key: "cost",
    icon: "fa fa-usd",
    heading: i18n.t("signupLayout:content.Heading4"),
    description: i18n.t("signupLayout:content.Description4"),
  },
];

export const signUpServerManagementContentItems = [
  {
    key: "discovery",
    icon: "fa fa-eye",
    heading: i18n.t("signupLayoutServerManagement:content.Heading1"),
    description: i18n.t("signupLayoutServerManagement:content.Description1"),
  },
  {
    key: "cost",
    icon: "fa fa-usd",
    heading: i18n.t("signupLayoutServerManagement:content.Heading2"),
    description: i18n.t("signupLayoutServerManagement:content.Description2"),
  },
  {
    key: "servermanagement",
    icon: "fa fa-server",
    heading: i18n.t("signupLayoutServerManagement:content.Heading3"),
    description: i18n.t("signupLayoutServerManagement:content.Description3"),
  },
  {
    key: "remoteSession",
    icon: "fa fa-external-link",
    heading: i18n.t("signupLayoutServerManagement:content.Heading4"),
    description: i18n.t("signupLayoutServerManagement:content.Description4"),
  },
];

import config from "../config";
import {
  CUSTOMER_ROLE_FULL_ACCESS_ID,
  EMAIL,
  FIRST_RUN_COMPLETED,
  CUSTOMER_ROLE_DAY2_ADMIN_ID,
} from "./app-constants.json";
import { isArrayWithLength } from "./array-methods";

const handleShowFRE = () => {
  const email = localStorage.getItem(EMAIL);
  const firstRunCompleted = localStorage.getItem(FIRST_RUN_COMPLETED);
  return ["cloudadmin@montycloud.com"].includes(email) && !firstRunCompleted;
};

export const redirectToFRE = (state, props) => {
  let roleId = localStorage.getItem("roleId");
  if (
    ![CUSTOMER_ROLE_FULL_ACCESS_ID, CUSTOMER_ROLE_DAY2_ADMIN_ID].includes(
      roleId
    )
  ) {
    return false;
  }

  if (config.demoApp) {
    return handleShowFRE();
  }

  if (!props.hasNoPlans) {
    return false;
  }
  const doAccountExist =
    props.filteredUserDataLoaded &&
    props.filteredUserData &&
    !isArrayWithLength(props.accountList) &&
    !isArrayWithLength(props.azureSubscriptionsList);
  return (
    ((!state.updated && props.hasNoPlans) || doAccountExist) &&
    props.customerPreferencesFetched &&
    state.isFirstRender
  );
};

export const getFREStepIndex = (
  subscriptionDetails,
  customerPreferences,
  successfullySubscribedToPlan
) => {
  if (config.demoApp) {
    // subscriptions will be always there
    return successfullySubscribedToPlan ? 2 : 0;
  }
  return subscriptionDetails
    ? 2
    : isArrayWithLength(customerPreferences)
    ? 1
    : 0;
};

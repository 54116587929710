import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "../../../../../../../config/customBaseQuery";
import {
  COST_DASHBOARD_LOOKUP,
  COST_DASHBOARD_TAG_VALUE,
} from "../../../../../../../config/service";
import { isEqual } from "lodash";

export const costTagValuesApi = createApi({
  reducerPath: "costTagValues",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getTagValues: builder.query({
      query: ({ queryParams }) => {
        return {
          url: `${COST_DASHBOARD_LOOKUP}${COST_DASHBOARD_TAG_VALUE}?${queryParams}`,
          method: "GET",
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return `tag-values_${endpointName}}`;
      },
      merge: (currentCache, newItems, { arg: { queryParams } }) => {
        const currentSearchTerm = currentCache?.searchTerm || "";
        const currentKey = currentCache?.key || "";
        const newSearchTerm =
          new URLSearchParams(queryParams).get("KeywordSearch") || "";
        const newKey = new URLSearchParams(queryParams).get("Key") || "";
        if (newSearchTerm !== currentSearchTerm || newKey !== currentKey) {
          return {
            Items: newItems.Items,
            Offset: newItems.Offset,
            searchTerm: newSearchTerm,
            key: newKey,
          };
        }
        return {
          ...currentCache,
          Items: [...(currentCache.Items || []), ...newItems.Items],
          Offset: newItems.Offset,
          searchTerm: newSearchTerm,
          key: newKey,
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg, previousArg);
      },
      transformResponse: (response, meta, arg) => {
        const newSearchTerm =
          new URLSearchParams(arg.queryParams).get("KeywordSearch") || "";
        const newKey = new URLSearchParams(arg.queryParams).get("Key");
        const transformedItems = response.Items.map((tagKey) => ({
          label: tagKey,
          value: tagKey,
        }));
        return {
          Items: transformedItems,
          Offset: response?.Offset,
          searchTerm: newSearchTerm,
          key: newKey,
        };
      },
    }),
  }),
});

export const { useGetTagValuesQuery } = costTagValuesApi;

export const isEmptyObject = (obj) => {
  return !(obj && obj.constructor === Object && Object.keys(obj).length > 0);
};

/**
 * Get the best matching key for a given value.
 * @param {Array} keys - The array of keys to match against the value.
 * @param {string} value - The value to search for a match in the keys.
 * @return {string} - The best matching key or an empty string if no match is found.
 */
export const getMatchKey = (keys, value = "") => {
  if (!keys || !keys.length) {
    return false;
  }

  const matches = keys.filter((key) =>
    value.toLowerCase().includes(key.toLowerCase())
  );

  if (!matches.length) {
    return "";
  }
  if (matches.length === 1) {
    return matches[0];
  }

  return matches.reduce((prev, curr) =>
    prev.length > curr.length ? prev : curr
  );
};

export const sortObject = (object, sortAsc = true, sortByValue = true) => {
  if (isEmptyObject(object)) {
    return object;
  }
  const arr = Object.entries(object);
  const index = sortByValue ? 1 : 0;
  sortAsc
    ? arr.sort((a, b) => a[index].localeCompare(b[index]))
    : arr.sort((a, b) => b[index].localeCompare(a[index]));

  // Convert the sorted array back into an object
  const sortedObject = Object.fromEntries(arr);

  return sortedObject;
};

export const convertObjectKeysToLowercase = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => convertObjectKeysToLowercase(item));
  }

  const convertedObj = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const lowercaseKey = key.toLowerCase();
      const value = obj[key];

      if (typeof value === "object" && value !== null) {
        convertedObj[lowercaseKey] = convertObjectKeysToLowercase(value);
      } else {
        convertedObj[lowercaseKey] = value;
      }
    }
  }

  return convertedObj;
};

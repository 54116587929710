import i18n from "../config/locale/i18n";

export const formatActionName = (action) => {
  let name;
  switch (action) {
    case "createImage":
      name = i18n.t("common:createImageText");
      break;
    case "scanPatch":
      name = i18n.t("common:scan").toLowerCase();
      break;
    case "installPatch":
      name = i18n.t("common:patch").toLowerCase();
      break;
    case "convertToManaged":
      name = i18n.t("common:convertToManaged");
      break;
    case "deregisterHybridServer":
      name = i18n.t("common:deregisterHybridServer");
      break;
    case "configureCloudWatchAgent":
      name = i18n.t("common:configureCloudWatchAgent");
      break;
    case "copyImage":
      name = i18n.t("ec2InstanceList:copyImage");
      break;
    case "shareImage":
      name = i18n.t("ec2InstanceList:shareImage");
      break;
    default:
      name = action;
      break;
  }
  return name;
};

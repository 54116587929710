export const MAP_PROJECT_DETAILS_REQUEST = "MAP_PROJECT_DETAILS_REQUEST";
export const MAP_PROJECT_DETAILS_SUCCESS = "MAP_PROJECT_DETAILS_SUCCESS";
export const MAP_PROJECT_DETAILS_FAILURE = "MAP_PROJECT_DETAILS_FAILURE";
export const MAP_PROJECT_RESOURCES_REQUEST = "MAP_PROJECT_RESOURCE_REQUEST";
export const MAP_PROJECT_RESOURCE_SUCCESS = "MAP_PROJECT_RESOURCE_SUCCESS";
export const MAP_PROJECT_EXECUTION_HISTORY_REQUEST =
  "MAP_PROJECT_EXECUTION_HISTORY_REQUEST";
export const MAP_PROJECT_EXECUTION_HISTORY_SUCCESS =
  "MAP_PROJECT_EXECUTION_HISTORY_SUCCESS";
export const MAP_PROJECT_SHOW_FIRST_RUN_POPUP =
  "MAP_PROJECT_SHOW_FIRST_RUN_POPUP";
export const MAP_PROJECT_HIDE_FIRST_RUN_POPUP =
  "MAP_PROJECT_HIDE_FIRST_RUN_POPUP";
export const SET_MAP_PROJECT_COMPLETED = "SET_MAP_PROJECT_COMPLETED";

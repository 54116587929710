import {
  CLEAR_ASSESSMENT_DETAILS,
  DELETE_SHARE_FAILURE,
  DELETE_SHARE_REQUEST,
  DELETE_SHARE_SUCCESS,
  FETCHING_ASSESSMENT_DETAILS_FAILURE,
  FETCHING_ASSESSMENT_DETAILS_START,
  FETCHING_ASSESSMENT_DETAILS_SUCCESS,
  FETCH_EXECUTION_HISTORY_FAILURE,
  FETCH_EXECUTION_HISTORY_REQUEST,
  FETCH_EXECUTION_HISTORY_SUCCESS,
  FETCH_LIST_SHARES_FAILURE,
  FETCH_LIST_SHARES_REQUEST,
  FETCH_LIST_SHARES_SUCCESS,
  MARK_COMPLETE_REQUEST,
  MARK_COMPLETE_RESET,
  MARK_COMPLETE_SUCCESS,
  RESTART_WORKLOAD_REQUEST,
  RESTART_WORKLOAD_RESET,
  RESTART_WORKLOAD_SUCCESS,
  SET_IMPROVEMENT_STATUS_OPTIONS,
  UPDATE_SHARE_FAILURE,
  UPDATE_SHARE_REQUEST,
  UPDATE_SHARE_SUCCESS,
} from "../actionTypes/assessments";
import {
  HIDE_FIRST_RUN_CHECKS_POPUP,
  SHOW_FIRST_RUN_CHECKS_POPUP,
} from "../actionTypes/details";

const initialState = {
  assessmentDetails: null,
  isFetching: false,
  assessmentFetchError: null,
  markingComplete: false,
  markedComplete: false,
  isRestartingWorkload: false,
  restartedWorkload: false,
  executionHistoryList: [],
  isExecutionListFetching: false,
  showFirstRunCheckPopUp: false,
  improvementStatuses: [],
  fetchingShares: false,
  assessmentShares: [],
  isShareUpdating: false,
  isShareDeleting: false,
};

const assessmentDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_ASSESSMENT_DETAILS_START: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCHING_ASSESSMENT_DETAILS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        assessmentDetails: action.payload.assessmentDetails,
        assessmentFetchError: null,
      };
    }
    case FETCHING_ASSESSMENT_DETAILS_FAILURE: {
      return {
        ...state,
        isFetching: false,
        assessmentDetails: null,
        assessmentFetchError: action.payload.error,
      };
    }
    case CLEAR_ASSESSMENT_DETAILS: {
      return {
        ...initialState,
      };
    }
    case MARK_COMPLETE_REQUEST: {
      return {
        ...state,
        markingComplete: true,
      };
    }
    case MARK_COMPLETE_SUCCESS: {
      return {
        ...state,
        markedComplete: true,
        markingComplete: false,
      };
    }
    case MARK_COMPLETE_RESET: {
      return {
        ...state,
        markedComplete: false,
        markingComplete: false,
      };
    }
    case RESTART_WORKLOAD_REQUEST: {
      return {
        ...state,
        isRestartingWorkload: true,
      };
    }
    case RESTART_WORKLOAD_SUCCESS: {
      return {
        ...state,
        isRestartingWorkload: false,
        restartedWorkload: true,
      };
    }
    case RESTART_WORKLOAD_RESET: {
      return {
        ...state,
        isRestartingWorkload: false,
        restartedWorkload: false,
      };
    }
    case FETCH_EXECUTION_HISTORY_REQUEST: {
      return {
        ...state,
        isExecutionListFetching: true,
      };
    }
    case FETCH_EXECUTION_HISTORY_SUCCESS: {
      return {
        ...state,
        isExecutionListFetching: false,
        executionHistoryList: action.payload,
      };
    }
    case FETCH_EXECUTION_HISTORY_FAILURE: {
      return {
        ...state,
        isExecutionListFetching: false,
      };
    }
    case SHOW_FIRST_RUN_CHECKS_POPUP: {
      return {
        ...state,
        showFirstRunCheckPopUp: true,
      };
    }
    case HIDE_FIRST_RUN_CHECKS_POPUP: {
      return {
        ...state,
        showFirstRunCheckPopUp: false,
      };
    }
    case SET_IMPROVEMENT_STATUS_OPTIONS: {
      return {
        ...state,
        improvementStatuses: action.payload,
      };
    }
    case FETCH_LIST_SHARES_REQUEST: {
      return {
        ...state,
        fetchingShares: true,
      };
    }
    case FETCH_LIST_SHARES_SUCCESS: {
      return {
        ...state,
        fetchingShares: false,
        assessmentShares: action.payload,
      };
    }
    case FETCH_LIST_SHARES_FAILURE: {
      return {
        ...state,
        fetchingShares: false,
      };
    }
    case UPDATE_SHARE_REQUEST: {
      return {
        ...state,
        isShareUpdating: true,
      };
    }
    case UPDATE_SHARE_SUCCESS: {
      return {
        ...state,
        isShareUpdating: false,
      };
    }
    case UPDATE_SHARE_FAILURE: {
      return {
        ...state,
        isShareUpdating: false,
      };
    }
    case DELETE_SHARE_REQUEST: {
      return {
        ...state,
        isShareDeleting: true,
      };
    }
    case DELETE_SHARE_SUCCESS: {
      return {
        ...state,
        isShareDeleting: false,
      };
    }
    case DELETE_SHARE_FAILURE: {
      return {
        ...state,
        isShareDeleting: false,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default assessmentDetailsReducer;

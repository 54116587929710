export const GET_BLUEPRINT_PARAMETERS_REQUEST =
  "GET_BLUEPRINT_PARAMETERS_REQUEST";
export const GET_BLUEPRINT_PARAMETERS_SUCCESS =
  "GET_BLUEPRINT_PARAMETERS_SUCCESS";
export const GET_BLUEPRINT_PARAMETERS_FAILURE =
  "GET_BLUEPRINT_PARAMETERS_FAILURE";
export const SET_BLUEPRINT_PARAMETERS = "SET_BLUEPRINT_PARAMETERS";
export const RESET_BLUEPRINT_PARAMETERS = "RESET_BLUEPRINT_PARAMETERS";
export const SET_CONFIG_FILLED_PARAM_KEY = "SET_CONFIG_FILLED_PARAM_KEY";
export const SET_COST_FILLED_PARAM = "SET_COST_FILLED_PARAM";
export const REQUEST_COST_FILLED_PARAM = "REQUEST_COST_FILLED_PARAM";
export const SAVE_DIRTY_LAUNCH_PARAMETERS = "SAVE_DIRTY_LAUNCH_PARAMETERS";

import {
  GET_ENVIRONMENT_DETAILS,
  ADD_RESOURCES_ENVIRONMENT,
  GET_ENVIRONMENTS_REQUEST,
  GET_ENVIRONMENTS_SUCCESS,
  GET_ENVIRONMENTS_FAILURE,
  CREATE_ENVIRONMENT_REQUEST,
  CREATE_ENVIRONMENT_SUCCESS,
  CREATE_ENVIRONMENT_FAILURE,
  CLEAR_ENVIRONMENT_DATA,
  GET_ENVIRONMENT_RESOURCES_REQUEST,
  GET_ENVIRONMENT_RESOURCES_SUCCESS,
  GET_ENVIRONMENT_RESOURCES_FAILURE,
  ADD_ENVIRONMENT_RESOURCES_REQUEST,
  ADD_ENVIRONMENT_RESOURCES_SUCCESS,
  ADD_ENVIRONMENT_RESOURCES_FAILURE,
  GET_ENVIRONMENT_RESOURCES_SUMMARY_REQUEST,
  GET_ENVIRONMENT_RESOURCES_SUMMARY_SUCCESS,
  GET_ENVIRONMENT_RESOURCES_SUMMARY_FAILURE,
  CLEAR_ENVIRONMENT_SUMMARY,
} from "../actionTypes/environment";

const initialState = {
  environmentDetails: null,
  environmentResources: null,
  addResourcesResponse: null,
  environmentList: null,
  isCreating: false,
  createdEnvironment: false,
  createdEnvId: null,
  isFetchingResources: false,
  isAddingResources: false,
  addedResources: false,
  environmentAccountNumber: null,
  environmentRegionCode: null,
  isFetchingEnvironmentResourceSummary: false,
  environmentResourceSummary: null,
  fetchedEnvironmentResourceSummary: false,
  failedToFetchEnvironmentResourceSummary: false,
  isFetchingResourcesFailed: false,
};

const environmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ENVIRONMENT_DETAILS:
      return {
        ...state,
        environmentDetails: action.payload,
      };
    case ADD_RESOURCES_ENVIRONMENT:
      return {
        ...state,
        addResourcesResponse: action.payload,
      };
    case GET_ENVIRONMENTS_REQUEST:
      return {
        ...state,
        environmentList: null,
        isFetching: true,
      };
    case GET_ENVIRONMENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        environmentList: action.payload,
      };
    case GET_ENVIRONMENTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case CREATE_ENVIRONMENT_REQUEST:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_ENVIRONMENT_SUCCESS:
      return {
        ...state,
        isCreating: false,
        createdEnvironment: true,
        createdEnvId: action.payload.ID,
      };
    case CREATE_ENVIRONMENT_FAILURE:
      return {
        ...state,
        isCreating: false,
        errorMessage: action.payload,
      };
    case CLEAR_ENVIRONMENT_DATA:
      return {
        ...state,
        createdEnvironment: initialState.createdEnvironment,
        createdEnvId: initialState.createdEnvId,
        addedResources: initialState.addedResources,
      };
    case GET_ENVIRONMENT_RESOURCES_REQUEST:
      return {
        ...state,
        isFetchingResources: true,
        isFetchingResourcesFailed: false,
        environmentResources: null,
        environmentAccountNumber: null,
        environmentRegionCode: null,
      };
    case GET_ENVIRONMENT_RESOURCES_SUCCESS:
      return {
        ...state,
        isFetchingResources: false,
        environmentResources: action.payload,
        environmentAccountNumber:
          action.payload && action.payload.length
            ? action.payload[0].AccountNumber
            : null,
        environmentRegionCode:
          action.payload && action.payload.length
            ? action.payload[0].Region
            : null,
      };
    case GET_ENVIRONMENT_RESOURCES_FAILURE:
      return {
        ...state,
        isFetchingResources: false,
        isFetchingResourcesFailed: true,
        errorMessage: action.payload,
      };
    case ADD_ENVIRONMENT_RESOURCES_REQUEST:
      return {
        ...state,
        isAddingResources: true,
      };
    case ADD_ENVIRONMENT_RESOURCES_SUCCESS:
      return {
        ...state,
        isAddingResources: false,
        addedResources: true,
      };
    case ADD_ENVIRONMENT_RESOURCES_FAILURE:
      return {
        ...state,
        isAddingResources: false,
        errorMessage: action.payload,
      };

    case GET_ENVIRONMENT_RESOURCES_SUMMARY_REQUEST:
      return {
        ...state,
        isFetchingEnvironmentResourceSummary: true,
        environmentResourceSummary: initialState.environmentResourceSummary,
        fetchedEnvironmentResourceSummary:
          initialState.fetchedEnvironmentResourceSummary,
        failedToFetchEnvironmentResourceSummary:
          initialState.failedToFetchEnvironmentResourceSummary,
      };
    case GET_ENVIRONMENT_RESOURCES_SUMMARY_SUCCESS:
      return {
        ...state,
        isFetchingEnvironmentResourceSummary: false,
        environmentResourceSummary: action.payload,
        fetchedEnvironmentResourceSummary: true,
        failedToFetchEnvironmentResourceSummary: false,
      };
    case GET_ENVIRONMENT_RESOURCES_SUMMARY_FAILURE:
      return {
        ...state,
        isFetchingEnvironmentResourceSummary: false,
        fetchedEnvironmentResourceSummary: false,
        failedToFetchEnvironmentResourceSummary: true,
      };
    case CLEAR_ENVIRONMENT_SUMMARY:
      return {
        ...state,
        isFetchingEnvironmentResourceSummary:
          initialState.isFetchingEnvironmentResourceSummary,
        environmentResources: initialState.environmentResources ,
        environmentResourceSummary: initialState.environmentResourceSummary,
        fetchedEnvironmentResourceSummary:
          initialState.fetchedEnvironmentResourceSummary,
        failedToFetchEnvironmentResourceSummary:
          initialState.failedToFetchEnvironmentResourceSummary,
      };
    default:
      return {
        ...state,
      };
  }
};

export default environmentReducer;

import {
  COMPUTE_INSTANCE_REQUEST,
  COMPUTE_INSTANCE_SUCCESS,
  COMPUTE_INSTANCE_FAILURE,
  CREATE_BACKUP_INSTANCE_REQUEST,
  CREATE_BACKUP_INSTANCE_SUCCESS,
  CREATE_BACKUP_INSTANCE_FAILURE,
  GET_INSTANCE_DETAILS_REQUEST,
  GET_INSTANCE_DETAILS_SUCCESS,
  GET_INSTANCE_DETAILS_FAILURE,
  GET_INSTANCE_SESSION_HISTORY_REQUEST,
  GET_INSTANCE_SESSION_HISTORY_SUCCESS,
  GET_INSTANCE_SESSION_HISTORY_FAILURE,
  GET_INSTANCE_SESSION_LOG_REQUEST,
  GET_INSTANCE_SESSION_LOG_SUCCESS,
  GET_INSTANCE_SESSION_LOG_FAILURE,
  INSTANCE_SESSION_START_REQUEST,
  INSTANCE_SESSION_START_SUCCESS,
  INSTANCE_SESSION_START_FAILURE,
  INSTANCE_SESSION_RESUME_REQUEST,
  INSTANCE_SESSION_RESUME_SUCCESS,
  INSTANCE_SESSION_RESUME_FAILURE,
  INSTANCE_SESSION_TERMINATE_REQUEST,
  INSTANCE_SESSION_TERMINATE_SUCCESS,
  INSTANCE_SESSION_TERMINATE_FAILURE,
  CLEAR_COMPUTE_INSTANCE_STATE,
  SET_IMAGE_DESCRIPTION,
  SET_IMAGE_NAME,
  SET_IMAGE_MANAGED_TAGS,
  GET_RDP_CONFIG_REQUEST,
  GET_RDP_CONFIG_SUCCESS,
  GET_RDP_CONFIG_FAILURE,
  CLEAR_RDP_CONFIG_DATA,
  GET_INSTANCE_TYPES,
  CLEAR_INSTANCE_DETAILS,
} from "../actionTypes/instance";

import { updateResourceTagsApi } from "../../organization/services/resource";
import {
  performComputeOperations,
  snapshotEC2Instances,
  getInstanceDetailsApi,
  getInstanceSessionsHistoryApi,
  getInstanceSessionLogApi,
  startSessionApi,
  resumeSessionApi,
  terminateSessionApi,
  getRDPConfigApi,
  convertToManageInstance,
  getInstanceTypesApi,
} from "../services/instance";

import { postNotification } from "../../../utils/notification-message";
import { formatDateToUTC } from "../../../utils/convert-date";
import { HYBRID_INITIALS, NAME_TAG } from "../constants/instance";
import { formatActionName } from "../../../utils/formatActionName";
import i18n from "../../../config/locale/i18n";
import {
  CONVERT_TO_MANAGED,
  MANAGE_RESOURCE_TAGS,
  STATUS_LOADING,
  STATUS_SUCCESS,
  STATUS_FAILURE,
} from "../../../utils/app-constants.json";

function requestComputeInstance() {
  return {
    type: COMPUTE_INSTANCE_REQUEST,
  };
}

export const receiveComputeInstance = (payload, params, type) => (dispatch) => {
  let action = formatActionName(params.Action);
  if (type) {
    action = type;
  }
  let id =
    params && (params.ResourceIds || params.SnapshotID || params.InstanceId);
  const message = i18n.t("tasks:jobSuccessMessage", {
    action,
    id,
    type:
      type === "rename image"
        ? i18n.t("common:image")
        : i18n.t("common:instance"),
  });
  dispatch(postNotification(message, "success"));
  dispatch({
    type: COMPUTE_INSTANCE_SUCCESS,
    payload,
  });
};

function computeInstanceError(payload) {
  return {
    type: COMPUTE_INSTANCE_FAILURE,
    payload,
  };
}

export const computeInstance = (params, type) => (dispatch) => {
  dispatch(requestComputeInstance());
  let operationApi;
  if (params.Action === MANAGE_RESOURCE_TAGS) {
    operationApi = updateResourceTagsApi;
  } else if (params.Action === CONVERT_TO_MANAGED) {
    operationApi = convertToManageInstance;
  } else {
    operationApi = performComputeOperations;
  }
  operationApi(params)
    .then((data) => {
      dispatch(receiveComputeInstance(data, params, type));
    })
    .catch((err) => {
      const error = JSON.parse(err.message);
      let message = i18n.t("common:errorMessage");
      if (error.data) {
        message = error.data.ErrorMessage;
      }
      if (error.status !== 403) {
        dispatch(postNotification(message, "danger"));
      }
      dispatch(computeInstanceError(message));
    });
};

export const clearInstanceState = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPUTE_INSTANCE_STATE,
  });
};

export const clearInstanceDetails = () => (dispatch) => {
  dispatch({
    type: CLEAR_INSTANCE_DETAILS,
  });
}

function requestCreateBackup() {
  return {
    type: CREATE_BACKUP_INSTANCE_REQUEST,
  };
}

export function receiveCreateBackup(payload) {
  return {
    type: CREATE_BACKUP_INSTANCE_SUCCESS,
    payload,
  };
}

function createBackupError(payload) {
  return {
    type: CREATE_BACKUP_INSTANCE_FAILURE,
    payload,
  };
}

export const setImageName = (payload) => ({
  type: SET_IMAGE_NAME,
  payload,
});

export const setImageDescription = (payload) => ({
  type: SET_IMAGE_DESCRIPTION,
  payload,
});

export const setManageTagsResource = (payload) => ({
  type: SET_IMAGE_MANAGED_TAGS,
  payload,
});

export const createBackup = (params) => (dispatch) => {
  dispatch(requestCreateBackup());
  snapshotEC2Instances(params)
    .then((data) => {
      dispatch(receiveCreateBackup(data));
      dispatch(postNotification(i18n.t("snapshot:snapShotCreated"), "success"));
    })
    .catch((err) => {
      const error = JSON.parse(err.message);
      const message = error.data.ErrorMessage;
      dispatch(createBackupError(message));
    });
};

export const getInstanceDetails = (instanceId) => (dispatch) => {
  dispatch({
    type: GET_INSTANCE_DETAILS_REQUEST,
  });

  getInstanceDetailsApi(instanceId)
    .then((data) => {
      let preProcessPayload =
        instanceId.substring(0, 2) === HYBRID_INITIALS
          ? preProcessHybridInstance(data)
          : preProcessInstance(data);
      dispatch({
        type: GET_INSTANCE_DETAILS_SUCCESS,
        payload: preProcessPayload,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_INSTANCE_DETAILS_FAILURE,
        payload: err,
      });
    });
};

const preProcessInstance = (instance) => {
  const nameTag =
    instance.Tags && instance.Tags.find((tag) => tag.Key === NAME_TAG);
  const name = nameTag ? nameTag.Value : "";
  return {
    instanceState: instance.State && instance.State.Name,
    instanceId: instance.InstanceId,
    name: name,
    instanceType: instance.InstanceType,
    regionCode: instance.RegionCode,
    availabilityZone: instance.Placement && instance.Placement.AvailabilityZone,
    statusChecks: "-",
    alarmStatus: "-",
    publicDNSIPv4: instance.PublicDnsName,
    ipv4PublicIP: instance.PublicIpAddress,
    ipv6IPS: "-",
    monitoring: instance.Monitoring && instance.Monitoring.State,
    launchTime: instance.LaunchTime,
    securityGroups: instance.SecurityGroups, // change this
    vpcID: instance.VpcId,
    subnetId: instance.SubnetId,
    rootDeviceType: instance.RootDeviceType,
    iamInstanceProfileName:
      instance.IamInstanceProfile && instance.IamInstanceProfile.Arn
        ? instance.IamInstanceProfile.Arn
        : "-",
    elasticIPs: "-", // check this,
    imageId: instance.ImageId,
    privateDNSName: instance.PrivateDnsName,
    privateIPAddress: instance.PrivateIpAddress,
    virtualizationType: instance.VirtualizationType,
    architecture: instance.Architecture,
    lifecycle: "-",
    owner: instance.NetworkInterfaces, // change this
    ebsOptimized: instance.EbsOptimized ? "Yes" : "No",
    stateTransitionReason: instance.StateTransitionReason,
    stateTransitionMessage: "-",
    rootDeviceName: instance.RootDeviceName,
    blockDevices: instance.BlockDeviceMappings, // change this
    placementGroup: instance.Placement && instance.Placement.GroupName,
    tenancy: instance.Placement && instance.Placement.Tenancy,
    hostId: "-",
    affinity: "-",
    amiLaunchIndex: instance.AmiLaunchIndex,
    reservation: "-",
    kernalId: "-",
    ramDiskId: "-",
  };
};

const preProcessHybridInstance = (instance) => {
  const nameTag =
    instance.Tags && instance.Tags.find((tag) => tag.Key === NAME_TAG);
  const name = nameTag ? nameTag.Value : "";
  return {
    instanceId: instance.InstanceId,
    pingStatus: instance.PingStatus,
    lastPingDateTime: instance.LastPingDateTime,
    agentVersion: instance.AgentVersion,
    isLatestVersion: instance.IsLatestVersion,
    platformType: instance.PlatformType,
    platformName: instance.PlatformName ? instance.PlatformName : name,
    platformVersion: instance.PlatformVersion,
    activationId: instance.ActivationId,
    iamRole: instance.IamRole,
    registrationDate: instance.RegistrationDate,
    resourceType: instance.ResourceType,
    name: instance.Name,
    ipAddress: instance.IPAddress,
    computerName: instance.ComputerName,
    associationStatus: instance.AssociationStatus,
    lastAssociationExecutionDate: instance.LastAssociationExecutionDate,
    lastSuccessfulAssociationExecutionDate:
      instance.LastSuccessfulAssociationExecutionDate,
    associationDetailedStatus:
      instance.AssociationOverview &&
      instance.AssociationOverview.DetailedStatus,
    successfulAssociatedInstancesAggregatedCount:
      instance.AssociationOverview &&
      instance.AssociationOverview.InstanceAssociationStatusAggregatedCount &&
      instance.AssociationOverview.InstanceAssociationStatusAggregatedCount
        .Success,
    failedAssociatedInstancesAggregatedCount:
      instance.AssociationOverview &&
      instance.AssociationOverview.InstanceAssociationStatusAggregatedCount &&
      instance.AssociationOverview.InstanceAssociationStatusAggregatedCount
        .Failed,
  };
};

const getSessionHistoryRequest = () => ({
  type: GET_INSTANCE_SESSION_HISTORY_REQUEST,
});

const getSessionHistorySuccess = (data) => ({
  type: GET_INSTANCE_SESSION_HISTORY_SUCCESS,
  payload: preprocessSessionHistory(data),
});

const getSessionHistoryFailure = () => ({
  type: GET_INSTANCE_SESSION_HISTORY_FAILURE,
});

export const getInstanceSessionHistory =
  (instanceId) => (dispatch, getState) => {
    dispatch(getSessionHistoryRequest());
    getInstanceSessionsHistoryApi(instanceId)
      .then((result) => {
        dispatch(getSessionHistorySuccess(result));
      })
      .catch((error) => {
        dispatch(getSessionHistoryFailure());
      });
  };

const preprocessSessionHistory = (history) => {
  let processedData = [];
  history.forEach((data) => {
    processedData.push({
      ...data,
      Status: data.Status.toLowerCase(),
      StartDate: formatDateToUTC(data.StartDate),
      EndDate: formatDateToUTC(data.EndDate),
      ViewLogs: data.SessionId + "," + data.Status.toLowerCase(),
    });
  });
  return processedData;
};

const getSessionLogRequest = () => ({
  type: GET_INSTANCE_SESSION_LOG_REQUEST,
});

const getSessionLogSuccess = (data) => ({
  type: GET_INSTANCE_SESSION_LOG_SUCCESS,
  payload: data,
});

const getSessionLogFailure = (errorMessage) => ({
  type: GET_INSTANCE_SESSION_LOG_FAILURE,
  payload: errorMessage,
});

export const getInstanceSessionLog =
  (instanceId, sessionId) => (dispatch, getState) => {
    dispatch(getSessionLogRequest());
    getInstanceSessionLogApi(instanceId, sessionId)
      .then((result) => {
        dispatch(getSessionLogSuccess(result));
      })
      .catch((error) => {
        let errorMessage;
        let message = JSON.parse(error.message);
        if (message) {
          errorMessage = message.data.ErrorMessage;
        }
        dispatch(getSessionLogFailure(errorMessage));
      });
  };

const sessionStartRequest = () => ({
  type: INSTANCE_SESSION_START_REQUEST,
});

const sessionStartSuccess = (data) => ({
  type: INSTANCE_SESSION_START_SUCCESS,
  payload: data,
});

const sessionStartFailure = (error) => ({
  type: INSTANCE_SESSION_START_FAILURE,
  payload: error,
});

export const startSession = (instanceId) => (dispatch, getState) => {
  dispatch(sessionStartRequest());
  startSessionApi(instanceId)
    .then((result) => {
      dispatch(sessionStartSuccess(result));
    })
    .catch((error) => {
      let errorMessage;
      let message = JSON.parse(error.message);
      if (message) {
        errorMessage = message.data.ErrorMessage;
      }
      dispatch(sessionStartFailure(errorMessage));
    });
};

const sessionResumeRequest = () => ({
  type: INSTANCE_SESSION_RESUME_REQUEST,
});

const sessionResumeSuccess = (data) => ({
  type: INSTANCE_SESSION_RESUME_SUCCESS,
  payload: data,
});

const sessionResumeFailure = () => ({
  type: INSTANCE_SESSION_RESUME_FAILURE,
});

export const resumeSession =
  (instanceId, sessionId) => (dispatch, getState) => {
    dispatch(sessionResumeRequest());
    resumeSessionApi(instanceId, sessionId)
      .then((result) => {
        dispatch(sessionResumeSuccess(result));
      })
      .catch((error) => {
        dispatch(sessionResumeFailure());
      });
  };

const sessionTerminateRequest = () => ({
  type: INSTANCE_SESSION_TERMINATE_REQUEST,
});

const sessionTerminateSuccess = (data) => ({
  type: INSTANCE_SESSION_TERMINATE_SUCCESS,
  payload: data,
});

const sessionTerminateFailure = (error) => ({
  type: INSTANCE_SESSION_TERMINATE_FAILURE,
  payload: error,
});

export const terminateSession =
  (instanceId, sessionId) => (dispatch, getState) => {
    dispatch(sessionTerminateRequest());
    terminateSessionApi(instanceId, sessionId)
      .then((result) => {
        dispatch(sessionTerminateSuccess(result));
      })
      .catch((error) => {
        let errorMessage;
        let message = JSON.parse(error.message);
        if (message) {
          errorMessage = message.data.ErrorMessage;
        }
        dispatch(sessionTerminateFailure(errorMessage));
      });
  };

export const getRDPConfig = (instanceId) => async (dispatch) => {
  dispatch({
    type: GET_RDP_CONFIG_REQUEST,
  });

  try {
    const request = await getRDPConfigApi(instanceId);
    const config = request && request.data;
    const preProcessedData = {
      configExists: config && config.ConfigExists,
      cfnPath: config && config.CFNPath,
    };
    dispatch({
      type: GET_RDP_CONFIG_SUCCESS,
      payload: preProcessedData,
    });
  } catch (err) {
    const parsedError = err && JSON.parse(err) && JSON.parse(err);
    let isAccessDenied = false;
    if (parsedError.status === 403) {
      isAccessDenied = true;
    }
    dispatch({
      type: GET_RDP_CONFIG_FAILURE,
      payload: isAccessDenied,
    });
  }
};

export const clearRDPConfigData = () => ({
  type: CLEAR_RDP_CONFIG_DATA,
});

export const getInstanceTypes = (regionCode) => async (dispatch) => {
  try {
    dispatch({
      type: GET_INSTANCE_TYPES,
      payload: {
        status: STATUS_LOADING,
      },
    });
    const params = `RegionCode=${regionCode}`;
    const { data } = await getInstanceTypesApi(params);
    dispatch({
      type: GET_INSTANCE_TYPES,
      payload: {
        items: data,
        status: STATUS_SUCCESS,
      },
    });
  } catch (error) {
    dispatch({
      type: GET_INSTANCE_TYPES,
      payload: {
        status: STATUS_FAILURE,
      },
    });
  }
};

export const clearInstanceTypesData = () => ({
  type: GET_INSTANCE_TYPES,
});

export const GET_ACCOUNTS_REQUEST = "GET_ACCOUNTS_REQUEST";
export const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS";
export const GET_ACCOUNTS_FAILURE = "GET_ACCOUNTS_FAILURE";
export const GET_DEPT_ACCOUNTS_REQUEST = "GET_DEPT_ACCOUNTS_REQUEST";
export const GET_DEPT_ACCOUNTS_SUCCESS = "GET_DEPT_ACCOUNTS_SUCCESS";
export const GET_DEPT_ACCOUNTS_FAILURE = "GET_DEPT_ACCOUNTS_FAILURE";
export const GET_STANDBY_ACCOUNTS_REQUEST = "GET_STANDBY_ACCOUNTS_REQUEST";
export const GET_STANDBY_ACCOUNTS_SUCCESS = "GET_STANDBY_ACCOUNTS_SUCCESS";
export const GET_STANDBY_ACCOUNTS_FAILURE = "GET_STANDBY_ACCOUNTS_FAILURE";
export const GET_AZURE_SUBSCRIPTIONS_REQUEST =
  "GET_AZURE_SUBSCRIPTIONS_REQUEST";
export const GET_AZURE_SUBSCRIPTIONS_SUCCESS =
  "GET_AZURE_SUBSCRIPTIONS_SUCCESS";
export const GET_AZURE_SUBSCRIPTIONS_FAILURE =
  "GET_AZURE_SUBSCRIPTIONS_FAILURE";

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import s from "./NewFirstRun.module.scss";
import classnames from "classnames";
import { Col, Row } from "reactstrap";
import { getAllOrganizations } from "../../first-run/actions/firstRun";
import { subscribeToSummaryEmails } from "../actions/new-first-run";
import { convertListValueLabel } from "../../../utils/convert-select-value";
import CustomSelect from "../../../components/CustomSelect";
import MCButton from "../../../components/MCButton/";
import { isArrayWithLength } from "../../../utils/array-methods";
import { addUsers } from "../../user-data/actions/user-data";
import SingleCheckbox from "../../../components/SingleCheckbox";
import ExternalLink from "../../../components/ExternalLink";
import i18n from "../../../config/locale/i18n";
import { useTranslation } from "react-i18next";

const slidesContent = [
  {
    stepNumber: "1",
    name: i18n.t("firstRun:inviteUsers"),
    heading: i18n.t("firstRun:inviteUserHeading"),
    placeholder: i18n.t("firstRun:emailPlaceHolder"),
    placeholderName: i18n.t("common:enterName"),
    roles: [
      { ID: "c71db059-6b93-4a95-9092-6d22d73a7fc6", Name: "Cloud Admin" },
      { ID: "8851ae0b-d16b-49a4-93e5-35fc38e87818", Name: "Project Owner" },
      {
        ID: "d3de658f-e8ab-5596-85ba-f2646831026f",
        Name: "Project User",
      },
      { ID: "9b7b0eb1-8755-4ad4-85b0-bad37341f739", Name: "Reports Access" },
      {
        ID: "no-access",
        Name: "No Access",
      },
    ],
  },
];

const OptionalFeatures = ({ parentNext }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["firstRun", "common"]);

  const {
    savedOrg,
    savingEmailPreference,
    successfullySavedEmailPreference,
    filteredUserData,
    savingUsers,
    userSaved,
  } = useSelector(({ firstRun, newFirstRun, globalConfig, userData }) => {
    return {
      savedOrg: firstRun.savedOrg,
      savingEmailPreference: newFirstRun.savingEmailPreference,
      successfullySavedEmailPreference:
        newFirstRun.successfullySavedEmailPreference,
      filteredUserData: globalConfig.filteredUserData,
      savingUsers: userData.savingUsers,
      userSaved: userData.userSaved,
    };
  }, shallowEqual);

  const [steps] = useState(slidesContent);
  const [users, setUsers] = useState([{ name: "", email: "" }]);
  const [disableUserNextBtn, setDisableUserNextBtn] = useState(true);

  useEffect(() => {
    if (savedOrg) {
      dispatch(getAllOrganizations());
    }
  }, [savedOrg]);

  useEffect(() => {
    if (successfullySavedEmailPreference) {
      parentNext();
    }
  }, [successfullySavedEmailPreference]);

  useEffect(() => {
    userSaved && onSaveEmails();
  }, [userSaved]);

  const validateEmail = (email) => {
    const reg = /\S+@\S+\.\S+/;
    return reg.test(email);
  };

  const onSaveEmails = () => {
    const emails =
      isArrayWithLength(users) &&
      users.filter((user) => user.sendWeeklyReport).map((user) => user.email);
    isArrayWithLength(emails)
      ? dispatch(subscribeToSummaryEmails(emails))
      : parentNext();
  };

  const rolesOptions = (roles) => convertListValueLabel(roles, "ID", "Name");

  const checkAllUsers = (_users) => {
    let allValid = true;
    _users.forEach((user) => {
      if (!(user.name && validateEmail(user.email) && user.role)) {
        allValid = false;
      }
    });
    return allValid;
  };

  const onInviteUserChange = (value, index, type) => {
    let _users = [...users];
    _users[index][type] = value;
    const isValid = checkAllUsers(_users);
    setDisableUserNextBtn(!isValid);
    setUsers(_users);
  };

  const addAnotherUser = () => {
    setUsers([...users, { name: "", email: "" }]);
    setDisableUserNextBtn(true);
  };

  const removeUser = (index) => {
    let _users = [...users];
    _users.splice(index, 1);
    const isValid = checkAllUsers(_users);
    setDisableUserNextBtn(!isValid);
    setUsers(_users);
  };

  const onSaveUsers = () => {
    const deptId =
      (filteredUserData &&
        isArrayWithLength(filteredUserData.Departments) &&
        filteredUserData.Departments[0].ID) ||
      "";
    const orgId =
      (filteredUserData &&
        isArrayWithLength(filteredUserData.Organizations) &&
        filteredUserData.Organizations[0].ID) ||
      "";
    const params = [];
    users.forEach((user) => {
      if (user.role.value !== "no-access") {
        const dept =
          user.role.label === "Full Access" ? "*" : deptId ? deptId : "*";
        let temp = {
          Name: user.name,
          Email: user.email,
          type: "human-user", // required only for demo env
          Description: "",
          Permissions: [
            {
              Role: user.role.value,
              Dept: dept,
              Org: orgId,
            },
          ],
        };
        params.push(temp);
      }
    });
    isArrayWithLength(params) ? dispatch(addUsers(params)) : onSaveEmails();
  };

  return (
    <div
      className={classnames(
        s.welcomeDivLarge,
        s.ContentBackground,
        s.ConnectAccountContainer
      )}
    >
      <Row className="px-2 px-4">
        <Col lg={7}>
          <div className="text-center">
            <img loading="lazy"
              src={"/images/dashboard/email.png"}
              alt="day2"
              className={classnames(
                s.sideImageFeatures,
                s.ConfigureAccessImage
              )}
            />
          </div>
          <div className={classnames("mt-5 ml-4", s.PartnershipLink)}>
            <ExternalLink
              text={t("readMoreAboutPartnership")}
              size="medium"
              externalLink="https://www.montycloud.com/aws/"
            />
          </div>
        </Col>
        <Col lg={5}>
          <div className="mt-3">
            {isArrayWithLength(steps) &&
              steps.map((step) => {
                return (
                  <React.Fragment key={step.name}>
                    <div
                      className={classnames(
                        s.MCHeading4,
                        s.Bold600,
                        s.relative,
                        "orange-text"
                      )}
                    >
                      {step.heading}
                    </div>

                    <>
                      <div className={s.Content}>
                        {users.map((user, index) => (
                          <React.Fragment key={index}>
                            <Row>
                              <Col xl={6} className={classnames("mt-2")}>
                                <input
                                  type="text"
                                  className={classnames(
                                    s.inputTextInvite,
                                    "mt-4 pt-1"
                                  )}
                                  placeholder={step.placeholderName}
                                  onChange={(e) =>
                                    onInviteUserChange(
                                      e.target.value,
                                      index,
                                      "name"
                                    )
                                  }
                                  value={user.name}
                                />
                              </Col>
                              <Col xl={6} className={classnames("mt-2")}>
                                <input
                                  type="text"
                                  className={classnames(
                                    s.inputTextInvite,
                                    "mt-4 pt-1"
                                  )}
                                  placeholder={step.placeholder}
                                  onChange={(e) =>
                                    onInviteUserChange(
                                      e.target.value,
                                      index,
                                      "email"
                                    )
                                  }
                                  value={user.email}
                                />
                              </Col>
                            </Row>

                            {step.roles && (
                              <div className={classnames("mt-2")}>
                                <Row>
                                  <Col xl={6} className={classnames("mt-2")}>
                                    <CustomSelect
                                      value={user.role ? [user.role] : null}
                                      onChange={(value) =>
                                        onInviteUserChange(value, index, "role")
                                      }
                                      options={rolesOptions(step.roles)}
                                      placeholder={t("selectRole")}
                                      isClearable={false}
                                      isMultiFilter={false}
                                      isMulti={false}
                                      extraStyles={{
                                        width: "100%",
                                      }}
                                    />
                                  </Col>
                                  <Col xl={6} className={classnames("mt-3")}>
                                    <SingleCheckbox
                                      checked={user.sendWeeklyReport}
                                      onChange={(event) =>
                                        onInviteUserChange(
                                          event.target.checked,
                                          index,
                                          "sendWeeklyReport"
                                        )
                                      }
                                      extraClassName={s.CheckBox}
                                      id={"checkbox-" + step.stepNumber + index}
                                      text={
                                        <span className={s.Opacity}>
                                          Send Weekly Reports
                                        </span>
                                      }
                                    />
                                  </Col>
                                </Row>
                              </div>
                            )}
                            {users.length > 1 && (
                              <div
                                className={classnames(
                                  s.addAnother,
                                  s.removeText,
                                  "mt-1"
                                )}
                                onClick={() => removeUser(index)}
                              >
                                <i className="fa fa-times-circle mr-2"></i>
                                {t("common:remove")}
                              </div>
                            )}
                          </React.Fragment>
                        ))}

                        <div
                          className={classnames(s.addAnother, "mt-4 mb-1")}
                          onClick={addAnotherUser}
                        >
                          <i className="fa fa-plus-circle mr-2"></i>
                          {t("common:addAnother")}
                        </div>
                      </div>
                      <div className={s.ButtonPosition}>
                        <MCButton
                          size="lg"
                          disabled={
                            savingUsers ||
                            savingEmailPreference ||
                            disableUserNextBtn
                          }
                          text={
                            savingUsers || savingEmailPreference
                              ? "Inviting users, please wait..."
                              : "Invite Users"
                          }
                          color="mc-blue"
                          onClick={onSaveUsers}
                          className={classnames(s.robotoFont, "mt-4")}
                        />

                        <span
                          className={classnames(
                            s.skipThisLink,
                            s.SkipThisPosition
                          )}
                          onClick={() => parentNext()}
                        >
                          {t("skipThis")}
                        </span>
                      </div>
                    </>
                  </React.Fragment>
                );
              })}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OptionalFeatures;

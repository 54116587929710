export const SAVE_SELECTED_INSIGHT = "SAVE_SELECTED_INSIGHT";

export const GET_BOT_INSIGHTS_REQUEST = "GET_BOT_INSIGHTS_REQUEST";
export const GET_BOT_INSIGHTS_SUCCESS = "GET_BOT_INSIGHTS_SUCCESS";
export const GET_BOT_INSIGHTS_FAILURE = "GET__BOT_INSIGHTS_FAILURE";

export const REMEDIATE_INSIGHT_REQUEST = "REMEDIATE_INSIGHT_REQUEST";
export const REMEDIATE_INSIGHT_SUCCESS = "REMEDIATE_INSIGHT_SUCCESS";
export const REMEDIATE_INSIGHT_FAILURE = "REMEDIATE_INSIGHT_FAILURE";
export const RESET_REMEDIATION_DATA = "RESET_REMEDIATION_DATA";

export const SUPPRESS_INSIGHTS_REQUEST = "SUPPRESS_INSIGHTS_REQUEST";
export const SUPPRESS_INSIGHTS_SUCCESS = "SUPPRESS_INSIGHTS_SUCCESS";
export const SUPPRESS_INSIGHTS_FAILURE = "SUPPRESS_INSIGHTS_FAILURE";

export const GET_ALL_BOT_TASKS_REQUEST = "GET_ALL_BOT_TASKS_REQUEST";
export const GET_ALL_BOT_TASKS_SUCCESS = "GET_ALL_BOT_TASKS_SUCCESS";
export const GET_ALL_BOT_TASKS_FAILURE = "GET_ALL_BOT_TASKS_FAILURE";
export const CLEAR_INSIGHTS_DATA = "CLEAR_INSIGHTS_DATA";
export const GET_INSIGHTS_EXECUTION_HISTORY_REQUEST =
  "GET_INSIGHTS_EXECUTION_HISTORY_REQUEST";
export const GET_INSIGHTS_EXECUTION_HISTORY_SUCCESS =
  "GET_INSIGHTS_EXECUTION_HISTORY_SUCCESS";
export const GET_INSIGHTS_EXECUTION_HISTORY_FAILURE =
  "GET_INSIGHTS_EXECUTION_HISTORY_FAILURE";
export const SAVE_INSIGHTS_CURRENT_TAB = "SAVE_INSIGHTS_CURRENT_TAB";
export const GET_BOT_OPS_SUMMARY_REQUEST = "GET_BOT_OPS_SUMMARY_REQUEST";
export const GET_BOT_OPS_SUMMARY_SUCCESS = "GET_BOT_OPS_SUMMARY_SUCCESS";
export const GET_BOT_OPS_SUMMARY_FAILURE = "GET_BOT_OPS_SUMMARY_FAILURE";
export const GET_BOT_SUMMARY_REQUEST = "GET_BOT_SUMMARY_REQUEST";
export const GET_BOT_RESOURCE_SUMMARY_REQUEST =
  "GET_BOT_RESOURCE_SUMMARY_REQUEST";
export const GET_BOT_STATUS_SUMMARY_REQUEST = "GET_BOT_STATUS_SUMMARY_REQUEST";
export const GET_BOT_OPEN_ISSUES_REQUEST = "GET_BOT_OPEN_ISSUES_REQUEST";
export const GET_BOT_INDUSTRY_STANDARDS_REQUEST =
  "GET_BOT_INDUSTRY_STANDARDS_REQUEST";
export const GET_BOT_SEVERITY_SUMMARY_REQUEST =
  "GET_BOT_SEVERITY_SUMMARY_REQUEST";
export const GET_BOT_TOP_FIVE_CHECKS_REQUEST =
  "GET_BOT_TOP_FIVE_CHECKS_REQUEST";
export const GET_BOT_TOP_FIVE_RESOURCES_REQUEST =
  "GET_BOT_TOP_FIVE_RESOURCES_REQUEST";

export const CLEAR_BOT_SUMMARY_DATA = "CLEAR_BOT_SUMMARY_DATA";

export const botSummaryTypeActionTypeMap = {
  ResourceSummary: GET_BOT_RESOURCE_SUMMARY_REQUEST,
  StatusSummary: GET_BOT_STATUS_SUMMARY_REQUEST,
  SeveritySummary: GET_BOT_SEVERITY_SUMMARY_REQUEST,
  OpenIssueSummary: GET_BOT_OPEN_ISSUES_REQUEST,
  TopFiveCheckSummary: GET_BOT_TOP_FIVE_CHECKS_REQUEST,
  TopFiveResourceSummary: GET_BOT_TOP_FIVE_RESOURCES_REQUEST,
  IndustryStandards: GET_BOT_INDUSTRY_STANDARDS_REQUEST,
};

export const CLEAR_INSIGHTS_LIST_DATA = "CLEAR_INSIGHTS_LIST_DATA";
export const CLEAR_CHART_DATA = "CLEAR_CHART_DATA";
export const GET_BOT_SECURITY_OPS_SUMMARY_SUCCESS =
  "GET_BOT_SECURITY_OPS_SUMMARY_SUCCESS";
export const GET_BOT_COMPLIANCE_OPS_SUMMARY_SUCCESS =
  "GET_BOT_COMPLIANCE_OPS_SUMMARY_SUCCESS";
export const SECURITY_BOT_NOT_CONFIGURED = "SECURITY_BOT_NOT_CONFIGURED";
export const COMPLIANCE_BOT_NOT_CONFIGURED = "COMPLIANCE_BOT_NOT_CONFIGURED";
export const CLEAR_INSIGHT_JOB_DATA = "CLEAR_INSIGHT_JOB_DATA";
export const UPDATE_SECURITY_BOT_SUMMARY_LAST_UPDATED =
  "UPDATE_SECURITY_BOT_SUMMARY_LAST_UPDATED";
export const UPDATE_COMPLIANCE_BOT_SUMMARY_LAST_UPDATED =
  "UPDATE_COMPLIANCE_BOT_SUMMARY_LAST_UPDATED";

export const DEPARTMENT_EDIT_MESSAGE = "Department changes saved";
export const ORGANIZATION_EDIT_MESSAGE = "Tenant changes saved";
export const DEPARTMENT_ADDED_MESSAGE = "Project added successfully";

export const ORG_TYPES = {
  ROOT: "ROOT",
  ORGANIZATION_UNIT: "ORGANIZATION_UNIT",
  ACCOUNT: "ACCOUNT",
  MEMBER: "MEMBER",
};

export const ACCOUNT_STATUS = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
};

export const ONBOARD_STATUS = {
  CONNECTED: "CONNECTED",
  INITIATED: "INITIATED",
  NOT_CONNECTED: "Not Connected",
};

export const VIEW_TYPE = {
  list: "list",
  grid: "grid",
};

export const DISCOVER_RESOURCES_TABS = {
  ORGHIERARCHY: "orgHierarchy",
  MAP: "map",
  REGIONS: "regions",
  RESOURCES: "resources",
  CONFIGURATION: "configuration",
  TAGS: "tags",
};

export const ORG_MEMBER_ACCOUNT_ACTIONS = {
  ONBOARD: "onboard",
  OFFBOARD: "offboard",
  MOVE: "move",
};

export const startSessionData = {
  TokenValue: "TokenValue",
  StreamUrl: "StreamUrl",
  SessionId: "SessionId"
};

export const terminateSessionData = {
  JobId: "Done"
};

export const consoleData = "c2gtNC4yJA==";

export const remoteSessionsList = [
  {
    SessionId: "assumed_role-0befa852af3d696d5",
    Target: "i-094373fd4c7cab34c",
    Status: "Terminated",
    StartDate: "2020-03-11 12:51:26.745000+00:00",
    EndDate: "2020-03-11 12:54:23.563000+00:00",
    DocumentName: "MC-AWS-ConfigureSessionManagerPreferences",
    Owner:
      "arn:aws:sts::717930997628:assumed-role/MontyCloud-ApplicationRole/assumed_role",
    OutputUrl: {
      S3OutputUrl:
        "https://us-east-1.console.aws.amazon.com/s3/object/montycloud-inventorybucketdatasync-717930997628/AWS:SessionLogs/assumed_role-0befa852af3d696d5.log"
    }
  },
  {
    SessionId: "assumed_role-061ad9cdf10b572bf",
    Target: "i-094373fd4c7cab34c",
    Status: "Terminated",
    StartDate: "2020-03-11 12:51:05.682000+00:00",
    EndDate: "2020-03-11 13:11:16.819000+00:00",
    DocumentName: "MC-AWS-ConfigureSessionManagerPreferences",
    Owner:
      "arn:aws:sts::717930997628:assumed-role/MontyCloud-ApplicationRole/assumed_role",
    OutputUrl: {
      S3OutputUrl:
        "https://us-east-1.console.aws.amazon.com/s3/object/montycloud-inventorybucketdatasync-717930997628/AWS:SessionLogs/assumed_role-061ad9cdf10b572bf.log"
    }
  },
  {
    SessionId: "assumed_role-011f919cb34577a54",
    Target: "i-094373fd4c7cab34c",
    Status: "Terminated",
    StartDate: "2020-03-11 12:37:31.134000+00:00",
    EndDate: "2020-03-11 12:57:35.284000+00:00",
    DocumentName: "MC-AWS-ConfigureSessionManagerPreferences",
    Owner:
      "arn:aws:sts::717930997628:assumed-role/MontyCloud-ApplicationRole/assumed_role",
    OutputUrl: {
      S3OutputUrl:
        "https://us-east-1.console.aws.amazon.com/s3/object/montycloud-inventorybucketdatasync-717930997628/AWS:SessionLogs/assumed_role-011f919cb34577a54.log"
    }
  },
  {
    SessionId: "assumed_role-05f4b2049d8ea7585",
    Target: "i-094373fd4c7cab34c",
    Status: "Terminated",
    StartDate: "2020-03-11 12:36:58.972000+00:00",
    EndDate: "2020-03-11 12:57:16.978000+00:00",
    DocumentName: "MC-AWS-ConfigureSessionManagerPreferences",
    Owner:
      "arn:aws:sts::717930997628:assumed-role/MontyCloud-ApplicationRole/assumed_role",
    OutputUrl: {
      S3OutputUrl:
        "https://us-east-1.console.aws.amazon.com/s3/object/montycloud-inventorybucketdatasync-717930997628/AWS:SessionLogs/assumed_role-05f4b2049d8ea7585.log"
    }
  },
  {
    SessionId: "assumed_role-0d2c3884b4bcdc587",
    Target: "i-094373fd4c7cab34c",
    Status: "Terminated",
    StartDate: "2020-03-11 12:36:26.362000+00:00",
    EndDate: "2020-03-11 12:56:44.534000+00:00",
    DocumentName: "MC-AWS-ConfigureSessionManagerPreferences",
    Owner:
      "arn:aws:sts::717930997628:assumed-role/MontyCloud-ApplicationRole/assumed_role",
    OutputUrl: {
      S3OutputUrl:
        "https://us-east-1.console.aws.amazon.com/s3/object/montycloud-inventorybucketdatasync-717930997628/AWS:SessionLogs/assumed_role-0d2c3884b4bcdc587.log"
    }
  }
];

import classnames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

import styles from "./Button.module.scss";
import { getClassNames } from "./utils";

const MCButton = ({
  text,
  disabled = false,
  color,
  size = "sm",
  onClick,
  icon,
  className,
  dataTour,
  isLink,
  linkTo,
  linkClassName,
  id,
  isLocked = false,
  leftIcon,
  rightIcon,
  title,
  variant = "",
  image,
  imgClass,
  onMouseDown = () => {},
  style = {},
}) => {
  const buttonClassname = classnames(
    styles.button,
    styles[size],
    styles[variant],
    className
  );
  const button = (
    <Button
      size={size}
      className={classnames(
        "mc-button",
        getClassNames(color),
        `mc-button-${size}`,
        buttonClassname
      )}
      onClick={onClick}
      id={id}
      disabled={disabled}
      color="none"
      data-tour={dataTour}
      title={title && title}
      onMouseDown={onMouseDown}
      style={style}
    >
      {isLocked ? (
        <i className={"mr-2 fa fa-lock font15 white-color"} />
      ) : (
        icon && <i className={classnames(icon, "mr-2")} />
      )}
      {image && (
        <img
          loading="lazy"
          src={image}
          alt="MontyCloud"
          className={classnames(imgClass)}
        />
      )}
      {leftIcon && leftIcon}
      {text}
      {rightIcon}
    </Button>
  );
  return isLink ? (
    <Link className={classnames("mc-link", linkClassName)} to={linkTo}>
      {button}
    </Link>
  ) : (
    button
  );
};

export default MCButton;

import {
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  CLEAR_DELETE_USER_DATA,
  GET_ALL_USERS_PORTAL_REQUEST,
  GET_ALL_USERS_PORTAL_SUCCESS,
  RESET_USERS_PORTAL,
  GET_PROJECT_USERS_PORTAL_REQUEST,
  GET_PROJECT_USERS_PORTAL_SUCCESS,
  GET_PROJECT_USERS_PORTAL_FAILURE,
  RESET_PROJECT_USERS_PORTAL,
  GET_ALL_SEARCH_USERS_SUCCESS,
  SET_USERS_LIST_MAP,
} from "../actionTypes/users";

const initialState = {
  isFetchingUsers: false,
  usersList: null,
  usersSearchList: null,
  fetchedUsers: false,
  isDeletingUser: false,
  deletedUser: false,
  isAccessDenied: false,
  fetchingUsersFailed: false,
  isFetchingPortalUsers: false,
  portalUsersList: null,
  isFetchingProjectPortalUsers: false,
  fetchedProjectPortalUsers: false,
  projectPortalUsersList: null,
  failedToLoadProjectPortalUsers: false,
  usersListMap: {},
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_USERS_REQUEST:
      return {
        ...state,
        isFetchingUsers: true,
        fetchedUsers: initialState.fetchedUsers,
        isAccessDenied: initialState.isAccessDenied,
        fetchingUsersFailed: initialState.fetchingUsersFailed,
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        isFetchingUsers: false,
        usersList: action.payload,
        fetchedUsers: true,
      };
    case GET_ALL_SEARCH_USERS_SUCCESS:
      return {
        ...state,
        isFetchingUsers: false,
        usersSearchList: action.payload,
        fetchedUsers: false,
      };

    case GET_ALL_USERS_FAILURE:
      return {
        ...state,
        isFetchingUsers: false,
        isAccessDenied: action.payload,
        fetchingUsersFailed: true,
      };
    case DELETE_USER_REQUEST:
      return {
        ...state,
        isDeletingUser: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isDeletingUser: false,
        deletedUser: true,
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        isDeletingUser: false,
        deletedUser: false,
      };
    case CLEAR_DELETE_USER_DATA:
      return {
        ...state,
        isDeletingUser: initialState.isDeletingUser,
        deletedUser: initialState.deletedUser,
      };
    case GET_ALL_USERS_PORTAL_REQUEST:
      return {
        ...state,
        isFetchingPortalUsers: true,
      };
    case GET_ALL_USERS_PORTAL_SUCCESS:
      return {
        ...state,
        isFetchingPortalUsers: false,
        portalUsersList: action.payload,
      };
    case RESET_USERS_PORTAL:
      return {
        ...state,
        portalUsersList: initialState.portalUsersList,
      };
    case GET_PROJECT_USERS_PORTAL_REQUEST:
      return {
        ...state,
        isFetchingProjectPortalUsers: true,
        projectPortalUsersList: initialState.projectPortalUsersList,
        fetchedProjectPortalUsers: initialState.fetchedProjectPortalUsers,
        failedToLoadProjectPortalUsers:
          initialState.failedToLoadProjectPortalUsers,
      };
    case GET_PROJECT_USERS_PORTAL_SUCCESS:
      return {
        ...state,
        isFetchingProjectPortalUsers: false,
        projectPortalUsersList: action.payload,
        fetchedProjectPortalUsers: true,
        failedToLoadProjectPortalUsers: false,
      };
    case GET_PROJECT_USERS_PORTAL_FAILURE:
      return {
        ...state,
        isFetchingProjectPortalUsers: false,
        projectPortalUsersList: null,
        fetchedProjectPortalUsers: false,
        failedToLoadProjectPortalUsers: true,
      };
    case RESET_PROJECT_USERS_PORTAL:
      return {
        ...state,
        isFetchingProjectPortalUsers: initialState.isFetchingProjectPortalUsers,
        projectPortalUsersList: initialState.projectPortalUsersList,
        fetchedProjectPortalUsers: initialState.fetchedProjectPortalUsers,
        failedToLoadProjectPortalUsers:
          initialState.failedToLoadProjectPortalUsers,
      };
    case SET_USERS_LIST_MAP:
      return {
        ...state,
        usersListMap: action.payload,
      };
    default:
      return state;
  }
};

export default users;

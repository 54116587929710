import {
  SET_FEATURES_LIST,
  START_PLAN_REQUEST,
  START_PLAN_SUCCESS,
  START_PLAN_FAILURE,
  SAVE_FEATURES_REQUEST,
  SAVE_FEATURES_SUCCESS,
  SAVE_FEATURES_FAILURE,
  SAVE_EMAILS_REQUEST,
  SAVE_EMAILS_SUCCESS,
  SAVE_EMAILS_FAILURE,
  CLEAR_START_PLAN,
  GET_CUSTOMER_PREFERENCE_REQUEST,
  GET_CUSTOMER_PREFERENCE_SUCCESS,
  GET_CUSTOMER_PREFERENCE_FAILURE,
} from "../actionTypes/new-first-run";
import {
  subscribeToPlan,
  saveCustomerPreference,
  getCustomerPreferenceApi,
  subscribeToTrialPlan,
} from "../services/new-first-run";
import { isArrayWithLength } from "../../../utils/array-methods";
import { showErrorNotification } from "../../../utils/error-notification";

import { FEATURES, TRIAL } from "../../../utils/app-constants.json";
import { upgradePlan } from "../../../actions/org-manager";
import { getLoggedInUserDetails } from "../../authentication/actions/auth";

export const setFeaturesList = (featureList) => ({
  type: SET_FEATURES_LIST,
  payload: featureList,
});

export const startPlan = (selectedFeature) => async (dispatch, getState) => {
  const {
    newFirstRun: { customerPreferences },
  } = getState();

  const preferenceAlreadySaved = isArrayWithLength(customerPreferences);

  dispatch({
    type: START_PLAN_REQUEST,
  });

  const payload = {
    PreferenceType: FEATURES,
    Preference: selectedFeature,
  };

  dispatch({
    type: SAVE_FEATURES_REQUEST,
  });

  if (!preferenceAlreadySaved) {
    await saveCustomerPreference(payload)
      .then((preference) => {
        let isPreference =
          preference && preference.data && preference.data.Preference;

        dispatch({
          type: SAVE_FEATURES_SUCCESS,
          payload: isPreference ? preference.data.Preference : [],
        });

        dispatch(setUpSubscribtionPlan());

        return isPreference;
      })
      .catch((error) => {
        dispatch({
          type: SAVE_FEATURES_FAILURE,
        });
        dispatch({
          type: START_PLAN_FAILURE,
        });
        showErrorNotification(dispatch, error);
        return false;
      });
  } else {
    dispatch({
      type: SAVE_FEATURES_SUCCESS,
      payload: customerPreferences,
    });

    dispatch(setUpSubscribtionPlan());
  }

  dispatch(getCustomerPreference());
};

export const setUpSubscribtionPlan = () => async (dispatch, getState) => {
  const {
    newFirstRun: { customerPreferences },
    auth: {
      isATrialUser,
      usersActiveOrganizationId,
      isFreemium,
      subscriptionDetails,
    },
  } = getState();

  // isFreemium > start trial > make subscription put call
  // not isATrialUser > make trial post call
  // isATrialUser > make upgrade call to customerPreferenceApi
  const isPreferencePresent = isArrayWithLength(customerPreferences);

  if (!isATrialUser && isPreferencePresent) {
    if (isFreemium) {
      /* put call to upgrade from freemium
      to Trial for old customers */

      const payload = {
        [TRIAL]: true,
      };

      let id = subscriptionDetails ? subscriptionDetails.SubscriptionId : null;

      if (id) {
        await subscribeToTrialPlan(id, payload)
          .then((res) => {
            dispatch({
              type: START_PLAN_SUCCESS,
            });
          })
          .catch((error) => {
            dispatch({
              type: START_PLAN_FAILURE,
            });

            /* to handle if subscription already passed or failed 
            but state not updated */

            dispatch(getLoggedInUserDetails());

            showErrorNotification(dispatch, error);
          });
      } else {
        dispatch({
          type: START_PLAN_FAILURE,
        });
      }
    } else {
      const payload = {
        Mode: TRIAL,
      };

      // post call to insert preference entry
      await subscribeToPlan(payload)
        .then((res) => {
          dispatch({
            type: START_PLAN_SUCCESS,
          });
        })
        .catch((error) => {
          dispatch({
            type: START_PLAN_FAILURE,
          });
          showErrorNotification(dispatch, error);
        });
    }
  }

  if (isATrialUser && isPreferencePresent) {
    dispatch({
      type: START_PLAN_REQUEST,
    });

    await upgradePlan(usersActiveOrganizationId, dispatch)
      .then((res) => {
        dispatch({
          type: START_PLAN_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: START_PLAN_FAILURE,
        });
        showErrorNotification(dispatch, error);
      });
  }
};

export const subscribeToSummaryEmails = (emails) => async (dispatch, state) => {
  const featurePreferencePayload = {
    PreferenceType: "EmailSubscriptions",
    Preference: {
      EmailToRootUser: true,
      AdditionalEmails: emails,
    },
  };
  dispatch({
    type: SAVE_EMAILS_REQUEST,
  });
  try {
    await saveCustomerPreference(featurePreferencePayload);
    dispatch({
      type: SAVE_EMAILS_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: SAVE_EMAILS_FAILURE,
    });
    showErrorNotification(dispatch, error);
  }
};

export const clearStartPlan = () => ({
  type: CLEAR_START_PLAN,
});

export const getCustomerPreference = () => async (dispatch) => {
  dispatch({
    type: GET_CUSTOMER_PREFERENCE_REQUEST,
  });
  try {
    const { data } = await getCustomerPreferenceApi();
    let payload = data && data.Features;
    dispatch({
      type: GET_CUSTOMER_PREFERENCE_SUCCESS,
      payload: payload || [],
    });
  } catch (error) {
    dispatch({
      type: GET_CUSTOMER_PREFERENCE_FAILURE,
    });
    showErrorNotification(dispatch, error);
  }
};

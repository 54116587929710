export const sortArrayAlphabetically = (array, key) => {
  return (
    array &&
    Array.isArray(array) &&
    array.sort((a, b) => a[key].localeCompare(b[key]))
  );
};

export const sortArrayByKey = (arr, key) => {
  return arr.sort((a, b) => {
    if (!a[key]) {
      return -1;
    }
    if (!b[key]) {
      return 1;
    }
    return 0;
  });
};

export const isArrayWithLength = (arrayData) => {
  return arrayData && Array.isArray(arrayData) && arrayData.length > 0;
};

export const isSetWithSize = (setDate) => {
  return setDate && setDate instanceof Set && setDate.size > 0;
};

export const getUniqueItems = (array, props = []) => {
  return [
    ...new Map(
      array.map((entry) => [props.map((k) => entry[k]).join("|"), entry])
    ).values(),
  ];
};

export const getArrayValues = (arryObj) => {
  let _arr = [];
  if (Array.isArray(arryObj) && arryObj.length > 0) {
    _arr = arryObj.map((arr) =>
      arr.value ? arr.value : arr.label ? arr.label : arr
    );
  }
  return _arr;
};

export const searchArrayValues = (searchKey, searchKeys, array) => {
  return isArrayWithLength(array)
    ? array.filter((item) => {
      return searchKeys.some((key) => {
        return (
          item[key] &&
          item[key]
            .toLowerCase()
            .includes(searchKey && searchKey.toLowerCase())
        );
      });
    })
    : [];
};

export const reduceFieldsArrayToParams = (fields) => {
  return (
    isArrayWithLength(fields) &&
    fields
      .filter((field) => !field.viewOnly)
      .reduce((obj, item) => {
        const value = typeof item.value === 'string' ? item.value.trim() : item.value;
        return {
          ...obj,
          [item["apiKey"]]: value,
        };
      }, {})
  );
};

export const mapFieldsArrayWithValues = (fields, values) => {
  return isArrayWithLength(fields) && isArrayWithLength(fields)
    ? fields.map((field) => {
      return {
        ...field,
        value: values?.[field.key] ?? field.value,
      };
    })
    : [];
};

export const groupListByKey = (list, key) => {
  let groupedData = {};
  if (isArrayWithLength(list) && key) {
    list.forEach((item) => {
      const itemKey = item[key];
      if (groupedData[itemKey]) {
        groupedData[itemKey].push(item);
      } else {
        groupedData[itemKey] = [item];
      }
    });
  }
  return groupedData;
};

import {
  GET_TASKS_REQUEST,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAILURE,
  GET_JOBS_REQUEST,
  GET_JOBS_SUCCESS,
  GET_JOBS_FAILURE,
  GET_APP_JOBS_REQUEST,
  GET_APP_JOBS_SUCCESS,
  GET_APP_JOBS_FAILURE,
  CLEAR_TASKS,
  CLEAR_APP_JOBS,
} from "../actionTypes/tasks";

const initialState = {
  isFetching: false,
  taskList: null,
  isFetchingJobs: false,
  isFetchingAppJobs: false,
  executionHistoryList: null,
  appExecutionHistoryList: null,
  taskOffset: null,
  jobTaskOffset: null,
  appJobTaskOffset: null,
  failedToFetchJobs: false,
  failedToFetchAppJobs: false,
};

const tasks = (state = initialState, action) => {
  switch (action.type) {
    case GET_TASKS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_TASKS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        taskList: action.payload.tasks,
        taskOffset: action.payload.offset,
      };
    case GET_TASKS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case GET_JOBS_REQUEST:
      return {
        ...state,
        isFetchingJobs: true,
        failedToFetchJobs: initialState.failedToFetchJobs,
      };
    case GET_JOBS_SUCCESS:
      return {
        ...state,
        isFetchingJobs: false,
        executionHistoryList: action.payload.tasks,
        jobTaskOffset: action.payload.offset,
      };
    case GET_JOBS_FAILURE:
      return {
        ...state,
        isFetchingJobs: false,
        failedToFetchJobs: true,
      };
    case GET_APP_JOBS_REQUEST:
      return {
        ...state,
        isFetchingAppJobs: true,
        failedToFetchAppJobs: initialState.failedToFetchAppJobs,
      };
    case GET_APP_JOBS_SUCCESS:
      return {
        ...state,
        isFetchingAppJobs: false,
        appExecutionHistoryList: action.payload.tasks,
        appJobTaskOffset: action.payload.offset,
      };
    case GET_APP_JOBS_FAILURE:
      return {
        ...state,
        isFetchingAppJobs: false,
        failedToFetchAppJobs: true,
      };
    case CLEAR_TASKS:
      return {
        ...state,
        ...initialState,
      };
    case CLEAR_APP_JOBS:
      return {
        ...state,
        isFetchingAppJobs: initialState.isFetchingAppJobs,
        appExecutionHistoryList: initialState.appExecutionHistoryList,
        appJobTaskOffset: initialState.appJobTaskOffset,
        failedToFetchAppJobs: initialState.failedToFetchAppJobs,
      };
    default:
      return {
        ...state,
      };
  }
};

export default tasks;

import {
  APPLICATION_BASE_URL,
  CUSTOMER_PREFERENCE,
  DEPARTMENT_BASE_URL,
  ENVIRONMENT_BASE_URL,
  ORGANIZATIONS_BASE_URL,
  ORGANIZATION_BASE_URL,
  ORG_MGR_ASSIGN_ORG,
  ORG_MGR_AUTH_USER_BASE,
  ORG_MGR_AVALILABEL_LIST,
  ORG_MGR_LIST_URL,
  ORG_MGR_SWITCH_CONTEXT,
  ORG_MGR_UNASSIGN_ORG,
  RESOURCES_BASE_URL,
} from "../../../config/service/index";
import { getApiService } from "../../../helpers/apiService";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiServiceHelper";

export const getAllOrganizationsApi = () => {
  return getApiService(WEB_REQUEST_TYPE.GET, ORGANIZATIONS_BASE_URL)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const getAllSettingsOrganizationsApi = () => {
  return getApiService(WEB_REQUEST_TYPE.GET, ORG_MGR_AVALILABEL_LIST)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const createOrganizationApi = (payload) => {
  return getApiService(WEB_REQUEST_TYPE.POST, ORGANIZATION_BASE_URL, payload)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const fetchOrganizationAccessListApi = (orgId) => {
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    `${ORG_MGR_AUTH_USER_BASE}?OrganizationId=${orgId}`
  );
};

export const revokeOrganizationAccessApi = ({ userId, orgId }) => {
  return getApiService(
    WEB_REQUEST_TYPE.DELETE,
    getRevokeAccessUrl({ userId, orgId })
  );
};

export const getAddUserToOrganizationUrl = ({ userId, orgId }) => {
  return `${ORG_MGR_AUTH_USER_BASE}/${userId}/${ORG_MGR_ASSIGN_ORG}/${orgId}`;
};

const getRevokeAccessUrl = ({ userId, orgId }) => {
  return `${ORG_MGR_AUTH_USER_BASE}/${userId}/${ORG_MGR_UNASSIGN_ORG}/${orgId}`;
};

export const addUsersToNewOrganizationApi = ({ userId, orgId }) => {
  return getApiService(
    WEB_REQUEST_TYPE.POST,
    getAddUserToOrganizationUrl({ userId, orgId })
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const switchOrganizationContextApi = (organisationID) => {
  return getApiService(
    WEB_REQUEST_TYPE.POST,
    `${ORG_MGR_SWITCH_CONTEXT}${organisationID}`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const getAllContextOrganizationsApi = () => {
  return getApiService(WEB_REQUEST_TYPE.GET, ORG_MGR_LIST_URL)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const updateOrganizationDetailsApi = ({ orgId, params }) => {
  return getApiService(
    WEB_REQUEST_TYPE.PUT,
    `${ORGANIZATION_BASE_URL}${orgId}`,
    params
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const customerPreferenceApi = ({ orgId, params }) => {
  return getApiService(
    WEB_REQUEST_TYPE.PUT,
    `${CUSTOMER_PREFERENCE}/${orgId}`,
    params
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const getOrganizationDetailsApi = (orgId) => {
  return getApiService(WEB_REQUEST_TYPE.GET, `${ORGANIZATION_BASE_URL}${orgId}`)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const getOrganizationResourcesApi = (orgId) => {
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    ORGANIZATION_BASE_URL +
      orgId +
      DEPARTMENT_BASE_URL +
      "all" +
      APPLICATION_BASE_URL +
      "all" +
      ENVIRONMENT_BASE_URL +
      "all" +
      RESOURCES_BASE_URL
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const saveOrganizationApi = (param) => {
  return getApiService(WEB_REQUEST_TYPE.POST, ORGANIZATION_BASE_URL, param)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const editOrganizationApi = (param, orgId) => {
  return getApiService(
    WEB_REQUEST_TYPE.PUT,
    `${ORGANIZATION_BASE_URL}${orgId}`,
    param
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const deleteOrganizationApi = (orgId) => {
  return getApiService(
    WEB_REQUEST_TYPE.DELETE,
    `${ORGANIZATION_BASE_URL}${orgId}`
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

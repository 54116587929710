import {
  GET_PATCH_SCHEDULED_TASKS_REQUEST,
  GET_PATCH_SCHEDULED_TASKS_SUCCESS,
  GET_PATCH_SCHEDULED_TASKS_FAILURE,
  GET_PATCH_JOBS_REQUEST,
  GET_PATCH_JOBS_SUCCESS,
  GET_PATCH_JOBS_FAILURE,
  DELETE_SCHEDULE_REQUEST,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAILURE,
  CLEAR_DELETE_SCHEDULE_DATA,
} from "../actionTypes/patchDeployment";

const initialState = {
  isFetchingPatchScheduledTasks: false,
  patchScheduleTaskList: null,
  isFetchingPatchJobs: false,
  patchExecutionHistoryList: null,
  deletingSchedule: false,
  deletedSchedule: false,
  deleteScheduleMessage: null,
  patchScheduleTaskOffset: null,
  patchExecutionHistoryOffset: null,
};

const patchDeployment = (state = initialState, action) => {
  switch (action.type) {
    case GET_PATCH_SCHEDULED_TASKS_REQUEST:
      return {
        ...state,
        isFetchingPatchScheduledTasks: true,
      };
    case GET_PATCH_SCHEDULED_TASKS_SUCCESS:
      return {
        ...state,
        isFetchingPatchScheduledTasks: false,
        patchScheduleTaskList: action.payload.tasks,
        patchScheduleTaskOffset: action.payload.offset,
      };
    case GET_PATCH_SCHEDULED_TASKS_FAILURE:
      return {
        ...state,
        isFetchingPatchScheduledTasks: false,
        patchScheduleTaskList: action.payload,
      };
    case GET_PATCH_JOBS_REQUEST:
      return {
        ...state,
        isFetchingPatchJobs: true,
      };
    case GET_PATCH_JOBS_SUCCESS:
      return {
        ...state,
        isFetchingPatchJobs: false,
        patchExecutionHistoryList: action.payload.tasks,
        patchExecutionHistoryOffset: action.payload.offset,
      };
    case GET_PATCH_JOBS_FAILURE:
      return {
        ...state,
        isFetchingPatchJobs: false,
        patchExecutionHistoryList: action.payload,
      };
    case DELETE_SCHEDULE_REQUEST:
      return {
        deletingSchedule: true,
        deleteScheduleMessage: action.payload,
      };
    case DELETE_SCHEDULE_SUCCESS:
      return {
        deletingSchedule: false,
        deletedSchedule: true,
        deleteScheduleMessage: action.payload,
      };
    case DELETE_SCHEDULE_FAILURE:
      return {
        deletingSchedule: false,
        deletedSchedule: false,
        deleteScheduleMessage: action.payload,
      };
    case CLEAR_DELETE_SCHEDULE_DATA:
      return {
        ...state,
        deletingSchedule: initialState.deletedSchedule,
        deletedSchedule: initialState.deletedSchedule,
        deleteScheduleMessage: initialState.deleteScheduleMessage,
      };
    default:
      return {
        ...state,
      };
  }
};

export default patchDeployment;

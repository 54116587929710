import appInsights from "../images/resources/Azure/Application_Insights.svg";
import appServices from "../images/resources/Azure/App_Services.svg";
import automations from "../images/resources/Azure/Automation-Accounts.svg";
import cosmoDB from "../images/resources/Azure/Cosmos-DB.svg";
import diskSnapshots from "../images/resources/Azure/Disks-Snapshots.svg";
import disks from "../images/resources/Azure/Disks.svg";
import eventGrid from "../images/resources/Azure/Event-Grid.svg";
import fnApps from "../images/resources/Azure/Function-Apps.svg";
import azImages from "../images/resources/Azure/Images.svg";
import keyVaults from "../images/resources/Azure/Key-Vaults.svg";
import recoveryServiceVault from "../images/resources/Azure/Recovery-Services-Vaults.svg";
import serviceBus from "../images/resources/Azure/Service-Bus.svg";
import sqlServer from "../images/resources/Azure/SQL-Server.svg";
import storageAccounts from "../images/resources/Azure/Storage-Accounts.svg";
import virtualMachine from "../images/resources/Azure/Virtual-Machine.svg";
import virtualNetworkGateway from "../images/resources/Azure/Virtual-Network-Gateways.svg";
import networkSecurityGroups from "../images/resources/Azure/Network-Security-Groups.svg";
import nat from "../images/resources/Azure/NAT.svg";
import extensions from "../images/resources/Azure/Extensions.svg";
import virtualNetworks from "../images/resources/Azure/Virtual-Networks.svg";
import logicApps from "../images/resources/Azure/Logic-Apps.svg";
import azureLogo from "../images/azure-icon.svg";
import { getMatchKey } from "./object-methods";

export const azureResourceImages = {
  AzureAppInsights: appInsights,
  AzureAppServices: appServices,
  AzureAutomations: automations,
  AzureCosmoDB: cosmoDB,
  AzureDisksSnapshots: diskSnapshots,
  AzureEventGrid: eventGrid,
  AzureFunctions: fnApps,
  AzureImages: azImages,
  AzureKeyVaults: keyVaults,
  AzureRecoveryServiceVault: recoveryServiceVault,
  AzureServiceBus: serviceBus,
  AzureSqlServer: sqlServer,
  Storage: storageAccounts,
  VirtualMachines: virtualMachine,
  AzureVirtualNetworkGateway: virtualNetworkGateway,

  "microsoft.network/virtualnetworks": virtualNetworks,

  // Virtual Network - NAT,NSG
  "microsoft.network/networknatrules": nat,
  "microsoft.network/networksecuritygroups": networkSecurityGroups,

  //storageaccounts
  "microsoft.storage/storageaccounts": storageAccounts,

  //Virtual Machines
  "microsoft.compute/virtualmachines": virtualMachine,
  "microsoft.compute/disks": disks,
  "microsoft.compute/virtualmachines/extensions": extensions,

  //Azure Functions
  "microsoft.web/sites/functions": fnApps,

  //Azure Automation
  "microsoft.automation": automations,

  // Azure SQL Server
  "microsoft.sql/servers": sqlServer,

  //keyVaults
  "microsoft.keyvault/vaults": keyVaults,

  // Service Bus
  "microsoft.servicebus/namespaces": serviceBus,

  //cosmos DB
  "microsoft.documentdb": cosmoDB,

  //Insights
  "microsoft.insights": appInsights,

  // Event Grids
  "microsoft.eventgrid": eventGrid,

  // SnapShots
  "microsoft.compute/snapshots": diskSnapshots,

  // Recovery Sevice vaults
  "microsoft.recoveryservices/vaults": recoveryServiceVault,

  //Azure Web Apps
  "microsoft.web/sites": appServices,

  //Images
  "microsoft.compute/images": azImages,

  //Logic Apps
  "microsoft.logic": logicApps,
};

export const AZURE_RESOURCE_IMAGES_KEYS = Object.keys(azureResourceImages);

export const getAzureResourceImage = (resourceType) => {
  let foundKey = getMatchKey(AZURE_RESOURCE_IMAGES_KEYS, resourceType);
  const image = foundKey ? azureResourceImages[foundKey] : azureLogo;
  return image;
};

export const GET_VOLUMES_REQUEST = "GET_VOLUMES_REQUEST";
export const GET_VOLUMES_SUCCESS = "GET_VOLUMES_SUCCESS";
export const GET_VOLUMES_FAILURE = "GET_VOLUMES_FAILURE";
export const CREATE_BACKUP_REQUEST = "CREATE_BACKUP_REQUEST";
export const CREATE_BACKUP_SUCCESS = "CREATE_BACKUP_SUCCESS";
export const CREATE_BACKUP_FAILURE = "CREATE_BACKUP_FAILURE";
export const CLEAR_CREATE_VOLUME_SNAPSHOT_DATA =
  "CLEAR_CREATE_VOLUME_SNAPSHOT_DATA";
export const RESET_VOLUMES_IS_FETCHED = " RESET_VOLUMES_IS_FETCHED";
export const RESET_VOLUME_LIST = " RESET_VOLUME_LIST";

import {
  ORGANIZATION_BASE_URL,
  DEPARTMENT_BASE_URL,
  ENVIRONMENT_BASE_URL,
  APPLICATION_BASE_URL,
  RESOURCES_BASE_URL,
  RESOURCES_LIST_URL,
  RESOURCE_EXIST_URL,
  ORGANIZATION_SUMMARY_URL,
  ORG_RESOURCE_TAGS_URL,
  ORG_INVENTORY_SUMMARY,
  ORG_GOVERNANCE_SUMMARY,
  RESOURCES_LIST_PAGINATED_URL,
} from "../../../config/service/index";
import { getApiService } from "../../../helpers/apiService";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiServiceHelper";

export const getResourceDetailsApi = async (resourceId) => {
  try {
    let response = await getApiService(
      WEB_REQUEST_TYPE.GET,
      ORGANIZATION_BASE_URL +
      "all" +
      DEPARTMENT_BASE_URL +
      "all" +
      APPLICATION_BASE_URL +
      "all" +
      ENVIRONMENT_BASE_URL +
      "all" +
      RESOURCES_BASE_URL +
      "?ResourceId=" +
      resourceId
    );
    const { data } = response;
    return data;
  } catch (error) {
    throw Error(error);
  }
};

export const getAllResourcesApi = async (params) => {
  try {
    let response = await getApiService(
      WEB_REQUEST_TYPE.GET,
      RESOURCES_LIST_URL + "?" + params
    );
    const { data } = response;
    return data;
  } catch (error) {
    throw Error(error);
  }
};

export const getAllResourcesPaginatedApi = async (params) => {
  try {
    let response = await getApiService(
      WEB_REQUEST_TYPE.GET,
      RESOURCES_LIST_PAGINATED_URL + "?" + params
    );
    const { data } = response;
    return data;
  } catch (error) {
    throw Error(error);
  }
};

export const getResourcesSummaryApi = async (param) => {
  try {
    let response = await getApiService(
      WEB_REQUEST_TYPE.GET,
      ORGANIZATION_SUMMARY_URL + "?" + param
    );
    const { data } = response;
    return data;
  } catch (error) {
    throw Error(error);
  }
};

export const getInventorySummaryApi = (param) => {
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    `${ORG_INVENTORY_SUMMARY}?${param}`
  );
};

export const getGovernanceSummaryApi = (param) => {
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    `${ORG_GOVERNANCE_SUMMARY}?${param}`
  );
};

export const doesResourceExistApi = async (params) => {
  try {
    let response = await getApiService(
      WEB_REQUEST_TYPE.GET,
      RESOURCE_EXIST_URL + params
    );
    const { data } = response;
    return data;
  } catch (error) {
    throw Error(error);
  }
};

export const updateResourceTagsApi = async (params) => {
  try {
    let response = await getApiService(
      WEB_REQUEST_TYPE.POST,
      ORG_RESOURCE_TAGS_URL,
      params
    );
    const { data } = response;
    return data;
  } catch (error) {
    throw Error(error);
  }
};

import {
  DEMO_USER_SIGN_IN,
  USER_BASE_URL,
  USER_CONFIRM_FORGOTPASSWORD_URL,
  USER_FORGOTPASSWORD_URL,
  USER_LOGOUT,
  USER_PRE_SIGN_IN,
  USER_REFRESH_TOKEN_URL,
  USER_RESET_PASSWORD_URL,
  USER_SIGN_IN,
  USER_SSO_SIGN_IN,
} from "../../../config/service";
import { getApiService } from "../../../helpers/apiService";
import { arrayStringify } from "../../../helpers/query-params";

import config from "../../../config";
import { WEB_REQUEST_TYPE } from "../../../helpers/apiServiceHelper";

export const preSignInApi = (params) => {
  return getApiService(WEB_REQUEST_TYPE.POST, USER_PRE_SIGN_IN, params)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const signInApi = (params) => {
  return getApiService(
    WEB_REQUEST_TYPE.POST,
    config.demoApp ? DEMO_USER_SIGN_IN : USER_SIGN_IN,
    params
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const ssoSignInApi = (params) => {
  return getApiService(
    WEB_REQUEST_TYPE.GET,
    `${USER_SSO_SIGN_IN}?${arrayStringify(params)}`
  )
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const sendForgotPasswordLinkApi = (params) => {
  return getApiService(WEB_REQUEST_TYPE.POST, USER_FORGOTPASSWORD_URL, params)
    .then((response) => {
      const { data } = response;
      return data.Data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const changePasswordApi = (params) => {
  return getApiService(
    WEB_REQUEST_TYPE.POST,
    USER_CONFIRM_FORGOTPASSWORD_URL,
    params
  )
    .then((response) => {
      const { data } = response;
      return data.Data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const resetPasswordApi = (params) => {
  return getApiService(WEB_REQUEST_TYPE.POST, USER_RESET_PASSWORD_URL, params)
    .then((response) => {
      const { data } = response;
      return data.Data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const getRefreshTokenApi = (params) => {
  return getApiService(WEB_REQUEST_TYPE.POST, USER_REFRESH_TOKEN_URL, params)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const userLogoutApi = (params) => {
  return getApiService(WEB_REQUEST_TYPE.POST, USER_LOGOUT, params)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

export const getLoggedInUserDetailsApi = () => {
  return getApiService(WEB_REQUEST_TYPE.GET, USER_BASE_URL, null, null, true)
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      throw Error(error);
    });
};

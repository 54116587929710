export const HYBRID_INITIALS = "mi";
export const NAME_TAG = "Name";
export const UPDATE_STATE = "UPDATE_STATE";
export const INSTANCE_STATES = ["start", "stop", "restart", "terminate"];
export const INSTANCE_PAGE_ACTIONS = {
  start: "start",
  stop: "stop",
  restart: "restart",
  terminate: "terminate",
  connect: "connect",
  info: "info",
  showMoreDetails: "show-more-details",
  convertToManaged: "convertToManaged",
  snapshotModal: "snapshotModal",
  editTagModal: "editTagModal",
  createImage: "createImage",
  resizeInstance: "resizeInstance",
  renameInstanceModal: "renameInstanceModal",
  scanPatch: "scanPatch",
  installPatch: "installPatch",
  deregisterHybridServer: "deregisterHybridServer",
  Scan: "Scan",
  Install: "Install",
  Convert: "Convert",
  running: "running",
  patched: "patched",
};

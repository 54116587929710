import Statsig from "statsig-js";

import AnalyticsService from "../analytics-service";

export default class StatsigService extends AnalyticsService {
    constructor(config) {
        super();
        this.statsigInitialized = false;
        this.config = config;
    }

    async initialize() {
        try {
            await Statsig.initialize(this.config.statsigKey, null, {
                environment: { tier: this.config.environment },
            });
            this.statsigInitialized = true;
        } catch (error) {
            console.error("Failed to initialize Statsig:", error);
        }
    }

    async identify(userId) {
        if (!this.statsigInitialized) {
            await this.initialize();
        }
        Statsig.updateUser({ userID: userId });
    }

    async track(eventName, properties) {
        if (!this.statsigInitialized) {
            await this.initialize();
        }
        Statsig.logEvent(eventName, eventName, properties);
    }
}

export const GET_LAUNCH_CONFIG_REQUEST = "GET_LAUNCH_CONFIG_REQUEST";
export const GET_LAUNCH_CONFIG_SUCCESS = "GET_LAUNCH_CONFIG_SUCCESS";
export const GET_LAUNCH_CONFIG_FAILURE = "GET_LAUNCH_CONFIG_FAILURE";
export const SAVE_LAUNCH_CONFIG_NAME = "SAVE_LAUNCH_CONFIG_NAME";
export const SAVE_LAUNCH_CONFIG_DESCRIPTION = "SAVE_LAUNCH_CONFIG_DESCRIPTION";
export const CREATE_LAUNCH_CONFIG_REQUEST = "CREATE_LAUNCH_CONFIG_REQUEST";
export const CREATE_LAUNCH_CONFIG_SUCCESS = "CREATE_LAUNCH_CONFIG_SUCCESS";
export const CREATE_LAUNCH_CONFIG_FAILURE = "CREATE_LAUNCH_CONFIG_FAILURE";
export const SAVE_SELECTED_CONFIG_FOR_BP_DEPLOY =
  "SAVE_SELECTED_CONFIG_FOR_BP_DEPLOY";
export const SAVE_SELECTED_LAUNCH_CONFIG_PARAMETERS =
  "SAVE_SELECTED_LAUNCH_CONFIG_PARAMETERS";
export const RESET_SAVED_LAUNCH_CONFIG_CHANGES =
  "RESET_SAVED_LAUNCH_CONFIG_CHANGES";
export const RESET_LAUNCH_CONFIG_DATA = "RESET_LAUNCH_CONFIG_DATA";
export const SAVE_LAUNCH_CONFIG_CUSTOMIZE_OPTION =
  "SAVE_LAUNCH_CONFIG_CUSTOMIZE_OPTION";
export const SAVE_LAUNCH_CONFIG_DATA_FOR_MODIFICATION =
  "SAVE_LAUNCH_CONFIG_DATA_FOR_MODIFICATION";
export const UPDATE_LAUNCH_CONFIG_REQUEST = "UPDATE_LAUNCH_CONFIG_REQUEST";
export const UPDATE_LAUNCH_CONFIG_SUCCESS = "UPDATE_LAUNCH_CONFIG_SUCCESS";
export const UPDATE_LAUNCH_CONFIG_FAILURE = "UPDATE_LAUNCH_CONFIG_FAILURE";
export const ENABLE_LAUNCH_CONFIG_REQUEST = "ENABLE_LAUNCH_CONFIG_REQUEST";
export const ENABLE_LAUNCH_CONFIG_SUCCESS = "ENABLE_LAUNCH_CONFIG_SUCCESS";
export const ENABLE_LAUNCH_CONFIG_FAILURE = "ENABLE_LAUNCH_CONFIG_FAILURE";
export const DELETE_LAUNCH_CONFIG_REQUEST = "DELETE_LAUNCH_CONFIG_REQUEST";
export const DELETE_LAUNCH_CONFIG_SUCCESS = "DELETE_LAUNCH_CONFIG_SUCCESS";
export const DELETE_LAUNCH_CONFIG_FAILURE = "DELETE_LAUNCH_CONFIG_FAILURE";

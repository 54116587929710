export const COMPUTE_INSTANCE_REQUEST = "COMPUTE_INSTANCE_REQUEST";
export const COMPUTE_INSTANCE_SUCCESS = "COMPUTE_INSTANCE_SUCCESS";
export const COMPUTE_INSTANCE_FAILURE = "COMPUTE_INSTANCE_FAILURE";
export const CREATE_BACKUP_INSTANCE_REQUEST = "CREATE_BACKUP_INSTANCE_REQUEST";
export const CREATE_BACKUP_INSTANCE_SUCCESS = "CREATE_BACKUP_INSTANCE_SUCCESS";
export const CREATE_BACKUP_INSTANCE_FAILURE = "CREATE_BACKUP_INSTANCE_FAILURE";
export const GET_INSTANCE_DETAILS_REQUEST = "GET_INSTANCE_DETAILS_REQUEST";
export const GET_INSTANCE_DETAILS_SUCCESS = "GET_INSTANCE_DETAILS_SUCCESS";
export const GET_INSTANCE_DETAILS_FAILURE = "GET_INSTANCE_DETAILS_FAILURE";
export const CLEAR_INSTANCE_DETAILS = "CLEAR_INSTANCE_DETAILS"
export const CLEAR_COMPUTE_INSTANCE_STATE = "CLEAR_COMPUTE_INSTANCE_STATE";

export const GET_INSTANCE_SESSION_HISTORY_REQUEST =
  "GET_INSTANCE_SESSION_HISTORY_REQUEST";
export const GET_INSTANCE_SESSION_HISTORY_SUCCESS =
  "GET_INSTANCE_SESSION_HISTORY_SUCCESS";
export const GET_INSTANCE_SESSION_HISTORY_FAILURE =
  "GET_INSTANCE_SESSION_HISTORY_FAILURE";

export const GET_INSTANCE_SESSION_LOG_REQUEST =
  "GET_INSTANCE_SESSION_LOG_REQUEST";
export const GET_INSTANCE_SESSION_LOG_SUCCESS =
  "GET_INSTANCE_SESSION_LOG_SUCCESS";
export const GET_INSTANCE_SESSION_LOG_FAILURE =
  "GET_INSTANCE_SESSION_LOG_FAILURE";

export const INSTANCE_SESSION_START_REQUEST = "INSTANCE_SESSION_START_REQUEST";
export const INSTANCE_SESSION_START_SUCCESS = "INSTANCE_SESSION_START_SUCCESS";
export const INSTANCE_SESSION_START_FAILURE = "INSTANCE_SESSION_START_FAILURE";

export const INSTANCE_SESSION_RESUME_REQUEST =
  "INSTANCE_SESSION_RESUME_REQUEST";
export const INSTANCE_SESSION_RESUME_SUCCESS =
  "INSTANCE_SESSION_RESUME_SUCCESS";
export const INSTANCE_SESSION_RESUME_FAILURE =
  "INSTANCE_SESSION_RESUME_FAILURE";

export const INSTANCE_SESSION_TERMINATE_REQUEST =
  "INSTANCE_SESSION_TERMINATE_REQUEST";
export const INSTANCE_SESSION_TERMINATE_SUCCESS =
  "INSTANCE_SESSION_TERMINATE_SUCCESS";
export const INSTANCE_SESSION_TERMINATE_FAILURE =
  "INSTANCE_SESSION_TERMINATE_FAILURE";

export const SET_IMAGE_NAME = "SET_IMAGE_NAME";
export const SET_IMAGE_DESCRIPTION = "SET_IMAGE_DESCRIPTION";
export const SET_IMAGE_MANAGED_TAGS = "SET_IMAGE_MANAGED_TAGS";

export const GET_RDP_CONFIG_REQUEST = "GET_RDP_CONFIG_REQUEST";
export const GET_RDP_CONFIG_SUCCESS = "GET_RDP_CONFIG_SUCCESS";
export const GET_RDP_CONFIG_FAILURE = "GET_RDP_CONFIG_FAILURE";
export const CLEAR_RDP_CONFIG_DATA = "CLEAR_RDP_CONFIG_DATA";
export const GET_INSTANCE_TYPES = "GET_INSTANCE_TYPES";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import McAlert from "../../../../components/Alert";
import AnimationPlayer from "../../../../components/AnimationPlayer/AnimationPlayer";
import Async from "../../../../components/Async/Async";
import CopyText from "../../../../components/CopyText/CopyText";
import LoggingOutLoader from "../../../../components/Header/LoggingOutLoader";
import Label from "../../../../components/Label/Label";
import MCButton from "../../../../components/MCButton/MCButton";
import Heading from "../../../../components/UIComponents/Heading";
import { withRouter } from "@hocs/withRouter";
import {
  CUSTOMER_ROLE_TYPE,
  MANDATORY,
} from "../../../../utils/app-constants.json";
import {
  mapFieldsArrayWithValues,
  reduceFieldsArrayToParams,
} from "../../../../utils/array-methods";
import {
  MFA_SETUP_COMPLETE,
  MFA_SETUP_SKIPPED,
} from "../../../../utils/local-storage-keys.json";
import { getInitialPage } from "../../../../utils/menu-items";
import { logoutUser } from "../../../authentication/actions/auth";
import CommonFormRenderer from "../../../commons/components/common-form-renderer/CommonFormRenderer";
import {
  clearResetRegisterMFAData,
  initiateMFASetup,
  registerMFADevice,
} from "../../actions/mfa";
import s from "./SetupMFA.module.scss";

const accessType = localStorage.getItem(CUSTOMER_ROLE_TYPE);
const initialPage = getInitialPage(accessType);

const SetupMFA = ({ history }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["mfa", "common"]);

  const fields = [
    {
      type: "text",
      label: t("common:code"),
      placeholder: t("common:enterKey", {
        label: t("common:code").toLowerCase(),
      }),
      required: true,
      key: "mfa-code",
      apiKey: "MFACode",
      id: "mfa-code",
      autoComplete: "off",
    },
  ];

  const supportedAuthenticators = [
    t("common:googleAuthenticator"),
    t("common:microsoftAuthenticator"),
  ];

  const [hasError, setHasError] = useState(true);
  const [updatedFields, setUpdatedFields] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showLogoutLoader, setShowLogoutLoader] = useState(false);

  const {
    isFetching,
    isFetchingFailed,
    mfaConfig,
    isVerifying,
    isVerified,
    mfaEnforcementType,
    mfaConfigured,
  } = useSelector(({ mfaReducer, auth }) => {
    const { mfaSetup, registerMFA } = mfaReducer;
    const mfaConfigured = mfaSetup.saveData && mfaSetup.saveData.mfaConfigured;

    return {
      isFetching: mfaSetup.isFetching,
      isFetchingFailed: mfaSetup.isFetchingFailed,
      mfaConfig: mfaSetup.fetchedDetails,
      mfaConfigured,
      isVerifying: registerMFA.isCreating,
      isVerified: registerMFA.isCreated,
      mfaEnforcementType: auth.mfaEnforcementType,
    };
  }, shallowEqual);

  const handleGetMFAConfig = () => {
    dispatch(initiateMFASetup());
  };

  useEffect(() => {
    handleGetMFAConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mfaConfigured) {
      localStorage.setItem(MFA_SETUP_COMPLETE, true);
      history.push(initialPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mfaConfigured]);

  const doLogout = () => {
    setShowLogoutLoader(true);
    dispatch(logoutUser());
  };

  const handleRedirection = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
      localStorage.setItem(MFA_SETUP_COMPLETE, true);
      history.push(initialPage);
      dispatch(clearResetRegisterMFAData());
    }, 3000);
  };

  const handleSkipMFASetup = () => {
    localStorage.setItem(MFA_SETUP_SKIPPED, true);
    history.push(initialPage);
  };

  useEffect(() => {
    if (isVerified) {
      handleRedirection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerified]);

  const handleSetupMFA = () => {
    const params = reduceFieldsArrayToParams(updatedFields);
    dispatch(registerMFADevice(params));
  };

  const handleUpdateFields = (values, hasError) => {
    const updatedFields = mapFieldsArrayWithValues(fields, values);
    setUpdatedFields(updatedFields);
    setHasError(hasError);
  };

  const isMandatory = mfaEnforcementType && mfaEnforcementType === MANDATORY;

  const content = (
    <div>
      {isMandatory && (
        <McAlert
          content={
            <div>
              <span className="text-bold font14">
                {t("common:important").toUpperCase()}
              </span>
              <div className="font14">{t("mfaRequirementText")}</div>
            </div>
          }
        />
      )}

      <Row className="mt-5">
        <Col xl={3}>
          <div className={s.QRCodeContainer}>
            <img
              loading="lazy"
              src={mfaConfig && mfaConfig.qrCodeBase64Encoded}
              alt={t("common:qrCode")}
            />
          </div>
          <div className="mt-4 text-bold text-center">{t("scanQRCode")}</div>
        </Col>
        <Col xl={9}>
          <div>
            <span className="text-bold">
              {t("common:stepNum", { num: t("common:1") })}:
            </span>
            <span className="ml-2">
              {t("setupMFAStep1")}
              <div className="mt-2">
                {supportedAuthenticators.map((authenticator, index) => (
                  <span className="font-bold-500" key={index}>
                    {authenticator},&nbsp;
                  </span>
                ))}
                <span>{t("authenticatorText")}</span>
              </div>
            </span>
          </div>
          <div className="mt-4 mb-4">
            <Label>{t("common:secret")}</Label>
            <div>
              {mfaConfig && (
                <CopyText text={mfaConfig.secretKey} id="copy-mfa-secret" />
              )}
            </div>
          </div>
          <div>
            <span className="text-bold">
              {t("common:stepNum", { num: t("common:2") })}:
            </span>
            <span className="ml-2">{t("setupMFAStep2")}</span>
          </div>
          <Row>
            <Col xl={4}>
              <CommonFormRenderer
                fields={fields}
                handleUpdateFields={handleUpdateFields}
                onSubmit={handleSetupMFA}
                isDisabled={isVerifying}
                className="mt-3"
              />
            </Col>
          </Row>
          <div className="mt-4">
            <MCButton
              disabled={hasError || isVerifying}
              text={
                isVerifying ? `${t("common:verifying")}...` : t("common:verify")
              }
              onClick={handleSetupMFA}
              color="mc-blue"
            />
            {!isVerifying && (
              <span
                className="external-link text-bold ml-3"
                onClick={handleSkipMFASetup}
              >
                {t("common:doThisLater")}
              </span>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );

  const successContent = (
    <div className={s.SuccessContent}>
      <AnimationPlayer type="success" />
      <div>{`${t("successfullySetupMFA")}...`}</div>
    </div>
  );

  return (
    <div className={s.Container}>
      <Row>
        <Col>
          <Heading
            className="display-inline-block mb-4"
            level={3}
            text="Set up multi-factor authentication"
          />
        </Col>
        <Col xl={2} className="text-right">
          <div className={s.logout} onClick={doLogout}>
            <i className="fa fa-sign-out mr-2" />
            {t("common:logout")}
          </div>
        </Col>
      </Row>
      {showLogoutLoader && (
        <div>
          <LoggingOutLoader />
        </div>
      )}
      <Async
        isFetching={isFetching}
        isFetchingFailed={isFetchingFailed}
        onSuccess={showSuccess ? successContent : content}
        errorMessage={
          <div className={s.ErrorMessage}>
            {t("common:somethingWentWrongText")}
          </div>
        }
        fetchedData={mfaConfig}
        handleAction={handleGetMFAConfig}
      />
      {isFetchingFailed && (
        <div className="text-center mt-5">
          <MCButton text={t("common:skip")} onClick={handleSkipMFASetup} />
        </div>
      )}
    </div>
  );
};

export default withRouter(SetupMFA);

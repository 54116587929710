export const SAVE_SSO_METADATA_TYPE = "SAVE_SSO_METADATA_TYPE";
export const SET_SSO_SIGN_IN_URL = "SET_SSO_SIGN_IN_URL";
export const SET_SSO_ISSUER_URL = "SET_SSO_ISSUER_URL";
export const SET_SSO_CERTIFICATE = "SET_SSO_CERTIFICATE";
export const SET_SSO_INVALID_FILE = "SET_SSO_INVALID_FILE";
export const SET_SSO_ERROR_MESSAGES = "SET_SSO_ERROR_MESSAGES";
export const SET_SSO_VALIDATING_FILE = "SET_SSO_VALIDATING_FILE";
export const SET_SSO_NAME = "SET_SSO_NAME";
export const SET_SSO_DESCRIPTION = "SET_SSO_DESCRIPTION";
export const SET_SSO_DEFAULT_DEPT = "SET_SSO_DEFAULT_DEPT";
export const SET_SSO_DEFAULT_ROLE = "SET_SSO_DEFAULT_ROLE";
export const RESET_SSO_SETTINGS = "RESET_SSO_SETTINGS";
export const SAVE_SSO_IDENTITY_PROVIDER_TYPE =
  "SAVE_SSO_IDENTITY_PROVIDER_TYPE";
export const SAVE_IDENTITY_PROVIDER_FILE = "SAVE_IDENTITY_PROVIDER_FILE";
export const SAVE_SSO_FILE_UPLOAD_STATUS = "SAVE_SSO_FILE_UPLOAD_STATUS";
export const RESET_ROLE_MAPPING_STATUS = "RESET_ROLE_MAPPING_STATUS";
export const SAVE_SSO_IDENTITY_PROVIDER = "SAVE_SSO_IDENTITY_PROVIDER";
export const SAVE_SSO_DATA_FOR_EDITING = "SAVE_SSO_DATA_FOR_EDITING";
export const GET_SSO_CONFIGURATIONS_REQUEST = "GET_SSO_CONFIGURATIONS_REQUEST";
export const GET_SSO_CONFIGURATIONS_SUCCESS = "GET_SSO_CONFIGURATIONS_SUCCESS";
export const GET_SSO_CONFIGURATIONS_FAILURE = "GET_SSO_CONFIGURATIONS_FAILURE";
export const GET_SSO_ACTIVE_SETTINGS_REQUEST =
  "GET_SSO_ACTIVE_SETTINGS_REQUEST";
export const GET_SSO_ACTIVE_SETTINGS_SUCCESS =
  "GET_SSO_ACTIVE_SETTINGS_SUCCESS";
export const GET_SSO_ACTIVE_SETTINGS_FAILURE =
  "GET_SSO_ACTIVE_SETTINGS_FAILURE";
export const GET_SSO_CONFIGURATION_ID_REQUEST =
  "GET_SSO_CONFIGURATION_ID_REQUEST";
export const GET_SSO_CONFIGURATION_ID_SUCCESS =
  "GET_SSO_CONFIGURATION_ID_SUCCESS";
export const GET_SSO_CONFIGURATION_ID_FAILURE =
  "GET_SSO_CONFIGURATION_ID_FAILURE";
export const SAVE_SSO_CONFIGURATIONS_REQUEST =
  "SAVE_SSO_CONFIGURATIONS_REQUEST";
export const SAVE_SSO_CONFIGURATIONS_SUCCESS =
  "SAVE_SSO_CONFIGURATIONS_SUCCESS";
export const SAVE_SSO_CONFIGURATIONS_FAILURE =
  "SAVE_SSO_CONFIGURATIONS_FAILURE";
export const DELETE_SSO_CONFIGURATIONS_REQUEST =
  "DELETE_SSO_CONFIGURATIONS_REQUEST";
export const DELETE_SSO_CONFIGURATIONS_SUCCESS =
  "DELETE_SSO_CONFIGURATIONS_SUCCESS";
export const DELETE_SSO_CONFIGURATIONS_FAILURE =
  "DELETE_SSO_CONFIGURATIONS_FAILURE";
export const ENABLE_SSO_REQUEST = "ENABLE_SSO_REQUEST";
export const ENABLE_SSO_SUCCESS = "ENABLE_SSO_SUCCESS";
export const ENABLE_SSO_FAILURE = "ENABLE_SSO_FAILURE";
export const GET_SSO_DOMAINS_REQUEST = "GET_SSO_DOMAINS_REQUEST";
export const GET_SSO_DOMAINS_SUCCESS = "GET_SSO_DOMAINS_SUCCESS";
export const GET_SSO_DOMAINS_FAILURE = "GET_SSO_DOMAINS_FAILURE";
export const ADD_SSO_DOMAIN_REQUEST = "ADD_SSO_DOMAIN_REQUEST";
export const ADD_SSO_DOMAIN_SUCCESS = "ADD_SSO_DOMAIN_SUCCESS";
export const ADD_SSO_DOMAIN_FAILURE = "ADD_SSO_DOMAIN_FAILURE";
export const VERIFY_SSO_DOMAIN_REQUEST = "VERIFY_SSO_DOMAIN_REQUEST";
export const VERIFY_SSO_DOMAIN_SUCCESS = "VERIFY_SSO_DOMAIN_SUCCESS";
export const VERIFY_SSO_DOMAIN_FAILURE = "VERIFY_SSO_DOMAIN_FAILURE";
export const DELETE_SSO_DOMAIN_REQUEST = "DELETE_SSO_DOMAIN_REQUEST";
export const DELETE_SSO_DOMAIN_SUCCESS = "DELETE_SSO_DOMAIN_SUCCESS";
export const DELETE_SSO_DOMAIN_FAILURE = "DELETE_SSO_DOMAIN_FAILURE";
export const RAISE_SSO_SUPPORT_TICKET_REQUEST =
  "RAISE_SSO_SUPPORT_TICKET_REQUEST";
export const RAISE_SSO_SUPPORT_TICKET_SUCCESS =
  "RAISE_SSO_SUPPORT_TICKET_SUCCESS";
export const RAISE_SSO_SUPPORT_TICKET_FAILURE =
  "RAISE_SSO_SUPPORT_TICKET_FAILURE";
export const GET_SSO_APPLICATION_ROLES_REQUEST =
  "GET_SSO_APPLICATION_ROLES_REQUEST";
export const GET_SSO_APPLICATION_ROLES_SUCCESS =
  "GET_SSO_APPLICATION_ROLES_SUCCESS";
export const GET_SSO_APPLICATION_ROLES_FAILURE =
  "GET_SSO_APPLICATION_ROLES_FAILURE";
export const SSO_UPDATE_CERTIFICATE_REQUEST = "SSO_UPDATE_CERTIFICATE_REQUEST";
export const SSO_UPDATE_CERTIFICATE_SUCCESS = "SSO_UPDATE_CERTIFICATE_SUCCESS";
export const SSO_UPDATE_CERTIFICATE_FAILURE = "SSO_UPDATE_CERTIFICATE_FAILURE";
export const IS_SSO_ENABLED_REQUEST = "IS_SSO_ENABLED_REQUEST";
export const IS_SSO_ENABLED_SUCCESS = "IS_SSO_ENABLED_SUCCESS";
export const IS_SSO_ENABLED_FAILURE = "IS_SSO_ENABLED_FAILURE";
export const SAVE_SSO_DETAILS_STATUS = "SAVE_SSO_DETAILS_STATUS";
export const GET_SSO_PROFILES_REQUEST = "GET_SSO_PROFILES_REQUEST";
export const GET_SSO_PROFILES_SUCCESS = "GET_SSO_PROFILES_SUCCESS";
export const GET_SSO_PROFILES_FAILURE = "GET_SSO_DOMAINS_FAILURE";
export const SET_SHOW_PROJECTS = "SET_SHOW_PROJECTS";

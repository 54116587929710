import {
  GET_BLUEPRINT_PARAMETERS_REQUEST,
  GET_BLUEPRINT_PARAMETERS_SUCCESS,
  GET_BLUEPRINT_PARAMETERS_FAILURE,
  SET_BLUEPRINT_PARAMETERS,
  RESET_BLUEPRINT_PARAMETERS,
  SET_CONFIG_FILLED_PARAM_KEY,
  SET_COST_FILLED_PARAM,
  REQUEST_COST_FILLED_PARAM,
  SAVE_DIRTY_LAUNCH_PARAMETERS,
} from "../actionTypes/parameter";

const initialState = {
  loadingParameters: false,
  parameterGroups: null,
  parameters: null,
  parametersClone: null,
  loadingParametersFailed: false,
  configParamKey: {},
  optionalParameter: {},
  loadingCostFilledParam: false,
  dirtyLaunchParameters: [],
};

const blueprintParameter = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLUEPRINT_PARAMETERS_REQUEST:
      return {
        ...state,
        loadingParameters: true,
        parameterGroups: initialState.parameterGroups,
        parameters: initialState.parameters,
        parametersClone: initialState.parametersClone,
        loadingParametersFailed: initialState.loadingParametersFailed,
      };
    case GET_BLUEPRINT_PARAMETERS_SUCCESS:
      return {
        ...state,
        loadingParameters: false,
        parameterGroups: action.payload.groups,
        parameters: action.payload.parameters,
        parametersClone: action.payload.parameters,
      };
    case GET_BLUEPRINT_PARAMETERS_FAILURE:
      return {
        ...state,
        loadingParameters: false,
        loadingParametersFailed: true,
      };
    case SET_BLUEPRINT_PARAMETERS:
      return {
        ...state,
        parameters: action.payload,
      };
    case RESET_BLUEPRINT_PARAMETERS:
      return {
        ...state,
        ...initialState,
      };
    case SET_CONFIG_FILLED_PARAM_KEY:
      return {
        ...state,
        configParamKey: action.payload,
      };
    case SET_COST_FILLED_PARAM:
      return {
        ...state,
        optionalParameter: action.payload,
        loadingCostFilledParam: false,
      };
    case REQUEST_COST_FILLED_PARAM:
      return {
        ...state,
        loadingCostFilledParam: true,
      };
    case SAVE_DIRTY_LAUNCH_PARAMETERS:
      return {
        ...state,
        dirtyLaunchParameters: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default blueprintParameter;

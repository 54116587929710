import { combineReducers } from "redux";
import { createReducer } from "../../../utils/create-reducer";
import {
  APP_TASKS_REORDER,
  APP_TASK_QUICK_ACTION,
} from "../../../utils/action-names.json";

const appTaskOrder = createReducer(APP_TASKS_REORDER);
const appTaskQuickAction = createReducer(APP_TASK_QUICK_ACTION);

export default combineReducers({
  appTaskOrder,
  appTaskQuickAction,
});

import {
  ADD_RESOURCES_DEPARTMENT,
  GET_DEPARTMENT_RESOURCES_REQUEST,
  GET_DEPARTMENT_RESOURCES_SUCCESS,
  GET_DEPARTMENT_RESOURCES_FAILURE,
  GET_DEPARTMENT_DETAILS_REQUEST,
  GET_DEPARTMENT_DETAILS_SUCCESS,
  GET_DEPARTMENT_DETAILS_FAILURE,
  SET_LOAD_ALL_RESOURCES,
  GET_ALL_DEPARTMENT_RESOURCES_REQUEST,
  GET_ALL_DEPARTMENT_RESOURCES_SUCCESS,
  GET_ALL_DEPARTMENT_RESOURCES_FAILURE,
  GET_PENDING_PROJECT_REQUESTS,
  GET_PENDING_PROJECT_REQUESTS_SUCCESS,
  GET_PENDING_PROJECT_REQUESTS_FAILURE,
  GET_INACTIVE_PROJECT_REQUESTS,
  GET_INACTIVE_PROJECT_REQUESTS_SUCCESS,
  GET_INACTIVE_PROJECT_REQUESTS_FAILURE,
  GET_DEPARTMENTS_LIST,
  GET_DEPARTMENTS_LIST_SUCCESS,
  GET_DEPARTMENTS_LIST_FAILURE,
  GET_DEPARTMENTS_POTENTIAL_SAVINGS,
  GET_DEPARTMENTS_POTENTIAL_SAVINGS_SUCCESS,
  GET_DEPARTMENTS_POTENTIAL_SAVINGS_FAILURE,
  GET_DEPARTMENTS_OPEN_OPS_ISSUES,
  GET_DEPARTMENTS_OPEN_OPS_ISSUES_SUCCESS,
  GET_DEPARTMENTS_OPEN_OPS_ISSUES_FAILURE,
  RESET_DEPARTMENTS_OPEN_OPS_ISSUES,
  GET_DEPARTMENT_APPLICATIONS,
  GET_DEPARTMENT_APPLICATIONS_SUCCESS,
  GET_DEPARTMENT_APPLICATIONS_FAILURE,
  RESET_ADD_PROJECT_REQUESTS,
  ADD_PROJECT_REQUESTS,
  ADD_PROJECT_REQUESTS_SUCCESS,
  ADD_PROJECT_REQUESTS_FAILURE,
  RESET_EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_REQUEST,
  EDIT_PROJECT_REQUEST_SUCCESS,
  EDIT_PROJECT_REQUEST_FAILURE,
  RESET_CREATE_DEPARTMENT,
  CREATE_DEPARTMENT,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAILURE,
  RESET_SAVE_DEPARTMENT_REQUEST,
  SAVE_DEPARTMENT_REQUEST,
  SAVE_DEPARTMENT_SUCCESS,
  SAVE_DEPARTMENT_FAILURE,
  RESET_DENY_PROJECT_REQUEST,
  DENY_PROJECT_REQUEST,
  DENY_PROJECT_REQUEST_SUCCESS,
  DENY_PROJECT_REQUEST_FAILURE,
  RESET_APPROVE_PROJECT_REQUEST,
  APPROVE_PROJECT_REQUEST,
  APPROVE_PROJECT_REQUEST_SUCCESS,
  APPROVE_PROJECT_REQUEST_FAILURE,
  RESET_DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  RESET_UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  RESET_WITHDRAW_PROJECT_REQUEST,
  WITHDRAW_PROJECT_REQUEST,
  WITHDRAW_PROJECT_REQUEST_SUCCESS,
  WITHDRAW_PROJECT_REQUEST_FAILURE,
} from "../actionTypes/department";

const initialState = {
  addResourcesResponse: null,
  isFetchingResources: false,
  departmentResources: null,
  isFetchingDetails: false,
  departmentDetails: null,
  loadAllResources: false,
  departmentResourcesFailed: false,
  isFetchingAllDeptResources: false,
  allDepartmentResourcesFailed: false,
  allDepartmentResources: null,
  fetchingPendingRequestsList: false,
  pendingRequestsListFailed: false,
  pendingRequestsList: null,
  fetchingInactiveRequestsList: false,
  inactiveRequestsListFailed: false,
  inactiveRequestsList: null,
  departmentsList: null,
  departmentsListFailed: false,
  fetchingDepartmentsList: false,
  fetchedDepartmentsList: false,
  departmentsPotentialSavings: null,
  departmentsPotentialSavingsFailed: false,
  fetchingDepartmentsPotentialSavings: false,
  departmentsOpenOpsIssues: null,
  departmentsOpenOpsIssuesFailed: false,
  fetchingDepartmentsOpenOpsIssues: false,
  departmentApplications: null,
  departmentApplicationsFailed: false,
  fetchingDepartmentApplications: false,
  createdDepartment: null,
  creatingDepartment: null,
  createdProjectRequest: false,
  creatingProjectRequest: false,
  editingProjectRequest: false,
  editedProjectRequest: false,
  editedDepartment: false,
  editingDepartment: false,
  deniedProject: false,
  denyingProject: false,
  approvedProject: false,
  approvingProject: false,
  deletedProject: false,
  deletingProject: false,
  updatedProject: false,
  updatingProject: false,
};

const departmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_RESOURCES_DEPARTMENT:
      return {
        ...state,
        addResourcesResponse: action.payload,
      };
    case GET_DEPARTMENT_RESOURCES_REQUEST:
      return {
        ...state,
        isFetchingResources: true,
        departmentResources: null,
      };
    case GET_DEPARTMENT_RESOURCES_SUCCESS:
      return {
        ...state,
        isFetchingResources: false,
        departmentResources: action.payload,
      };
    case GET_DEPARTMENT_RESOURCES_FAILURE:
      return {
        ...state,
        isFetchingResources: false,
        departmentResourcesFailed: true,
      };
    case GET_ALL_DEPARTMENT_RESOURCES_REQUEST:
      return {
        ...state,
        isFetchingAllDeptResources: true,
        allDepartmentResources: initialState.allDepartmentResources,
        allDepartmentResourcesFailed: initialState.allDepartmentResourcesFailed,
      };
    case GET_ALL_DEPARTMENT_RESOURCES_SUCCESS:
      return {
        ...state,
        isFetchingAllDeptResources: false,
        allDepartmentResources: action.payload,
      };
    case GET_ALL_DEPARTMENT_RESOURCES_FAILURE:
      return {
        ...state,
        isFetchingAllDeptResources: false,
        allDepartmentResourcesFailed: true,
      };
    case GET_DEPARTMENT_DETAILS_REQUEST:
      return {
        ...state,
        isFetchingDetails: true,
      };
    case GET_DEPARTMENT_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingDetails: false,
        departmentDetails: action.payload,
      };
    case GET_DEPARTMENT_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingDetails: false,
        errorMessage: action.payload,
      };
    case SET_LOAD_ALL_RESOURCES:
      return {
        ...state,
        loadAllResources: action.payload,
      };
    case GET_PENDING_PROJECT_REQUESTS:
      return {
        ...state,
        fetchingPendingRequestsList: true,
      };
    case GET_PENDING_PROJECT_REQUESTS_SUCCESS:
      return {
        ...state,
        fetchingPendingRequestsList: false,
        pendingRequestsList: action.payload,
      };
    case GET_PENDING_PROJECT_REQUESTS_FAILURE:
      return {
        ...state,
        fetchingPendingRequestsList: false,
        pendingRequestsListFailed: action.payload,
      };
    case GET_INACTIVE_PROJECT_REQUESTS:
      return {
        ...state,
        fetchingInactiveRequestsList: true,
      };
    case GET_INACTIVE_PROJECT_REQUESTS_SUCCESS:
      return {
        ...state,
        fetchingInactiveRequestsList: false,
        inactiveRequestsList: action.payload,
      };
    case GET_INACTIVE_PROJECT_REQUESTS_FAILURE:
      return {
        ...state,
        fetchingInactiveRequestsList: false,
        inactiveRequestsListFailed: action.payload,
      };
    case GET_DEPARTMENTS_LIST:
      return {
        ...state,
        fetchingDepartmentsList: true,
        fetchedDepartmentsList: initialState.fetchedDepartmentsList,
      };
    case GET_DEPARTMENTS_LIST_SUCCESS:
      return {
        ...state,
        fetchingDepartmentsList: false,
        fetchedDepartmentsList: true,
        departmentsList: action.payload,
      };
    case GET_DEPARTMENTS_LIST_FAILURE:
      return {
        ...state,
        fetchingDepartmentsList: false,
        fetchedDepartmentsList: true,
        departmentsListFailed: action.payload,
      };
    case GET_DEPARTMENTS_POTENTIAL_SAVINGS:
      return {
        ...state,
        fetchingDepartmentsPotentialSavings: true,
      };
    case GET_DEPARTMENTS_POTENTIAL_SAVINGS_SUCCESS:
      return {
        ...state,
        fetchingDepartmentsPotentialSavings: false,
        departmentsPotentialSavings: action.payload,
      };
    case GET_DEPARTMENTS_POTENTIAL_SAVINGS_FAILURE:
      return {
        ...state,
        fetchingDepartmentsPotentialSavings: false,
        departmentsPotentialSavingsFailed: action.payload,
      };
    case GET_DEPARTMENTS_OPEN_OPS_ISSUES:
      return {
        ...state,
        fetchingDepartmentsOpenOpsIssues: true,
      };
    case GET_DEPARTMENTS_OPEN_OPS_ISSUES_SUCCESS:
      return {
        ...state,
        fetchingDepartmentsOpenOpsIssues: false,
        departmentsOpenOpsIssues: action.payload,
      };
    case GET_DEPARTMENTS_OPEN_OPS_ISSUES_FAILURE:
      return {
        ...state,
        fetchingDepartmentsOpenOpsIssues: false,
        departmentsOpenOpsIssuesFailed: action.payload,
      };
    case RESET_DEPARTMENTS_OPEN_OPS_ISSUES:
      return {
        ...state,
        fetchingDepartmentsOpenOpsIssues: false,
        departmentsOpenOpsIssues: initialState.departmentsOpenOpsIssues,
        departmentsOpenOpsIssuesFailed:
          initialState.departmentsOpenOpsIssuesFailed,
      };
    case GET_DEPARTMENT_APPLICATIONS:
      return {
        ...state,
        fetchingDepartmentApplications: true,
      };
    case GET_DEPARTMENT_APPLICATIONS_SUCCESS:
      return {
        ...state,
        fetchingDepartmentApplications: false,
        departmentApplications: action.payload,
      };
    case GET_DEPARTMENT_APPLICATIONS_FAILURE:
      return {
        ...state,
        fetchingDepartmentApplications: false,
        departmentApplicationsFailed: action.payload,
      };
    case RESET_CREATE_DEPARTMENT:
      return {
        ...state,
        createdDepartment: initialState.createdDepartment,
      };
    case CREATE_DEPARTMENT:
      return {
        ...state,
        creatingDepartment: true,
      };
    case CREATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        creatingDepartment: false,
        createdDepartment: true,
      };
    case CREATE_DEPARTMENT_FAILURE:
      return {
        ...state,
        creatingDepartment: false,
        createdDepartment: false,
      };
    case RESET_ADD_PROJECT_REQUESTS: {
      return {
        createdProjectRequest: initialState.createdProjectRequest,
      };
    }
    case ADD_PROJECT_REQUESTS: {
      return {
        creatingProjectRequest: true,
      };
    }
    case ADD_PROJECT_REQUESTS_SUCCESS:
      return {
        ...state,
        creatingProjectRequest: false,
        createdProjectRequest: true,
      };
    case ADD_PROJECT_REQUESTS_FAILURE:
      return {
        ...state,
        creatingProjectRequest: false,
        createdProjectRequest: false,
      };
    case RESET_EDIT_PROJECT_REQUEST:
      return {
        ...state,
        editedProjectRequest: initialState.editedProjectRequest,
      };
    case EDIT_PROJECT_REQUEST: {
      return {
        editingProjectRequest: true,
      };
    }
    case EDIT_PROJECT_REQUEST_SUCCESS:
      return {
        ...state,
        editingProjectRequest: false,
        editedProjectRequest: true,
      };
    case EDIT_PROJECT_REQUEST_FAILURE:
      return {
        ...state,
        editingProjectRequest: false,
        editedProjectRequest: false,
      };
    case RESET_SAVE_DEPARTMENT_REQUEST:
      return {
        ...state,
        editedDepartment: initialState.editedDepartment,
      };
    case SAVE_DEPARTMENT_REQUEST:
      return {
        ...state,
        editingDepartment: true,
      };
    case SAVE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        editedDepartment: true,
        editingDepartment: false,
      };
    case SAVE_DEPARTMENT_FAILURE:
      return {
        ...state,
        editedDepartment: false,
        editingDepartment: false,
      };
    case RESET_DENY_PROJECT_REQUEST:
      return {
        ...state,
        deniedProject: initialState.deniedProject,
      };
    case DENY_PROJECT_REQUEST:
      return {
        ...state,
        denyingProject: true,
      };
    case DENY_PROJECT_REQUEST_SUCCESS:
      return {
        ...state,
        deniedProject: true,
        denyingProject: false,
      };
    case DENY_PROJECT_REQUEST_FAILURE:
      return {
        ...state,
        deniedProject: false,
        denyingProject: false,
      };

    case RESET_APPROVE_PROJECT_REQUEST:
      return {
        ...state,
        approvedProject: initialState.approvedProject,
      };
    case APPROVE_PROJECT_REQUEST:
      return {
        ...state,
        approvingProject: true,
      };
    case APPROVE_PROJECT_REQUEST_SUCCESS:
      return {
        ...state,
        approvedProject: true,
        approvingProject: false,
      };
    case APPROVE_PROJECT_REQUEST_FAILURE:
      return {
        ...state,
        approvedProject: false,
        approvingProject: false,
      };
    case RESET_DELETE_PROJECT_REQUEST:
      return {
        ...state,
        deletedProject: initialState.deletedProject,
      };
    case DELETE_PROJECT_REQUEST:
      return {
        ...state,
        deletingProject: true,
      };
    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        deletedProject: true,
        deletingProject: false,
      };
    case DELETE_PROJECT_FAILURE:
      return {
        ...state,
        deletedProject: false,
        deletingProject: false,
      };
    case RESET_UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        updatedProject: initialState.updatedProject,
      };
    case UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        updatingProject: true,
      };
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        updatedProject: true,
        updatingProject: false,
      };
    case UPDATE_PROJECT_FAILURE:
      return {
        ...state,
        updatedProject: false,
        updatingProject: false,
      };
    case RESET_WITHDRAW_PROJECT_REQUEST:
      return {
        ...state,
        withdrawnProject: initialState.withdrawnProject,
      };
    case WITHDRAW_PROJECT_REQUEST:
      return {
        ...state,
        withdrawingProject: true,
      };
    case WITHDRAW_PROJECT_REQUEST_SUCCESS:
      return {
        ...state,
        withdrawnProject: true,
        withdrawingProject: false,
      };
    case WITHDRAW_PROJECT_REQUEST_FAILURE:
      return {
        ...state,
        withdrawnProject: false,
        withdrawingProject: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default departmentReducer;

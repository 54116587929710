import {
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  RESEND_CONFIRMATION_REQUEST,
  RESEND_CONFIRMATION_SUCCESS,
  RESEND_CONFIRMATION_FAILURE,
  SIGNUP_PAGE_UNLOADED,
} from "../actionTypes/signup";

const token = localStorage.getItem("token");
export default function signup(
  state = {
    isFetching: false,
    isAuthenticated: !!token,
    signedUp: false,
    sendingConfirmationEmail: false,
    confirmationEmailResent: false,
    confirmationEmailFailed: false,
    signUpFailed: false,
  },
  action
) {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        errorMessage: "",
        signUpFailed: false,
      });
    case SIGNUP_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        signedUp: true,
        isAuthenticated: true,
        errorMessage: "",
        signUpFailed: false,
      });
    case SIGNUP_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        signUpFailed: true,
        errorMessage: action.payload,
      });
    case RESEND_CONFIRMATION_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        sendingConfirmationEmail: true,
        confirmationEmailResent: false,
        confirmationEmailFailed: false,
        isAuthenticated: false,
        errorMessage: "",
      });
    case RESEND_CONFIRMATION_SUCCESS:
      return Object.assign({}, state, {
        sendingConfirmationEmail: false,
        confirmationEmailResent: true,
      });
    case RESEND_CONFIRMATION_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        sendingConfirmationEmail: false,
        confirmationEmailFailed: true,
        errorMessage: action.payload,
      });
    case SIGNUP_PAGE_UNLOADED:
      return Object.assign({}, state, {
        errorMessage: "",
        sendingConfirmationEmail: false,
        confirmationEmailResent: false,
        signedUp: false,
      });
    default:
      return state;
  }
}

/* eslint-disable import/prefer-default-export */

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const CHANGE_ACTIVE_SIDEBAR_ITEM = "CHANGE_ACTIVE_SIDEBAR_ITEM";
export const SET_SIDEBAR_AUTO_COLLAPSE_MODE = "SET_SIDEBAR_AUTO_COLLAPSE_MODE";

export function toggleSidebar() {
  return {
    type: TOGGLE_SIDEBAR,
  };
}

export function openSidebar() {
  return {
    type: OPEN_SIDEBAR,
  };
}

export function closeSidebar() {
  return {
    type: CLOSE_SIDEBAR,
  };
}

export function changeActiveSidebarItem(activeItem) {
  return {
    type: CHANGE_ACTIVE_SIDEBAR_ITEM,
    activeItem,
  };
}

export function setSidebarAutoCollapseMode(payload) {
  return {
    type: SET_SIDEBAR_AUTO_COLLAPSE_MODE,
    payload,
  };
}

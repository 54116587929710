export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_SEARCH_USERS_SUCCESS = "GET_ALL_SEARCH_USERS_SUCCESS";
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";
export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";
export const CLEAR_DELETE_USER_DATA = "CLEAR_DELETE_USER_DATA";
export const GET_ALL_USERS_PORTAL_REQUEST = "GET_ALL_USERS_PORTAL_REQUEST";
export const GET_ALL_USERS_PORTAL_SUCCESS = "GET_ALL_USERS_PORTAL_SUCCESS";
export const RESET_USERS_PORTAL = "RESET_USERS_PORTAL";

export const GET_PROJECT_USERS_PORTAL_REQUEST =
  "GET_PROJECT_USERS_PORTAL_REQUEST";
export const GET_PROJECT_USERS_PORTAL_SUCCESS =
  "GET_PROJECT_USERS_PORTAL_SUCCESS";
export const GET_PROJECT_USERS_PORTAL_FAILURE =
  "GET_PROJECT_USERS_PORTAL_FAILURE";
export const RESET_PROJECT_USERS_PORTAL = "RESET_PROJECT_USERS_PORTAL";

export const SET_USERS_LIST_MAP = "SET_USERS_LIST_MAP";

import {
  GET_SERVERGROUPS_REQUEST,
  GET_SERVERGROUPS_SUCCESS,
  GET_SERVERGROUPS_FAILURE,
  UPDATE_SERVER_GROUPS_SEARCH_KEY,
  RESET_SERVER_GROUP_LIST,
} from "../actionTypes/server-groups";

const initialState = {
  isFetching: false,
  serverGroupList: null,
  showServerGroupList: true,
  serverGroupSearchKey: "",
};

export default function serverGroups(state = initialState, action) {
  switch (action.type) {
    case GET_SERVERGROUPS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_SERVERGROUPS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        serverGroupList: action.payload,
        showServerGroupList: true,
      };
    case GET_SERVERGROUPS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case UPDATE_SERVER_GROUPS_SEARCH_KEY:
      return {
        ...state,
        serverGroupSearchKey: action.payload,
      };
    case RESET_SERVER_GROUP_LIST:
      return {
        ...state,
        serverGroupList: initialState.serverGroupList,
      };
    default:
      return state;
  }
}

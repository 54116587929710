import React from "react";
import { Terminal } from "xterm";
import { FitAddon } from "xterm-addon-fit";
import { withTranslation } from "react-i18next";
import { consoleData } from "../../../../network/factories/remote-sessions";

class InstanceRemoteSessionDemo extends React.Component {
  constructor(props) {
    super(props);
    this.store = {
      Term: null,
      Socket: null
    };
    this.state = {
      SessionId: null,
      InstanceId: null
    };
  }
  componentDidMount() {
    this.setUpSessionLogData(consoleData);
  }

  setUpSessionLogData = log => {
    const term = new Terminal();
    const fitAddon = new FitAddon();
    term.loadAddon(fitAddon);

    term.open(document.getElementById("xterm"));
    fitAddon.fit();
    this.store["Term"] = term;
    this.store.Term.write(window.atob(log));
  };

  render() {
    return <div id="xterm"></div>;
  }
}

export default withTranslation(["ec2InstanceList"])(InstanceRemoteSessionDemo);

import {
  ASSIGN_PROJECT_TO_BLUEPRINT_FAILURE,
  ASSIGN_PROJECT_TO_BLUEPRINT_REQUEST,
  ASSIGN_PROJECT_TO_BLUEPRINT_SUCCESS,
  GET_BLUEPRINT_SHARED_CATALOG_FAILURE,
  GET_BLUEPRINT_SHARED_CATALOG_MASTER_SUCCESS,
  GET_BLUEPRINT_SHARED_CATALOG_REQUEST,
  GET_BLUEPRINT_SHARED_CATALOG_SUCCESS,
  GET_BLUEPRINT_SHARES_LIST_FAILURE,
  GET_BLUEPRINT_SHARES_LIST_REQUEST,
  GET_BLUEPRINT_SHARES_LIST_SUCCESS,
  SHARE_BLUEPRINT_FAILURE,
  SHARE_BLUEPRINT_REQUEST,
  SHARE_BLUEPRINT_RESET,
  SHARE_BLUEPRINT_SUCCESS,
  UNSHARE_BLUEPRINT_FAILURE,
  UNSHARE_BLUEPRINT_REQUEST,
  UNSHARE_BLUEPRINT_RESET,
  UNSHARE_BLUEPRINT_SUCCESS,
} from "../actionTypes/sharedCatalog";

const initialState = {
  blueprintSharedCatalogList: null,
  blueprintSharedCatalogMasterList: null,
  isLoadingSharedBlueprintCatalog: false,
  sharedBlueprintCatalogLoaded: false,
  sharedBlueprintCatalogFailed: false,
  isSharingBlueprint: false,
  blueprintShared: false,
  isSharingBlueprintFailure: false,
  isFetchingBlueprintShares: false,
  blueprintSharesList: null,
  isFetchingBlueprintSharesFailed: false,
  assignProjectRequestLoading: false,
  assignProjectRequestFailed: false,
  assignProjectRequestSuccess: false,
  isUnsharingBlueprint: false,
  blueprintUnshared: false,
  isUnsharingBlueprintFailure: false,
};


const blueprintSharedCatalog = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLUEPRINT_SHARED_CATALOG_REQUEST:
      return { ...state, isLoadingSharedBlueprintCatalog: true };
    case GET_BLUEPRINT_SHARED_CATALOG_FAILURE:
      return {
        ...state,
        sharedBlueprintCatalogFailed: true,
        isLoadingSharedBlueprintCatalog: false,
        sharedBlueprintCatalogLoaded: false,
      };
    case GET_BLUEPRINT_SHARED_CATALOG_SUCCESS:
      return {
        ...state,
        isLoadingSharedBlueprintCatalog: false,
        sharedBlueprintCatalogLoaded: true,
        sharedBlueprintCatalogFailed: false,
        blueprintSharedCatalogList: action.payload,
      };
    case GET_BLUEPRINT_SHARED_CATALOG_MASTER_SUCCESS:
      return {
        ...state,
        isLoadingSharedBlueprintCatalog: false,
        sharedBlueprintCatalogLoaded: true,
        sharedBlueprintCatalogFailed: false,
        blueprintSharedCatalogMasterList: action.payload,
      };

    case SHARE_BLUEPRINT_REQUEST:
      return {
        ...state,
        isSharingBlueprint: true,
        isSharingBlueprintFailure: false,
      };
    case SHARE_BLUEPRINT_SUCCESS:
      return {
        ...state,
        isSharingBlueprint: false,
        blueprintShared: true,
      };
    case SHARE_BLUEPRINT_FAILURE:
      return {
        ...state,
        isSharingBlueprint: false,
        isSharingBlueprintFailure: true,
      };
    case GET_BLUEPRINT_SHARES_LIST_REQUEST:
      return {
        ...state,
        isFetchingBlueprintShares: true,
        isFetchingBlueprintSharesFailed: false,
      };
    case GET_BLUEPRINT_SHARES_LIST_SUCCESS:
      return {
        ...state,
        isFetchingBlueprintShares: false,
        blueprintSharesList: action.payload,
      };
    case GET_BLUEPRINT_SHARES_LIST_FAILURE:
      return {
        ...state,
        isFetchingBlueprintShares: false,
        isFetchingBlueprintSharesFailed: true,
      };
    case SHARE_BLUEPRINT_RESET:
      return {
        isSharingBlueprint: false,
        blueprintShared: false,
      };
    case ASSIGN_PROJECT_TO_BLUEPRINT_FAILURE:
      return {
        ...state,
        assignProjectRequestLoading: false,
        assignProjectRequestFailed: true,
        assignProjectRequestSuccess: false,
      };
    case ASSIGN_PROJECT_TO_BLUEPRINT_REQUEST:
      return {
        ...state,
        assignProjectRequestLoading: true,
        assignProjectRequestFailed: false,
        assignProjectRequestSuccess: true,
      };
    case ASSIGN_PROJECT_TO_BLUEPRINT_SUCCESS:
      return {
        ...state,
        assignProjectRequestLoading: false,
        assignProjectRequestFailed: false,
        assignProjectRequestSuccess: true,
      };
    case UNSHARE_BLUEPRINT_REQUEST:
      return {
        ...state,
        isUnsharingBlueprint: true,
        isUnsharingBlueprintFailure: false,
      };
    case UNSHARE_BLUEPRINT_SUCCESS:
      return {
        ...state,
        isUnsharingBlueprint: false,
        blueprintUnshared: true,
      };
    case UNSHARE_BLUEPRINT_FAILURE:
      return {
        ...state,
        isUnsharingBlueprint: false,
        isUnsharingBlueprintFailure: true,
      };
    case UNSHARE_BLUEPRINT_RESET:
      return {
        isUnsharingBlueprint: false,
        blueprintUnshared: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default blueprintSharedCatalog;

import { showErrorNotification } from "../utils/error-notification";
import { postNotification } from "../utils/notification-message";
import {
  STATUS_LOADING,
  STATUS_SUCCESS,
  STATUS_FAILURE,
} from "../utils/app-constants.json";

export const DATA_TYPES = {
  OBJECT: "OBJECT",
  ARRAY: "ARRAY",
  BOOLEAN: "BOOLEAN",
  STRING: "STRING",
};

export const CRUDAction = async ({
  type,
  name,
  dispatch,
  service,
  preProcessFunction,
  dispatchPreprocess = false,
  successMessage,
  failureMessage,
  showToaster = true,
  params,
  dataType = DATA_TYPES.ARRAY,
  clearOnRefresh = true,
  dispatchAlways,
  dispatchOnError,
}) => {
  const uppercaseName = name.toUpperCase();
  dispatch({
    type: `${type}_${uppercaseName}_REQUEST`,
    dataType,
    clearOnRefresh,
    ...(dispatchAlways && {
      payload: preProcessFunction(null, STATUS_LOADING),
    }),
  });
  try {
    const { data } = await service(params);
    const preproccessData = preProcessFunction
      ? dispatchPreprocess
        ? dispatch(preProcessFunction(data))
        : preProcessFunction(data, dispatchAlways && STATUS_SUCCESS)
      : data;
    successMessage &&
      dispatch(postNotification(successMessage, STATUS_SUCCESS));
    dispatch({
      type: `${type}_${uppercaseName}_SUCCESS`,
      payload: preproccessData,
      dataType,
    });
  } catch (err) {
    const parsedError = err && JSON.parse(err) && JSON.parse(err);
    dispatchOnError && dispatch(dispatchOnError(parsedError));
    let isAccessDenied = false;
    if (parsedError.status === 403) {
      isAccessDenied = true;
    }
    const message = showErrorNotification(
      dispatch,
      err,
      failureMessage,
      showToaster
    );
    dispatch({
      type: `${type}_${uppercaseName}_FAILURE`,
      payload: dispatchAlways
        ? preProcessFunction(null, STATUS_FAILURE, isAccessDenied)
        : message,
      dataType,
      isAccessDenied,
    });
  }
};

export const updatedDataAction = ({
  name,
  dispatch,
  payload,
  dataType = DATA_TYPES.ARRAY,
}) => {
  const uppercaseName = name.toUpperCase();
  dispatch({
    type: `UPDATED_${uppercaseName}`,
    payload,
    dataType,
  });
};

export const saveDataAction = ({ name, dispatch, payload }) => {
  const uppercaseName = name.toUpperCase();
  dispatch({
    type: `SAVE_${uppercaseName}`,
    payload,
  });
};

export const clearDataAction = ({ name, dispatch }) => {
  const uppercaseName = name.toUpperCase();
  dispatch({
    type: `CLEAR_${uppercaseName}`,
  });
};

export const CHANGE_THEME = "CHANGE_THEME";
export const CLEAR_UTILITY = "CLEAR_UTILITY";
export const TOGGLE_SWITCHER_SIDEBAR = "TOGGLE_SWITCHER_SIDEBAR";
export const TOGGLE_CREATE_SIDEBAR = "TOGGLE_CREATE_SIDEBAR";

export function changeTheme(payload) {
  return {
    type: CHANGE_THEME,
    payload,
  };
}

export function toggleSwitcherUtility() {
  return {
    type: TOGGLE_SWITCHER_SIDEBAR,
  };
}

export function toggleCreateUtility() {
  return {
    type: TOGGLE_CREATE_SIDEBAR,
  };
}

export function clearUtility() {
  return {
    type: CLEAR_UTILITY,
  };
}

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "reactstrap";
import s from "./CopyText.module.scss";
import classnames from "classnames";
import PropTypes from "prop-types";

const CopyText = ({ text, id, textClassName, showText = true }) => {
  const { t } = useTranslation(["common"]);
  const [textCopied, setTextCopied] = useState(false);

  const target = `copy-text-${id}`;
  return (
    <div className="handle-action">
      <span
        id={target}
        onClick={() => {
          if (text) {
            navigator.clipboard.writeText(text);
            setTextCopied(true);
            setTimeout(() => {
              setTextCopied(false);
            }, 2000);
          }
        }}
        className={classnames(s.CopyIcon, {
          "disabled-cursor": !text,
        })}
      >
        <i className={"fa fa-clone"} title={t("copy")} />
      </span>
      {showText && <span className={classnames(textClassName)}>{text}</span>}

      <Tooltip isOpen={textCopied} placement="top" target={target}>
        <span>
          <i className={"fa fa-check"} /> {t("common:copied")}!
        </span>
      </Tooltip>
    </div>
  );
};

CopyText.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string,
  textClassName: PropTypes.string,
  showText: PropTypes.bool,
};

CopyText.defaultProps = {
  text: "",
  id: "",
  textClassName: "",
  showText: true,
};
export default CopyText;

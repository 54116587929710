import React from "react";
import classnames from "classnames";
import HandleApiError from "../../modules/commons/components/handle-api-error";
import { isArrayWithLength } from "../../utils/array-methods";
import { isEmptyObject } from "../../utils/object-methods";
import PropTypes from "prop-types";

const Async = ({
  isFetching,
  fetchedData,
  onSuccess,
  isFetchingFailed,
  onFailure,
  errorMessage = "",
  handleAction,
  messageFetchingData,
  removeLoadingClass = false,
  buttonClass,
  loadingClass,
  isAccessDenied,
}) => {
  const hasData = Array.isArray(fetchedData)
    ? isArrayWithLength(fetchedData)
    : typeof fetchedData === "object"
    ? !isEmptyObject(fetchedData)
    : fetchedData;

  return isFetching && !hasData ? (
    <>
      <div
        className={classnames(!removeLoadingClass && "loading", loadingClass)}
      >
        <i className="fa fa-spin fa-spinner" />
      </div>
      <div className="text-center">{messageFetchingData}</div>
    </>
  ) : isFetchingFailed ? (
    onFailure ? (
      onFailure()
    ) : (
      <HandleApiError
        action={handleAction}
        message={errorMessage}
        showRetry={!isAccessDenied}
        buttonClass={buttonClass}
      />
    )
  ) : fetchedData ? (
    typeof onSuccess === "function" ? (
      onSuccess()
    ) : (
      onSuccess
    )
  ) : null;
};

Async.propTypes = {
  isFetching: PropTypes.bool,
  fetchedData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onSuccess: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  isFetchingFailed: PropTypes.bool,
  onFailure: PropTypes.func,
  errorMessage: PropTypes.string,
  handleAction: PropTypes.func,
  messageFetchingData: PropTypes.string,
  removeLoadingClass: PropTypes.bool,
  buttonClass: PropTypes.string,
  loadingClass: PropTypes.string,
  isAccessDenied: PropTypes.bool,
};

export default Async;

export const ORG_MGR_RESET = "ORG_MGR_RESET";

// dropdown
export const ORG_MGR_DROPDOWN_LOADING = "ORGMGR_DROPDOWN_LOADING";
export const ORG_MGR_DROPDOWN_LOADED = "ORGMGR_DROPDOWN_LOADED";
export const ORG_MGR_DROPDOWN_LOADING_FALIED = "ORGMGR_DROPDOWN_LOADING_FALIED";
export const ORG_MGR_SWITCHING = "ORG_MGR_SWITCHING";
export const ORG_MGR_SWITCHED = "ORG_MGR_SWITCHED";
export const ORG_MGR_SWITCHING_FAILED = "ORG_MGR_SWITCHING_FAILED";
export const ORG_MGR_SWITCHING_AFTER_CREATE = "ORG_MGR_SWITCHING_AFTER_CREATE";
// org settings list
export const ORG_MGR_LIST_LOADING = "ORG_MGR_LIST_LOADING";
export const ORG_MGR_LIST_LOADED = "ORG_MGR_LIST_LOADED";
export const ORG_MGR_LIST_LOADING_FALIED = "ORG_MGR_LIST_LOADING_FALIED";
export const ORG_MGR_SET_ACTIVE = "ORG_MGR_SET_ACTIVE";

//create tenant
export const ORG_MGR_UPDATE_DETAILS_FORM = "ORG_MGR_UPDATE_DETAILS_FORM";
export const ORG_MGR_UPDATE_INVITES = "ORG_MGR_UPDATE_INVITES";
export const ORG_MGR_CREATING = "ORG_MGR_CREATING";
export const ORG_MGR_CREATED = "ORG_MGR_CREATED";
export const ORG_MGR_CREATING_FAILED = "ORG_MGR_CREATING_FAILED";

// inviting users
export const ORG_MGR_INVITING_USERS = "ORG_MGR_INVITING_USERS";
export const ORG_MGR_INVITED = "ORG_MGR_INVITED";
export const ORG_MGR_USER_INVITED = "ORG_MGR_USER_INVITED";
export const ORG_MGR_INVITING_FAILED = "ORG_MGR_INVITING_FAILED";

// edit tenant
export const ORG_MGR_EDIT = "ORG_MGR_EDIT";
export const ORG_MGR_EDIT_LOADED = "ORG_MGR_EDIT_LOADED";
export const ORG_MGR_UPDATED = "ORG_MGR_UPDATED";
export const ORG_MGR_UPDATE_FAILED = "ORG_MGR_UPDATE_FAILED";

// list a users assigned to tenant
export const ORG_MGR_ACCESS_LIST_REQUEST = "ORG_MGR_ACCESS_LIST_REQUEST";
export const ORG_MGR_ACCESS_LIST_SUCCESS = "ORG_MGR_ACCESS_LIST_SUCCESS";
export const ORG_MGR_ACCESS_LIST_CLEAR = "ORG_MGR_ACCESS_LIST_CLEAR";

// remove access
export const ORG_MGR_ACCESS_REVOKE = "ORG_MGR_ACCESS_REVOKE";
export const ORG_MGR_ACCESS_REVOKE_REQUEST = "ORG_MGR_ACCESS_REVOKE_REQUEST";
export const ORG_MGR_ACCESS_REVOKING = "ORG_MGR_ACCESS_REVOKING";
export const ORG_MGR_ACCESS_REVOKE_SUCCESS = "ORG_MGR_ACCESS_REVOKE_SUCCESS";
export const ORG_MGR_ACCESS_REVOKE_RESET = "ORG_MGR_ACCESS_REVOKE_RESET";

// delete tenant
export const ORG_MGR_DELETE_ORG = "ORG_MGR_DELETE_ORG";
export const ORG_MGR_DELETEING_ORG = "ORG_MGR_DELETEING_ORG";
export const ORG_MGR_DELETED_ORG = "ORG_MGR_DELETED_ORG";
export const ORG_MGR_DELETE_ABORT = "ORG_MGR_DELETE_ABORT";


export const ORG_MGR_SET_ACTIVE_LOADING = "ORG_MGR_SET_ACTIVE_LOADING";

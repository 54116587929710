export const GET_AWS_RESOURCES_COUNT_SUCCESS =
  "GET_AWS_RESOURCES_COUNT_SUCCESS";
export const GET_AWS_RESOURCES_COUNT_REQUEST =
  "GET_AWS_RESOURCES_COUNT_REQUEST";

export const GET_AZURE_RESOURCES_COUNT_SUCCESS =
  "GET_AZURE_RESOURCES_COUNT_SUCCESS";
export const GET_AZURE_RESOURCES_COUNT_REQUEST =
  "GET_AZURE_RESOURCES_COUNT_REQUEST";

export const GET_GLOBAL_AWS_RESOURCE_SUMMARY_REQUEST =
  "GET_GLOBAL_AWS_RESOURCE_SUMMARY_REQUEST";
export const GET_GLOBAL_AWS_RESOURCE_SUMMARY_SUCCESS =
  "GET_GLOBAL_AWS_RESOURCE_SUMMARY_SUCCESS";
export const GET_GLOBAL_AWS_RESOURCE_SUMMARY_FAILURE =
  "GET_GLOBAL_AWS_RESOURCE_SUMMARY_FAILURE";

export const GET_GLOBAL_AZURE_RESOURCE_SUMMARY_REQUEST =
  "GET_GLOBAL_AZURE_RESOURCE_SUMMARY_REQUEST";
export const GET_GLOBAL_AZURE_RESOURCE_SUMMARY_SUCCESS =
  "GET_GLOBAL_AZURE_RESOURCE_SUMMARY_SUCCESS";
export const GET_GLOBAL_AZURE_RESOURCE_SUMMARY_FAILURE =
  "GET_GLOBAL_AZURE_RESOURCE_SUMMARY_FAILURE";

export const GET_GLOBAL_GOVERNANCE_SUMMARY_REQUEST =
  "GET_GLOBAL_GOVERNANCE_SUMMARY_REQUEST";

export const GET_GLOBAL_GOVERNANCE_SUMMARY_SUCCESS =
  "GET_GLOBAL_GOVERNANCE_SUMMARY_SUCCESS";

export const GET_GLOBAL_GOVERNANCE_SUMMARY_FAILURE =
  "GET_GLOBAL_GOVERNANCE_SUMMARY_FAILURE";

export const GET_GLOBAL_AWS_REGION_SUMMARY_REQUEST =
  "GET_GLOBAL_AWS_REGION_SUMMARY_REQUEST";
export const GET_GLOBAL_AWS_REGION_SUMMARY_SUCCESS =
  "GET_GLOBAL_AWS_REGION_SUMMARY_SUCCESS";
export const GET_GLOBAL_AWS_REGION_SUMMARY_FAILURE =
  "GET_GLOBAL_AWS_REGION_SUMMARY_FAILURE";

export const GET_GLOBAL_AZURE_REGION_SUMMARY_REQUEST =
  "GET_GLOBAL_AZURE_REGION_SUMMARY_REQUEST";
export const GET_GLOBAL_AZURE_REGION_SUMMARY_SUCCESS =
  "GET_GLOBAL_AZURE_REGION_SUMMARY_SUCCESS";
export const GET_GLOBAL_AZURE_REGION_SUMMARY_FAILURE =
  "GET_GLOBAL_AZURE_REGION_SUMMARY_FAILURE";

export const SAVE_COST_POLICY_DETAILS = "SAVE_COST_POLICY_DETAILS";
export const SAVE_COST_POLICY_PERIOD = "SAVE_COST_POLICY_PERIOD";
export const SAVE_COST_POLICY_BUDGET_TYPE = "SAVE_COST_POLICY_BUDGET_TYPE";
export const SAVE_COST_POLICY_BUDGET_START_MONTH = "SAVE_COST_POLICY_BUDGET_START_MONTH";
export const SAVE_COST_POLICY_BUDGET_END_MONTH = "SAVE_COST_POLICY_BUDGET_END_MONTH";
export const SAVE_COST_POLICY_BUDGET_AMOUNT_TYPE = "SAVE_COST_POLICY_AMOUNT_TYPE";
export const SAVE_COST_POLICY_BUDGET_AMOUNT_START_MONTH = "SAVE_COST_POLICY_BUDGET_AMOUNT_START_MONTH";
export const SAVE_COST_POLICY_BUDGET_AMOUNT_END_MONTH = "SAVE_COST_POLICY_BUDGET_AMOUNT_END_MONTH";
export const SAVE_COST_POLICY_MONTHLY_BUDGETS = "SAVE_COST_POLICY_MONTHLY_BUDGETS";
export const SAVE_COST_POLICY_FIXED_BUDGET = "SAVE_COST_POLICY_FIXED_BUDGET";
export const CLEAR_COST_POLICY_DATA = "CLEAR_COST_POLICY_DATA";
export const SAVE_COST_POLICY_COST_AGGREGATION_TYPE = "SAVE_COST_AGGREGATION_TYPE";
export const SAVE_ADVANCED_COST_OPTIONS = "SAVE_ADVANCED_COST_OPTIONS";
export const CREATE_COST_POLICY_REQUEST = "CREATE_COST_POLICY_REQUEST";
export const CREATE_COST_POLICY_SUCCESS = "CREATE_COST_POLICY_SUCCESS";
export const CREATE_COST_POLICY_FAILURE = "CREATE_COST_POLICY_FAILURE";
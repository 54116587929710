import { 
  TOGGLE_ADD_ACCOUNT_SUCCESS, TOGGLE_DELETE_ACCOUNT_SUCCESS, SHOW_ACCOUNT_LIST 
} from '../actionTypes/toggle';

export default function toggle(state = {
  showAddAccount: false,
  showAccountList: true,
  showDeleteAccount: false
}, action) {
  switch (action.type) {

    case TOGGLE_ADD_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        showAddAccount: !state.showAddAccount,
        showAccountList: !state.showAccountList,
        showDeleteAccount: false
      });

    case TOGGLE_DELETE_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        showAddAccount: false,
        showAccountList: !state.showAccountList,
        showDeleteAccount: !state.showDeleteAccount
      });
    case SHOW_ACCOUNT_LIST:
      return Object.assign({}, state, {
        showAddAccount: false,
        showAccountList: true,
        showDeleteAccount: false
      });

    default:
      return state;
  }
}

import {
  POST_BILLING_GROUP_REQUEST,
  POST_BILLING_GROUP_SUCCESS,
  POST_BILLING_GROUP_FAILURE,
  GET_OU_LIST_REQUEST,
  GET_OU_LIST_SUCCESS,
  GET_OU_LIST_FAILURE,
  CREATE_TENANT_AWS_ACCOUNT_FAILURE,
  CREATE_TENANT_AWS_ACCOUNT_REQUEST,
  CREATE_TENANT_AWS_ACCOUNT_SUCCESS,
  GET_BILLING_GROUP_EXISTING_ACCOUNTS_REQUEST,
  GET_BILLING_GROUP_EXISTING_ACCOUNTS_SUCCESS,
  GET_BILLING_GROUP_EXISTING_ACCOUNTS_FAILURE,
  RESET_CREATE_BILLING_GROUP,
} from "../actionTypes/createBillingGroupActionTypes";

const initialState = {
  isPostingBillingGroup: false,
  postingBillingGroupFailed: false,
  isPostedBillingGroup: false,
  postingBillingGroupError: {},
  isFetchingOUList: false,
  fetchingOUListFailed: false,
  isFetchedOUList: false,
  ouList: [],
  isCreatingTenantAwsAccount: false,
  createTenantAwsAccountSuccess: null,
  isCreatingTenantAwsAccountFailed: false,
  isFetchingExisitngBGAccountsList: false,
  fetchingExisitngBGAccountsListFailed: false,
  exisitngBGAccountsList: [],
};

const createBillingGroup = (state = initialState, action) => {
  switch (action.type) {
    case POST_BILLING_GROUP_REQUEST:
      return {
        ...state,
        isPostingBillingGroup: true,
        postingBillingGroupFailed: initialState.postingBillingGroupFailed,
        isPostedBillingGroup: initialState.isPostedBillingGroup,
      };
    case POST_BILLING_GROUP_SUCCESS:
      return {
        ...state,
        isPostingBillingGroup: false,
        isPostedBillingGroup: true,
      };
    case POST_BILLING_GROUP_FAILURE:
      return {
        ...state,
        isPostingBillingGroup: false,
        postingBillingGroupFailed: true,
        isPostedBillingGroup: true,
        postingBillingGroupError: action.payload,
      };
    case GET_OU_LIST_REQUEST:
      return {
        ...state,
        isFetchingOUList: true,
        fetchingOUListFailed: initialState.fetchingOUListFailed,
        isFetchedOUList: initialState.isFetchedOUList,
      };
    case GET_OU_LIST_SUCCESS:
      return {
        ...state,
        isFetchingOUList: false,
        isFetchedOUList: true,
        ouList: action.payload,
      };
    case GET_OU_LIST_FAILURE:
      return {
        ...state,
        isFetchingOUList: false,
        fetchingOUListFailed: true,
        isFetchedOUList: true,
      };
    case CREATE_TENANT_AWS_ACCOUNT_REQUEST:
      return {
        ...state,
        isCreatingTenantAwsAccount: true,
      };

    case CREATE_TENANT_AWS_ACCOUNT_SUCCESS:
      return {
        ...state,
        isCreatingTenantAwsAccount: false,
        createTenantAwsAccountSuccess: action.payload,
        isCreatingTenantAwsAccountFailed: false,
      };
    case CREATE_TENANT_AWS_ACCOUNT_FAILURE:
      return {
        ...state,
        isCreatingTenantAwsAccount: false,
        isCreatingTenantAwsAccountFailed: true,
      };
    case GET_BILLING_GROUP_EXISTING_ACCOUNTS_REQUEST:
      return {
        ...state,
        isFetchingExisitngBGAccountsList: true,
      };

    case GET_BILLING_GROUP_EXISTING_ACCOUNTS_SUCCESS:
      return {
        ...state,
        isFetchingExisitngBGAccountsList: false,
        exisitngBGAccountsList: action.payload,
        fetchingExisitngBGAccountsListFailed: false,
      };
    case GET_BILLING_GROUP_EXISTING_ACCOUNTS_FAILURE:
      return {
        ...state,
        isFetchingExisitngBGAccountsList: false,
        fetchingExisitngBGAccountsListFailed: true,
      };
    case RESET_CREATE_BILLING_GROUP:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default createBillingGroup;

export const SAVE_REVIEW_NAME = "SAVE_REVIEW_NAME";
export const SAVE_REVIEW_DESCRIPTION = "SAVE_REVIEW_DESCRIPTION";
export const SAVE_REVIEW_OWNER = "SAVE_REVIEW_OWNER";
export const SAVE_HOSTED_REGION = "SAVE_HOSTED_REGION";
export const SAVE_REVIEW_ARCHITECTURAL_DESIGN_URL =
  "SAVE_REVIEW_ARCHITECTURAL_DESIGN_URL";
export const SAVE_REVIEW_ENV_TYPE = "SAVE_REVIEW_ENV_TYPE";
export const FETCHING_INDUSTRY_DATA_SUCCESS = "FETCHING_INDUSTRY_DATA_SUCCESS";
export const FETCHING_INDUSTRY_DATA_FAILURE = "FETCHING_INDUSTRY_DATA_FAILURE";
export const SAVE_REVIEW_INDUSTRY_TYPE = "SAVE_REVIEW_INDUSTRY_TYPE";
export const SAVE_REVIEW_INDUSTRY = "SAVE_REVIEW_INDUSTRY";
export const SAVE_SELECTED_ACCOUNTS = "SAVE_SELECTED_ACCOUNTS";
export const SAVE_SELECTED_DEPARTMENT = "SAVE_SELECTED_DEPARTMENT";
export const TOGGLE_WORKLOAD = "TOGGLE_WORKLOAD";
export const SAVE_SELECTED_SCOPE_MODE = "SAVE_SELECTED_SCOPE_MODE";
export const DISABLE_NEXT_STEP = "DISABLE_NEXT_STEP";
export const SET_ACCOUNTS = "SET_ACCOUNTS";
export const SHOW_ENVIRONMENTS = "SHOW_ENVIRONMENTS";
export const SELECT_ALL_ACCOUNTS_REGIONS = "SELECT_ALL_ACCOUNTS_REGIONS";
export const SELECT_ALL_PROJECTS_ENVS = "SELECT_ALL_PROJECTS_ENVS";
export const SET_DEPARTMENTS = "SET_DEPARTMENTS";
export const SET_ENVIRONMENTS = "SET_ENVIRONMENTS";
export const SET_TOGGLE = "SET_TOGGLE";

export const SET_WORKLOAD_ACCOUNTS = "SET_WORKLOAD_ACCOUNTS";

// Assessment creation
export const CREATE_ASSESSMENT_REQUEST = "CREATE_ASSESSMENT_REQUEST";
export const CREATE_ASSESSMENT_SUCCESS = "CREATE_ASSESSMENT_SUCCESS";
export const CREATE_ASSESSMENT_FAILURE = "CREATE_ASSESSMENT_FAILURE";
export const CREATE_ASSESSMENT_RESET = "CREATE_ASSESSMENT_RESET";
export const SET_ASSESSMENT_DETAILS = "SET_ASSESSMENT_DETAILS";

export const EDIT_ASSESSMENT_REQUEST = "EDIT_ASSESSMENT_REQUEST";
export const EDIT_ASSESSMENT_SUCCESS = "EDIT_ASSESSMENT_SUCCESS";
export const EDIT_ASSESSMENT_FAILURE = "EDIT_ASSESSMENT_FAILURE";
export const CLEAR_WORKLOAD_SCOPE = "CLEAR_WORKLOAD_SCOPE";
export const CLEAR_SELECTED_ACCOUNTS_REGIONS =
  "CLEAR_SELECTED_ACCOUNTS_REGIONS";

export const CLEAR_SELECTED_PROJECT_ENVIRONMENTS =
  "CLEAR_SELECTED_PROJECT_ENVIRONMENTS";

// Checks popup
export const SHOW_FIRST_RUN_CHECKS_POPUP = "SHOW_FIRST_RUN_CHECKS_POPUP";
export const HIDE_FIRST_RUN_CHECKS_POPUP = "HIDE_FIRST_RUN_CHECKS_POPUP";

export const SET_IS_ASSESSMENT_SCOPE_VALID = "SET_IS_ASSESSMENT_SCOPE_VALID";

import { useNavigate, useLocation, useParams } from "react-router-dom";

export const withRouter = (WrappedComponent) => (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  // Construct the match object
  const match = {
    params: params,
    path: props.path, // The path pattern used to match. You need to provide this as a prop.
    url: location.pathname, // The matched portion of the URL
  };

  // Construct a history-like object
  const history = {
    push: (path, state) => navigate(path, { state, replace: false }),
    replace: (path, state) => navigate(path, { state, replace: true }),
    go: navigate,
    location: location,
    listen: () => {
      // Not directly available in v6
    },
  };

  return (
    <WrappedComponent
      {...props}
      navigate={navigate}
      location={location}
      params={params}
      match={match}
      history={history}
    />
  );
};

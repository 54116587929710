import React from "react";
import classnames from "classnames";
import {
  preProcessAccountStatus,
  preProcessRegionStatus,
} from "../modules/account/actions/accounts";
import { getDesiredStatus } from "../utils/desired-status";
import { UncontrolledTooltip } from "reactstrap";
import s from "../modules/account/components/accounts/Accounts.module.scss";
import { formatDateWithoutZone } from "../utils/convert-date";


export const accountStatusFormatter = (cell) => {
  if (!(cell && cell.key)) {
    cell = preProcessAccountStatus(cell);
  }
  return (
    <span>
      <span className={classnames("circle account-status", cell.key)} />
      &nbsp; {cell.value}
    </span>
  );
};

export const RegionStatusFormatter = (cell) => {
  if (!cell || !cell.key) {
    cell = preProcessRegionStatus(cell);
  }
  return (
    <span>
      <span className={classnames("circle account-status", cell.key)} />
      &nbsp; {cell.value}
    </span>
  );
};

export const DASFormatter = (cell, row) => {
  const desiredStatus = getDesiredStatus(cell && cell.Status);
  const failedStatus = cell && cell.Status === "FAILED";
  return (
    <div className={s.Status} id={"desired-account-status" + row.accountNumber}>
      <span className={desiredStatus.classNames} />
      {` ${desiredStatus.humanizedStatus} `}

      {cell && cell.Status !== "NOT AVAILABLE" && (
        <>
          <i className="fa fa-info-circle" />
          <UncontrolledTooltip
            placement="bottom"
            autohide={false}
            target={"desired-account-status" + row.accountNumber}
          >
            {cell &&
              cell.LastRun &&
              `Last Run: ${formatDateWithoutZone(cell.LastRun)}`}
            {failedStatus && (
              <>
                <br />
                <br />
                <span>
                  Please sign in to this AWS Account and open the
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://console.aws.amazon.com/cloudformation"
                  >
                    &nbsp; AWS CloudFormation
                  </a>
                  . On the Stacks page, select the stack that begins with
                  “desired-account-state” and hit delete. Please try again when
                  the stack has been successfully deleted.
                </span>
              </>
            )}
          </UncontrolledTooltip>
        </>
      )}
    </div>
  );
};

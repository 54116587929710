import {
  DESIRED_STATE_POLICY_NAME_CHANGE,
  DESIRED_STATE_POLICY_TYPE_CHANGE,
  DESIRED_STATE_POLICY_DESCRIPTION_CHANGE,
  DESIRED_STATE_POLICY_RESET_FORM_FIELDS,
  SET_NEXT_STEP_STATE,
  DELETE_DESIRED_STATE_POLICY_REQUEST,
  DELETE_DESIRED_STATE_POLICY_SUCCESS,
  DELETE_DESIRED_STATE_POLICY_FAILURE,
  GET_DESIRED_STATE_POLICY_DETAIL_REQUEST,
  GET_DESIRED_STATE_POLICY_DETAIL_SUCCESS,
  GET_DESIRED_STATE_POLICY_DETAIL_FAILURE,
  SAVE_DESIRED_STATE_POLICY_REQUEST,
  SAVE_DESIRED_STATE_POLICY_SUCCESS,
  SAVE_DESIRED_STATE_POLICY_FAILURE,
  SET_DESIRED_STATE_SCHEDULER,
} from "../actionTypes/desired-state-policy";

import {
  GET_DESIRED_STATE_RULES_SUCCESS,
  DELETE_DESIRED_STATE_RULE,
  SAVE_DESIRED_STATE_RULE,
  EDIT_DESIRED_STATE_RULE,
  GET_DESIRED_STATE_RULE_PARAMS_REQUEST,
  GET_DESIRED_STATE_RULE_PARAMS_SUCCESS,
  DESIRED_STATE_RULE_TYPE_CHANGE,
  DESIRED_STATE_RULE_FORM_CHANGE,
  DESIRED_STATE_RULE_NAME_CHANGE,
  REMEDIATION_CHANGE,
  SET_RULE_REMEDIATION_TYPE,
  RESET_DESIRED_STATE_RULE_FORM,
  EDIT_DESIRED_STATE_RULE_FORM,
  DESIRED_STATE_SEVERITY_CHANGE,
  DESIRED_STATE_RULE_FILES_CHECK_REGISTRY,
  DESIRED_STATE_RULE_WINDOWS_REGISTRY,
  SET_REMEDIATION_ENABLED,
  SET_DRAGGABLE_RULE,
  TOGGLE_WINDOWS_LINUX,
} from "../actionTypes/desired-state-rule";

const initialState = {
  editDesiredStatePolicy: false,
  desiredStatePolicyName: "",
  desiredStatePolicyDescription: "",
  desiredStatePolicyType: "",
  desiredStatePolicyId: "",
  disableNextStep: true,
  isFetchingRuleParams: false,
  setInitialDSRuleParams: null,
  desiredStateRuleList: [],
  desiredStateRuleType: null,
  desiredStateRuleForm: [],
  desiredStateRuleFilesCheck: {},
  desiredStateRuleWindowsRegistry: {},
  desiredStateRuleName: "",
  remediationScript: {},
  windowsScript: true,
  isEditRule: false,
  createdPolicy: false,
  complianceSeverity: "",
  ruleId: "",
  scheduleDesiredState: true,
  remediationEnabled: true,
  switchDssRuleOs: true,
};

const desiredStatePolicy = (state = initialState, action) => {
  switch (action.type) {
    case SET_DESIRED_STATE_SCHEDULER:
      return {
        ...state,
        scheduleDesiredState: action.payload,
      };
    case DESIRED_STATE_POLICY_NAME_CHANGE:
      return {
        ...state,
        desiredStatePolicyName: action.payload,
      };
    case DESIRED_STATE_POLICY_DESCRIPTION_CHANGE:
      return {
        ...state,
        desiredStatePolicyDescription: action.payload,
      };
    case DESIRED_STATE_POLICY_TYPE_CHANGE:
      return {
        ...state,
        desiredStatePolicyType: action.payload,
      };
    case DESIRED_STATE_SEVERITY_CHANGE:
      return {
        ...state,
        complianceSeverity: action.payload,
      };
    case TOGGLE_WINDOWS_LINUX:
      return {
        ...state,
        switchDssRuleOs: action.payload,
      };
    case DESIRED_STATE_POLICY_RESET_FORM_FIELDS:
      return {
        ...state,
        ...initialState,
      };
    case DELETE_DESIRED_STATE_POLICY_REQUEST:
      return {
        ...state,
        isDeletingPolicy: true,
      };
    case DELETE_DESIRED_STATE_POLICY_SUCCESS:
      return {
        ...state,
        deletedPolicy: true,
        isDeletingPolicy: false,
      };
    case DELETE_DESIRED_STATE_POLICY_FAILURE:
      return {
        ...state,
        deletedPolicy: false,
        isDeletingPolicy: false,
      };
    case GET_DESIRED_STATE_POLICY_DETAIL_REQUEST:
      return {
        ...state,
        isFetchingDetails: true,
      };
    case GET_DESIRED_STATE_POLICY_DETAIL_SUCCESS:
      const { desiredStatePolicyDetails, processedDetails } = action.payload;
      return {
        ...state,
        isFetchingDetails: false,
        desiredStatePolicyDetails: desiredStatePolicyDetails,
        editDesiredStatePolicy: true,
        desiredStatePolicyId: processedDetails.DesiredStatePolicyID,
        desiredStatePolicyDescription:
          processedDetails.DesiredStatePolicyDescription,
        desiredStatePolicyName: processedDetails.DesiredStatePolicyName,
        desiredStatePolicyType: processedDetails.DesiredStatePolicyType,
      };
    case GET_DESIRED_STATE_RULES_SUCCESS:
      return {
        ...state,
        isFetchingDetails: false,
        desiredStateRuleList: action.payload,
      };
    case GET_DESIRED_STATE_POLICY_DETAIL_FAILURE:
      return {
        ...state,
        isFetchingDetails: false,
        errorMessage: action.payload,
      };
    case SAVE_DESIRED_STATE_POLICY_REQUEST:
      return {
        ...state,
        isCreatingPolicy: true,
      };
    case SAVE_DESIRED_STATE_POLICY_SUCCESS:
      return {
        ...state,
        createdPolicy: true,
        isCreatingPolicy: false,
      };
    case SAVE_DESIRED_STATE_POLICY_FAILURE:
      return {
        ...state,
        createdPolicy: false,
        isCreatingPolicy: false,
      };
    case SET_REMEDIATION_ENABLED:
      return {
        ...state,
        remediationEnabled: action.payload,
      };
    case SET_NEXT_STEP_STATE:
      return {
        ...state,
        disableNextStep: action.payload,
      };
    case DELETE_DESIRED_STATE_RULE:
      let newRules = [...state.desiredStateRuleList];
      newRules = newRules.filter((item, index) => {
        if (action.payload !== index) {
          return item;
        } else return null;
      });
      return {
        ...state,
        desiredStateRuleList: newRules,
      };
    case SAVE_DESIRED_STATE_RULE:
      let newRule = [...state.desiredStateRuleList];
      newRule.push(action.payload);
      return {
        ...state,
        desiredStateRuleList: newRule,
      };
    case EDIT_DESIRED_STATE_RULE: {
      let newRule = [...state.desiredStateRuleList];
      let updatedData = newRule.filter((item, index) => {
        if (action.payload.ruleIndex !== index) {
          return item;
        } else return null;
      });
      updatedData.splice(
        action.payload.ruleIndex,
        0,
        action.payload.preProcessedRules
      );
      return {
        ...state,
        desiredStateRuleList: updatedData,
      };
    }

    case EDIT_DESIRED_STATE_RULE_FORM: {
      let newRule = [...state.desiredStateRuleList];
      let updatedData = newRule.filter((item, index) => {
        if (action.payload === index) {
          return item;
        } else return null;
      });
      return {
        ...state,
        isEditRule: true,
        desiredStateRuleForm: updatedData[0].parameterMetaData || [],
        desiredStateRuleType: updatedData[0].ruleType,
        desiredStateRuleName: updatedData[0].ruleName || "",
        remediationScript: updatedData[0].remediationScript || {},
        windowsScript: updatedData[0].windowsScript,
        complianceSeverity: updatedData[0].complianceSeverity || "",
        desiredStateRuleFilesCheck:
          updatedData[0].desiredStateRuleFilesCheck || {},
        desiredStateRuleWindowsRegistry:
          updatedData[0].desiredStateRuleWindowsRegistry || {},
        ruleId: updatedData[0].ruleId,
        remediationEnabled: updatedData[0].remediationEnabled,
        switchDssRuleOs: updatedData[0].switchDssRuleOs,
      };
    }
    case SET_DRAGGABLE_RULE:
      return {
        ...state,
        desiredStateRuleList: action.payload,
      };
    case RESET_DESIRED_STATE_RULE_FORM:
      return {
        ...state,
        desiredStateRuleForm: [],
        desiredStateRuleType: null,
        desiredStateRuleName: "",
        remediationScript: {},
        windowsScript: true,
        remediationLinuxScript: "",
        complianceSeverity: "",
        desiredStateRuleFilesCheck: {},
        desiredStateRuleWindowsRegistry: {},
        isEditRule: false,
        remediationEnabled: true,
        switchDssRuleOs: initialState.switchDssRuleOs,
      };
    case GET_DESIRED_STATE_RULE_PARAMS_REQUEST:
      return {
        ...state,
        isFetchingRuleParams: true,
      };
    case GET_DESIRED_STATE_RULE_PARAMS_SUCCESS:
      return {
        ...state,
        isFetchingRuleParams: false,
        setInitialDSRuleParams: action.payload,
      };
    case DESIRED_STATE_RULE_TYPE_CHANGE:
      return {
        ...state,
        desiredStateRuleType: action.payload,
      };
    case DESIRED_STATE_RULE_NAME_CHANGE:
      return {
        ...state,
        desiredStateRuleName: action.payload,
      };
    case DESIRED_STATE_RULE_FORM_CHANGE:
      return {
        ...state,
        desiredStateRuleForm: action.payload,
      };
    case DESIRED_STATE_RULE_FILES_CHECK_REGISTRY:
      return {
        ...state,
        desiredStateRuleFilesCheck: action.payload,
      };
    case DESIRED_STATE_RULE_WINDOWS_REGISTRY:
      return {
        ...state,
        desiredStateRuleWindowsRegistry: action.payload,
      };
    case REMEDIATION_CHANGE:
      return {
        ...state,
        remediationScript: {
          ...state.remediationScript,
          ...action.payload,
        },
      };
    case SET_RULE_REMEDIATION_TYPE:
      return {
        ...state,
        windowsScript: action.payload,
      };
    default:
      return state;
  }
};

export default desiredStatePolicy;

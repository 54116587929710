import {
  RESET_TOKEN_FAILURE,
  RESET_TOKEN_SUCCESS,
  RESET_TOKEN_REQUEST,
} from "../actionTypes/token";

const token = localStorage.getItem("token");

const initialState = {
  isAuthenticated: !!token,
};

export default function tokenReducer(state = initialState, action) {
  switch (action.type) {
    case RESET_TOKEN_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
      };
    case RESET_TOKEN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      };
    case RESET_TOKEN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}

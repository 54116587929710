/**
 *
 * @param {*} args
 */
export function debug(...args) {
  console.debug(new Date().toISOString(), ...args);
}

/**
 *
 * @param {*} args
 */
export function info(...args) {
  console.info(new Date().toISOString(), ...args);
}

/**
 *
 * @param {*} args
 */
export function warn(...args) {
  console.warn(new Date().toISOString(), ...args);
}

/**
 *
 * @param {*} args
 */
export function error(...args) {
  console.error(new Date().toISOString(), ...args);
}

import React from "react";
import s from "./ExternalLink.module.scss";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

const ExternalLink = ({
  externalLink,
  icon = externalLink && "fa-external-link",
  text,
  isPopup,
  onClick,
  iconPosition = "left",
  size = "small",
  hideHelpLinkText = false,
  className,
}) => {
  const { t } = useTranslation(["common", "blueprints"]);
  const helpLinkText = text || t("viewInfo");
  const iconContent = <i className={classnames(`fa ${icon}`, s.infoIcon)}></i>;
  const content = (
    <div
      className={classnames("view-all", s.size, s[`${size}`], className)}
      onClick={onClick && onClick}
    >
      {icon && iconPosition === "left" && iconContent}
      {!hideHelpLinkText && helpLinkText}
      {icon && iconPosition === "right" && <>&nbsp; {iconContent}</>}
    </div>
  );
  if (isPopup) {
    return content;
  }
  return (
    <a
      href={externalLink}
      target="_blank"
      rel="noopener noreferrer"
      className={classnames(
        "view-all mc-text-base-regular",
        s.size,
        s[`${size}`]
      )}
    >
      {content}
    </a>
  );
};

export default ExternalLink;

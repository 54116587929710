import {
  GET_ACCOUNT_TAGS_REQUEST,
  GET_ACCOUNT_TAGS_SUCCESS,
  GET_ACCOUNT_TAGS_FAILURE,
  DISABLE_NEXT_STEP,
  HIDE_PREV_STEP,
  SET_ACCOUNT_NAME,
  CLEAR_ACCOUNT_DATA,
  GET_CHECK_API_PERMISSION_REQUEST,
  GET_CHECK_API_PERMISSION_SUCCESS,
  GET_CHECK_API_PERMISSION_FAILURE,
  SET_ADD_ACCOUNT_MODAL,
  CUSTOMER_ACCOUNT_REQUESTED,
  CUSTOMER_ACCOUNT_SUCCESS,
  CUSTOMER_ACCOUNT_FAILED,
  CUSTOMER_ACCOUNT_STATUS_REQUESTED,
  CUSTOMER_ACCOUNT_STATUS_SUCCESS,
  CUSTOMER_ACCOUNT_STATUS_FAILED,
  RESET_CONFIGURE_ACCOUNT,
  SAVE_ACCOUNT_NAME_REQUESTED,
  SAVE_ACCOUNT_NAME_SUCESS,
  SAVE_ACCOUNT_NAME_FAILED,
  CONTINUE_ACCOUNT_ONBOARDING,
  SAVE_SELECTED_REGIONS,
  GET_ACCOUNT_DETAILS_REQUEST,
  GET_ACCOUNT_DETAILS_SUCCESS,
  GET_ACCOUNT_DETAILS_FAILURE,
  SET_SELECTED_ACCOUNT_ID,
  SET_CONFIGURE_ACCESS_BTN_CLICKED,
  CLEAR_CUSTOMER_ACCOUNT_NAME_SAVED,
  SET_AZURE_ACCOUNT_MODAL,
  SET_SELECTED_CUSTOMER_ACCOUNT_ID,
  CLEAR_ACCOUNT_DETAILS,
  RESET_ONBOARIDNG,
  SET_CONFIGURE_AWS_ACCOUNT_STATE,
  CLEAR_CONFIGURE_AWS_ACCOUNT_STATE,
} from "../actionTypes/account";
import { CONNECTED_STATUS } from "../../../utils/app-constants.json";
import { configureAWSAccountStates } from "../utils/account-constants";

const initialState = {
  selectedAccountId: null,
  isCreatingAccount: false,
  createdAccount: false,
  isGettingTags: false,
  failedToGetTags: false,
  disableNextStep: true,
  hidePrevStep: false,
  accountName: "",
  accountId: "",
  addAccountErrorMessage: "",
  loadedPermissions: false,
  isLoadingPermissions: false,
  hasAccessToAddAccount: null,
  addAccountModal: false,
  externalId: null,
  customerAccountId: null,
  isFetchingOnboardingTemplate: false,
  customerAccountStatus: null,
  accountNumber: "",
  savingAccountName: false,
  customerAccountNameSaved: false,
  isContinueOnboarding: false,
  onboardingUrl: null,
  selectedRegions: null,
  customerAccountDetails: null,
  isFetchingCustomerAccountDetails: false,
  customerAccountDetailsFetched: false,
  fetchingCustomerAccountDetailsFailed: false,
  customerAccountStatusSuccess: false,
  isConfigureAccessBtnClicked: false,
  failedToSaveAccountName: false,
  onboardingScript: null,
  addAzureAccountModal: false,
  selectedCustomerAccountId: null,
  onboardingError: "",
  configureAWSAccountState:
    configureAWSAccountStates.SHOW_CONFIGURE_PERMISSIONS,
};

export default function addAccount(state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_TAGS_REQUEST:
      return {
        ...state,
        isGettingTags: true,
        failedToGetTags: initialState.failedToGetTags,
      };
    case GET_ACCOUNT_TAGS_SUCCESS:
      return {
        ...state,
        isGettingTags: false,
        montyCloudInventoryId: action.payload.MontyCloudInventoryId,
        montyCloudTagId: action.payload.MontyCloudTagId,
        externalId: action.payload.ExternalId,
      };
    case GET_ACCOUNT_TAGS_FAILURE:
      return {
        ...state,
        isGettingTags: false,
        errorMessage: action.payload,
        failedToGetTags: true,
      };
    case DISABLE_NEXT_STEP:
      return {
        ...state,
        disableNextStep: action.payload,
      };
    case HIDE_PREV_STEP:
      return {
        ...state,
        hidePrevStep: action.payload,
      };
    case SET_ACCOUNT_NAME:
      return {
        ...state,
        accountName: action.payload,
      };
    case CLEAR_ACCOUNT_DATA:
      return {
        ...state,
        ...initialState,
      };
    case GET_CHECK_API_PERMISSION_REQUEST:
      return {
        ...state,
        isLoadingPermissions: true,
        loadedPermissions: initialState.loadedPermissions,
        hasAccessToAddAccount: initialState.hasAccessToAddAccount,
      };
    case GET_CHECK_API_PERMISSION_SUCCESS:
      return {
        ...state,
        isLoadingPermissions: false,
        loadedPermissions: true,
        hasAccessToAddAccount: action.payload,
      };
    case GET_CHECK_API_PERMISSION_FAILURE:
      return {
        ...state,
        isLoadingPermissions: false,
        loadedPermissions: true,
        hasAccessToAddAccount: false,
        permissionsLoadingError: action.payload,
      };
    case SET_ADD_ACCOUNT_MODAL:
      return {
        ...state,
        addAccountModal: action.payload,
      };
    case CUSTOMER_ACCOUNT_REQUESTED:
      return {
        ...state,
        isFetchingOnboardingTemplate: true,
      };
    case CUSTOMER_ACCOUNT_SUCCESS:
      return {
        ...state,
        isFetchingOnboardingTemplate: false,
        customerAccountId: action.payload.CustomerAccountId,
        onboardingUrl: action.payload.OnboardingURL
          ? action.payload.OnboardingURL
          : initialState.onboardingUrl,
        onboardingScript: action.payload.ScriptText
          ? action.payload.ScriptText
          : initialState.onboardingScript,
      };
    case CUSTOMER_ACCOUNT_FAILED:
      return {
        ...state,
        isFetchingOnboardingTemplate: false,
        customerAccountErrorMessage: action.payload,
      };
    case CUSTOMER_ACCOUNT_STATUS_REQUESTED:
      return {
        ...state,
      };
    case CUSTOMER_ACCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        customerAccountStatus: action.payload.Status,
        accountNumber: action.payload.AccountNumber,
        selectedAccountId: action.payload.AccountNumber,
        selectedCustomerAccountId: action.payload.CustomerAccountId,
        onboardingError: action.payload.Error,
        customerAccountStatusSuccess:
          action.payload.Status === CONNECTED_STATUS,
        ...(action.payload.AccountName && {
          accountName: action.payload.AccountName,
        }),
      };
    case CUSTOMER_ACCOUNT_STATUS_FAILED:
      return {
        ...state,
        customerAccountStatus: initialState.customerAccountStatus,
        customerAccountStatusErrorMessage: action.payload,
      };
    case RESET_CONFIGURE_ACCOUNT:
      return {
        ...state,
        customerAccountStatus: initialState.customerAccountStatus,
        accountNumber: initialState.accountNumber,
        customerAccountStatusSuccess: initialState.customerAccountStatusSuccess,
        customerAccountId: initialState.customerAccountId,
        onboardingError: initialState.onboardingError,
        onboardingUrl: initialState.onboardingUrl,
        accountName: action.isFRE
          ? state.accountName
          : initialState.accountName,
        customerAccountNameSaved: initialState.customerAccountNameSaved,
        isConfigureAccessBtnClicked: initialState.isConfigureAccessBtnClicked,
      };
    case SAVE_ACCOUNT_NAME_REQUESTED:
      return {
        ...state,
        savingAccountName: true,
        customerAccountNameSaved: initialState.customerAccountNameSaved,
        failedToSaveAccountName: initialState.failedToSaveAccountName,
      };
    case SAVE_ACCOUNT_NAME_SUCESS:
      return {
        ...state,
        savingAccountName: false,
        customerAccountNameSaved: true,
      };
    case SAVE_ACCOUNT_NAME_FAILED:
      return {
        ...state,
        savingAccountName: false,
        customerAccountNameSaved: false,
        failedToSaveAccountName: true,
      };
    case CONTINUE_ACCOUNT_ONBOARDING:
      return {
        ...state,
        addAccountModal: true,
        customerAccountId: action.payload,
        isContinueOnboarding: true,
      };
    case SAVE_SELECTED_REGIONS:
      return {
        ...state,
        selectedRegions: action.payload,
      };
    case GET_ACCOUNT_DETAILS_REQUEST:
      return {
        ...state,
        isFetchingCustomerAccountDetails: true,
        customerAccountDetailsFetched:
          initialState.customerAccountDetailsFetched,
        fetchingCustomerAccountDetailsFailed:
          initialState.fetchingCustomerAccountDetailsFailed,
      };
    case GET_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        customerAccountDetails: action.payload,
        isFetchingCustomerAccountDetails: false,
        customerAccountDetailsFetched: true,
      };
    case GET_ACCOUNT_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingCustomerAccountDetails: false,
        fetchingCustomerAccountDetailsFailed: true,
      };
    case SET_SELECTED_ACCOUNT_ID:
      return {
        ...state,
        selectedAccountId: action.accountId,
      };
    case SET_CONFIGURE_ACCESS_BTN_CLICKED:
      return {
        ...state,
        isConfigureAccessBtnClicked: true,
      };
    case CLEAR_CUSTOMER_ACCOUNT_NAME_SAVED:
      return {
        ...state,
        customerAccountNameSaved: false,
      };
    case SET_AZURE_ACCOUNT_MODAL:
      return {
        ...state,
        addAzureAccountModal: action.payload,
      };
    case SET_SELECTED_CUSTOMER_ACCOUNT_ID:
      return {
        ...state,
        selectedCustomerAccountId: action.payload,
      };

    case CLEAR_ACCOUNT_DETAILS:
      return {
        ...state,
        customerAccountDetails: initialState.customerAccountDetails,
        isFetchingCustomerAccountDetails:
          initialState.isFetchingCustomerAccountDetails,
        customerAccountDetailsFetched:
          initialState.customerAccountDetailsFetched,
      };
    case RESET_ONBOARIDNG: {
      return {
        ...state,
        customerAccountStatus: initialState.customerAccountStatus,
        customerAccountStatusSuccess: initialState.customerAccountStatusSuccess,
        accountNumber: initialState.accountNumber,
        customerAccountId: initialState.customerAccountId,
        onboardingUrl: initialState.onboardingUrl,
        onboardingScript: initialState.onboardingScript,
        onboardingError: initialState.onboardingError,
        customerAccountNameSaved: initialState.customerAccountNameSaved,
        isConfigureAccessBtnClicked: initialState.isConfigureAccessBtnClicked,
        accountName: initialState.accountName,
      };
    }
    case SET_CONFIGURE_AWS_ACCOUNT_STATE: {
      return {
        ...state,
        configureAWSAccountState: action.payload,
      };
    }
    case CLEAR_CONFIGURE_AWS_ACCOUNT_STATE: {
      return {
        ...state,
        configureAWSAccountState: initialState.configureAWSAccountState,
      };
    }
    default:
      return state;
  }
}

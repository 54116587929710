import React from "react";
import classnames from "classnames";
import { FormGroup, Input, Label, UncontrolledTooltip } from "reactstrap";

const ToggleSwitch = ({
  disabledText,
  enabledText,
  id,
  checked,
  onChange,
  disabled,
  tooltip,
  tooltipId,
  className,
}) => {
  return (
    <div className={classnames(className, "mc-text-base-regular")}>
      {disabledText}
      <FormGroup className="display-inline-block checkbox-ios mx-2 mb-0">
        <Label htmlFor={id} className="switch">
          <Input
            type="checkbox"
            className="ios"
            id={id}
            bsSize="small"
            checked={checked}
            disabled={disabled}
            onChange={onChange}
          />
          <i />
        </Label>
      </FormGroup>
      {enabledText}
      {tooltip && (
        <>
          <span className="ml-2">
            <i className="fa fa-info-circle color-gray" id={tooltipId}></i>
          </span>
          <UncontrolledTooltip
            placement="right"
            autohide={false}
            target={tooltipId}
          >
            {tooltip}
          </UncontrolledTooltip>
        </>
      )}
    </div>
  );
};

export default ToggleSwitch;

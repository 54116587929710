import qs from "qs";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const stringify = (obj, options = {}) => {
  return qs.stringify(obj, { indices: false, ...options });
};

export const arrayStringify = (params) => {
  return qs.stringify(params, { arrayFormat: "brackets" });
};

export function updateUrlParams(path, paramsObject) {
  const urlSearchParams = new URLSearchParams(
    path.slice(path.indexOf("?") + 1)
  );
  const existingParams = new URLSearchParams(urlSearchParams);
  for (const key in paramsObject) {
    existingParams.set(key, paramsObject[key]);
  }
  const updatedParamsString = existingParams.toString();
  return updatedParamsString
    ? `${path.split("?")[0]}?${updatedParamsString}`
    : path.split("?")[0];
}

export const useUpdateQueryParam = ({ queryParamKey, path }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const updateQueryParam = useCallback(
    (queryParamValue) => {
      let query = updateUrlParams(location.search, {
        [queryParamKey]: queryParamValue,
      });
      navigate(`${path}${query}`);
    },
    [location.search, navigate, path, queryParamKey]
  );
  return updateQueryParam;
};

export const useDeleteQueryParams = ({ queryParamKey, path }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const deleteQueryParam = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(queryParamKey);

    const query = searchParams.toString() ? `?${searchParams.toString()}` : "";
    navigate(`${path}${query}`);
  }, [location.search, navigate, path, queryParamKey]);

  return deleteQueryParam;
};

import {
  TF_CODE_FILE_FETCHED,
  TF_CODE_FILE_FETCHING,
  TF_CODE_FILE_FETCH_FAILED,
  CLEAR_BLUEPRINT_MANIFEST,
  GET_INFRA_SETUP_INFO_REQUEST,
  GET_INFRA_SETUP_INFO_SUCCESS,
  GET_INFRA_SETUP_INFO_FAILURE,
  CLEAR_INFRA_SETUP_INFO,
} from "../actionTypes/blueprint";

import {
  GET_ALL_BLUEPRINTS_REQUEST,
  GET_ALL_BLUEPRINTS_SUCCESS,
  GET_ALL_BLUEPRINTS_FAILURE,
  GET_ALL_BLUEPRINTS_MASTER_REQUEST,
  GET_ALL_BLUEPRINTS_MASTER_SUCCESS,
  GET_ALL_BLUEPRINTS_MASTER_FAILURE,
  SET_SELECTED_BLUEPRINT_FOR_LAUNCH,
  GET_BLUEPRINT_TEMPLATE_REQUEST,
  GET_BLUEPRINT_TEMPLATE_SUCCESS,
  GET_BLUEPRINT_TEMPLATE_FAILURE,
  GET_BLUEPRINT_MANIFEST_REQUEST,
  GET_BLUEPRINT_MANIFEST_SUCCESS,
  GET_BLUEPRINT_MANIFEST_FAILURE,
  GET_BLUEPRINT_STACKS_REQUEST,
  GET_BLUEPRINT_STACKS_SUCCESS,
  GET_BLUEPRINT_STACKS_FAILURE,
  GET_BLUEPRINT_PERMISSIONS_REQUEST,
  GET_BLUEPRINT_PERMISSIONS_SUCCESS,
  GET_BLUEPRINT_PERMISSIONS_FAILURE,
  GET_BLUEPRINT_DIAGRAM_URL,
  CLEAR_BLUEPRINT_DATA,
  SAVE_BLUEPRINT_TYPE,
  UPDATE_BLUEPRINT_SEARCH_KEY,
  GET_ALL_BLUEPRINT_STACKS_REQUEST,
  GET_ALL_BLUEPRINT_STACKS_SUCCESS,
  GET_ALL_BLUEPRINT_STACKS_FAILURE,
  BLUEPRINT_PERMISSION_CHECK_REQUEST,
  BLUEPRINT_PERMISSION_CHECK_SUCCESS,
  BLUEPRINT_PERMISSION_CHECK_FAILURE,
  BLUEPRINT_ACTIONS_REQUEST,
  BLUEPRINT_ACTIONS_SUCCESS,
  BLUEPRINT_ACTIONS_FAILURE,
  DELETE_BLUEPRINT_REQUEST,
  DELETE_BLUEPRINT_SUCCESS,
  DELETE_BLUEPRINT_FAILURE,
  REVIEW_BLUEPRINT_REQUEST,
  REVIEW_BLUEPRINT_SUCCESS,
  REVIEW_BLUEPRINT_FAILURE,
  DISABLE_ROUTINE_TASK_SUCCESS,
  DISABLE_ROUTINE_TASK_REQUEST,
  DISABLE_ROUTINE_TASK_FAILURE,
  DELETE_ROUTINE_TASK_REQUEST,
  DELETE_ROUTINE_TASK_SUCCESS,
  DELETE_ROUTINE_TASK_FAILURE,
  SAVE_BLUEPRINT_PARAMETERS,
  RESET_BLUEPRINT_LIST,
} from "../actionTypes/blueprints";

const initialState = {
  blueprintsLoaded: false,
  isBlueprintsLoading: false,
  blueprintSearchKey: "",
  blueprintList: null,
  blueprintMasterList: null,
  isTemplateLoading: false,
  blueprintTemplate: null,
  isManifestLoading: false,
  blueprintManifest: null,
  isBlueprintStacksLoading: false,
  isBlueprintStacksLoaded: false,
  blueprintStacks: null,
  selectedBlueprintForLaunch: null,
  blueprintDashboard: null,
  blueprintError: null,
  diagramUrl: null,
  savedBlueprintType: "",
  allBlueprintStacks: null,
  allStacksLoading: false,
  allStacksLoaded: false,
  allStacksFailed: false,
  isBlueprintPermissionsLoading: false,
  blueprintHasPermissions: false,
  blueprintPermissionsFailed: null,
  blueprintActions: null,
  blueprintActionsLoading: null,
  savingBundleTask: false,
  blueprintsFailed: false,
  deletingBlueprint: false,
  blueprintDeleted: false,
  sendingBlueprintReviewRequest: false,
  blueprintReviewRequestSent: false,
  blueprintManifestLoaded: false,
  blueprintManifestFailed: false,
  deletedRoutineTask: false,
  disabledRoutineTask: false,
  disablingRoutineTask: false,
  deletingRoutineTask: false,
  blueprintParameters: null,
  blueprintStacksFailed: false,
  permissionsLoaded: false,
  bpPermissionsFailed: false,
  codeview: {
    isFetchingFile: false,
    folders: {},
  },
  isFetchingInfraSetupDetail: false,
  isFetchingInfraSetupDetailFailed: false,
  infraSetupDone: false,
  isFetchedInfraSetUpDetail: false,
  fetchingInfraSetupFailureMessage: "",
};

const blueprints = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_BLUEPRINT_PARAMETERS:
      return {
        ...state,
        blueprintParameters: action.payload,
      };
    case GET_ALL_BLUEPRINTS_REQUEST:
      return {
        ...state,
        isBlueprintsLoading: true,
      };
    case GET_ALL_BLUEPRINTS_SUCCESS:
      return {
        ...state,
        isBlueprintsLoading: false,
        blueprintsLoaded: true,
        blueprintList: action.payload,
      };
    case GET_ALL_BLUEPRINTS_FAILURE:
      return {
        ...state,
        isBlueprintsLoading: false,
        blueprintsLoaded: false,
        blueprintsFailed: true,
      };
    case GET_ALL_BLUEPRINTS_MASTER_REQUEST:
      return {
        ...state,
        isBlueprintsMasterLoading: true,
      };
    case GET_ALL_BLUEPRINTS_MASTER_SUCCESS:
      return {
        ...state,
        isBlueprintsMasterLoading: false,
        blueprintsMasterLoaded: true,
        blueprintMasterList: action.payload,
        blueprintsMasterFailed: false,
      };
    case GET_ALL_BLUEPRINTS_MASTER_FAILURE:
      return {
        ...state,
        isBlueprintsMasterLoading: false,
        blueprintsMasterLoaded: false,
        blueprintsMasterFailed: true,
      };
    case GET_BLUEPRINT_TEMPLATE_REQUEST:
      return {
        ...state,
        isTemplateLoading: true,
      };
    case GET_BLUEPRINT_TEMPLATE_SUCCESS:
      return {
        ...state,
        isTemplateLoading: false,
        blueprintTemplate: action.payload,
      };
    case GET_BLUEPRINT_TEMPLATE_FAILURE:
      return {
        ...state,
        isTemplateLoading: false,
      };
    case GET_BLUEPRINT_MANIFEST_REQUEST:
      return {
        ...state,
        isManifestLoading: true,
        blueprintManifestLoaded: initialState.blueprintManifestLoaded,
        blueprintManifestFailed: initialState.blueprintManifestFailed,
      };
    case GET_BLUEPRINT_MANIFEST_SUCCESS:
      return {
        ...state,
        isManifestLoading: false,
        blueprintManifest: action.payload,
        blueprintManifestLoaded: true,
      };
    case GET_BLUEPRINT_MANIFEST_FAILURE:
      return {
        ...state,
        isManifestLoading: false,
        blueprintManifestLoaded: true,
        blueprintManifestFailed: true,
      };
    case GET_BLUEPRINT_STACKS_REQUEST:
      return {
        ...state,
        isBlueprintStacksLoading: true,
        isBlueprintStacksLoaded: initialState.isBlueprintStacksLoaded,
        isBlueprintStacksFailed: initialState.isBlueprintStacksFailed,
        blueprintStacks: initialState.blueprintStacks,
      };
    case GET_BLUEPRINT_STACKS_SUCCESS:
      return {
        ...state,
        isBlueprintStacksLoading: false,
        isBlueprintStacksLoaded: true,
        blueprintStacks: action.payload,
      };
    case GET_BLUEPRINT_STACKS_FAILURE:
      return {
        ...state,
        isBlueprintStacksLoading: false,
        isBlueprintStacksLoaded: false,
        blueprintStacksFailed: true,
      };
    case GET_BLUEPRINT_PERMISSIONS_REQUEST:
      return {
        ...state,
        isBlueprintRolePermissionsLoading: true,
      };
    case DISABLE_ROUTINE_TASK_REQUEST:
      return {
        ...state,
        disablingRoutineTask: true,
        disabledRoutineTask: false,
      };
    case DISABLE_ROUTINE_TASK_SUCCESS:
      return {
        ...state,
        disablingRoutineTask: false,
        disabledRoutineTask: true,
      };
    case DISABLE_ROUTINE_TASK_FAILURE:
      return {
        ...state,
        disablingRoutineTask: false,
        disabledRoutineTask: false,
      };
    case DELETE_ROUTINE_TASK_REQUEST:
      return {
        ...state,
        deletingRoutineTask: true,
        deletedRoutineTask: false,
      };
    case DELETE_ROUTINE_TASK_SUCCESS:
      return {
        ...state,
        deletingRoutineTask: false,
        deletedRoutineTask: true,
      };
    case DELETE_ROUTINE_TASK_FAILURE:
      return {
        ...state,
        deletingRoutineTask: false,
        deletedRoutineTask: false,
      };
    case GET_BLUEPRINT_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isBlueprintRolePermissionsLoading: false,
        blueprintRolePermissions: action.payload,
      };
    case GET_BLUEPRINT_PERMISSIONS_FAILURE:
      return {
        ...state,
        isBlueprintRolePermissionsLoading: false,
        blueprintRolePermissions: action.payload,
      };
    case SET_SELECTED_BLUEPRINT_FOR_LAUNCH:
      return {
        ...state,
        selectedBlueprintForLaunch: action.payload,
      };
    case GET_BLUEPRINT_DIAGRAM_URL:
      return {
        ...state,
        diagramUrl: action.payload,
      };
    case CLEAR_BLUEPRINT_DATA:
      return {
        ...state,
        ...initialState,
        selectedBlueprintForLaunch: state.selectedBlueprintForLaunch,
        blueprintList: state.blueprintList,
        blueprintMasterList: state.blueprintMasterList,
        blueprintSearchKey: state.blueprintSearchKey,
      };
    case SAVE_BLUEPRINT_TYPE:
      return {
        ...state,
        savedBlueprintType: action.payload,
      };
    case UPDATE_BLUEPRINT_SEARCH_KEY:
      return {
        ...state,
        blueprintSearchKey: action.payload,
      };
    case GET_ALL_BLUEPRINT_STACKS_REQUEST:
      return {
        ...state,
        allStacksLoaded: initialState.allStacksLoaded,
        allStacksLoading: true,
        allStacksFailed: initialState.allStacksFailed,
        allBlueprintStacks: initialState.allBlueprintStacks,
      };
    case GET_ALL_BLUEPRINT_STACKS_SUCCESS:
      return {
        ...state,
        allStacksLoading: false,
        allStacksLoaded: true,
        allBlueprintStacks: action.payload,
      };
    case GET_ALL_BLUEPRINT_STACKS_FAILURE:
      return {
        ...state,
        allStacksLoaded: false,
        allStacksLoading: false,
        allStacksFailed: true,
      };
    case BLUEPRINT_PERMISSION_CHECK_REQUEST:
      return {
        ...state,
        isBlueprintPermissionsLoading: true,
        blueprintHasPermissions: initialState.blueprintHasPermissions,
        permissionsLoaded: false,
        bpPermissionsFailed: initialState.bpPermissionsFailed,
      };
    case BLUEPRINT_PERMISSION_CHECK_SUCCESS:
      return {
        ...state,
        isBlueprintPermissionsLoading: false,
        blueprintHasPermissions: action.payload,
        permissionsLoaded: true,
      };
    case BLUEPRINT_PERMISSION_CHECK_FAILURE:
      return {
        ...state,
        isBlueprintPermissionsLoading: false,
        blueprintHasPermissions: false,
        blueprintPermissionsFailed: action.payload,
        bpPermissionsFailed: true,
      };
    case BLUEPRINT_ACTIONS_REQUEST:
      return {
        ...state,
        blueprintActionsLoading: true,
      };
    case BLUEPRINT_ACTIONS_SUCCESS:
      return {
        ...state,
        blueprintActionsLoading: false,
        blueprintActions: action.payload,
      };
    case BLUEPRINT_ACTIONS_FAILURE:
      return {
        ...state,
        blueprintActionsLoading: false,
        blueprintActions: action.payload,
      };
    case DELETE_BLUEPRINT_REQUEST:
      return {
        ...state,
        deletingBlueprint: true,
        blueprintDeleted: false,
      };
    case DELETE_BLUEPRINT_SUCCESS:
      return {
        ...state,
        deletingBlueprint: false,
        blueprintDeleted: true,
      };
    case DELETE_BLUEPRINT_FAILURE:
      return {
        ...state,
        deletingBlueprint: false,
        blueprintDeleted: false,
      };
    case REVIEW_BLUEPRINT_REQUEST:
      return {
        ...state,
        sendingBlueprintReviewRequest: true,
        blueprintReviewRequestSent: false,
      };
    case REVIEW_BLUEPRINT_SUCCESS:
      return {
        ...state,
        sendingBlueprintReviewRequest: false,
        blueprintReviewRequestSent: true,
      };
    case REVIEW_BLUEPRINT_FAILURE:
      return {
        ...state,
        sendingBlueprintReviewRequest: false,
        blueprintReviewRequestSent: false,
      };
    case RESET_BLUEPRINT_LIST:
      return {
        ...state,
        blueprintList: initialState.blueprintList,
      };

    // code view

    case TF_CODE_FILE_FETCHING:
      return {
        ...state,
        codeview: {
          ...state.codeview,
          isFetchingFile: true,
        },
      };

    case TF_CODE_FILE_FETCHED:
      return {
        ...state,
        codeview: {
          isFetchingFile: false,
          folders: action.payload,
        },
      };

    case TF_CODE_FILE_FETCH_FAILED:
      return {
        ...state,
        codeview: {
          ...state.codeview,
          isFetchingFile: false,
        },
      };

    case CLEAR_BLUEPRINT_MANIFEST:
      return {
        ...state,
        isManifestLoading: initialState.isManifestLoading,
        blueprintManifest: initialState.blueprintManifest,
        blueprintManifestLoaded: initialState.blueprintManifestLoaded,
        blueprintManifestFailed: initialState.blueprintManifestFailed,
      };

    case GET_INFRA_SETUP_INFO_REQUEST:
      return {
        ...state,
        isFetchingInfraSetupDetail: true,
        isFetchingInfraSetupDetailFailed: false,
      };

    case GET_INFRA_SETUP_INFO_SUCCESS:
      return {
        ...state,
        isFetchingInfraSetupDetail: false,
        isFetchingInfraSetupDetailFailed: false,
        infraSetupDone: action.payload,
        isFetchedInfraSetUpDetail: true,
      };

    case GET_INFRA_SETUP_INFO_FAILURE:
      return {
        ...state,
        isFetchingInfraSetupDetail: false,
        isFetchingInfraSetupDetailFailed: true,
        isFetchedInfraSetUpDetail: true,
        fetchingInfraSetupFailureMessage: action.payload,
      };

    case CLEAR_INFRA_SETUP_INFO:
      return {
        ...state,
        isFetchingInfraSetupDetail: initialState.isFetchingInfraSetupDetail,
        isFetchingInfraSetupDetailFailed:
          initialState.isFetchingInfraSetupDetailFailed,
        infraSetupDone: initialState.infraSetupDone,
        isFetchedInfraSetUpDetail: initialState.isFetchedInfraSetUpDetail,
        fetchingInfraSetupFailureMessage:
          initialState.fetchingInfraSetupFailureMessage,
      };
    default:
      return {
        ...state,
      };
  }
};

export default blueprints;

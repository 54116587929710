import {
  RESET_TOKEN_FAILURE,
  RESET_TOKEN_SUCCESS,
  RESET_TOKEN_REQUEST,
} from "../actionTypes/token";

export function requestToken() {
  return {
    type: RESET_TOKEN_REQUEST,
  };
}

export function requestTokenSuccess() {
  return {
    type: RESET_TOKEN_SUCCESS,
  };
}

export function requestTokenFailure() {
  return {
    type: RESET_TOKEN_FAILURE,
  };
}

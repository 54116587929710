import { createApi } from "@reduxjs/toolkit/query/react";
import {
  FINDING_SUMMARY,
  WAR_ASSESSMENT_WORKLOAD_BASE_URL,
} from "../../../config/service";
import { axiosBaseQuery } from "../../../config/customBaseQuery";

export const findingSummaryApi = createApi({
  reducerPath: "assessmentFindingSummary",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getAssessmentFindingSummary: builder.query({
      query: ({ workloadId, param }) => {
        let params = encodeURI(JSON.stringify(param));
        return {
          url: `${WAR_ASSESSMENT_WORKLOAD_BASE_URL}${workloadId}${FINDING_SUMMARY}?Filters=${params}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetAssessmentFindingSummaryQuery } = findingSummaryApi;

export const GET_ALL_BLUEPRINTS_REQUEST = "GET_ALL_BLUEPRINTS_REQUEST";
export const GET_ALL_BLUEPRINTS_SUCCESS = "GET_ALL_BLUEPRINTS_SUCCESS";
export const GET_ALL_BLUEPRINTS_FAILURE = "GET_ALL_BLUEPRINTS_FAILURE";
export const GET_ALL_BLUEPRINTS_MASTER_REQUEST =
  "GET_ALL_BLUEPRINTS_MASTER_REQUEST";
export const GET_ALL_BLUEPRINTS_MASTER_SUCCESS =
  "GET_ALL_BLUEPRINTS_MASTER_SUCCESS";
export const GET_ALL_BLUEPRINTS_MASTER_FAILURE =
  "GET_ALL_BLUEPRINTS_MASTER_FAILURE";
export const SET_SELECTED_BLUEPRINT_FOR_LAUNCH =
  "SET_SELECTED_BLUEPRINT_FOR_LAUNCH";
export const GET_BLUEPRINT_TEMPLATE_REQUEST = "GET_BLUEPRINT_TEMPLATE_REQUEST";
export const GET_BLUEPRINT_TEMPLATE_SUCCESS = "GET_BLUEPRINT_TEMPLATE_SUCCESS";
export const GET_BLUEPRINT_TEMPLATE_FAILURE = "GET_BLUEPRINT_TEMPLATE_FAILURE";
export const GET_BLUEPRINT_MANIFEST_REQUEST = "GET_BLUEPRINT_MANIFEST_REQUEST";
export const GET_BLUEPRINT_MANIFEST_SUCCESS = "GET_BLUEPRINT_MANIFEST_SUCCESS";
export const GET_BLUEPRINT_MANIFEST_FAILURE = "GET_BLUEPRINT_MANIFEST_FAILURE";
export const GET_BLUEPRINT_STACKS_REQUEST = "GET_BLUEPRINT_STACKS_REQUEST";
export const GET_BLUEPRINT_STACKS_SUCCESS = "GET_BLUEPRINT_STACKS_SUCCESS";
export const GET_BLUEPRINT_STACKS_FAILURE = "GET_BLUEPRINT_STACKS_FAILURE";
export const GET_BLUEPRINT_PERMISSIONS_REQUEST =
  "GET_BLUEPRINT_PERMISSIONS_REQUEST";
export const GET_BLUEPRINT_PERMISSIONS_SUCCESS =
  "GET_BLUEPRINT_PERMISSIONS_SUCCESS";
export const GET_BLUEPRINT_PERMISSIONS_FAILURE =
  "GET_BLUEPRINT_PERMISSIONS_FAILURE";
export const GET_BLUEPRINT_DIAGRAM_URL = "GET_BLUEPRINT_DIAGRAM_URL";
export const CLEAR_BLUEPRINT_DATA = "CLEAR_BLUEPRINT_DATA";
export const SAVE_BLUEPRINT_TYPE = "SAVE_BLUEPRINT_TYPE";
export const UPDATE_BLUEPRINT_SEARCH_KEY = "UPDATE_BLUEPRINT_SEARCH_KEY";

export const GET_ALL_BLUEPRINT_STACKS_REQUEST =
  "GET_ALL_BLUEPRINT_STACKS_REQUEST";
export const GET_ALL_BLUEPRINT_STACKS_SUCCESS =
  "GET_ALL_BLUEPRINT_STACKS_SUCCESS";
export const GET_ALL_BLUEPRINT_STACKS_FAILURE =
  "GET_ALL_BLUEPRINT_STACKS_FAILURE";

export const BLUEPRINT_PERMISSION_CHECK_REQUEST =
  "BLUEPRINT_PERMISSION_CHECK_REQUEST";
export const BLUEPRINT_PERMISSION_CHECK_SUCCESS =
  "BLUEPRINT_PERMISSION_CHECK_SUCCESS";
export const BLUEPRINT_PERMISSION_CHECK_FAILURE =
  "BLUEPRINT_PERMISSION_CHECK_FAILURE";

export const BLUEPRINT_ACTIONS_REQUEST = "BLUEPRINT_ACTIONS_REQUEST";
export const BLUEPRINT_ACTIONS_SUCCESS = "BLUEPRINT_ACTIONS_SUCCESS";
export const BLUEPRINT_ACTIONS_FAILURE = "BLUEPRINT_ACTIONS_FAILURE";
export const DELETE_BLUEPRINT_REQUEST = "DELETE_BLUEPRINT_REQUEST";
export const DELETE_BLUEPRINT_SUCCESS = "DELETE_BLUEPRINT_SUCCESS";
export const DELETE_BLUEPRINT_FAILURE = "DELETE_BLUEPRINT_FAILURE";
export const REVIEW_BLUEPRINT_REQUEST = "REVIEW_BLUEPRINT_REQUEST";
export const REVIEW_BLUEPRINT_SUCCESS = "REVIEW_BLUEPRINT_SUCCESS";
export const REVIEW_BLUEPRINT_FAILURE = "REVIEW_BLUEPRINT_FAILURE";
export const DISABLE_ROUTINE_TASK_REQUEST = "DISABLE_ROUTINE_TASK_REQUEST";
export const DISABLE_ROUTINE_TASK_SUCCESS = "DISABLE_ROUTINE_TASK_SUCCESS";
export const DISABLE_ROUTINE_TASK_FAILURE = "DISABLE_ROUTINE_TASK_FAILURE";
export const DELETE_ROUTINE_TASK_REQUEST = "DELETE_ROUTINE_TASK_REQUEST";
export const DELETE_ROUTINE_TASK_SUCCESS = "DELETE_ROUTINE_TASK_SUCCESS";
export const DELETE_ROUTINE_TASK_FAILURE = "DELETE_ROUTINE_TASK_FAILURE";
export const SAVE_BLUEPRINT_PARAMETERS = "SAVE_BLUEPRINT_PARAMETERS";

export const RESET_BLUEPRINT_LIST = "RESET_BLUEPRINT_LIST";

export const GET_IMAGES_REQUEST = "GET_IMAGES_REQUEST";
export const GET_IMAGES_SUCCESS = "GET_IMAGES_SUCCESS";
export const GET_IMAGES_FAILURE = "GET_IMAGES_FAILURE";

export const COPY_IMAGE_REQUEST = "COPY_IMAGE_REQUEST";
export const COPY_IMAGE_SUCCESS = "COPY_IMAGE_SUCCESS";
export const COPY_IMAGE_FAILURE = "COPY_IMAGE_FAILURE";

export const SHARE_IMAGE_REQUEST = "SHARE_IMAGE_REQUEST";
export const SHARE_IMAGE_SUCCESS = "SHARE_IMAGE_SUCCESS";
export const SHARE_IMAGE_FAILURE = "SHARE_IMAGE_FAILURE";

export const SAVE_SELECTED_IMAGE = "SAVE_SELECTED_IMAGE";
export const SAVE_COPY_IMAGE_PAYLOAD = "SAVE_COPY_IMAGE_PAYLOAD";

export const SET_COPY_IMAGE_NAME = "SET_COPY_IMAGE_NAME";
export const SET_COPY_IMAGE_DESCRIPTION = "SET_COPY_IMAGE_DESCRIPTION";
export const SET_COPY_IMAGE_ENCRYPT_TARGET = "SET_COPY_IMAGE_ENCRYPT_TARGET";

export const CLEAR_IMAGE_DATA = "CLEAR_IMAGE_DATA";
export const RESET_IMAGE_FETCHED = "RESET_IMAGE_FETCHED";

export const GET_ALL_IMAGES_REQUEST = "GET_ALL_IMAGES_REQUEST";
export const GET_ALL_IMAGES_SUCCESS = "GET_ALL_IMAGES_SUCCESS";
export const GET_ALL_IMAGES_FAILURE = "GET_ALL_IMAGES_FAILURE";

export const DELETE_IMAGE_SNAPSHOT_REQUEST = "DELETE_IMAGE_SNAPSHOT_REQUEST";
export const DELETE_IMAGE_SNAPSHOT_SUCCESS = "DELETE_IMAGE_SNAPSHOT_SUCCESS";
export const DELETE_IMAGE_SNAPSHOT_FAILURE = "DELETE_IMAGE_SNAPSHOT_FAILURE";

export const SAVE_SELECTED_SNAPSHOTS_FOR_IMAGE =
  "SAVE_SELECTED_SNAPSHOTS_FOR_IMAGE";
export const RESET_SELECTED_SNAPSHOTS_FOR_IMAGE =
  "RESET_SELECTED_SNAPSHOTS_FOR_IMAGE";

export const SET_SHARE_IMAGE_TYPE = "SET_SHARE_IMAGE_TYPE";
export const SET_SELECTED_ACCOUNT_SHARE_IMAGE =
  "SET_SELECTED_ACCOUNT_SHARE_IMAGE";
export const SET_REMOVED_ACCOUNT = "SET_REMOVED_ACCOUNT";
export const SET_CREATE_VOLUME_PERMISSION = "SET_CREATE_VOLUME_PERMISSION";
export const SET_RESOURCE_ID = "SET_RESOURCE_ID";
export const CLEAR_SHARE_IMAGE_DATA = "CLEAR_SHARE_IMAGE_DATA";
export const SET_PREVIOUSLY_SELECTED_IMAGE = "SET_PREVIOUSLY_SELECTED_IMAGE";
export const SET_SHARE_IMAGE_STATE = "SET_SHARE_IMAGE_STATE";

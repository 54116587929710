import { v4 as uuidv4 } from "uuid";
import { serializeClientMessage } from "./serialize";
import { ConvertStringToByteArray } from "./serialize_utility";

/**
 * Build the handshake message
 * @param {*} TokenVaue
 */
export function getHandShakeMessage(TokenValue) {
  var handshakePayLoad = {
    MessageSchemaVersion: "1.0",
    RequestId: uuidv4(),
    TokenValue: TokenValue
  };
  var handShakeString = JSON.stringify(handshakePayLoad);
  // console.log("getHandShakeMessage", handShakeString);
  return handShakeString;
}

/***
 * Build the acknowledge message
 */
export function getAcknowledgementMessage({
  MessageId,
  MessageType,
  SequenceNumber
}) {
  const acknowledgeContent = {
    AcknowledgedMessageType: MessageType.trim(),
    AcknowledgedMessageId: MessageId,
    AcknowledgedMessageSequenceNumber: SequenceNumber,
    IsSequentialMessage: true
  };

  const acknowledgeContentString = JSON.stringify(acknowledgeContent);
  // console.log("acknowledgeContentString", acknowledgeContentString)
  const acknowledgeMessage = serializeClientMessage(
    SequenceNumber,
    "acknowledge",
    ConvertStringToByteArray(acknowledgeContentString),
    1
  );
  return acknowledgeMessage;
}

export function getColRowInput(cols, rows) {
  const inputContent = {
    rows: rows,
    cols: cols
  };
  const inputContentString = JSON.stringify(inputContent);
  const termInfo = serializeClientMessage(
    0,
    "input_stream_data",
    ConvertStringToByteArray(inputContentString),
    3
  );
  return termInfo;
}

/***
 * Get row info of the terminal
 */
export function getColRowInfo(terminal) {
  const rows = terminal.rows;
  const cols = terminal.cols;
  return getColRowInput(cols, rows);
}

/**
 * Build user input message
 * @param {*} SequenceNumber
 */
export function getUserIputMessage(sequenceNumber, data) {
  const inputContentString = data;
  const inputMessage = serializeClientMessage(
    sequenceNumber,
    "input_stream_data",
    ConvertStringToByteArray(inputContentString),
    1
  );
  return inputMessage;
}

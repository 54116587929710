import { combineReducers } from "redux";
import { createReducer } from "../../../utils/create-reducer";
import {
  TASK_OUTPUT_LOGS,
  TASK_JOB_DETAILS,
  PATCH_SCAN_DSS_TASK_REPORT
} from "../../../utils/action-names.json";

const taskOutputLogs = createReducer(TASK_OUTPUT_LOGS);
const taskJobDetails = createReducer(TASK_JOB_DETAILS);
const patchScanDssTaskReport = createReducer(PATCH_SCAN_DSS_TASK_REPORT);


export default combineReducers({
  taskOutputLogs,
  taskJobDetails,
  patchScanDssTaskReport,
});

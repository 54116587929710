import { ACCOUNT_TYPES } from "../../account/utils/account-constants";
import {
  ADD_RESOURCES_TO_DEPT_FAILED,
  ADD_RESOURCES_TO_DEPT_REQUEST,
  ADD_RESOURCES_TO_DEPT_SUCCESS,
  CLEAR_DEPARTMENT_DATA,
  CLEAR_DISCOVERY_SUMMARY,
  CLEAR_FIRST_RUN_DATA,
  CLEAR_SELECTED_DEPT,
  CONFIGURE_REGIONS_FAILED,
  CONFIGURE_REGIONS_REQUEST,
  CONFIGURE_REGIONS_SUCCESS,
  EDIT_ORGANIZATION_FAILED,
  EDIT_ORGANIZATION_REQUEST,
  EDIT_ORGANIZATION_SUCCESS,
  GET_ALL_DEPARTMENTS_FAILED,
  GET_ALL_DEPARTMENTS_REQUEST,
  GET_ALL_DEPARTMENTS_SUCCESS,
  GET_ALL_ORGANIZATIONS_FAILED,
  GET_ALL_ORGANIZATIONS_REQUEST,
  GET_ALL_ORGANIZATIONS_SUCCESS,
  GET_ALL_RESOURCES_FAILED,
  GET_ALL_RESOURCES_REQUEST,
  GET_ALL_RESOURCES_SUCCESS,
  GET_DISCOVERY_SUMMARY_FAILED,
  GET_DISCOVERY_SUMMARY_REQUEST,
  GET_DISCOVERY_SUMMARY_SUCCESS,
  OFFBOARD_MEMBER_ACCOUNT_FAILED,
  OFFBOARD_MEMBER_ACCOUNT_REQUEST,
  OFFBOARD_MEMBER_ACCOUNT_SUCCESS,
  ONBOARD_MEMBER_ACCOUNT_FAILED,
  ONBOARD_MEMBER_ACCOUNT_REQUEST,
  ONBOARD_MEMBER_ACCOUNT_SUCCESS,
  ORG_HIERARCHY_ERROR,
  ORG_HIERARCHY_REQUEST,
  ORG_HIERARCHY_SUCCESS,
  SAVE_DEPARTMENT_FAILED,
  SAVE_DEPARTMENT_REQUEST,
  SAVE_DEPARTMENT_SUCCESS,
  SAVE_ORGANIZATION_FAILED,
  SAVE_ORGANIZATION_REQUEST,
  SAVE_ORGANIZATION_SUCCESS,
  SET_ACCOUNT_TYPE,
  SET_DEPARTMENT_DESCRIPTION,
  SET_DEPARTMENT_NAME,
  SET_HIDE_CLOSE_BUTTON,
  SET_ORGANIZATION_DESCRIPTION,
  SET_ORGANIZATION_NAME,
  SET_SELECTED_CLOUD_PROVIDER,
  SET_SELECTED_DEPT,
  SET_SELECTED_ORG,
  UPDATE_DEPARTMENT_SEARCH_KEY,
  TOGGLE_VIEW_TYPE,
} from "../actionTypes/firstRun";

const initialState = {
  organizationName: "",
  organizationDescription: "",
  departmentName: "",
  departmentDescription: "",
  isSavingOrg: false,
  orgMessage: null,
  isFetchingDiscoveryDetails: false,
  discoveryDetails: null,
  fetchingDiscoveryDetailsFailed: false,
  isResourceLoading: false,
  resourceList: null,
  isOrganizationsLoading: false,
  organizationList: null,
  isSavingDept: false,
  savedDept: false,
  addDepartmentErrorMessage: null,
  isDepartmentsLoading: false,
  departmentList: null,
  addingResources: null,
  addingResourcesMsg: null,
  departmentsLoaded: false,
  deptsLoaded: false, // Duplicate copy
  loadingDepartmentsFailed: false,
  selectedDept: {
    ID: "",
    checked: false,
  },
  selectedOrgId: null,
  savedOrg: false,
  departmentSearchKey: "",
  configuringRegions: false,
  regionsConfigured: false,
  hideCloseButton: false,
  savingOrgFailed: false,
  savingDeptFailed: false,
  customerPreferences: null,
  isEditingOrg: false,
  orgEditMessage: null,
  editedOrg: false,
  editingOrgFailed: false,
  selectedCloudProvider: null,
  selectedAccountType: ACCOUNT_TYPES.STANDALONE,
  orgHierarchy: null,
  isOrgHierarchyLoading: false,
  isOnboardingMemberAccount: false,
  isOffboardingMemberAccount: false,
  viewType: "list",
  accOnboardInitiatedList: [],
};

const firstRun = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORGANIZATION_NAME:
      return {
        ...state,
        organizationName: action.payload,
      };
    case SET_ORGANIZATION_DESCRIPTION:
      return {
        ...state,
        organizationDescription: action.payload,
      };
    case SET_DEPARTMENT_NAME:
      return {
        ...state,
        departmentName: action.payload,
      };
    case SET_DEPARTMENT_DESCRIPTION:
      return {
        ...state,
        departmentDescription: action.payload,
      };
    case SAVE_ORGANIZATION_REQUEST:
      return {
        ...state,
        isSavingOrg: true,
        orgMessage: action.payload,
        savingOrgFailed: initialState.savingOrgFailed,
      };
    case SAVE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isSavingOrg: false,
        savedOrg: true,
        selectedOrgId: action.payload.ID,
        orgMessage: action.payload,
      };
    case SAVE_ORGANIZATION_FAILED:
      return {
        ...state,
        isSavingOrg: false,
        savedOrg: false,
        orgMessage: action.payload,
        savingOrgFailed: true,
      };
    case EDIT_ORGANIZATION_REQUEST:
      return {
        ...state,
        isEditingOrg: true,
        orgEditMessage: initialState.orgEditMessage,
        editedOrg: initialState.editedOrg,
        editingOrgFailed: initialState.savingOrgFailed,
      };
    case EDIT_ORGANIZATION_SUCCESS:
      return {
        ...state,
        isEditingOrg: false,
        editedOrg: true,
        orgEditMessage: action.payload,
      };
    case EDIT_ORGANIZATION_FAILED:
      return {
        ...state,
        isEditingOrg: false,
        editedOrg: false,
        orgEditMessage: action.payload,
        editingOrgFailed: true,
      };
    case GET_DISCOVERY_SUMMARY_REQUEST:
      return {
        ...state,
        isFetchingDiscoveryDetails: true,
        fetchingDiscoveryDetailsFailed:
          initialState.fetchingDiscoveryDetailsFailed,
      };
    case GET_DISCOVERY_SUMMARY_SUCCESS:
      return {
        ...state,
        isFetchingDiscoveryDetails: false,
        discoveryDetails: action.payload,
      };
    case GET_DISCOVERY_SUMMARY_FAILED:
      return {
        ...state,
        isFetchingDiscoveryDetails: false,
        fetchingDiscoveryDetailsFailed: true,
      };
    case GET_ALL_RESOURCES_REQUEST:
      return {
        ...state,
        isResourceLoading: true,
        resourceList: initialState.resourceList,
      };
    case GET_ALL_RESOURCES_SUCCESS:
      return {
        ...state,
        isResourceLoading: false,
        resourceList: action.payload,
      };
    case GET_ALL_RESOURCES_FAILED:
      return {
        ...state,
        isResourceLoading: false,
      };
    case GET_ALL_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        isOrganizationsLoading: true,
        organizationList: initialState.organizationList,
      };
    case GET_ALL_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizationList: action.payload,
        selectedOrganization: action.payload[0],
        isOrganizationsLoading: false,
        organizationLoaded: true,
        selectedOrgId:
          action.payload && action.payload.length > 0
            ? action.payload[0].ID
            : null,
      };
    case GET_ALL_ORGANIZATIONS_FAILED:
      return {
        ...state,
        isOrganizationsLoading: false,
      };
    case SAVE_DEPARTMENT_REQUEST:
      return {
        ...state,
        isSavingDept: true,
        addDepartmentErrorMessage: initialState.addDepartmentErrorMessage,
        savingDeptFailed: initialState.savingDeptFailed,
        savedDept: initialState.savedDept,
      };
    case SAVE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isSavingDept: false,
        savedDept: true,
        addDepartmentErrorMessage: initialState.addDepartmentErrorMessage,
        departmentName: initialState.departmentName,
        departmentDescription: initialState.departmentDescription,
      };
    case SAVE_DEPARTMENT_FAILED:
      return {
        ...state,
        isSavingDept: false,
        savedDept: false,
        addDepartmentErrorMessage: action.payload,
        savingDeptFailed: true,
      };
    case GET_ALL_DEPARTMENTS_REQUEST:
      return {
        ...state,
        isDepartmentsLoading: true,
        departmentList: initialState.departmentList,
        deptsLoaded: initialState.deptsLoaded,
        loadingDepartmentsFailed: initialState.loadingDepartmentsFailed,
      };
    case GET_ALL_DEPARTMENTS_SUCCESS:
      return {
        ...state,
        departmentList: action.payload,
        departmentsLoaded: true,
        deptsLoaded: true,
        isDepartmentsLoading: false,
      };
    case GET_ALL_DEPARTMENTS_FAILED:
      return {
        ...state,
        departmentList: action.payload,
        isDepartmentsLoading: false,
        loadingDepartmentsFailed: true,
      };
    case ADD_RESOURCES_TO_DEPT_REQUEST:
      return {
        ...state,
        addingResources: true,
        addingResourcesMsg: initialState.addingResourcesMsg,
      };
    case ADD_RESOURCES_TO_DEPT_SUCCESS:
      return {
        ...state,
        addingResources: false,
        addingResourcesMsg: action.payload,
      };
    case ADD_RESOURCES_TO_DEPT_FAILED:
      return {
        ...state,
        addingResources: false,
        addingResourcesMsg: action.payload,
      };
    case SET_SELECTED_DEPT:
      return {
        ...state,
        selectedDept: action.payload,
      };
    case CLEAR_SELECTED_DEPT:
      return {
        ...state,
        selectedDept: initialState.selectedDept,
      };
    case SET_SELECTED_ORG:
      return {
        selectedOrgId: action.payload,
      };
    case CLEAR_DEPARTMENT_DATA:
      return {
        ...state,
        savedDept: false,
        addDepartmentErrorMessage: initialState.addDepartmentErrorMessage,
      };
    case CLEAR_FIRST_RUN_DATA:
      return {
        ...state,
        isFetchingDiscoveryDetails: initialState.isFetchingDiscoveryDetails,
        selectedDept: initialState.selectedDept,
        addDepartmentErrorMessage: initialState.addDepartmentErrorMessage,
        discoveryDetails: initialState.discoveryDetails,
        hideCloseButton: initialState.hideCloseButton,
        regionsConfigured: initialState.regionsConfigured,
        selectedCloudProvider: initialState.selectedCloudProvider,
        selectedAccountType: initialState.selectedAccountType,
        orgHierarchy: initialState.orgHierarchy,
        isOrgHierarchyLoading: initialState.isOrgHierarchyLoading,
        FREDataCleared: true,
      };
    case UPDATE_DEPARTMENT_SEARCH_KEY:
      return {
        ...state,
        departmentSearchKey: action.payload,
      };
    case CONFIGURE_REGIONS_REQUEST:
      return {
        ...state,
        configuringRegions: true,
        regionsConfigured: false,
      };
    case CONFIGURE_REGIONS_SUCCESS:
      return {
        ...state,
        configuringRegions: false,
        regionsConfigured: true,
      };
    case CONFIGURE_REGIONS_FAILED:
      return {
        ...state,
        configuringRegions: false,
        regionsConfigured: false,
      };
    case SET_HIDE_CLOSE_BUTTON:
      return {
        ...state,
        hideCloseButton: action.payload,
      };
    case SET_SELECTED_CLOUD_PROVIDER:
      return {
        ...state,
        selectedCloudProvider: action.payload,
      };
    case SET_ACCOUNT_TYPE: {
      return {
        ...state,
        selectedAccountType: action.payload,
      };
    }
    case CLEAR_DISCOVERY_SUMMARY:
      return {
        ...state,
        discoveryDetails: initialState.discoveryDetails,
      };
    case ORG_HIERARCHY_REQUEST: {
      return {
        ...state,
        isOrgHierarchyLoading: true,
      };
    }
    case ORG_HIERARCHY_SUCCESS: {
      return {
        ...state,
        isOrgHierarchyLoading: false,
        orgHierarchy: action.payload,
      };
    }
    case ORG_HIERARCHY_ERROR: {
      return {
        ...state,
        isOrgHierarchyLoading: false,
      };
    }
    case ONBOARD_MEMBER_ACCOUNT_REQUEST: {
      return {
        ...state,
        isOnboardingMemberAccount: true,
      };
    }
    case ONBOARD_MEMBER_ACCOUNT_SUCCESS: {
      return {
        ...state,
        isOnboardingMemberAccount: false,
        accOnboardInitiatedList: [
          ...state.accOnboardInitiatedList,
          action.payload,
        ],
      };
    }
    case ONBOARD_MEMBER_ACCOUNT_FAILED: {
      return {
        ...state,
        isOnboardingMemberAccount: false,
      };
    }
    case OFFBOARD_MEMBER_ACCOUNT_REQUEST: {
      return {
        ...state,
        isOffboardingMemberAccount: true,
      };
    }
    case OFFBOARD_MEMBER_ACCOUNT_SUCCESS: {
      return {
        ...state,
        isOffboardingMemberAccount: false,
      };
    }
    case OFFBOARD_MEMBER_ACCOUNT_FAILED: {
      return {
        ...state,
        isOffboardingMemberAccount: false,
      };
    }
    case TOGGLE_VIEW_TYPE: {
      return {
        ...state,
        viewType: action.viewType,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

export default firstRun;

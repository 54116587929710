export const SAVE_CREATE_USER_USERNAME = "SAVE_CREATE_USER_USERNAME";
export const SAVE_CREATE_USER_PASSWORD = "SAVE_CREATE_USER_PASSWORD";
export const SAVE_CREATE_USER_DESCRIPTION = "SAVE_CREATE_USER_DESCRIPTION";
export const SAVE_CREATE_USER_EMAIL = "SAVE_CREATE_USER_EMAIL";
export const SAVE_CREATE_USER_SELECTED_ROLES =
  "SAVE_CREATE_USER_SELECTED_ROLES";
export const SAVE_CREATE_USER_SELECTED_CHARACTER =
  "SAVE_CREATE_USER_SELECTED_CHARACTER";
export const SAVE_CREATE_USER_SELECTED_DEVICE =
  "SAVE_CREATE_USER_SELECTED_DEVICE";
export const SAVE_CREATE_USER_SELECTED_OPERATOR =
  "SAVE_CREATE_USER_SELECTED_OPERATOR";
export const SAVE_CREATE_USER_DEVICE_PRODUCT_ID =
  "SAVE_CREATE_USER_DEVICE_PRODUCT_ID";
export const SAVE_CREATE_USER_DEVICE_PRODUCT_NUMBER =
  "SAVE_CREATE_USER_DEVICE_PRODUCT_NUMBER";
export const SAVE_CREATE_USER_CHARACTER_NAME =
  "SAVE_CREATE_USER_CHARACTER_NAME";
export const SAVE_CREATE_USER_CHARACTER_DESCRIPTION =
  "SAVE_CREATE_USER_CHARACTER_DESCRIPTION";
export const SAVE_CREATE_USER_CHARACTER = "SAVE_CREATE_USER_CHARACTER";
export const CREATE_ADMIN_USER_REQUEST = "CREATE_ADMIN_USER_REQUEST";
export const CREATE_ADMIN_USER_SUCCESS = "CREATE_ADMIN_USER_SUCCESS";
export const CREATE_ADMIN_USER_FAILURE = "CREATE_ADMIN_USER_FAILURE";
export const EDIT_ADMIN_USER_REQUEST = "EDIT_ADMIN_USER_REQUEST";
export const EDIT_ADMIN_USER_SUCCESS = "EDIT_ADMIN_USER_SUCCESS";
export const EDIT_ADMIN_USER_FAILURE = "EDIT_ADMIN_USER_FAILURE";
export const CLEAR_CREATE_ADMIN_USER_DATA = "CLEAR_CREATE_ADMIN_USER_DATA";
export const CLEAR_CREATE_CHARACTER_DATA = "CLEAR_CREATE_CHARACTER_DATA";
export const SAVE_CREATE_USER_ADDED_ROLES = "SAVE_CREATE_USER_ADDED_ROLES";
export const CLEAR_CREATE_ADMIN_USER_ROLES = "CLEAR_CREATE_ADMIN_USER_ROLES";
export const SAVE_ADMIN_USER_FOR_EDITING = "SAVE_ADMIN_USER_FOR_EDITING";
export const IS_USER_ROLE_MAPPING_DIRTY = "IS_USER_ROLE_MAPPING_DIRTY";
export const SAVE_CREATE_USER_CALENDLY = "SAVE_CREATE_USER_CALENDLY";

// organization access actins
export const ADD_ORG_TO_ACCESS_LIST = "ADD_ORG_TO_ACCESS_LIST";
export const CLEAR_ORG_ACCESS_LIST = "CLEAR_ORG_ACCESS_LIST";
export const ADD_ORG_ACCESS_LIST_LOADING = "ADD_ORG_ACCESS_LIST_LOADING";
export const ADD_ORG_ACCESS_LIST_LOADED = "ADD_ORG_ACCESS_LIST_LOADED";
export const ADD_ORG_REMOVE_ACCESS_LIST = "ADD_ORG_REMOVE_ACCESS_LIST";

import React from 'react';
import i18n from "../../../config/locale/i18n";
import {
  GLOBAL_CONFIG_ACCOUNTS,
  GLOBAL_CONFIG_AZURE_SUBSCRIPITIONS,
} from "../../../reducers/global-config";
import { getDeptName, getRegionName } from "../../../utils/account-region";
import {
  ACTIVE,
  CLOUDPROVIDERS,
  CONNECTED_STATUS,
  FAILED_TO_CONNECT,
  INITIATED,
  PENDING,
  DISCONNECTION_IN_PROGRESS,
  DISCONNECTION_FAILED,
  SETUP_IN_PROGRESS,
  TEMPORARILY_DISABLED,
  UPGRADE_REQUIRED,
  UPGRADE_STARTED,
  CONNECTION_LOST,
  READY_TO_DISCONNECT
} from "../../../utils/app-constants.json";
import { isArrayWithLength } from "../../../utils/array-methods";
import { humanizedDateFormatWithOrdinal } from "../../../utils/convert-date";
import { showErrorNotification } from "../../../utils/error-notification";
import { getSelectedOrgs } from "../../../utils/org-params";
import resourceTypeCodes from "../../../utils/resourceTypeCodes.json";
import {
  GET_ACCOUNTS_FAILURE,
  GET_ACCOUNTS_REQUEST,
  GET_ACCOUNTS_SUCCESS,
  GET_AZURE_SUBSCRIPTIONS_FAILURE,
  GET_AZURE_SUBSCRIPTIONS_REQUEST,
  GET_AZURE_SUBSCRIPTIONS_SUCCESS,
  GET_DEPT_ACCOUNTS_FAILURE,
  GET_DEPT_ACCOUNTS_REQUEST,
  GET_DEPT_ACCOUNTS_SUCCESS,
  GET_STANDBY_ACCOUNTS_FAILURE,
  GET_STANDBY_ACCOUNTS_REQUEST,
  GET_STANDBY_ACCOUNTS_SUCCESS,
} from "../actionTypes/accounts";
import { getAllAccountsApi } from "../services/accounts";
import { FatUpArrowIcon, ProgressCheckIcon } from '../../../images/files';

const requestGetAccounts = (dept) => (dispatch) => {
  if (dept) {
    dispatch({
      type: GET_DEPT_ACCOUNTS_REQUEST,
    });
  } else {
    dispatch({
      type: GET_ACCOUNTS_REQUEST,
    });
  }
};

const receiveGetAccounts = (payload, dept) => (dispatch) => {
  if (dept) {
    dispatch({
      type: GET_DEPT_ACCOUNTS_SUCCESS,
      payload,
    });
  } else {
    dispatch({
      type: GET_ACCOUNTS_SUCCESS,
      payload,
    });
  }
};

export const filterUserData = (allAccounts, isDepartmentView) => (dispatch) => {
  dispatch({
    type: GLOBAL_CONFIG_ACCOUNTS,
    payload: allAccounts,
    isDepartmentView: isDepartmentView,
  });
};

const getAccountsError = (payload, dept) => (dispatch) => {
  if (dept) {
    dispatch({
      type: GET_DEPT_ACCOUNTS_FAILURE,
      payload,
    });
  } else {
    dispatch({
      type: GET_ACCOUNTS_FAILURE,
      payload,
    });
  }
};

export const getAccounts =
  (deptId, isDepartmentView, isFirstRunView) => (dispatch, getState) => {
    const { globalConfig } = getState();
    let filters = getSelectedOrgs(globalConfig.filteredUserData);
    if (deptId) {
      Array.isArray(deptId)
        ? (filters.Depts = [...deptId])
        : (filters.Depts = [deptId]);
    }

    filters.Detail = true;
    filters.ResourceType = resourceTypeCodes.CUSTOMER_ACCOUNT;
    if (isFirstRunView) {
      filters.IsFirstRunView = true;
    }
    let params = `Filters=${encodeURI(JSON.stringify(filters))}&CloudProvider=${
      CLOUDPROVIDERS.AWS
    }`;
    dispatch(requestGetAccounts(deptId));
    getAllAccountsApi(params)
      .then((data) => {
        const accounts = preProcessAccounts(data, globalConfig);
        dispatch(receiveGetAccounts(accounts, deptId));
        dispatch(filterUserData(accounts, isDepartmentView));
      })
      .catch((err) => {
        const error =
          err && err.message
            ? err.message
            : i18n.t("addAccount:failedToFetchAccountsList");
        const message = showErrorNotification(dispatch, JSON.stringify(error));
        dispatch(getAccountsError(message, deptId));
      });
  };

export const requestAzureSubscriptions = () => (dispatch) => {
  dispatch({
    type: GET_AZURE_SUBSCRIPTIONS_REQUEST,
  });
};

export const receivedAzureSubscriptions = (payload) => (dispatch) => {
  dispatch({
    type: GET_AZURE_SUBSCRIPTIONS_SUCCESS,
    payload,
  });
};

export const requestAzureSubscriptionsError = (payload) => (dispatch) => {
  dispatch({
    type: GET_AZURE_SUBSCRIPTIONS_FAILURE,
    payload,
  });
};

export const setGlobalAzureSubscriptions = (subscriptions) => (dispatch) => {
  dispatch({
    type: GLOBAL_CONFIG_AZURE_SUBSCRIPITIONS,
    payload: subscriptions,
  });
};
export const getAzureSubscriptions = () => (dispatch, getState) => {
  const { globalConfig } = getState();
  var filters = getSelectedOrgs(globalConfig.filteredUserData);

  filters.Detail = true;
  filters.ResourceType = resourceTypeCodes.CUSTOMER_ACCOUNT;

  let params = `Filters=${encodeURI(JSON.stringify(filters))}&CloudProvider=${
    CLOUDPROVIDERS.AZURE
  }`;
  dispatch(requestAzureSubscriptions());
  getAllAccountsApi(params)
    .then((data) => {
      let subscriptions = preProcessAzureSubscriptions(data);
      dispatch(receivedAzureSubscriptions(subscriptions));
      dispatch(setGlobalAzureSubscriptions(subscriptions));
    })
    .catch((err) => {
      const error = err ? err.message : "";
      const message = showErrorNotification(dispatch, error);
      dispatch(requestAzureSubscriptionsError(message));
    });
};

export const preProcessAccounts = (accounts, globalConfig) => {
  const _accounts = [];
  accounts.forEach((account) => {
    if (account.AccountNumber) {
      const departments =
        isArrayWithLength(account.Departments) &&
        account.Departments.map((deptId) => {
          return {
            deptName: getDeptName(
              deptId,
              globalConfig && globalConfig.departments
            ),
            deptId: deptId,
          };
        });
      _accounts.push({
        accountName: account.AccountName,
        accountNumber: account.AccountNumber,
        departmentId: account.hasOwnProperty("DepartmentId")
          ? account.DepartmentId
          : null,
        Departments: account.Departments,
        customerAccountId: account.CustomerAccountId,
        accountStatus: account.AccountStatus,
        departments,
        departmentName: isArrayWithLength(departments)
          ? departments.map((dept) => dept.deptName).join(", ")
          : "-",
        discoveryStatus: account.DiscoveryStatus,
        isDeleted: account.IsDeleted,
        isDisabled: account.IsDisabled,
        isSelected: false,
        regionDiscovered: account.RegionDiscovered,
        regionSummary: account.RegionSummary
          ? account.RegionSummary.Summary
          : null,
        regionsConfigured: account.RegionSummary
          ? account.RegionSummary.RegionsConfigured
          : null,
        regionList: account.RegionSummary
          ? account.RegionSummary.Regions
          : null,
        //TODO: upgrade regionlist to add names and remove this key
        regions:
          account.RegionSummary &&
          isArrayWithLength(account.RegionSummary.Regions) &&
          account.RegionSummary.Regions.filter(
            (region) => region.Status === CONNECTED_STATUS
          ).map((region) => {
            return {
              regionCode: region.RegionCode,
              regionName: getRegionName(
                region.RegionCode,
                globalConfig && globalConfig.regions
              ),
            };
          }),
        resourceId: account.ResourceId,
        isConnected: account.AccountStatus === CONNECTED_STATUS,
        hideActions: account.AccountStatus !== CONNECTED_STATUS,
        upgradeRequired: account.BuildUpgradeRequired,
        upgradeMeta: account.AccountUpgradeMeta,
        desiredAccountStateInfo: account.DesiredAccountStateInfo,
        adminPrivilege: account.AdminPrivilege,
        adminUpgradeRequired: account.AdminUpgradeRequired,
        offBoardingLink: account.OffBoardingLink,
        createdOn: humanizedDateFormatWithOrdinal(account.CreatedOn),
        ...account,
      });
    }
  });
  return _accounts;
};

export const preProcessAccountStatus = (status) => {
  let item = {
    key: "",
    value: "",
    status,
  };
  switch (status) {
    case INITIATED:
      item.key = "initiated";
      item.value = i18n.t("common:initiated");
      break;
    case ACTIVE:
      item.key = "active";
      item.value = i18n.t("common:active");
      break;
    case CONNECTED_STATUS:
      item.key = "connected";
      item.value = i18n.t("common:connected");
      break;
    case PENDING:
      item.key = "pending";
      item.value = i18n.t("common:pending");
      break;
    case SETUP_IN_PROGRESS:
      item.key = "pending";
      item.value = i18n.t("common:setUpInProgress");
      break;
    case TEMPORARILY_DISABLED:
      item.key = "disabled";
      item.value = i18n.t("common:temporarilyDisabled");
      break;
    case DISCONNECTION_IN_PROGRESS:
      item.key = "removal-progress";
      item.value = i18n.t("common:disconnectionInProgress");
      break;
    case DISCONNECTION_FAILED:
      item.key = "error";
      item.value = i18n.t("common:disconnectionFailed");
      break;
    case CONNECTION_LOST:
      item.key = "error";
      item.value = i18n.t("common:connectionLost");
      break;
    case UPGRADE_REQUIRED:
      item.key = "upgrade-required";
      item.value = i18n.t("common:upgradeRequired");
      break;
    case UPGRADE_STARTED:
      item.key = "upgrade-in-progress";
      item.value = i18n.t("common:upgradeInProgress");
      break;
    case READY_TO_DISCONNECT:
        item.key = "ready-to-disconnect";
        item.value = i18n.t("common:readyToDisconnect");
      break;
    default:
      item.key = "unknown";
      item.value = status;
      break;
  }
  return item;
};

export const getAccountDetailByStatus = (status)=>{
  switch (status) {
    case INITIATED:
      return {
        title:i18n.t("common:initiated"),
        icon:<ProgressCheckIcon fill="#EAB61A" width={12} height={12}  />
      }
      case READY_TO_DISCONNECT:
        return {
          title:i18n.t("common:readyToDisconnect"),
          icon: <i className="circle account-status connected" />
        }
     
    case ACTIVE:
      return {
        title:i18n.t("common:active"),
        icon: <i className="circle account-status connected" />
      }
    case CONNECTED_STATUS:
      return {
        title:i18n.t("common:connected"),
        icon: <i className="circle account-status connected" />
      }

    case PENDING:
      return {
        title:i18n.t("common:pending"),
        icon: <ProgressCheckIcon fill="#EAB61A" width={12} height={12}  />
      }
      
    case SETUP_IN_PROGRESS:
      return {
        title:i18n.t("common:setUpInProgress"),
        icon: <i className="circle account-status connected" />
      }

    case TEMPORARILY_DISABLED:
      return {
        title:i18n.t("common:temporarilyDisabled"),
        icon: <i className="circle account-status" />
      }
    case DISCONNECTION_IN_PROGRESS:
      return {
        title:i18n.t("common:disconnectionInProgress"),
        icon: <ProgressCheckIcon fill="#EAB61A" width={12} height={12}  />
      }
    case DISCONNECTION_FAILED:
      return {
        title:i18n.t("common:disconnectionFailed"),
        icon: <i className="fa fa-exclamation-triangle text-danger" />
      }
    case CONNECTION_LOST:
      return {
        title:i18n.t("common:connectionLost"),
        icon: <i className="fa fa-exclamation-triangle text-danger" />
      }

    case UPGRADE_REQUIRED:
      return {
        title:i18n.t("common:upgradeRequired"),
        icon:  <FatUpArrowIcon fill="#EAB61A" width={12} height={12}  />
      }
    case UPGRADE_STARTED:
      return {
        title:i18n.t("common:upgradeInProgress"),
        icon: <FatUpArrowIcon fill="#EAB61A" width={12} height={12}  />
      }
    default:
      return {
        title:'Unknown',
        icon:  <i className="circle account-status" />
      }
  }
  
}

export const preProcessRegionStatus = (status) => {
  let item = {
    key: "",
    value: "",
    status,
  };
  switch (status) {
    case CONNECTED_STATUS:
      item.key = "connected";
      item.value = "Ok";
      break;
    case FAILED_TO_CONNECT:
      item.key = "failed";
      item.value = "Failed";
      break;
    default:
      item.key = "unknown";
      item.value = status;
      break;
  }
  return item;
};

export const getStandbyAccounts = () => (dispatch, getState) => {
  const { globalConfig } = getState();
  const { organizations } = globalConfig;
  if (organizations && organizations.length) {
    const orgId = organizations[0].ID;
    let filters = getSelectedOrgs(globalConfig.filteredUserData);
    filters.Orgs = [orgId];
    filters.Detail = true;
    filters.ResourceType = resourceTypeCodes.CUSTOMER_ACCOUNT;
    dispatch({
      type: GET_STANDBY_ACCOUNTS_REQUEST,
    });
    var params = "Filters=" + encodeURI(JSON.stringify(filters));
    getAllAccountsApi(params)
      .then((data) => {
        const accounts = preProcessAccounts(data, globalConfig);
        dispatch({
          type: GET_STANDBY_ACCOUNTS_SUCCESS,
          payload: accounts,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_STANDBY_ACCOUNTS_FAILURE,
          payload: error,
        });
      });
  }
};

export const preProcessAzureSubscriptions = (subscriptions) => {
  let processedSubscriptions = [];

  if (isArrayWithLength(subscriptions)) {
    subscriptions.forEach((subscription) => {
      processedSubscriptions.push({
        ...subscription,
        accountStatus: subscription.AccountStatus,
        discoveryStatus: subscription.DiscoveryStatus,
        accountName: subscription.AccountName,
        accountNumber: subscription.AccountNumber,
        subscriptionId: subscription.AccountNumber,
        subscriptionName: subscription.AccountName,
        subscriptionStatus: subscription.AccountStatus,
        customerAccountId: subscription.CustomerAccountId,
        hideActions: subscription.AccountStatus !== CONNECTED_STATUS,
        createdOn: humanizedDateFormatWithOrdinal(subscription.CreatedOn),
      });
    });
  }

  return processedSubscriptions;
};

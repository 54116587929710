import {
  SAVE_DAS_PARAMETERS,
  CLEAR_DAS_PARAMETERS,
} from "../actionTypes/parameters";

const initialState = {
  parameters: {},
};

const dasParameters = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_DAS_PARAMETERS:
      return {
        ...state,
        parameters: action.payload,
      };
    case CLEAR_DAS_PARAMETERS:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default dasParameters;

import { DATA_TYPES } from "../actions/index";

const initialState = {
  isFetching: false,
  isFetchingFailed: false,
  fetchedList: [],
  fetchedDetails: {},
  fetchedValue: null,
  failedToFetch: null,
  isCreating: false,
  isCreatingFailed: false,
  created: null,
  failedToCreate: false,
  isCreated: false,
  isUpdating: false,
  isUpdatingFailed: false,
  updated: null,
  failedToUpdate: false,
  isUpdated: false,
  isDeleting: false,
  isDeletingFailed: false,
  deleted: null,
  failedToDelete: false,
  isDeleted: false,
  saveData: null,
  isFetched: false,
  isAccessDenied: false,
};

const getPayloadKey = (dataType) => {
  const { OBJECT, ARRAY, BOOLEAN, STRING } = DATA_TYPES;
  let payloadKey = "";
  switch (dataType) {
    case OBJECT:
      payloadKey = "fetchedDetails";
      break;
    case ARRAY:
      payloadKey = "fetchedList";
      break;
    case BOOLEAN:
    case STRING:
      payloadKey = "fetchedValue";
      break;
    default:
      payloadKey = "fetchedList";
      break;
  }
  return payloadKey;
};

export const createReducer = (name) => {
  const uppercaseName = name.toUpperCase();

  return (state = initialState, action) => {
    const payloadKey = getPayloadKey(action.dataType);
    const { clearOnRefresh } = action;
    switch (action.type) {
      case `GET_${uppercaseName}_REQUEST`:
        return {
          isFetching: true,
          isFetchingFailed: initialState.isFetchingFailed,
          [payloadKey]: clearOnRefresh
            ? initialState[payloadKey]
            : action.payload
            ? action.payload
            : state[payloadKey],
          failedToFetch: initialState.failedToFetch,
          isFetched: initialState.isFetched,
          isAccessDenied: initialState.isAccessDenied,
        };
      case `GET_${uppercaseName}_SUCCESS`:
        return {
          isFetching: false,
          [payloadKey]: action.payload,
          isFetched: true,
        };
      case `GET_${uppercaseName}_FAILURE`:
        return {
          isFetching: false,
          isFetchingFailed: true,
          failedToFetch: action.payload,
          isAccessDenied: action.isAccessDenied,
          ...(action.payload && { [payloadKey]: action.payload }),
        };
      case `UPDATED_${uppercaseName}`:
        return {
          [payloadKey]: action.payload,
        };
      case `CLEAR_${uppercaseName}`:
        return {
          ...state,
          ...initialState,
        };
      case `CREATE_${uppercaseName}_REQUEST`:
        return {
          isCreating: true,
          isCreatingFailed: initialState.isCreatingFailed,
          isCreated: initialState.isCreated,
          created: initialState.created,
          failedToCreate: initialState.failedToCreate,
        };
      case `CREATE_${uppercaseName}_SUCCESS`:
        return {
          isCreating: false,
          isCreated: true,
          created: action.payload,
        };
      case `CREATE_${uppercaseName}_FAILURE`:
        return {
          isCreating: false,
          isCreatingFailed: true,
          failedToCreate: action.payload,
        };
      case `UPDATE_${uppercaseName}_REQUEST`:
        return {
          isUpdating: true,
          isUpdatingFailed: initialState.isUpdatingFailed,
          isUpdated: initialState.isUpdated,
          updated: initialState.updated,
          failedToUpdate: initialState.failedToUpdate,
        };
      case `UPDATE_${uppercaseName}_SUCCESS`:
        return {
          isUpdating: false,
          isUpdated: true,
          updated: action.payload,
        };
      case `UPDATE_${uppercaseName}_FAILURE`:
        return {
          isUpdating: false,
          isUpdatingFailed: true,
          failedToUpdate: action.payload,
        };
      case `DELETE_${uppercaseName}_REQUEST`:
        return {
          ...state,
          isDeleting: true,
          isDeletingFailed: initialState.isDeletingFailed,
          isDeleted: initialState.isDeleted,
          deleted: initialState.deleted,
          failedToDelete: initialState.failedToDelete,
        };
      case `DELETE_${uppercaseName}_SUCCESS`:
        return {
          ...state,
          isDeleting: false,
          isDeleted: true,
          deleted: action.payload,
        };
      case `DELETE_${uppercaseName}_FAILURE`:
        return {
          ...state,
          isDeleting: false,
          isDeletingFailed: true,
          failedToDelete: action.payload,
        };
      case `SAVE_${uppercaseName}`:
        return {
          saveData: action.payload,
        };
      default:
        return state;
    }
  };
};

import { STATUS_SUCCESS } from "../../utils/app-constants.json";

// Lottie player
export const animationPlayerURL =
  "https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js";

// Lottie Animations
export const successAnimationPath =
  "https://assets2.lottiefiles.com/packages/lf20_y2hxPc.json";

export const getAnimationPath = (type) => {
  let path = "";
  switch (type) {
    case STATUS_SUCCESS:
      path = successAnimationPath;
      break;
    default:
      break;
  }
  return path;
};

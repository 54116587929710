import classnames from "classnames";
import React from "react";
import RadioButton from "../RadioButton";
import SingleCheckbox from "../SingleCheckbox";
import styles from "./Checkbox.module.scss";

const Checkbox = ({
  id,
  name,
  content,
  onChange,
  checked,
  className,
  dataTour,
  type,
  contentClass,
  isDisabled,
  size,
  handleEventClick,
  isLocked,
  renderVerticallyAligned,
}) => {
  let extraParams = {};
  if (!handleEventClick) {
    extraParams = {
      ...extraParams,
      onChange,
    };
  }
  let elementsParams = {};
  if (handleEventClick) {
    elementsParams = {
      ...elementsParams,
      onClick: onChange,
      checked: checked,
    };
  }
  return (
    <>
      <input
        className={styles.Checkbox}
        id={id}
        type="checkbox"
        checked={checked}
        {...extraParams}
        name={name}
        disabled={isDisabled}
      />
      <label
        data-tour={dataTour}
        className={classnames(
          styles.CheckboxLabel,
          renderVerticallyAligned && styles.verticallyAligned,
          checked && styles.Checked,
          className,
          isDisabled ? styles.DisabledInput : ""
        )}
        htmlFor={id}
      >
        <div {...elementsParams} className={classnames(contentClass)}>
          {content}
        </div>
        <span
          className={classnames(
            renderVerticallyAligned ? null : styles.ButtonContainer,
            size === "large" && styles.LargeButton
          )}
        >
          {!isDisabled &&
            !isLocked &&
            type === "radio" &&
            (size === "large" ? (
              <RadioButton id={id} checked={checked} onChange={onChange} />
            ) : !checked ? (
              <i className={classnames(styles.Button, "fa fa-circle-o")} />
            ) : (
              <i className={classnames(styles.Button, "fa fa-dot-circle-o")} />
            ))}
          {!isDisabled &&
            !isLocked &&
            type === "checkbox" &&
            (size === "large" ? (
              <SingleCheckbox id={id} checked={checked} onChange={onChange} />
            ) : !checked ? (
              <i
                className={classnames(styles.Button, "fa fa-square-o")}
                {...elementsParams}
              />
            ) : (
              <i
                className={classnames(styles.Button, "fa fa-check-square-o")}
                {...elementsParams}
              />
            ))}
          {isDisabled && size !== "large" && !isLocked && (
            <i className={classnames(styles.Button, "fa fa-lock")} />
          )}
          {isLocked && size === "large" && (
            <i
              className={classnames(
                styles.Button,
                styles.LockedButton,
                "fa fa-lock"
              )}
            />
          )}
        </span>
      </label>
    </>
  );
};

export default Checkbox;

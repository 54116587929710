import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import classnames from "classnames";
import { Row, Col, FormGroup, Input, InputGroup } from "reactstrap";

import Loader from "../../../../components/Loader/Loader";
import CopyScript from "../../../../components/CopyScript/CopyScript";
import MCButton from "../../../../components/MCButton/MCButton";

import azureImg from "../../../../images/resources/Azure/Azure.png";

import {
  getCustomerAccountStatus,
  setAccountName,
  resetConfigureAccount,
  saveCustomerAccountName,
  setDisableNextStep,
  getOnboardingTemplate,
  resetAccountOnboarding,
} from "../../../account/actions/account";
import { setSelectedCloudProvider } from "../../../first-run/actions/firstRun";

import styles from "../NewFirstRun.module.scss";
import configureStyles from "./ConfigureAzureSubscription.module.scss";
import {
  INITIATED,
  CONNECTED_STATUS,
  FAILED,
  PENDING,
} from "../../../../utils/app-constants.json";

import { configureAzureSubscriptionStates } from "../../constants";

const ConfigureAzureAccess = ({ isFRE, parentNext }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["firstRun"]);
  const [editSubscriptionName, setEditSubscriptionName] = useState(false);
  const [configureAzureSubscriptionState, setConfigureAzureSubscriptionState] =
    useState(configureAzureSubscriptionStates.SHOW_ONBOARDING_SCRIPT);
  const [loop, setLoop] = useState(null);
  const [showProgressLoader, setShowProgressLoader] = useState(false);

  const {
    customerAccountId,
    customerAccountStatus,
    accountName,
    accountNumber,
    savingAccountName,
    customerAccountNameSaved,
    onboardingScript,
    customerAccountStatusSuccess,
    isFetchingOnboardingTemplate,
    onboardingError,
  } = useSelector(({ addAccount }) => {
    return {
      customerAccountId: addAccount.customerAccountId,
      customerAccountStatus: addAccount.customerAccountStatus,
      accountName: addAccount.accountName,
      accountNumber: addAccount.accountNumber,
      savingAccountName: addAccount.savingAccountName,
      customerAccountNameSaved: addAccount.customerAccountNameSaved,
      onboardingScript: addAccount.onboardingScript,
      customerAccountStatusSuccess: addAccount.customerAccountStatusSuccess,
      isFetchingOnboardingTemplate: addAccount.isFetchingOnboardingTemplate,
      onboardingError: addAccount.onboardingError,
    };
  }, shallowEqual);

  const azureImage = (
    <img
      loading="lazy"
      className="mr-2"
      src={azureImg}
      width={45}
      height={45}
      alt="monty-cloud-azure"
    />
  );

  const showBackButtonInFRE =
    isFRE && (!customerAccountStatus || customerAccountStatus === PENDING);

  useEffect(() => {
    if (!onboardingScript) {
      dispatch(getOnboardingTemplate());
    }
  }, [onboardingScript, dispatch]);

  useEffect(() => {
    if (
      onboardingScript &&
      customerAccountId &&
      !customerAccountStatusSuccess &&
      !loop
    ) {
      setLoop(
        setInterval(() => {
          dispatch(getCustomerAccountStatus(customerAccountId));
        }, 15000)
      );
      setTimeout(() => {
        setShowProgressLoader(true);
      }, 10000);
    }

    if (
      loop &&
      (customerAccountStatusSuccess || customerAccountStatus === FAILED)
    ) {
      clearInterval(loop);
      setLoop(null);
      setShowProgressLoader(false);
    }
    return () => {
      if (loop) {
        clearInterval(loop);
        setLoop(null);
        setShowProgressLoader(false);
      }
    };
  }, [
    dispatch,
    onboardingScript,
    customerAccountId,
    customerAccountStatusSuccess,
    customerAccountStatus,
    loop,
  ]);

  useEffect(() => {
    if (!customerAccountStatus && !isFetchingOnboardingTemplate) {
      setConfigureAzureSubscriptionState(
        configureAzureSubscriptionStates.SHOW_ONBOARDING_SCRIPT
      );
    }

    if (customerAccountStatus && customerAccountStatus === INITIATED) {
      setConfigureAzureSubscriptionState(
        configureAzureSubscriptionStates.SHOW_SCRIPT_EXECUTION_MESSAGE
      );
    }

    if (customerAccountStatus && customerAccountStatus === FAILED) {
      setConfigureAzureSubscriptionState(
        configureAzureSubscriptionStates.SHOW_SUBSCRIPTION_CONNECTION_FAILURE
      );
    }

    if (customerAccountStatus === CONNECTED_STATUS) {
      setConfigureAzureSubscriptionState(
        configureAzureSubscriptionStates.SHOW_SET_SUBSCRIPTION_NAME
      );
      if (!isFRE) {
        dispatch(setDisableNextStep(false));
      }
    }
  }, [
    dispatch,
    customerAccountStatus,
    t,
    isFetchingOnboardingTemplate,
    isFRE,
    loop,
  ]);

  const startOverConfig = () => {
    setConfigureAzureSubscriptionState(
      configureAzureSubscriptionStates.SHOW_ONBOARDING_SCRIPT
    );
    dispatch(resetConfigureAccount(true));
    dispatch(getOnboardingTemplate());
  };

  const changeAccountName = (event) => {
    dispatch(setAccountName(event.target.value));
  };

  const handleSaveCustomerAccountName = () => {
    const params = {
      AccountName: accountName,
    };
    dispatch(saveCustomerAccountName(customerAccountId, params));
  };

  const handleResetCloudProvider = () => {
    dispatch(setSelectedCloudProvider(null));
    dispatch(resetAccountOnboarding());
  };

  //TODO: Move to separate components
  const onboardingScriptContent = (
    <div className={classnames(configureStyles.onboardingContainer)}>
      <div className={`${styles.MCHeading4} ${styles.Bold600} mt-4 mb-1`}>
        {t("connectAzureMessage.step1")}
      </div>
      <div className={`${styles.MCHeading4} ${styles.Bold500} mb-4`}>
        {t("connectAzureMessage.startConnection")}
      </div>
      <div>
        <CopyScript id="azure-onboarding-script" text={onboardingScript} />
      </div>
      <div className={`${styles.MCHeading4} ${styles.Bold600} mt-2 mb-1`}>
        {t("connectAzureMessage.step2")}
      </div>
      <div className={`${styles.MCHeading4} ${styles.Bold500} mb-2`}>
        {t("connectAzureMessage.keepEyeOnProgress")}
      </div>
      {showProgressLoader && (
        <div className="d-flex justify-content-center align-items-center mc-text-base-regular">
          <Loader className={configureStyles.syncLoader} />
          {t("syncInProgress")}
        </div>
      )}
      {showBackButtonInFRE && (
        <MCButton
          className="mt-4 mb-4 float-left"
          text={
            <>
              <i className="fa fa-caret-left mr-2" />
              {t("common:previous")}
            </>
          }
          onClick={() => handleResetCloudProvider()}
          size="sm"
        />
      )}
    </div>
  );

  const sricptExecutionContent = (
    <div className="text-center mt-5">
      <span className={configureStyles.loading}>
        <i className="fa fa-spin fa-spinner ml-2" />
      </span>
      <h5 className="mb-4 mt-5">
        {t("addAccount:subscriptionConnectionMessagePart1")}
      </h5>
      <p>{t("addAccount:subscriptionConnectionMessagePart2")}</p>
      <p>{t("addAccount:subscriptionConnectionMessagePart3")}</p>
      <button
        className="btn orange-text btn-sm button-without-border"
        onClick={() => startOverConfig()}
        style={{ fontSize: "14px" }}
      >
        <i className="fa fa-repeat" /> {t("addAccount:btn.startOver")}
      </button>
    </div>
  );

  const showSubscriptionFailureContent = (
    <div className="mt-5 text-center">
      <p className="mb-4">
        <strong className={`${styles.MCHeading3} ${styles.Bold500}`}>
          {onboardingError
            ? onboardingError
            : t("addAccount:subscriptionOnboardingFailedMessage.errorOccurred")}
        </strong>
      </p>
      <p>{t("addAccount:subscriptionOnboardingFailedMessage.tryAgain")}</p>
      <button
        className="btn orange-text btn-sm button-without-border"
        onClick={() => startOverConfig()}
      >
        <i className="fa fa-repeat" /> {t("addAccount:btn.startOver")}
      </button>
    </div>
  );

  const showSetSubscriptionNameContent = (
    <div className={classnames("text-center mt-4")}>
      {
        <div
          className={classnames(
            configureStyles.successIcon,
            configureStyles.fontLarge
          )}
        >
          <i className="fa fa-check-circle text-success" />
        </div>
      }

      <Row className="m-0 mt-3">
        {
          <>
            <Col xl={12} className="px-0">
              <div className={`${styles.MCHeading3} ${styles.Bold500}`}>
                <div>{t("addAccount:subscriptionConnectedMessage1")}</div>
                <div>
                  <Trans
                    i18nKey="addAccount:subscriptionConnectedMessage2"
                    values={{
                      subscriptionName: `${accountName} (${accountNumber})`,
                    }}
                    components={[<strong></strong>]}
                  />
                </div>
                <div>{t("addAccount:subscriptionConnectedMessage3")}</div>
              </div>
            </Col>
          </>
        }
      </Row>
      <Row className="m-0 mt-4">
        {
          <>
            {!editSubscriptionName ? (
              <Col xl={12}>
                <div
                  className={`${styles.MCHeading3} ${styles.Bold600} d-flex justify-content-center`}
                >
                  <div className="d-flex">
                    <div className="color-grey-600 mr-2">
                      {t("addAccount:changeSubscriptionName")}
                    </div>
                    <Trans
                      i18nKey="addAccount:subscriptionConnectedMessage2"
                      values={{
                        subscriptionName: accountName,
                      }}
                      components={[<strong></strong>]}
                    />
                  </div>
                  <div>
                    <i
                      className="fa fa-pencil ml-2 cursor-pointer"
                      onClick={() => setEditSubscriptionName(true)}
                    />
                  </div>
                </div>
              </Col>
            ) : (
              <>
                {!isFRE && <Col xl={4}></Col>}
                <Col xl={isFRE ? 12 : 4}>
                  <FormGroup>
                    <InputGroup>
                      <Input
                        className={classnames(configureStyles.addAccount)}
                        value={accountName}
                        onChange={(e) => changeAccountName(e)}
                        type="text"
                        required
                        name="name"
                        id="search-input1"
                        placeholder={t("addAccount:newAccountNameTooltip")}
                        disabled={customerAccountNameSaved}
                      />
                      <MCButton
                        className="ml-2 float-right"
                        onClick={() => handleSaveCustomerAccountName()}
                        disabled={customerAccountNameSaved || savingAccountName}
                        text={
                          <>
                            {!customerAccountNameSaved
                              ? t("addAccount:btn.saveName")
                              : t("common:confirmed")}
                            &nbsp;
                            <i className="fa fa-check ml-2" />
                          </>
                        }
                        color="mc-blue"
                        size="sm"
                      />
                      {!customerAccountNameSaved && (
                        <MCButton
                          className="ml-2 float-right"
                          disabled={
                            customerAccountNameSaved || savingAccountName
                          }
                          text={t("common:cancelLabel")}
                          onClick={() => setEditSubscriptionName(false)}
                          size="sm"
                        />
                      )}
                    </InputGroup>
                  </FormGroup>
                  {customerAccountNameSaved && (
                    <div className={configureStyles.fontSmall}>
                      {t("addAccount:subscriptionNameSavedMessage")}
                    </div>
                  )}
                </Col>
              </>
            )}
          </>
        }
      </Row>
      {isFRE && (
        <Row
          className={classnames(
            styles.robotoFont,
            "mx-0",
            configureStyles.nextButton
          )}
        >
          <MCButton
            size="lg"
            disabled={savingAccountName}
            text={
              <>
                {savingAccountName
                  ? t("addAccount:savingLabel")
                  : t("common:next")}
                <i className="fa fa-caret-right ml-2" />
              </>
            }
            color="mc-blue"
            onClick={() => parentNext()}
            className={classnames(styles.robotoFont)}
          />
        </Row>
      )}
    </div>
  );

  const displayConnectAzueSubscriptionStates = () => {
    switch (configureAzureSubscriptionState) {
      case configureAzureSubscriptionStates.SHOW_ONBOARDING_SCRIPT:
        return onboardingScriptContent;
      case configureAzureSubscriptionStates.SHOW_SCRIPT_EXECUTION_MESSAGE:
        return sricptExecutionContent;
      case configureAzureSubscriptionStates.SHOW_SUBSCRIPTION_CONNECTION_FAILURE:
        return showSubscriptionFailureContent;
      case configureAzureSubscriptionStates.SHOW_SET_SUBSCRIPTION_NAME:
        return showSetSubscriptionNameContent;
      default:
        return onboardingScriptContent;
    }
  };

  return (
    <>
      <div
        className={`${styles.MCHeading2} ${styles.Bold600} d-flex align-items-center`}
      >
        {azureImage}
        {t("connectAzureHeading")}
      </div>
      {isFetchingOnboardingTemplate ? (
        <Loader />
      ) : (
        displayConnectAzueSubscriptionStates()
      )}
    </>
  );
};

export default ConfigureAzureAccess;

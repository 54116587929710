import { combineReducers } from "redux";
import { createReducer } from "../../../utils/create-reducer";
import {
  ORG_MFA_CONFIG,
  INITIATE_MFA_SETUP,
  REGISTER_MFA_DEVICE,
  VERIFY_MFA,
  USER_MFA_CONFIG,
} from "../../../utils/action-names.json";

const orgMFAConfig = createReducer(ORG_MFA_CONFIG);
const mfaSetup = createReducer(INITIATE_MFA_SETUP);
const registerMFA = createReducer(REGISTER_MFA_DEVICE);
const verifyMFA = createReducer(VERIFY_MFA);
const userMFAConfig = createReducer(USER_MFA_CONFIG);

export default combineReducers({
  orgMFAConfig,
  mfaSetup,
  registerMFA,
  verifyMFA,
  userMFAConfig,
});

export const UPDATE_CHART_DATA_REQUEST = "UPDATE_CHART_DATA_REQUEST";
export const UPDATE_CHART_DATA_SUCCESS = "UPDATE_CHART_DATA_SUCCESS";
export const UPDATE_CHART_DATA_FAILURE = "UPDATE_CHART_DATA_FAILURE";
export const DELETE_CHART_DATA = "DELETE_CHART_DATA";
export const UPDATE_COST_FILTER_OPTIONS = "UPDATE_COST_FILTER_OPTIONS";
export const COST_FILTER_OPTIONS_REQUEST = "COST_FILTER_OPTIONS_REQUEST";
export const COST_FILTER_OPTIONS_FAILURE = "COST_FILTER_OPTIONS_FAILURE";
export const UPDATE_COLORS = "UPDATE_COLORS";
export const UPDATE_ACCOUNTS_COLORS = "UPDATE_ACCOUNTS_COLORS";
export const UPDATE_SERVICES_COLORS = "UPDATE_SERVICES_COLORS";
export const UPDATE_RESOURCEGROUPS_COLORS = "UPDATE_RESOURCEGROUPS_COLORS";

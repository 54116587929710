import {
  GET_VOLUMES_REQUEST,
  GET_VOLUMES_SUCCESS,
  GET_VOLUMES_FAILURE,
  CREATE_BACKUP_REQUEST,
  CREATE_BACKUP_SUCCESS,
  CREATE_BACKUP_FAILURE,
  CLEAR_CREATE_VOLUME_SNAPSHOT_DATA,
  RESET_VOLUMES_IS_FETCHED,
  RESET_VOLUME_LIST,
} from "../actionTypes/volumes";

const initialState = {
  isFetchingVolumeList: false,
  isFetchedVolumeList: false,
  volumeList: null,
  showVolumeList: true,
  isCreating: false,
  createdBackup: false,
  createSnapshotErrorMessages: null,
};
const volumes = (state = initialState, action) => {
  switch (action.type) {
    case GET_VOLUMES_REQUEST:
      return {
        ...state,
        isFetchingVolumeList: true,
        isFetchedVolumeList: false,
      };
    case GET_VOLUMES_SUCCESS:
      return {
        ...state,
        isFetchingVolumeList: false,
        volumeList: action.payload,
        showVolumeList: true,
        isFetchedVolumeList: true,
      };
    case GET_VOLUMES_FAILURE:
      return {
        ...state,
        isFetchingVolumeList: false,
        isFetchedVolumeList: false,
        errorMessage: action.payload,
      };
    case RESET_VOLUMES_IS_FETCHED:
      return {
        ...state,
        isFetchedVolumeList: false,
      };
    case CREATE_BACKUP_REQUEST:
      return {
        ...state,
        isCreating: true,
        createSnapshotErrorMessages: initialState.createSnapshotErrorMessages,
        createdBackup: false,
      };
    case CREATE_BACKUP_SUCCESS:
      return {
        ...state,
        isCreating: false,
        createdBackup: true,
      };
    case CREATE_BACKUP_FAILURE:
      return {
        ...state,
        isCreating: false,
        createSnapshotErrorMessages: action.payload,
        createdBackup: false,
      };
    case CLEAR_CREATE_VOLUME_SNAPSHOT_DATA:
      return {
        ...state,
        createSnapshotErrorMessages: initialState.createSnapshotErrorMessages,
      };
    case RESET_VOLUME_LIST:
      return {
        ...state,
        volumeList: initialState.volumeList,
      };
    default:
      return {
        ...state,
      };
  }
};

export default volumes;

import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Input } from "reactstrap";
import MCButton from "../../MCButton/MCButton";
import Popup from "../../Popup/Popup";
import RadioButton from "../../RadioButton/RadioButton";
import { createSupportTicket } from "../actions/supportTicket";
import s from "./SupportTicket.module.scss";
import { logoutUser } from "../../../modules/authentication/actions/auth";
import LoggingOutLoader from "../../Header/LoggingOutLoader";
import ExternalLink from "../../ExternalLink/ExternalLink";
import { useTranslation } from "react-i18next";
import { permissionsLink } from "../../../utils/app-links";

const CONNECT_ACCOUNT_PREFIX = "FRE/Connect Account";
const OTHERS = "Other";
const SANDBOX_OPTION =
  "I would like to explore DAY2™ in a sandbox environment before connecting my AWS account";
const SECURITY_OPTION =
  "I have security concerns and would like to know more about your permissions in my AWS account";
const LEARN_MORE_OPTION =
  "I am still unclear, I would like to learn more about DAY2™";

const options = [
  {
    id: "1",
    value: SANDBOX_OPTION,
  },
  {
    id: "2",
    value: SECURITY_OPTION,
  },
  {
    id: "3",
    value: LEARN_MORE_OPTION,
  },
  {
    id: "4",
    value: OTHERS,
  },
];

const SupportTicketForFREAccount = ({ toggleModal }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["firstRun", "common"]);

  const [showLogoutLoader, setShowLogoutLoader] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [othersValue, setOthersValue] = useState("");

  const { isCreating, isCreated } = useSelector(({ supportTicket }) => {
    return {
      isCreating: supportTicket.isCreating,
      isCreated: supportTicket.isCreated,
    };
  }, shallowEqual);

  const doLogout = () => {
    setShowLogoutLoader(true);
    dispatch(logoutUser());
  };

  const submitTicket = () => {
    const selected = selectedOption;
    const subject = `${CONNECT_ACCOUNT_PREFIX} - ${selected.value}`;
    let message = selected.value;
    if (selected.value === OTHERS) {
      message = othersValue;
    }
    const url = window.location.href;
    dispatch(createSupportTicket(subject, message, url));
  };

  const disabledSubmit =
    isCreating ||
    !selectedOption.value ||
    (selectedOption && selectedOption.value === OTHERS && !othersValue);

  const supportTicketFooter = (
    <>
      <MCButton
        size="sm"
        text={
          isCreating
            ? `${t("common:submittingFeedback")}...`
            : `${t("common:submitFeedback")}`
        }
        color="mc-blue"
        disabled={disabledSubmit}
        onClick={() => submitTicket()}
      />
    </>
  );

  const supportTicketContent = (
    <>
      {isCreated ? (
        <div className={s.FeedbackSuccess}>
          <div className={s.Heading}>{t("common:thankYou")}</div>
          <div className={s.SubHeading}>{t("common:feedbackSuccessful")}</div>
          {selectedOption.value === SECURITY_OPTION && (
            <div className="comment">
              <h6>
                <strong>{t("readMoreAboutSecurity")}</strong>
              </h6>
              <ExternalLink
                text={t("permissionsDAY2need")}
                size="medium"
                externalLink={permissionsLink}
              />
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="text-center">
            <h4 className="mt-5">
              <strong>{t("common:loveYourFeedback")}</strong>
            </h4>
            <h6 className="mt-4">{t("helpUsUnderstandAccount")}</h6>
          </div>
          <div className="mt-4">
            {options.map((option) => {
              return (
                <div>
                  <RadioButton
                    checked={option.id === selectedOption.id}
                    onChange={(e) => setSelectedOption(option)}
                    text={option.value}
                    id={option.id}
                    disabled={isCreating}
                  />
                </div>
              );
            })}
          </div>

          {selectedOption.value === OTHERS && (
            <Input
              className="form-control"
              id="othersText"
              type="textarea"
              value={othersValue}
              onChange={(e) => setOthersValue(e.target.value)}
              disabled={isCreating}
            />
          )}
        </>
      )}
    </>
  );

  const submittedSupportTicketFooter = (
    <>
      <MCButton
        size="sm"
        text={t("common:logout")}
        onClick={() => doLogout()}
      />
      <MCButton
        size="sm"
        text={t("goBackToConnectAccount")}
        color="mc-blue"
        onClick={() => toggleModal()}
      />
    </>
  );

  return (
    <>
      <Popup
        isOpen={true}
        toggle={() => toggleModal()}
        className={s.modal}
        header=""
        headerClassName={s.header}
        contentClass="p-4"
        content={
          <>
            {showLogoutLoader && (
              <div>
                <LoggingOutLoader />
              </div>
            )}
            {supportTicketContent}
          </>
        }
        footer={isCreated ? submittedSupportTicketFooter : supportTicketFooter}
        closeIcon={s.closeIcon}
      />
    </>
  );
};

export default SupportTicketForFREAccount;

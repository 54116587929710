import { UTM_CAMPAIGN, UTM_SOURCE } from "../../../utils/app-constants.json";
import { RESEND_CONFIRMATION_FAILURE, RESEND_CONFIRMATION_REQUEST, RESEND_CONFIRMATION_SUCCESS, SIGNUP_FAILURE, SIGNUP_REQUEST, SIGNUP_SUCCESS } from "../actionTypes/signup";
import { resendConfirmationApi, signUpApi } from "../services/signup";

function requestSignup() {
  return {
    type: SIGNUP_REQUEST,
  };
}

export function receiveSignup() {
  return {
    type: SIGNUP_SUCCESS,
  };
}

function signupError(payload) {
  return {
    type: SIGNUP_FAILURE,
    payload,
  };
}

export function signUp(params, campaignKey, sourceKey) {
  return (dispatch) => {
    dispatch(requestSignup());
    if (params) {
      let queryParams = "";
      if (campaignKey) {
        queryParams = `?${UTM_CAMPAIGN}=${campaignKey}&${UTM_SOURCE}=${sourceKey}`;
      }
      signUpApi(params, queryParams)
        .then((res) => {
          dispatch(receiveSignup());
        })
        .catch((err) => {
          const error = JSON.parse(err.message);
          if (error.data) {
            const message = error.data.ErrorMessage;
            dispatch(signupError(message));
          }
        });
    } else {
      dispatch(signupError("Something went wrong. please try again"));
    }
  };
}

function requestResendConfirmation() {
  return {
    type: RESEND_CONFIRMATION_REQUEST,
  };
}

export function receiveResendConfirmation() {
  return {
    type: RESEND_CONFIRMATION_SUCCESS,
  };
}

function resendConfirmationError(payload) {
  return {
    type: RESEND_CONFIRMATION_FAILURE,
    payload,
  };
}

export function resendConfirmation(data) {
  return (dispatch) => {
    dispatch(requestResendConfirmation());
    if (data) {
      resendConfirmationApi(data)
        .then((res) => {
          dispatch(receiveResendConfirmation());
        })
        .catch((err) => {
          const error = JSON.parse(err.message);
          const message = error.data.ErrorMessage;
          dispatch(resendConfirmationError(message));
        });
    } else {
      dispatch(
        resendConfirmationError("Something went wrong. please try again")
      );
    }
  };
}

import {
  GET_SNAPSHOTS_REQUEST,
  GET_SNAPSHOTS_SUCCESS,
  GET_SNAPSHOTS_FAILURE,
} from "../actionTypes/snapshots";

const initialState = {
  isSnapshotListFetching: false,
  isSnapshotListFetched: false,
  snapshotList: null,
  showSnapshotList: true,
};

const snapshots = (state = initialState, action) => {
  switch (action.type) {
    case GET_SNAPSHOTS_REQUEST:
      return {
        ...state,
        isSnapshotListFetching: true,
        isSnapshotListFetched: false,
      };
    case GET_SNAPSHOTS_SUCCESS:
      return {
        ...state,
        isSnapshotListFetching: false,
        snapshotList: action.payload,
        showSnapshotList: true,
        isSnapshotListFetched: true,
      };
    case GET_SNAPSHOTS_FAILURE:
      return {
        ...state,
        isSnapshotListFetching: false,
        errorMessage: action.payload,
        isSnapshotListFetched: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default snapshots;

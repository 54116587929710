import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import SupportTicketForFREAccount from "../../../../../components/SupportTicket/components/SupportTicketForFREAccount";
import AccountAlreadyExists from "./AccountAlreadyExists";
import StackCreation from "./StackCreation";
import SetAccountDetails from "./SetAccountDetails";
import ConnectAccountInfo from "./ConnectAccountInfo";
import ConfigureAWSAccountPermissions from "./ConfigureAWSAccountPermissions";

import { setHideCloseButton } from "../../../../first-run/actions/firstRun";
import {
  getCustomerAccountStatus,
  getOnboardingTemplate,
  resetConfigureAccount,
  setDisableNextStep,
  saveSelectedRegions,
  setConfigureAWSAccountState,
  clearConfigureAWSAccountState,
} from "../../../actions/account";

import { configureAWSAccountStates } from "../../../utils/account-constants";
import {
  PENDING,
  INITIATED,
  CONNECTED_STATUS,
} from "../../../../../utils/app-constants.json";

import config from "../../../../../config";
import { isArrayWithLength } from "../../../../../utils/array-methods";
import { useOnUnmount } from "../../../../../hooks/useOnUnmount";

const ConfigureAWSAccount = ({
  firstRunView,
  createDAY2Context,
  selectedRegions,
  resetComponent,
  orgExists,
  frePreviousStep,
  disableNextButton,
}) => {
  const { t } = useTranslation("addAccount");
  const dispatch = useDispatch();
  const retryIntervalTime = 15000;

  const [showConnectAccountHelp, setShowConnectAccountHelp] = useState(false);

  const [accountStatusInterval, setAccountStatusInterval] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [newTab, setNewTab] = useState(null);

  const [freConfigureButtonClicked, setFreConfigureButtonClicked] =
    useState(false);

  const {
    selectedAccountType,
    customerAccountStatus,
    onboardingUrl,
    customerAccountId,
    customerAccountStatusSuccess,
    isFetchingOnboardingTemplate,
    savingAccountName,
    customerAccountNameSaved,
    isConfigureAccessBtnClicked,
    filteredUserData,
    regionsLoaded,
    configureAWSAccountState,
  } = useSelector(({ firstRun, addAccount, globalConfig }) => {
    return {
      selectedAccountType: firstRun.selectedAccountType,
      customerAccountStatus: addAccount.customerAccountStatus,
      onboardingUrl: addAccount.onboardingUrl,
      customerAccountId: addAccount.customerAccountId,
      customerAccountStatusSuccess: addAccount.customerAccountStatusSuccess,
      isFetchingOnboardingTemplate: addAccount.isFetchingOnboardingTemplate,
      savingAccountName: addAccount.savingAccountName,
      customerAccountNameSaved: addAccount.customerAccountNameSaved,
      isConfigureAccessBtnClicked: addAccount.isConfigureAccessBtnClicked,
      filteredUserData: globalConfig.filteredUserData,
      regionsLoaded: globalConfig.regionsLoaded,
      configureAWSAccountState: addAccount.configureAWSAccountState,
    };
  }, shallowEqual);

  const showBackButton = useMemo(
    () =>
      firstRunView &&
      !isLoading &&
      (!customerAccountStatus || customerAccountStatus === PENDING),
    [firstRunView, isLoading, customerAccountStatus]
  );

  const fetchCustomerAccountStatus = useCallback(() => {
    dispatch(setHideCloseButton(true));
    if (onboardingUrl) {
      if (customerAccountId) {
        dispatch(getCustomerAccountStatus(customerAccountId));
      }
    } else {
      dispatch(getOnboardingTemplate());
    }
  }, [customerAccountId, onboardingUrl, dispatch]);

  const handleConnectAccountInFRE = useCallback(() => {
    setIsLoading(true);
    setFreConfigureButtonClicked(true);
    if (orgExists) {
      fetchCustomerAccountStatus();
    } else if (!orgExists) {
      createDAY2Context();
    }
  }, [createDAY2Context, fetchCustomerAccountStatus, orgExists]);

  const handleStartOver = useCallback(() => {
    if (accountStatusInterval) {
      clearInterval(accountStatusInterval);
      setAccountStatusInterval(null);
    }
    setNewTab(null);

    setIsLoading(false);
    setFreConfigureButtonClicked(false);
    dispatch(
      setConfigureAWSAccountState(
        configureAWSAccountStates.SHOW_CONFIGURE_PERMISSIONS
      )
    );
    dispatch(resetConfigureAccount(firstRunView));
  }, [accountStatusInterval, firstRunView, dispatch]);

  const toggleConnectAccountHelp = useCallback(() => {
    setShowConnectAccountHelp(!showConnectAccountHelp);
  }, [showConnectAccountHelp]);

  useEffect(() => {
    dispatch(setDisableNextStep(true));
  }, [dispatch]);

  useEffect(() => {
    if (!savingAccountName && customerAccountNameSaved && !disableNextButton) {
      dispatch(setDisableNextStep(false));
    }
  }, [
    dispatch,
    savingAccountName,
    customerAccountNameSaved,
    disableNextButton,
  ]);

  const isAccountStatusIntervalNotSet = useMemo(
    () =>
      onboardingUrl &&
      customerAccountId &&
      isConfigureAccessBtnClicked &&
      !customerAccountStatusSuccess &&
      !accountStatusInterval,
    [
      accountStatusInterval,
      customerAccountStatusSuccess,
      isConfigureAccessBtnClicked,
      customerAccountId,
      onboardingUrl,
    ]
  );

  const newTabDoesntExistInConnectAccountFlow = useMemo(
    () => !firstRunView && !newTab,
    [firstRunView, newTab]
  );

  const newTabDoesntExistInFREFlow = useMemo(
    () => firstRunView && freConfigureButtonClicked && !newTab,
    [firstRunView, newTab, freConfigureButtonClicked]
  );

  const isAccountConnectedOrExists = useMemo(
    () =>
      accountStatusInterval &&
      (customerAccountStatusSuccess ||
        (customerAccountStatus &&
          customerAccountStatus.toLowerCase() === t("accountAlreadyExists"))),
    [
      accountStatusInterval,
      customerAccountStatusSuccess,
      customerAccountStatus,
      t,
    ]
  );

  /**
   * useEffect opens the fetched cfn template in a new tab and starts polling the account status to see if it is connected.
   * It also stops the poll if the account is connected successfully or if the account already exists
   */
  useEffect(() => {
    if (isAccountStatusIntervalNotSet) {
      if (newTabDoesntExistInConnectAccountFlow || newTabDoesntExistInFREFlow) {
        const link = config.cloudFormationUrl + onboardingUrl;
        const tab = window.open();
        if (tab) {
          tab.document && tab.document.write(t("newTabLoadingMsg"));
          tab.location.href = link;
          setNewTab(tab);
          dispatch(getCustomerAccountStatus(customerAccountId));
          setAccountStatusInterval(
            setInterval(() => {
              dispatch(getCustomerAccountStatus(customerAccountId));
            }, retryIntervalTime)
          );
        }
      }
    }

    if (isAccountConnectedOrExists) {
      clearInterval(accountStatusInterval);
      setAccountStatusInterval(null);
      setNewTab(null);
    }
  }, [
    dispatch,
    onboardingUrl,
    customerAccountId,
    accountStatusInterval,
    t,
    isAccountStatusIntervalNotSet,
    newTabDoesntExistInConnectAccountFlow,
    newTabDoesntExistInFREFlow,
    isAccountConnectedOrExists,
  ]);

  useEffect(() => {
    return () => {
      if (accountStatusInterval) {
        clearInterval(accountStatusInterval);
        setAccountStatusInterval(null);
      }
    };
  }, [accountStatusInterval]);

  /**
   * useEffect sets the differet states of the ConfigureAWSAccount component based on the customerAccountStatus
   */
  useEffect(() => {
    if (!customerAccountStatus && !isFetchingOnboardingTemplate) {
      dispatch(
        setConfigureAWSAccountState(
          configureAWSAccountStates.SHOW_CONFIGURE_PERMISSIONS
        )
      );
    }

    if (
      customerAccountStatus &&
      (customerAccountStatus === INITIATED || customerAccountStatus === PENDING)
    ) {
      dispatch(
        setConfigureAWSAccountState(
          configureAWSAccountStates.SHOW_STACK_CREATION
        )
      );
    }

    if (
      customerAccountStatus &&
      customerAccountStatus === t("accountAlreadyExists")
    ) {
      dispatch(
        setConfigureAWSAccountState(
          configureAWSAccountStates.SHOW_ACCOUNT_ALREADY_EXISTS
        )
      );
    }

    if (customerAccountStatus === CONNECTED_STATUS) {
      dispatch(
        setConfigureAWSAccountState(
          configureAWSAccountStates.SHOW_SET_ACCOUNT_DETAILS
        )
      );
    }
  }, [
    customerAccountStatus,
    t,
    isFetchingOnboardingTemplate,
    accountStatusInterval,
    dispatch,
  ]);

  useEffect(() => {
    if (isFetchingOnboardingTemplate) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isFetchingOnboardingTemplate]);

  useEffect(() => {
    if (regionsLoaded && !isArrayWithLength(selectedRegions)) {
      const regionList = filteredUserData.Regions.map((region) => ({
        ...region,
        isSelected: false,
      }));

      dispatch(saveSelectedRegions(regionList));
    }
  }, [filteredUserData, regionsLoaded, dispatch, selectedRegions]);

  useOnUnmount(() => {
    dispatch(clearConfigureAWSAccountState());
  }, []);

  const displayConnectAWSAccountStates = useCallback(() => {
    switch (configureAWSAccountState) {
      case configureAWSAccountStates.SHOW_CONFIGURE_PERMISSIONS:
        return (
          <ConfigureAWSAccountPermissions
            firstRunView={firstRunView}
            toggleConnectAccountHelp={toggleConnectAccountHelp}
            selectedAccountType={selectedAccountType}
            showBackButton={showBackButton}
            handleOnPreviousStep={frePreviousStep}
            handleConnectAccount={handleConnectAccountInFRE}
            fetchCustomerAccountStatus={fetchCustomerAccountStatus}
            isLoading={isLoading}
          />
        );
      case configureAWSAccountStates.SHOW_STACK_CREATION:
        return <StackCreation handleStartOver={handleStartOver} />;
      case configureAWSAccountStates.SHOW_ACCOUNT_ALREADY_EXISTS:
        return <AccountAlreadyExists handleStartOver={handleStartOver} />;
      case configureAWSAccountStates.SHOW_SET_ACCOUNT_DETAILS:
        return (
          <SetAccountDetails
            firstRunView={firstRunView}
            selectedRegions={selectedRegions}
          />
        );
      default:
        return (
          <ConfigureAWSAccountPermissions
            firstRunView={firstRunView}
            toggleConnectAccountHelp={toggleConnectAccountHelp}
            selectedAccountType={selectedAccountType}
            showBackButton={showBackButton}
            handleOnPreviousStep={frePreviousStep}
            handleConnectAccount={handleConnectAccountInFRE}
            fetchCustomerAccountStatus={fetchCustomerAccountStatus}
            isLoading={isLoading}
          />
        );
    }
  }, [
    configureAWSAccountState,
    handleStartOver,
    isLoading,
    firstRunView,
    selectedAccountType,
    showBackButton,
    toggleConnectAccountHelp,
    frePreviousStep,
    selectedRegions,
    handleConnectAccountInFRE,
    fetchCustomerAccountStatus,
  ]);

  return (
    <>
      {showConnectAccountHelp && (
        <SupportTicketForFREAccount toggleModal={toggleConnectAccountHelp} />
      )}
      {!firstRunView ? (
        <ConnectAccountInfo
          selectedAccountType={selectedAccountType}
          firstRunView={firstRunView}
        />
      ) : null}
      {displayConnectAWSAccountStates()}
    </>
  );
};

export default ConfigureAWSAccount;

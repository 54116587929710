import {
  FETCHING_MAP_PROJECT_START,
  FETCHING_MAP_PROJECT_SUCCESS,
  FETCHING_MAP_PROJECT_FAILURE,
  FETCHING_MAP_PROJECT_COUNT_SUCCESS,
  FETCHING_MAP_PROJECT_COUNT_FAILURE,
  FETCHING_MAP_PROJECT_COUNT_START,
  DELETE_MAP_PROJECT_START,
  DELETE_MAP_PROJECT_SUCCESS,
  DELETE_MAP_PROJECT_FAILURE,
  UPDATE_MAP_PROJECT_START,
  UPDATE_MAP_PROJECT_SUCCESS,
  UPDATE_MAP_PROJECT_FAILURE,
} from "../actionTypes/mapProjects";
import { filterMapProjectsByStatus } from "../utils/utils";

const initialState = {
  fetchingMapProject: false,
  mapProjects: [],
  pendingMapProjects: [],
  completedMapProjects: [],
  markingComplete: false,
  markedComplete: false,
  updatingMapProjects: false,
  updatedMapProjects: false,
  deletingMapProjects: false,
  deletedMapProjects: false,
  sharingmapProjects: false,
  sharedmapProjectst: false,
  totalMapProjectsCount: [],
  isFetchingCount: false,
  currentListCount : 0
};

const MAPProjectReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCHING_MAP_PROJECT_START:
      return {
        ...state,
        fetchingMapProject: true,
        pendingMapProjects: initialState.pendingMapProjects,
        completedMapProjects: initialState.completedMapProjects,
        mapProjects: initialState.mapProjects,
      };
    case FETCHING_MAP_PROJECT_SUCCESS: {
      const {
        data: { Items, TotalRecords },
      } = payload;

      const { pending, completed } = filterMapProjectsByStatus(Items);

      return {
        ...state,
        mapProjects: Items,
        pendingMapProjects: pending,
        completedMapProjects: completed,
        fetchingMapProject: false,
        currentListCount : TotalRecords
      };
    }
    case FETCHING_MAP_PROJECT_FAILURE:
      return {
        ...state,
        fetchingMapProject: false,
        pendingMapProjects: initialState.pendingMapProjects,
        completedMapProjects: initialState.completedMapProjects,
        mapProjects: initialState.mapProjects,
      };
    case FETCHING_MAP_PROJECT_COUNT_START:
      return {
        ...state,
        isFetchingCount: true,
      };

    case FETCHING_MAP_PROJECT_COUNT_SUCCESS:
      return {
        ...state,
        isFetchingCount: false,
        totalMapProjectsCount: payload,
      };
    case FETCHING_MAP_PROJECT_COUNT_FAILURE:
      return {
        ...state,
        isFetchingCount: false,
      };
    case DELETE_MAP_PROJECT_START:
      return {
        ...state,
        deletingMapProjects: true,
      };
    case DELETE_MAP_PROJECT_SUCCESS:
      return {
        ...state,
        deletingMapProjects: false,
      };
    case DELETE_MAP_PROJECT_FAILURE:
      return {
        ...state,
        deletingMapProjects: false,
      };
    case UPDATE_MAP_PROJECT_START:
      return {
        ...state,
        updatingMapProjects: true,
      };
    case UPDATE_MAP_PROJECT_SUCCESS:
      return {
        ...state,
        updatingMapProjects: false,
        updatedMapProjects: true,
      };
    case UPDATE_MAP_PROJECT_FAILURE:
      return {
        ...state,
        updatedMapProjects: false,
        updatingMapProjects: false,
      };

    default: {
      return {
        ...state,
      };
    }
  }
};

export default MAPProjectReducer;

import appConstants from "./app-constants";
import resourceList from "./resourceList.json";
import azureResourceList from "./azure-resourceList.json";
import { isArrayWithLength } from "./array-methods";

const getAccountNameList = (accNumbers, accountList) => {
  let allAccName = [];
  accountList &&
    accountList.length &&
    accountList.forEach((item) => {
      accNumbers.forEach((accNumber) => {
        if (item.accountNumber === accNumber) {
          allAccName.push(item.accountName);
        }
      });
    });
  return allAccName;
};

export const getAccountName = (accountNumber, accountList) => {
  var acc = isArrayWithLength(accountNumber)
    ? isArrayWithLength(accountList) &&
      getAccountNameList(accountNumber, accountList)
    : isArrayWithLength(accountList) &&
      accountList.find((acc) => acc.accountNumber === accountNumber);

  return acc ? acc.accountName : "";
};

export const getAccountNameWithNumber = (accountNumber, accountList) => {
  var acc = Array.isArray(accountNumber)
    ? getAccountNameList(accountNumber, accountList)
    : accountList.find((acc) => acc.accountNumber === accountNumber);

  return acc
    ? `${acc.accountName.replace("Acme Corp.", "")} (${accountNumber})`
    : "";
};

const getRegionNameList = (regionCodes, regionList) => {
  let allRegionName = [];
  regionList &&
    regionList.length &&
    regionList.forEach((item) => {
      regionCodes.forEach((code) => {
        if (item.regionCode === code) {
          allRegionName.push(item.regionName);
        }
      });
    });
  return allRegionName;
};

export const getRegionName = (regionCode, regionList, showRegionName) => {
  var reg = isArrayWithLength(regionCode)
    ? getRegionNameList(regionCode, regionList)
    : isArrayWithLength(regionList) &&
      regionList.find((reg) => reg.regionCode === regionCode);
  return regionCode === "global"
    ? regionCode
    : reg
    ? reg.regionName
    : showRegionName
    ? regionCode
    : "";
};

export const getRegionNameWithCode = (
  regionCode,
  regionList,
  showRegionName
) => {
  return `${getRegionName(
    regionCode,
    regionList,
    showRegionName
  )} (${regionCode})`;
};

export const getOrgName = (orgId, orgList) => {
  var org =
    isArrayWithLength(orgList) && orgList.find((org) => org.ID === orgId);
  return org ? org.Name : "Default";
};

const getDeptNameList = (deptId, deptList) => {
  let allDeptName = [];
  deptList &&
    deptList.length &&
    deptList.forEach((item) => {
      deptId.forEach((id) => {
        if (item.ID === id) {
          allDeptName.push(item.Name);
        }
      });
    });
  return allDeptName;
};

export const getDeptName = (deptId, deptList) => {
  var dept = isArrayWithLength(deptId)
    ? getDeptNameList(deptId, deptList)
    : isArrayWithLength(deptList) &&
      deptList.find((dept) => dept.ID === deptId);
  return dept ? (isArrayWithLength(dept) ? dept : dept.Name) : "Unassigned";
};

export const getAppName = (appId, appList) => {
  var app =
    isArrayWithLength(appList) && appList.find((app) => app.ID === appId);
  if (!app) {
    return appId === appConstants.DEFAULT ? appConstants.DEFAULT : "-";
  }
  return app.Name;
};

export const doesAppExist = (resourceId, appList) => {
  let app = null;
  app = appList.find((app) => app.Value === resourceId);
  return app;
};

export const doesTagAppExist = (resourceKey, resourceValue, appList) => {
  let appListData = [];
  appList &&
    appList.forEach((app) => {
      if (
        app.Value === resourceValue &&
        app.Key === resourceKey &&
        app.Dimension === "Tags"
      ) {
        appListData.push(app);
      }
    });
  return appListData;
};

export const getEnvName = (envId, envList) => {
  var env =
    isArrayWithLength(envList) &&
    envList.find((env) => {
      return env.ID === envId;
    });
  if (!env) {
    return envId === appConstants.DEFAULT ? appConstants.DEFAULT : "-";
  }
  return env.Name;
};

export const getResourceName = (resourceType) => {
  return resourceType ? resourceList[resourceType] : "";
};

export const getAzureResourceName = (resourceType) => {
  return resourceType && azureResourceList[resourceType]
    ? azureResourceList[resourceType]
    : resourceType;
};

export const doesTagAppWithRegionExists = (
  selectedResourceData,
  applications,
  type
) => {
  let existingRegTagApp = [];
  selectedResourceData.departmentId &&
    selectedResourceData.departmentId.forEach(
      (l) =>
        applications &&
        applications.length &&
        applications.forEach((app) => {
          if (
            app.Value === selectedResourceData.resourceValue &&
            app.Key === selectedResourceData.resourceKey &&
            app.Dimension === type
          ) {
            if (l === app.DepartmentId) {
              existingRegTagApp.push({
                regionCode: app.RegionCode,
                accountNum: app.AccountNumber,
              });
            }
          }
        })
    );
  return existingRegTagApp;
};

export const flattenOrgHeirarchy = (array = [], parentId = "") => {
  let result = [];
  array.forEach(function (a) {
    result.push({ ...a, parentId, id: a.Id });
    if (Array.isArray(a.Children)) {
      result = result.concat(flattenOrgHeirarchy(a.Children, a.Id));
    }
  });
  return result;
};

export const GET_APPLICATION_RESOURCES = "GET_APPLICATION_RESOURCES";
export const ADD_RESOURCES_APPLICATION = "ADD_RESOURCES_APPLICATION";
export const SET_APPLICATION_NAME = "SET_APPLICATION_NAME";
export const GET_APPLICATIONS_REQUEST = "GET_APPLICATIONS_REQUEST";
export const GET_APPLICATIONS_SUCCESS = "GET_APPLICATIONS_SUCCESS";
export const GET_APPLICATIONS_FAILURE = "GET_APPLICATIONS_FAILURE";
export const GET_APPLICATION_DETAILS_REQUEST =
  "GET_APPLICATION_DETAILS_REQUEST";
export const GET_APPLICATION_DETAILS_SUCCESS =
  "GET_APPLICATION_DETAILS_SUCCESS";
export const GET_APPLICATION_DETAILS_FAILURE =
  "GET_APPLICATION_DETAILS_FAILURE";
export const CREATE_APPLICATION_REQUEST = "CREATE_APPLICATION_REQUEST";
export const CREATE_APPLICATION_SUCCESS = "CREATE_APPLICATION_SUCCESS";
export const CREATE_APPLICATION_FAILURE = "CREATE_APPLICATION_FAILURE";
export const CLEAR_APPLICATION_DATA = "CLEAR_APPLICATION_DATA";
export const GET_APPLICATION_RESOURCES_REQUEST =
  "GET_APPLICATION_RESOURCES_REQUEST";
export const GET_APPLICATION_RESOURCES_SUCCESS =
  "GET_APPLICATION_RESOURCES_SUCCESS";
export const GET_APPLICATION_RESOURCES_FAILURE =
  "GET_APPLICATION_RESOURCES_FAILURE";
export const ADD_APPLICATION_RESOURCES_REQUEST =
  "ADD_APPLICATION_RESOURCES_REQUEST";
export const ADD_APPLICATION_RESOURCES_SUCCESS =
  "ADD_APPLICATION_RESOURCES_SUCCESS";
export const ADD_APPLICATION_RESOURCES_FAILURE =
  "ADD_APPLICATION_RESOURCES_FAILURE";
export const SET_APP_ENV_DATA = "SET_APP_ENV_DATA";
export const GET_BLUEPRINT_ID_REQUEST = "GET_BLUEPRINT_ID_REQUEST";
export const GET_BLUEPRINT_ID_SUCCESS = "GET_BLUEPRINT_ID_SUCCESS";
export const GET_BLUEPRINT_ID_FAILURE = "GET_BLUEPRINT_ID_FAILURE";
export const GET_APPLICATION_PARAMETERS_REQUEST =
  "GET_APPLICATION_PARAMETERS_REQUEST";
export const GET_APPLICATION_PARAMETERS_SUCCESS =
  "GET_APPLICATION_PARAMETERS_SUCCESS";
export const GET_APPLICATION_PARAMETERS_FAILURE =
  "GET_APPLICATION_PARAMETERS_FAILURE";

export const GET_APPLICATION_DIAGRAM_URL = "GET_APPLICATION_DIAGRAM_URL";

export const GET_APP_ALERTS_REQUEST = "GET_APP_ALERTS_REQUEST";
export const GET_APP_ALERTS_SUCCESS = "GET_APP_ALERTS_SUCCESS";
export const GET_APP_ALERTS_FAILURE = "GET_APP_ALERTS_FAILURE";
export const UPDATE_APPLICATION_SEARCH_KEY = "UPDATE_APPLICATION_SEARCH_KEY";

export const DELETE_APPLICATION_REQUEST = "DELETE_APPLICATION_REQUEST";
export const DELETE_APPLICATION_SUCCESS = "DELETE_APPLICATION_SUCCESS";
export const DELETE_APPLICATION_FAILURE = "DELETE_APPLICATION_FAILURE";
export const DELETE_APPLICATION_CLEAR_DATA = "DELETE_APPLICATION_CLEAR_DATA";

export const APPLICATION_ACTIONS_REQUEST = "APPLICATION_ACTIONS_REQUEST";
export const APPLICATION_ACTIONS_SUCCESS = "APPLICATION_ACTIONS_SUCCESS";
export const APPLICATION_ACTIONS_FAILURE = "APPLICATION_ACTIONS_FAILURE";
export const CLEAR_APPLICATION_ACTIONS = "CLEAR_APPLICATION_ACTIONS";


//toggle cost usage
export const UPDATE_APPLICATION_PERMISSIONS_REQUEST =
  "UPDATE_APPLICATION_PERMISSIONS_REQUEST";
export const UPDATE_APPLICATION_PERMISSIONS_SUCCESS =
  "UPDATE_APPLICATION_PERMISSIONS_SUCCESS";
export const UPDATE_APPLICATION_PERMISSIONS_FAILURE =
  "UPDATE_APPLICATION_PERMISSIONS_FAILURE";
export const RESET_APPLICATION_PERMISSIONS_DATA =
  "RESET_APPLICATION_PERMISSIONS_DATA";

export const CLEAR_APPLICATION_PARAMETERS = "CLEAR_APPLICATION_PARAMETERS";
export const GET_APPS_OPEN_OPS_ISSUES_REQUEST =
  "GET_APPS_OPEN_OPS_ISSUES_REQUEST";
export const GET_APPS_OPEN_OPS_ISSUES_SUCCESS =
  "GET_APPS_OPEN_OPS_ISSUES_SUCCESS";
export const GET_APPS_OPEN_OPS_ISSUES_FAILURE =
  "GET_APPS_OPEN_OPS_ISSUES_FAILURE";

export const UPDATE_APPLICATION_REQUEST = "UPDATE_APPLICATION_REQUEST";
export const UPDATE_APPLICATION_SUCCESS = "UPDATE_APPLICATION_SUCCESS";
export const UPDATE_APPLICATION_FAILURE = "UPDATE_APPLICATION_FAILURE";
export const CLEAR_UPDATED_APPLICATION = "CLEAR_UPDATED_APPLICATION";

export const GET_APP_DETAILS_REQUEST = "GET_APP_DETAILS_REQUEST";
export const GET_APP_DETAILS_SUCCESS = "GET_APP_DETAILS_SUCCESS";
export const GET_APP_DETAILS_FAILURE = "GET_APP_DETAILS_FAILURE";
export const CLEAR_APP_DETAILS = "CLEAR_APP_DETAILS";

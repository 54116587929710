export const convertListValueLabel = (option, valueKey, labelKey) => {
  let valuePair = [];
  if (option) {
    if (Array.isArray(option)) {
      option.map((list) => {
        return valuePair.push({
          value: valueKey ? list[valueKey] : list,
          label: labelKey ? list[labelKey] : list,
        });
      });
    } else {
      valuePair.push({
        value: valueKey ? option[valueKey] : option,
        label: labelKey ? option[labelKey] : option,
      });
    }
  }
  return valuePair;
};

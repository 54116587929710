import { createSupportTicketApi } from "../services/supportTicket";
import { SUPPORT_TICKET } from "../../../utils/action-names.json";
import { CRUDAction } from "../../../actions";
import { CREATE } from "../../../utils/action-types.json";
import i18n from "../../../config/locale/i18n";

export const createSupportTicket = (
  subject,
  message,
  url,
  featureType
) => async (dispatch) => {
  const params = {};
  if (subject) {
    params.Subject = subject;
  }
  if (message) {
    params.Message = message;
  }
  if (url) {
    params.Url = url;
  }
  if (featureType) {
    params.FeatureType = featureType;
  }

  return await CRUDAction({
    type: CREATE,
    name: SUPPORT_TICKET,
    dispatch,
    service: createSupportTicketApi,
    showToaster: true,
    params,
    failureMessage: i18n.t("common:somethingWentWrong"),
  });
};

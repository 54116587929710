import {
  UPDATE_OPS_DASHBOARD_LAST_UPDATED,
  SELECTED_LANDING_DASHBOARD,
  UPDATE_AZURE_DASHBOARD_LAST_UPDATED,
} from "../actionTypes/dashboard";
import { DASHBOARD_TYPES } from "../../../utils/app-constants.json";

import moment from "moment";

const initialState = {
  opsDashboardLastUpdated: moment.utc(),
  selectedLandingDashboard: DASHBOARD_TYPES.CLOUDOPS,
  azureDashboardLastUpdated: moment.utc(),
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_OPS_DASHBOARD_LAST_UPDATED:
      return {
        ...state,
        opsDashboardLastUpdated: moment.utc(),
      };
    case SELECTED_LANDING_DASHBOARD:
      return {
        ...state,
        selectedLandingDashboard: action.payload,
      };
    case UPDATE_AZURE_DASHBOARD_LAST_UPDATED:
      return {
        ...state,
        azureDashboardLastUpdated: moment.utc(),
      };
    default:
      return state;
  }
};

export default dashboard;

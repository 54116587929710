import {
  GET_FBP_MANAGEMENT_ACCOUNTS_REQUEST,
  GET_FBP_MANAGEMENT_ACCOUNTS_SUCCESS,
  GET_FBP_MANAGEMENT_ACCOUNTS_FAILURE,
  CLEAR_FBP_PARAMETERS,
  SET_FBP_NOTIFICATION_PARAMETER,
  CLEAR_FBP_NOTIFICATION_PARAMETER,
} from "../actionTypes/foundationalBestPractices";

const initialState = {
  isFetchingFBPManagementAccountsList: false,
  fbpManagementAccountsList: null,
  isFetchingFBPManagementAccountsListFailed: false,
  fbpManagementAccountsListLoaded: false,
  parameters: {},
  notificationParameter: [],
};

const foundationBestPracticesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FBP_MANAGEMENT_ACCOUNTS_REQUEST: {
      return {
        ...state,
        isFetchingFBPManagementAccountsList: true,
        isFetchingFBPManagementAccountsListFailed: false,
      };
    }
    case GET_FBP_MANAGEMENT_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        isFetchingFBPManagementAccountsList: false,
        fbpManagementAccountsList: action.payload,
        fbpManagementAccountsListLoaded: true,
      };
    }
    case GET_FBP_MANAGEMENT_ACCOUNTS_FAILURE: {
      return {
        ...state,
        isFetchingFBPManagementAccountsList: false,
        isFetchingFBPManagementAccountsListFailed: true,
        fbpManagementAccountsListLoaded: true,
      };
    }
    case CLEAR_FBP_PARAMETERS: {
      return {
        ...state,
        parameters: initialState.parameters,
      };
    }
    case SET_FBP_NOTIFICATION_PARAMETER:
      return {
        ...state,
        notificationParameter: action.payload,
      };
    case CLEAR_FBP_NOTIFICATION_PARAMETER:
      return {
        ...state,
        notificationParameter: initialState.notificationParameter,
      };
    default: {
      return { ...state };
    }
  }
};

export default foundationBestPracticesReducer;

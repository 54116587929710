import {
  ADD_RESOURCES_APPLICATION,
  GET_APPLICATIONS_REQUEST,
  GET_APPLICATIONS_SUCCESS,
  GET_APPLICATIONS_FAILURE,
  GET_APPLICATION_DETAILS_REQUEST,
  GET_APPLICATION_DETAILS_SUCCESS,
  GET_APPLICATION_DETAILS_FAILURE,
  CREATE_APPLICATION_REQUEST,
  CREATE_APPLICATION_SUCCESS,
  CREATE_APPLICATION_FAILURE,
  CLEAR_APPLICATION_DATA,
  GET_APPLICATION_RESOURCES_REQUEST,
  GET_APPLICATION_RESOURCES_SUCCESS,
  GET_APPLICATION_RESOURCES_FAILURE,
  ADD_APPLICATION_RESOURCES_REQUEST,
  ADD_APPLICATION_RESOURCES_SUCCESS,
  ADD_APPLICATION_RESOURCES_FAILURE,
  SET_APP_ENV_DATA,
  UPDATE_APPLICATION_SEARCH_KEY,
  GET_BLUEPRINT_ID_REQUEST,
  GET_BLUEPRINT_ID_SUCCESS,
  GET_BLUEPRINT_ID_FAILURE,
  GET_APPLICATION_PARAMETERS_REQUEST,
  GET_APPLICATION_PARAMETERS_SUCCESS,
  GET_APPLICATION_PARAMETERS_FAILURE,
  GET_APP_ALERTS_REQUEST,
  GET_APP_ALERTS_SUCCESS,
  GET_APP_ALERTS_FAILURE,
  DELETE_APPLICATION_REQUEST,
  DELETE_APPLICATION_SUCCESS,
  DELETE_APPLICATION_FAILURE,
  DELETE_APPLICATION_CLEAR_DATA,
  APPLICATION_ACTIONS_REQUEST,
  APPLICATION_ACTIONS_SUCCESS,
  APPLICATION_ACTIONS_FAILURE,
  CLEAR_APPLICATION_ACTIONS,
  GET_APPLICATION_DIAGRAM_URL,
  UPDATE_APPLICATION_PERMISSIONS_REQUEST,
  UPDATE_APPLICATION_PERMISSIONS_SUCCESS,
  UPDATE_APPLICATION_PERMISSIONS_FAILURE,
  RESET_APPLICATION_PERMISSIONS_DATA,
  CLEAR_APPLICATION_PARAMETERS,
  GET_APPS_OPEN_OPS_ISSUES_REQUEST,
  GET_APPS_OPEN_OPS_ISSUES_SUCCESS,
  GET_APPS_OPEN_OPS_ISSUES_FAILURE,
  UPDATE_APPLICATION_REQUEST,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION_FAILURE,
  CLEAR_UPDATED_APPLICATION,
  GET_APP_DETAILS_REQUEST,
  GET_APP_DETAILS_SUCCESS,
  GET_APP_DETAILS_FAILURE,
  CLEAR_APP_DETAILS,
} from "../actionTypes/application";

const initialState = {
  applicationDetails: null,
  addResourcesResponse: null,
  isFetching: false,
  applicationsLoaded: false,
  isCreating: false,
  createdAppId: null,
  applicationList: null,
  isFetchingDetails: false,
  createdApplication: false,
  applicationLoadFailure: null,
  isFetchingResources: false,
  applicationResources: null,
  isAddingResources: false,
  addedResources: false,
  envData: null,
  environmentList: [
    {
      id: "dev",
      value: "Dev",
      isSelected: false,
    },
    {
      id: "staging",
      value: "Staging",
      isSelected: false,
    },
    {
      id: "prod",
      value: "Prod",
      isSelected: false,
    },
  ],
  applicationSearchKey: "",
  isFetchingBlueprint: false,
  applicationBlueprintId: null,
  blueprintIdLoaded: false,
  blueprintIdFailed: false,
  isParametersLoading: false,
  parameters: null,
  output: null,
  parametersLoaded: false,
  appAlertsList: [],
  isFetchingAppAlerts: false,
  isAppAlertsLoaded: false,
  deletingApplication: false,
  deletedApplicationSuccess: false,
  deleteApplicationError: false,
  fetchingAppActionList: false,
  appActionList: null,
  diagramUrl: null,
  appActionListLoaded: false,
  appActionListFailed: false,
  updatingApplicationPermissions: false,
  applicationPermissionsUpdated: false,
  appParameters: null,
  appParametersFailed: false,
  fetchingDetailsFailed: false,
  appsOpenOpsIssues: null,
  appsOpenOpsIssuesFailed: false,
  fetchingappsOpenOpsIssues: false,
  isUpdatingApp: false,
  updatedApp: null,
  updatingAppFailed: false,
  appDetails: null, //Stores state from new app details endpoint
  isFetchingAppDetails: false,
  fetchingAppDetailsFailed: false,
};

const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_RESOURCES_APPLICATION:
      return {
        ...state,
        addResourcesResponse: action.payload,
      };
    case GET_APPLICATIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        applicationsLoaded: initialState.applicationsLoaded,
        applicationLoadFailure: null,
        applicationList: state.applicationList,
      };
    case GET_APPLICATIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        applicationsLoaded: true,
        applicationList: action.payload,
        applicationLoadFailure: null,
      };
    case GET_APPLICATIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        applicationsLoaded: true,
        applicationLoadFailure: true,
      };
    case UPDATE_APPLICATION_SEARCH_KEY:
      return {
        ...state,
        applicationSearchKey: action.payload,
      };
    case GET_APPLICATION_DETAILS_REQUEST:
      return {
        ...state,
        isFetchingDetails: true,
        fetchingDetailsFailed: initialState.fetchingDetailsFailed,
      };
    case GET_APPLICATION_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingDetails: false,
        applicationDetails: action.payload,
      };
    case GET_APPLICATION_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingDetails: false,
        errorMessage: action.payload,
        fetchingDetailsFailed: true,
      };
    case CREATE_APPLICATION_REQUEST:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_APPLICATION_SUCCESS:
      return {
        ...state,
        isCreating: false,
        createdApplication: true,
        createdAppId: action.payload.ID,
      };
    case CREATE_APPLICATION_FAILURE:
      return {
        ...state,
        isCreating: false,
        errorMessage: action.payload,
      };
    case ADD_APPLICATION_RESOURCES_REQUEST:
      return {
        ...state,
        isAddingResources: true,
      };
    case ADD_APPLICATION_RESOURCES_SUCCESS:
      return {
        ...state,
        isAddingResources: false,
        addedResources: true,
      };
    case ADD_APPLICATION_RESOURCES_FAILURE:
      return {
        ...state,
        isAddingResources: false,
        errorMessage: action.payload,
      };
    case CLEAR_APPLICATION_DATA:
      return {
        ...state,
        environmentList: initialState.environmentList,
        createdApplication: initialState.createdApplication,
        createdAppId: initialState.createdAppId,
        addedResources: initialState.addedResources,
        envData: initialState.envData,
        applicationBlueprintId: initialState.applicationBlueprintId,
        appActionList: initialState.appActionList,
        appActionListLoaded: initialState.appActionListLoaded,
        appActionListFailed: initialState.appActionListFailed,
      };
    case GET_APPLICATION_RESOURCES_REQUEST:
      return {
        ...state,
        isFetchingResources: true,
        applicationResources: null,
      };
    case GET_APPLICATION_RESOURCES_SUCCESS:
      return {
        ...state,
        isFetchingResources: false,
        applicationResources: action.payload,
      };
    case GET_APPLICATION_RESOURCES_FAILURE:
      return {
        ...state,
        isFetchingResources: false,
        errorMessage: action.payload,
      };
    case SET_APP_ENV_DATA:
      return {
        ...state,
        envData: action.payload,
      };
    case GET_BLUEPRINT_ID_REQUEST:
      return {
        ...state,
        isFetchingBlueprint: true,
        applicationBlueprintId: initialState.applicationBlueprintId,
        blueprintIdLoaded: initialState.blueprintIdLoaded,
        blueprintIdFailed: initialState.blueprintIdFailed,
      };
    case GET_BLUEPRINT_ID_SUCCESS:
      return {
        ...state,
        isFetchingBlueprint: false,
        applicationBlueprintId: action.payload,
        blueprintIdLoaded: true,
        blueprintIdFailed: false,
      };
    case GET_BLUEPRINT_ID_FAILURE:
      return {
        ...state,
        isFetchingBlueprint: false,
        applicationBlueprintId: false,
        blueprintIdLoaded: false,
        blueprintIdFailed: true,
      };
    case GET_APPLICATION_PARAMETERS_REQUEST:
      return {
        ...state,
        isParametersLoading: true,
        appParameters: initialState.aappParameters,
        parametersLoaded: initialState.parametersLoaded,
        appParametersFailed: initialState.appParametersFailed,
      };
    case GET_APPLICATION_PARAMETERS_SUCCESS:
      return {
        ...state,
        isParametersLoading: false,
        appParameters: action.payload,
        parameters: action.payload.Parameters,
        output: action.payload.Output,
        parametersLoaded: true,
        appParametersFailed: false,
      };
    case GET_APPLICATION_PARAMETERS_FAILURE:
      return {
        ...state,
        isParametersLoading: false,
        parametersErrorMessage: action.payload,
        parametersLoaded: false,
        appParametersFailed: true,
      };
    case CLEAR_APPLICATION_PARAMETERS:
      return {
        ...state,
        isParametersLoading: initialState.isParametersLoading,
        parametersErrorMessage: initialState.parametersErrorMessage,
        parametersLoaded: initialState.parametersLoaded,
        appParametersFailed: initialState.appParametersFailed,
        appParameters: initialState.appParameters,
        parameters: initialState.parameters,
        output: initialState.output,
      };
    case GET_APP_ALERTS_REQUEST:
      return {
        ...state,
        isFetchingAppAlerts: true,
        appAlertsList: initialState.appAlertsList,
        isAppAlertsLoaded: initialState.isAppAlertsLoaded,
      };
    case GET_APP_ALERTS_SUCCESS:
      return {
        ...state,
        isFetchingAppAlerts: false,
        appAlertsList: action.payload,
        isAppAlertsLoaded: true,
      };
    case GET_APP_ALERTS_FAILURE:
      return {
        ...state,
        isFetchingAppAlerts: false,
        appAlertsErrorMessage: action.payload,
        isAppAlertsLoaded: false,
      };
    case DELETE_APPLICATION_REQUEST:
      return {
        ...state,
        deletingApplication: true,
        deletedApplicationSuccess: initialState.deletedApplicationSuccess,
        deleteApplicationError: initialState.deleteApplicationError,
      };
    case DELETE_APPLICATION_SUCCESS:
      return {
        ...state,
        deletingApplication: initialState.deletingApplication,
        deletedApplicationSuccess: true,
      };
    case DELETE_APPLICATION_FAILURE:
      return {
        ...state,
        deletingApplication: initialState.deletingApplication,
        deletedApplicationSuccess: initialState.deletedApplicationSuccess,
        deleteApplicationError: action.payload,
      };
    case DELETE_APPLICATION_CLEAR_DATA:
      return {
        ...state,
        deletingApplication: initialState.deletingApplication,
        deletedApplicationSuccess: initialState.deletedApplicationSuccess,
        deleteApplicationError: initialState.deleteApplicationError,
      };
    case APPLICATION_ACTIONS_REQUEST:
      return {
        ...state,
        fetchingAppActionList: true,
        appActionListLoaded: initialState.appActionListLoaded,
        appActionListFailed: initialState.appActionListFailed,
      };
    case APPLICATION_ACTIONS_SUCCESS:
      return {
        ...state,
        fetchingAppActionList: false,
        appActionList: action.payload,
        appActionListLoaded: true,
      };
    case APPLICATION_ACTIONS_FAILURE:
      return {
        ...state,
        fetchingAppActionList: false,
        appActionList: action.payload,
        appActionListLoaded: true,
        appActionListFailed: true,
      };
    case CLEAR_APPLICATION_ACTIONS:
      return {
        ...state,
        fetchingAppActionList: false,
        appActionList: null,
        appActionListLoaded: false,
        appActionListFailed: false,
      };
    case GET_APPLICATION_DIAGRAM_URL:
      return {
        ...state,
        diagramUrl: action.payload,
      };
    case UPDATE_APPLICATION_PERMISSIONS_REQUEST:
      return {
        ...state,
        updatingApplicationPermissions: true,
        applicationPermissionsUpdated: false,
      };
    case UPDATE_APPLICATION_PERMISSIONS_SUCCESS:
      return {
        ...state,
        updatingApplicationPermissions: false,
        applicationPermissionsUpdated: true,
      };
    case UPDATE_APPLICATION_PERMISSIONS_FAILURE:
      return {
        ...state,
        updatingApplicationPermissions: false,
        applicationPermissionsUpdated: false,
      };
    case RESET_APPLICATION_PERMISSIONS_DATA:
      return {
        ...state,
        updatingApplicationPermissions:
          initialState.updatingApplicationPermissions,
        applicationPermissionsUpdated:
          initialState.applicationPermissionsUpdated,
      };
    case GET_APPS_OPEN_OPS_ISSUES_REQUEST:
      return {
        ...state,
        fetchingappsOpenOpsIssues: true,
      };
    case GET_APPS_OPEN_OPS_ISSUES_SUCCESS:
      return {
        ...state,
        fetchingappsOpenOpsIssues: false,
        appsOpenOpsIssues: action.payload,
      };
    case GET_APPS_OPEN_OPS_ISSUES_FAILURE:
      return {
        ...state,
        fetchingappsOpenOpsIssues: false,
        appsOpenOpsIssuesFailed: action.payload,
      };
    case UPDATE_APPLICATION_REQUEST:
      return {
        ...state,
        isUpdatingApp: true,
        updatedApp: initialState.updatedApp,
      };
    case UPDATE_APPLICATION_SUCCESS:
      return {
        ...state,
        isUpdatingApp: false,
        updatedApp: action.payload,
      };
    case UPDATE_APPLICATION_FAILURE:
      return {
        ...state,
        isUpdatingApp: false,
      };
    case CLEAR_UPDATED_APPLICATION:
      return {
        ...state,
        updatedApp: null,
      };
    case GET_APP_DETAILS_REQUEST:
      return {
        ...state,
        isFetchingAppDetails: true,
        fetchingAppDetailsFailed: false,
      };
    case GET_APP_DETAILS_SUCCESS:
      return {
        ...state,
        isFetchingAppDetails: false,
        appDetails: action.payload,
      };
    case GET_APP_DETAILS_FAILURE:
      return {
        ...state,
        isFetchingAppDetails: false,
        fetchingAppDetailsFailed: true,
      };
    case CLEAR_APP_DETAILS:
      return {
        ...state,
        isFetchingAppDetails: initialState.isFetchingAppDetails,
        appDetails: initialState.appDetails,
        fetchingAppDetailsFailed: initialState.fetchingAppDetailsFailed,
      };
    default:
      return {
        ...state,
      };
  }
};

export default applicationReducer;
